import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import "react-phone-input-2/lib/material.css";

const PREFIX = "TimeField";

const classes = {
  textField: `${PREFIX}-textField`,
  input: `${PREFIX}-input`,
  iconsWrapper: `${PREFIX}-iconsWrapper`,
  checkbox: `${PREFIX}-checkbox`,
  timeWrapper: `${PREFIX}-timeWrapper`,
  helperText: `${PREFIX}-helperText`,
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`&.${classes.textField}`]: {
    [theme.breakpoints.down("md")]: {
      width: "48%",
    },
  },

  [`& .${classes.input}`]: {
    width: 100,
    height: 57,
    "& > input:invalid": {
      color: "#f44336",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  [`& .${classes.iconsWrapper}`]: {
    width: 96,
    textAlign: "center",
    margin: "-16px 0",
  },

  [`& .${classes.checkbox}`]: {
    margin: "-16px 0",
  },

  [`& .${classes.timeWrapper}`]: {
    minWidth: 100,
    position: "relative",
    top: 4,
  },

  [`& .${classes.helperText}`]: {
    marginLeft: 6,
  },
}));

dayjs.extend(customParseFormat);

const types = {
  startTime: {
    label: "Start Time",
    helperText: "Send alert from",
  },
  endTime: {
    label: "End Time",
    helperText: "Send alert until",
  },
};

const TimeField = ({ id, onInputChange, value }) => {
  return (
    <StyledTextField
      name={id}
      id={id}
      label={types[id].label}
      variant="outlined"
      size="small"
      value={value}
      onChange={onInputChange}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      helperText={types[id].helperText}
      InputProps={{
        className: classes.input,
      }}
      inputProps={{
        "data-cy": id,
        pattern: "((1[0-2]|0?[1-9]):([0-5][0-9]) ([AP][M]))",
      }}
      className={classes.textField}
    />
  );
};
export default TimeField;

TimeField.propTypes = {
  id: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
