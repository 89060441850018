import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  height: 208,
  margin: "0 60px",
  justifyContent: "center",

  "@media print": {
    boxShadow: "none",
    overflow: "visible",
  },
}));

const ErrorView = ({ messageKey }) => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <Typography variant="h5" align="center">
        {t(messageKey)}
      </Typography>
    </StyledBox>
  );
};

export default ErrorView;

ErrorView.propTypes = {
  messageKey: PropTypes.string,
};

ErrorView.defaultProps = {
  messageKey: "card-loading-error-message",
};
