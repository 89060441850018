import { useMutation, useQueryClient } from "react-query";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { addRecipient } from "../../../services/alerts";
import useRecipientManagement from "../../../utils/useRecipientManagement";
import Card from "../../../../CommonComponents/UI/Card/Card";
import CardActions from "../../../../CommonComponents/UI/Card/CardActions";
import CardContent from "../../../../CommonComponents/UI/Card/CardContent";
import TimeField from "./TimeField";
import PhoneField from "./PhoneField";
import days from "./days";

const PREFIX = "AddRecipients";

const classes = {
  container: `${PREFIX}-container`,
  input: `${PREFIX}-input`,
  textField: `${PREFIX}-textField`,
  gridItem: `${PREFIX}-gridItem`,
  timeWrapper: `${PREFIX}-timeWrapper`,
  phoneInput: `${PREFIX}-phoneInput`,
  divider: `${PREFIX}-divider`,
  daysContainer: `${PREFIX}-daysContainer`,
  cardActions: `${PREFIX}-cardActions`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "flex-start",
  },

  [`& .${classes.input}`]: {
    height: 57,
  },

  [`& .${classes.textField}`]: {
    height: 57,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 260,
    },
  },

  [`& .${classes.gridItem}`]: {
    marginBottom: theme.spacing(5),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(6),
      width: "auto",
    },
  },

  [`& .${classes.timeWrapper}`]: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  [`& .${classes.phoneInput}`]: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 260,
    },
  },

  [`& .${classes.divider}`]: {
    marginTop: 10,
  },

  [`& .${classes.daysContainer}`]: {
    maxHeight: 200,
  },

  [`& .${classes.cardActions}`]: {
    padding: "20px 28px",
  },
}));

const AddRecipients = ({ setToast, availableAlerts }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate: createRecipient } = useMutation(addRecipient, {
    onSuccess: (response) => {
      queryClient.setQueryData("alerts/recipients", (oldData) => {
        return [...oldData, response];
      });
      setToast({
        show: true,
        type: "success",
        message: `${response.name} successfully added`,
      });
    },
    onError: () =>
      setToast({
        show: true,
        type: "error",
        message: "We had a problem updating the user, try again",
      }),
  });

  const {
    recipientState,
    reset,
    toggleAlerts,
    toggleDays,
    onInputChange,
    onPhoneChange,
    onPhoneBlur,
    convertToISOTime,
  } = useRecipientManagement();

  const {
    alerts_enabled,
    days_enabled,
    name,
    phone_number,
    startTime,
    endTime,
  } = recipientState;

  const onCreate = () => {
    createRecipient(convertToISOTime(), {
      onSuccess: reset,
    });
  };

  return (
    <Root>
      <Card title={t("add-recipient")}>
        <CardContent>
          <Grid item md={9}>
            <Grid container className={classes.container}>
              <Grid item className={classes.gridItem}>
                <TextField
                  name="name"
                  placeholder={t("enter-a-name")}
                  label={t("name")}
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={onInputChange}
                  inputProps={{
                    "aria-label": "name",
                    className: classes.input,
                    "data-cy": "name-field",
                  }}
                  InputProps={{
                    className: classes.textField,
                  }}
                />
              </Grid>
              <Grid
                item
                className={`${classes.gridItem} ${classes.phoneInput}`}
              >
                <PhoneField
                  onPhoneChange={onPhoneChange}
                  onPhoneBlur={onPhoneBlur}
                  value={phone_number}
                />
              </Grid>
              <Grid
                item
                className={`${classes.gridItem} ${classes.timeWrapper}`}
              >
                <TimeField
                  id="startTime"
                  value={startTime}
                  onInputChange={onInputChange}
                />
                <TimeField
                  id="endTime"
                  value={endTime}
                  onInputChange={onInputChange}
                />
              </Grid>
            </Grid>

            <Box mt={3} mb={4}>
              <span>Alert this user about:</span>
              <Box>
                {Object.entries(availableAlerts).map(
                  ([key, { value, label, translationKey }]) => (
                    <FormControlLabel
                      labelPlacement="end"
                      key={key}
                      control={
                        <Checkbox
                          name={value}
                          inputProps={{
                            "data-cy": value,
                          }}
                          color="primary"
                          className={classes.checkbox}
                          checked={alerts_enabled.includes(value)}
                          onChange={toggleAlerts}
                        />
                      }
                      label={t(translationKey)}
                    />
                  )
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography>Send Alerts:</Typography>
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  name="everyday"
                  inputProps={{
                    "data-cy": "everyday",
                  }}
                  color="primary"
                  className={classes.checkbox}
                  checked={days_enabled.includes("everyday")}
                  onChange={toggleDays}
                />
              }
              label="Everyday"
            />
            <Divider className={classes.divider} />
            <Grid
              container
              className={classes.daysContainer}
              direction="column"
            >
              {Object.keys(days).map((key) => (
                <Grid item xs={6} key={key}>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        name={key}
                        inputProps={{
                          "data-cy": key,
                        }}
                        color="primary"
                        className={classes.checkbox}
                        disabled={days_enabled.includes("everyday")}
                        checked={days_enabled.includes(key)}
                        onChange={toggleDays}
                      />
                    }
                    label={days[key].long}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            variant="contained"
            disabled={!phone_number || !name}
            onClick={onCreate}
          >
            + {t("add-recipient")}
          </Button>
        </CardActions>
      </Card>
    </Root>
  );
};

export default AddRecipients;

AddRecipients.propTypes = {
  setToast: PropTypes.func.isRequired,
  availableAlerts: PropTypes.object.isRequired,
};
