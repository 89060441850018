import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Toast from "../../../CommonComponents/UI/Toast";
import TimeField, { isValidTime } from "./TimeField";
import TimeFieldDisplay from "./TimeFieldDisplay";
import Button from "./Button";

import Schedule from "@mui/icons-material/Schedule";
import { ERROR_ALREADY_ADDED, ERROR_INVALID_TIME } from "./errorMessages";

const PREFIX = "ScheduleSettingsView";

const classes = {
  warningText: `${PREFIX}-warningText`,
  SSVPenIcon: `${PREFIX}-SSVPenIcon`,
  SSVScheduleTitle: `${PREFIX}-SSVScheduleTitle`,
  SSVScheduleTitleText: `${PREFIX}-SSVScheduleTitleText`,
  SSVScheduleInput: `${PREFIX}-SSVScheduleInput`,
  SSVScheduleTimeFieldInput: `${PREFIX}-SSVScheduleTimeFieldInput`,
  SSVScheduleDisplay: `${PREFIX}-SSVScheduleDisplay`,
  SSVScheduleTimeFieldDisplay: `${PREFIX}-SSVScheduleTimeFieldDisplay`,
};

const Root = styled("div")(() => ({
  [`& .${classes.warningText}`]: {
    color: "#ff5d5a",
    fontSize: 16,
  },

  [`& .${classes.SSVPenIcon}`]: {
    display: "flex",
    height: 38,
    width: 38,
    fontSize: 38,
  },

  [`& .${classes.SSVScheduleTitle}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 24,
  },

  [`& .${classes.SSVScheduleTitleText}`]: {
    fontSize: 20,
    marginLeft: 12,
    color: "#595959",
  },

  [`& .${classes.SSVScheduleInput}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    marginBottom: 40,
  },

  [`& .${classes.SSVScheduleTimeFieldInput}`]: {
    width: "calc(25% - 36)",
    marginRight: 36,
    minWidth: 120,
  },

  [`& .${classes.SSVScheduleDisplay}`]: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  [`& .${classes.SSVScheduleTimeFieldDisplay}`]: {
    flex: "1 1 calc(25% - 36px)",
    marginRight: 36,
    marginBottom: 16,
    minWidth: 88,
    "&:nth-child(4n)": {
      marginRight: 0,
    },
  },
}));

const ScheduleIcon = styled(() => (
  <Schedule htmlColor={"#999999"} fontSize="inherit" />
))({});

const addTime = (times, newTime) => {
  const found = times.find((time) => time.start === newTime.start);
  if (found) return times;
  return [...times, newTime];
};

const ScheduleSettingsView = ({ initialTimes, onChange, id }) => {
  const [currentTime, setCurrentTime] = useState({ start: "0:00 am" });
  const { t } = useTranslation();

  let times = initialTimes;

  const [doFocus, setDoFocus] = useState(false);

  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  const [errorState, setErrorState] = useState({
    hasError: false,
    errorMessage: "",
  });

  const createError = (message) =>
    setErrorState({ hasError: true, errorMessage: message });

  const removeError = () =>
    setErrorState({ hasError: false, errorMessage: "" });

  const onSaveClick = () => {
    removeError();
    if (!currentTime.start || !isValidTime(currentTime.start)) {
      createError(ERROR_INVALID_TIME);
      return;
    }
    if (times.find((time) => time.start === currentTime.start)) {
      createError(ERROR_ALREADY_ADDED);
      return;
    }
    const updated = addTime(times, currentTime);
    onChange({ updatedTimes: updated });
    setCurrentTime({ start: "0:00 am" });
    setDoFocus(true);

    setToast({
      show: true,
      type: "success",
      message: t("successfully-added"),
    });
  };

  // want to keep a 4 column layout
  const remainingslots = 4 - (times.length % 4);
  const disableEntry = remainingslots === 0;

  return (
    <Root>
      <div className={classes.SSVScheduleTitle} data-cy="main-schedule">
        <div className={classes.SSVPenIcon}>
          <ScheduleIcon />
        </div>
        <span className={classes.SSVScheduleTitleText}>
          {t("scheduled-times")}
        </span>
      </div>
      <div className={classes.SSVScheduleInput}>
        <div className={classes.SSVScheduleTimeFieldInput}>
          <TimeField
            afterRender={(inputRef) => {
              if (doFocus) {
                inputRef.current.focus();
                setDoFocus(false);
              }
            }}
            error={errorState.hasError}
            disabled={disableEntry}
            value={currentTime.start}
            onChange={(event, value) => {
              setCurrentTime({ start: value });
            }}
          />
          {errorState.hasError ? (
            <span className={classes.WarningText}>
              {errorState.errorMessage}
            </span>
          ) : (
            ""
          )}
        </div>
        <Button
          data-thirdparty={`schedule-settings-button-${id}`}
          data-cy={`schedule-settings-button-${id}`}
          disabled={disableEntry}
          onClick={onSaveClick}
        >
          {t("add-time")}
        </Button>
      </div>
      <div className={classes.SSVScheduleDisplay}>
        {times.map(({ start }, index) => {
          return (
            <div className={classes.SSVScheduleTimeFieldDisplay} key={start}>
              <TimeFieldDisplay
                time={start}
                tag={`schedule-settings-button-remove-${id}-${index}`}
                onClick={() => {
                  const updated = times.filter(
                    (time) => time && time.start !== start
                  );
                  onChange({ updatedTimes: updated });
                }}
              />
            </div>
          );
        })}
        {Array.from(Array(remainingslots)).map((_, index) => {
          return (
            <div className={classes.SSVScheduleTimeFieldDisplay} key={index}>
              <TimeFieldDisplay />
            </div>
          );
        })}
      </div>
      <Toast toast={toast} closeToast={closeToast} />
    </Root>
  );
};

export default ScheduleSettingsView;
