import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowForward from "@mui/icons-material/ArrowForwardSharp";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LineChart from "./LineChart";
import TimeBudgetIcon from "../../../../CommonComponents/Icons/TimeBudget";
import DonutChartTile from "../../../../CommonComponents/UI/DonutChartTile";
import { getHerdProductivityTrends } from "../../../services/trends";
import kpiInfo from "../../../utils/kpiInfo";
import theme, { colors } from "../../../../CommonComponents/UI/theme";
import useMediaQuery from "../../../../CommonComponents/utils/useMediaQuery";

const useStyles = makeStyles(() => ({
  chartSection: {
    position: "relative",
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      minHeight: 400,
      marginTop: -12,
      marginBottom: 25,
    },
  },
  chart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "calc(100% - 70px)",
    marginTop: 10,
    "@media print": {
      padding: "10px 0 0 0",
      boxShadow: "none",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    position: "relative",
    "@media print": {
      boxShadow: "none",
      marginLeft: 700,
      justifyContent: "flex-start",
      marginTop: -75,
    },
  },
  checkboxSection: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  checkboxWrapper: {
    display: "flex",
    flexDirection: "column",
    width: 176,
    padding: "0 0 10px 6px",
    [theme.breakpoints.up("md")]: {
      padding: "0 30px 0 20px",
      borderRight: `1px solid ${colors.mercuryGray}`,
      "&:first-of-type": {
        paddingLeft: 0,
        width: 156,
      },
      "&:last-of-type": {
        borderRight: "none",
      },
    },
  },
  arrowWrapper: {
    margin: 0,
    fontSize: 20,
    lineHeight: 1,
  },
  formControl: {
    margin: 0,
    alignItems: "flex-start",
  },
  label: {
    color: colors.labelGray,
    fontSize: 14,
  },
  checkbox: {
    padding: 0,
    alignSelf: "flex-start",
  },
  arrow: {
    verticalAlign: "middle",
    margin: "0 5px 0 -5px",
  },
  text: {
    fontSize: 14,
  },
  span: {
    display: "inline-block",
  },
}));

const allGroups = "All Groups";

const KPICharts = ({ selectedGroup, selectedPeriod }) => {
  const classes = useStyles();
  const chartSectionRef = useRef(classes.chartSection);
  const { t } = useTranslation();
  let isLargeScreen = useMediaQuery(
    `(min-width: ${theme.breakpoints.values.md}px)`
  );

  const [checkboxes, setCheckboxes] = useState({
    feedbunk: true,
    lying: true,
    standing: true,
    emptyPen: true,
    uncategorized: true,
  });

  const [disabled, setDisabled] = useState({
    feedbunk: false,
    lying: false,
    standing: false,
    emptyPen: false,
    uncategorized: false,
  });

  const { data } = useQuery(
    ["herdProductivityTrends", { period: selectedPeriod }],
    getHerdProductivityTrends
  );

  const trends =
    selectedGroup === allGroups
      ? data.summaries.farm
      : data.summaries.groups.find((summary) => summary.group === selectedGroup)
          ?.summary;

  const currentTrends = trends.map((trend) => {
    if (checkboxes[trend.kpi]) {
      return trend;
    }
    return { ...trend, raw_value: 0 };
  });

  useEffect(() => {
    const numSelected = Object.values(checkboxes).reduce(
      (acc, current) => acc + current,
      0
    );

    // If there's only one checked box left, disable it to prevent an empty chart
    if (numSelected === 1) {
      const checkedKey = Object.keys(checkboxes).find((key) => checkboxes[key]);

      setDisabled((items) => ({
        ...items,
        [checkedKey]: true,
      }));
    }

    // Enable all the checkboxes again
    if (numSelected === 2) {
      setDisabled({
        feedbunk: false,
        lying: false,
        standing: false,
        emptyPen: false,
        uncategorized: false,
      });
    }
  }, [checkboxes]);

  const toggleCheckbox = (e) => {
    setCheckboxes((checkboxes) => ({
      ...checkboxes,
      [e.target.id]: !checkboxes[e.target.id],
    }));
  };

  return (
    <Grid
      item
      container
      spacing={3}
      ref={chartSectionRef}
      className={classes.chartSection}
    >
      <Grid item xs={12} md={8}>
        <section className={classes.checkboxSection}>
          {trends.filter((trend) => trend.kpi !== "uncategorized").map(({ difference, kpi, label, increase, value }) => {
            const direction = increase ? "up" : "down";
            const textColor = kpiInfo[kpi][direction];
            return (
              <div key={kpi} className={classes.checkboxWrapper}>
                {isLargeScreen ? (
                  <FormControlLabel
                    labelPlacement="bottom"
                    key={kpi}
                    classes={{
                      root: classes.formControl,
                      label: classes.label,
                    }}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        style={{
                          color: disabled[kpi] ? "#BDBDBD" : kpiInfo[kpi].color,
                        }}
                        disabled={disabled[kpi]}
                        name={label}
                        id={kpi}
                        checked={checkboxes[kpi]}
                        onChange={toggleCheckbox}
                      />
                    }
                    label={t(kpiInfo[kpi].translationKey)}
                  />
                ) : (
                  <div> {t(kpiInfo[kpi].translationKey)} </div>
                )}

                <div className={classes.arrowWrapper} data-cy={`${kpi}-value`}>
                  {increase !== null && (
                    <ArrowForward
                      className={classes.arrow}
                      style={{
                        color: textColor,
                        transform: increase
                          ? "rotate(-45deg)"
                          : "rotate(45deg)",
                      }}
                    />
                  )}

                  <Typography variant="h5" className={classes.span}>
                    {value}
                  </Typography>
                </div>
                {increase !== null && (
                  <Typography
                    className={classes.text}
                    style={{
                      color: textColor,
                    }}
                  >
                    {difference}
                  </Typography>
                )}
              </div>
            );
          })}
        </section>
        {isLargeScreen && (
          <Paper elevation={0} classes={{ root: classes.chart }}>
            <LineChart
              selectedGroup={selectedGroup}
              selectedPeriod={selectedPeriod}
              checkboxes={checkboxes}
            />
          </Paper>
        )}
      </Grid>
      {isLargeScreen && (
        <Grid
          item
          xs={12}
          md={4}
          ref={chartSectionRef}
          className={classes.chartSection}
        >
          <Paper elevation={0} classes={{ root: classes.paper }}>
            <DonutChartTile
              averages={currentTrends}
              config={{
                lying: { color: kpiInfo.lying.color, order: 1 },
                standing: { color: kpiInfo.standing.color, order: 2 },
                feedbunk: { color: kpiInfo.feedbunk.color, order: 3 },
                emptyPen: { color: kpiInfo.emptyPen.color, order: 4 },
                uncategorized: { color: kpiInfo.uncategorized.color, order: 5 },
              }}
              icon={TimeBudgetIcon}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default KPICharts;
