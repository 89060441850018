import theme, { colors } from "../../UI/theme";
import { flexRowMixIn, flexColumnMixIn } from "./cssMixins";

export const insightsOverviewCssMixIn = {
  wrapper: {
    ...flexColumnMixIn,
    height: "100%",
    background: colors.whiteSmoke,
    padding: "0",
    borderLeftWidth: "4px",
    borderLeftStyle: "solid",
    borderLeftColor: colors.gray88,
    width: "30%",
    [theme.breakpoints.up("xs")]: {
      minWidth: "0",
      padding: "0",
      borderLeft: "none",
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      borderLeftColor: colors.gray88,
      display: "flex",
      width: "40%",
    },
    [theme.breakpoints.up("xl")]: {
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      borderLeftColor: colors.gray88,
      display: "flex",
      width: "30%",
    },
  },
  print: {
    "@media print": {
      display: "block",
      width: "100%",
    },
  },
};

export const insightsCssMixIn = {
  insights: {
    ...flexRowMixIn,
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
};
