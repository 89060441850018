import axios from "axios";

export async function getHerdProductivityKpis({ queryKey }) {
  const [_, params] = queryKey;
  const response = await axios.get(
    `${window._env_.API_URL}/private/herd-productivity/kpis`,
    {
      params,
    }
  );
  return response.data;
}
