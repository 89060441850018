import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "../../../CommonComponents/UI/Table";
import Link from "../../../CommonComponents/UI/Link";
import Switch from "../../../CommonComponents/UI/Switch";
import { useRouteMatch } from "react-router-dom";
import { getTimeForDisplay } from "../../../CommonComponents/utils/time";
import Grid from "@mui/material/Grid";
import { colors } from "../../../CommonComponents/UI/theme";

const PREFIX = "Pens";

const classes = {
  content: `${PREFIX}-content`,
  table: `${PREFIX}-table`,
  highlight: `${PREFIX}-highlight`,
  highlightLabel: `${PREFIX}-highlightLabel`,
  labelContainer: `${PREFIX}-labelContainer`,
  problem: `${PREFIX}-problem`,
  tableToolbar: `${PREFIX}-tableToolbar`,
  tablePeriod: `${PREFIX}-tablePeriod`,
  link: `${PREFIX}-link`,
  linkCell: `${PREFIX}-linkCell`,
  switch: `${PREFIX}-switch`,
};
const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 900,
    marginTop: 16,
    "@media print": {
      marginTop: 325,
    },
  },

  [`& .${classes.highlight}`]: {
    color: "red",
    [`& .${classes.problem}`]: {
      backgroundColor: colors.tableProblem,
      color: colors.white,
    },
  },

  [`& .${classes.problem}`]: {},

  [`& .${classes.highlightLabel}`]: {
    fontSize: 12,
  },

  [`& .${classes.labelContainer}`]: {
    [theme.breakpoints.up("md")]: {
      marginTop: 42,
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.link}`]: {
    display: "inline-block",
    padding: "5px 0",
    width: "100%",
    height: "100%",
    paddingLeft: 8,
    [theme.breakpoints.up("xxl")]: {
      padding: 16,
    },
    "@media print": {
      color: colors.black,
    },
  },

  [`& .${classes.linkCell}`]: {
    padding: 0,
  },
}));

const sort = (key) => (array, order) => {
  array.sort((a, b) => {
    if (order === "asc") {
      return a[key].props.text.localeCompare(b[key].props.text, "en", {
        sensitivy: "base",
        numeric: true,
      });
    }
    return b[key].props.text.localeCompare(a[key].props.text, "en", {
      sensitivy: "base",
      numeric: true,
    });
  });
  return array;
};

const Wrap = (kpi, url) => {
  const isProblemCell =
    typeof kpi.target_reached === "boolean" && !kpi.target_reached;
  const linkClass = isProblemCell
    ? `${classes.link} ${classes.problem}`
    : classes.link;
  return <Link className={linkClass} text={kpi.value || kpi} url={url} />;
};

const generateConversion = (path) => (pen) => {
  const {
    name,
    group,
    id,
    lyingTime,
    emptyPen,
    feedbunk: feedbunkTime,
    standing: standingTime,
  } = pen;

  const lyingInBed = getTimeForDisplay(lyingTime.value);

  const outOfPen = getTimeForDisplay(emptyPen.value);

  const standing = getTimeForDisplay(standingTime.value);

  const feedbunk = getTimeForDisplay(feedbunkTime.value);

  return {
    penName: Wrap(name, `${path}/pens/${id}/lying`),
    key: id,
    group: group,
    lyingInBed: Wrap(
      { value: lyingInBed, target_reached: lyingTime.target_reached },
      `${path}/pens/${id}/lying`
    ),
    outOfPen: Wrap(
      { value: outOfPen, target_reached: emptyPen.target_reached },
      `${path}/pens/${id}/out-of-pen`
    ),
    standing: Wrap(
      { value: standing, target_reached: standingTime.target_reached },
      `${path}/pens/${id}/standing`
    ),
    feedbunkTime: Wrap(
      { value: feedbunk, target_reached: feedbunkTime.target_reached },
      `${path}/pens/${id}/feedbunk`
    ),
  };
};

const Pens = ({ data }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [shouldHighlightPens, setShouldHighlightPens] = useState(false);
  const convertForDisplay = generateConversion(match.path);
  const rows = data.map(convertForDisplay);

  const toggleMissedTargets = () => {
    setShouldHighlightPens((shouldHighlightPens) => !shouldHighlightPens);
  };

  const headers = [
    {
      id: "penName",
      label: t("pens-capitalised"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("penName"),
    },
    {
      id: "group",
      label: t("groups-capitalised"),
    },
    {
      id: "feedbunkTime",
      label: t("feedbunk-time"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("feedbunkTime"),
    },
    {
      id: "lyingInBed",
      label: t("lying-in-bed"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("lyingInBed"),
    },
    {
      id: "standing",
      label: t("standing-time"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("standing"),
    },
    {
      id: "outOfPen",
      label: t("out-of-pen"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("outOfPen"),
    },
  ];

  return (
    <StyledGrid>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={6} className={classes.labelContainer}>
          <FormControlLabel
            labelPlacement="start"
            classes={{ label: classes.highlightLabel }}
            style={{ marginLeft: 0 }}
            data-cy="missed-targets-toggle"
            control={
              <Switch
                checked={shouldHighlightPens}
                name="Targets"
                onChange={toggleMissedTargets}
              />
            }
            label={t("highlight-missed-targets")}
          />
        </Grid>
      </Grid>
      <Table
        data={{ headers, rows }}
        defaultOrderBy="penName"
        className={classNames(classes.table, {
          [classes.highlight]: shouldHighlightPens,
        })}
      />
    </StyledGrid>
  );
};

export default Pens;
