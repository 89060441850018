import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import LoadingSpinner from "../LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
}));

const OpsPenDetailsSpinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <LoadingSpinner />
    </div>
  );
};

export default OpsPenDetailsSpinner;
