import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const PREFIX = "Card";

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

const StyledMuiCard = styled(MuiCard)(({ theme }) => ({
  [`&.${classes.card}`]: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    minWidth: 290,
  },

  [`& .${classes.title}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(5),
  },
}));

const Card = ({ children, title }) => {
  return (
    <StyledMuiCard className={classes.card}>
      <Typography variant="h5" className={classes.title} data-cy="card-title">
        {title}
      </Typography>
      <Divider />
      {children}
    </StyledMuiCard>
  );
};

export default Card;

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
