import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";

import classNames from "classnames";
import theme from "./theme";
import TableFilterLabel from "./TableFilterLabel";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      "&:before": {
        content: '""',
        position: "absolute",
        right: -10,
        top: 12,
        zIndex: 1,
        background: "linear-gradient(to right, transparent, white)",
        height: "calc(100% - 12px)",
        width: 80,
        pointerEvents: "none",
      },
    },
    "@media print": {
      "&:before": {
        content: "none",
      },
    },
  },
  cell: {
    padding: "2px 8px",
    whiteSpace: "nowrap",
    height: 34,
    [theme.breakpoints.up("xxl")]: {
      padding: 16,
    },
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 0,
      "&:first-child": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: 135,
      },
      "&:nth-child(2)": {
        paddingLeft: 8,
      },
    },
  },
  headCell: {
    height: 34,
    [theme.breakpoints.down("xxl")]: {
      padding: "8px 0px 8px 8px",
    },
  },
  stickyCell: {
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      zIndex: 100,
      left: 0,
      backgroundColor: "#fafafa",
      boxShadow: "inset -7px 0 6px -7px rgba(0,0,0,0.4)",
    },
    "@media print": {
      boxShadow: "none",
    },
  },
}));

const Table = ({ data, defaultOrderBy, className }) => {
  const { headers, rows } = data;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [filterOption, setFilterOption] = React.useState(null);

  const onRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onRequestFilter = (column) => (selectedOption) => {
    setFilterOption({ column, selectedOption });
  };

  const onRequestClearFilter = () => setFilterOption(null);

  const filteredRows =
    filterOption !== null
      ? rows.filter(
          (row) => row[filterOption.column] === filterOption.selectedOption
        )
      : rows;

  const sortedRows = orderBy
    ? headers
        .find((header) => header.id === orderBy)
        .sortFn(filteredRows, order)
    : rows;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid container item classes={{ item: classes.tableContainer }}>
          <Grid item xs={12} style={{ overflowX: "auto", paddingBottom: 6 }}>
            <MuiTable className={className}>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={header.id}
                      align={header.align}
                      className={classNames(
                        classes.headCell,
                        header.headClass,
                        {
                          [classes.stickyCell]: index === 0,
                        }
                      )}
                      sortDirection={orderBy === header.id ? order : false}
                    >
                      {header.sortable ? (
                        <TableSortLabel
                          active={orderBy === header.id}
                          direction={orderBy === header.id ? order : "asc"}
                          onClick={() => onRequestSort(header.id)}
                        >
                          {header.label}
                        </TableSortLabel>
                      ) : header.filterable ? (
                        <TableFilterLabel
                          text={header.label}
                          onSelected={onRequestFilter(header.id)}
                          onClear={onRequestClearFilter}
                          options={[
                            ...new Set(rows.map((row) => row[header.id])),
                          ]}
                        />
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((row) => {
                  return (
                    <TableRow
                      key={row.key}
                      onClick={row.onClick}
                      data-cy="table-row"
                    >
                      {headers.map((header, index) => (
                        <TableCell
                          align={header.align}
                          className={classNames(
                            header.cellClass,
                            classes.cell,
                            {
                              [classes.stickyCell]: index === 0,
                            }
                          )}
                          key={`${header.id}-${row.key}`}
                        >
                          {row[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          </Grid>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Table;

Table.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
      })
    ).isRequired,
  }),
  defaultOrderBy: PropTypes.string,
};

Table.defaultProps = {
  defaultOrderBy: null,
};
