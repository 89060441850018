import React from "react";
import { useTranslation } from "react-i18next";
import ArrowForward from "@mui/icons-material/ArrowForwardSharp";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { colors } from "../../CommonComponents/UI/theme";
import Typography from "@mui/material/Typography";
import KPITable from "./KPITable";

const PREFIX = "KPI";

const classes = {
  arrow: `${PREFIX}-arrow`,
  label: `${PREFIX}-label`,
  trendSection: `${PREFIX}-trendSection`,
  text: `${PREFIX}-text`,
  table: `${PREFIX}-table`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0 8px 0 8px",
  borderLeft: `1px solid ${colors.mercuryGray}`,
  flexBasis: "50%",
  flexGrow: 1,

  [theme.breakpoints.down("sm")]: {
    "&:nth-of-type(2n+1)": {
      paddingLeft: 0,
      borderLeft: "none",
    },
    "&:nth-of-type(1), &:nth-of-type(2)": {
      marginBottom: 30,
    },
  },
  [theme.breakpoints.up("sm")]: {
    flexBasis: 0,
    "&:first-of-type": {
      paddingLeft: 0,
      borderLeft: "none",
    },
  },

  [`& .${classes.arrow}`]: {
    verticalAlign: "middle",
    fontSize: 20,
    margin: "0 0 0 -4px",
    [theme.breakpoints.up("md")]: {
      fontSize: 26,
    },
  },

  [`& .${classes.label}`]: {
    color: colors.labelGray,
    fontSize: 14,
  },

  [`& .${classes.text}`]: {
    fontSize: 14,
    display: "inline-block",
    marginLeft: 5,
  },
  [`& .${classes.trendSection}`]: {
    height: 64,
  },
  [`& .${classes.table}`]: {
    marginTop: 16,
  },
}));

const KPI = ({ kpiInfo, trend }) => {
  const { t } = useTranslation();
  const { difference, kpi, increase, value, worst_pens: worstPens } = trend;
  const direction = increase ? "up" : "down";
  const textColor = kpiInfo[kpi][direction];

  return (
    <StyledBox>
      <div className={classes.trendSection}>
        <div className={classes.label}>{t(kpiInfo[kpi].translationKey)}</div>

        <Typography variant="h5" data-cy={`${kpi}-value`}>
          {increase !== null && (
            <ArrowForward
              className={classes.arrow}
              style={{
                color: textColor,
                transform: increase ? "rotate(-45deg)" : "rotate(45deg)",
              }}
            />
          )}

          {value}
          {increase !== null && (
            <span
              className={classes.text}
              style={{
                color: textColor,
              }}
            >
              {difference}
            </span>
          )}
        </Typography>
      </div>
      <KPITable worstPens={worstPens} kpi={kpi} />
    </StyledBox>
  );
};

export default KPI;
