const overviewInitState = {
  dates: "",
  summaries: [],
  trends: {},
};

const allGroups = "All Groups";

export const initialOpsState = {
  last7Days: { ...overviewInitState },
  last30Days: { ...overviewInitState },
  selectedDateRange: "last7Days",
  selectedPrintSection: {
    printPensOverview: false,
    printInsightsOverview: false,
  },
  overview: { ...overviewInitState },
  selectedPen: {
    selectedPenId: null,
    isPenSelected: false,
    isPenLoading: false,
  },
  groups: [allGroups],
  selectedGroup: allGroups,
};

export const opsReducer = (state, action) => {
  switch (action.type) {
    case "SELECT_DATE_RANGE":
      const groups = [
        ...state[action.payload].summaries
          .map((summary) => summary.group)
          .filter(Boolean),
        allGroups,
      ];
      const filteredByGroup =
        state.selectedGroup === allGroups
          ? state[action.payload].summaries
          : state[action.payload].summaries.filter(
              (summary) => summary.group === state.selectedGroup
            );
      return {
        ...state,
        selectedDateRange: action.payload,
        overview: {
          ...state[action.payload],
          summaries: filteredByGroup,
        },
        groups,
      };
    case "SELECT_GROUP":
      const filtered =
        action.payload === allGroups
          ? state[state.selectedDateRange].summaries
          : state[state.selectedDateRange].summaries.filter(
              (summary) => summary.group === action.payload
            );
      return {
        ...state,
        selectedGroup: action.payload,
        overview: { ...state.overview, summaries: filtered },
      };
    case "SELECT_PRINT_SECTION":
      return {
        ...state,
        selectedPrintSection: {
          ...state.selectedPrintSection,
          ...action.payload,
        },
      };
    case "LOADING_PEN":
      return {
        ...state,
        selectedPen: {
          ...state.selectedPen,
          ...{ isPenSelected: false, isPenLoading: true },
        },
      };
    case "SELECT_PEN_ID":
      return {
        ...state,
        selectedPen: {
          ...state.selectedPen,
          ...{
            ...action.payload,
            isPenSelected: !!action.payload.selectedPenId,
            isPenLoading: false,
          },
        },
      };
    default:
      throw new Error();
  }
};
