import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

const PREFIX = "PhoneField";

const classes = {
  phoneField: `${PREFIX}-phoneField`,
};

const Root = styled("div")(() => ({
  [`&.${classes.phoneField}`]: {
    "& > .react-tel-input > .form-control:focus": {
      borderColor: "#008389",
      boxShadow: "0 0 0 1px #008389",
    },
  },
}));

const PhoneField = ({ value, onPhoneChange, onPhoneBlur }) => {
  const { t } = useTranslation();

  return (
    <Root className={classes.phoneField}>
      <PhoneInput
        inputProps={{ "data-cy": "phone_number" }}
        country={"us"}
        value={value}
        onChange={onPhoneChange}
        onBlur={onPhoneBlur}
        inputStyle={{ width: "100%" }}
        placeholder=""
        preferredCountries={["us", "mx", "gb", "ie", "sa"]}
        specialLabel={t("phone")}
      />
    </Root>
  );
};

export default PhoneField;

PhoneField.propTypes = {
  onPhoneChange: PropTypes.func.isRequired,
  onPhoneBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
