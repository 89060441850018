import axios from "axios";

export async function fetchLog() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/alerts/log/`
  );
  return response.data;
}

export async function fetchSettings() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/alerts/settings/`
  );
  return response.data;
}

export async function editAlert({ id, ...params }) {
  const response = axios.patch(
    `${window._env_.API_URL}/private/alerts/settings/${id}/`,
    {
      params,
    }
  );
  return response;
}

export async function fetchRecipients() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/alerts/recipients/`
  );
  return response.data;
}

export async function addRecipient(params) {
  const response = await axios.post(
    `${window._env_.API_URL}/private/alerts/recipients/`,
    { params }
  );
  return response.data;
}

export async function editRecipient({ id, ...params }) {
  const response = await axios.patch(
    `${window._env_.API_URL}/private/alerts/recipients/${id}/`,
    { params }
  );
  return response.data;
}

export async function removeRecipient({ id }) {
  const response = await axios.delete(
    `${window._env_.API_URL}/private/alerts/recipients/${id}/`
  );
  return response;
}
