import { useEffect, useReducer } from "react";
import { minutesToHours } from "../../CommonComponents/utils/time.js";
import alertTypes from "../Pages/Alerts/Recipients/alertTypes";

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const update = (state, name, newValue) => {
  const toUpdate = deepClone(state);
  toUpdate[name] = newValue;
  return toUpdate;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "update-input": {
      const { name, value } = action.payload;
      return update(state, name, { value, error: "" });
    }
    case "raise-input-error": {
      const { name, error } = action.payload;
      const { value } = state[name];
      return update(state, name, { value, error });
    }
    case "update-enabled":
      return update(state, "enabled", action.payload);
    case "reset":
      return init(action.payload);
    default:
      throw new Error("Edit Alert Section has entered an unknown state");
  }
};

const init = ({ id, label, threshold, enabled }) => {
  const { hours, minutes } = minutesToHours(threshold);
  return {
    id,
    label,
    hours: { value: hours, error: "" },
    mins: { value: minutes, error: "" },
    enabled,
  };
};

const useAlertMangement = (initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const reset = () => dispatch({ type: "reset", payload: { ...initialState } });

  useEffect(() => {
    dispatch({ type: "reset", payload: { ...initialState } });
  }, [initialState]);

  const setSwitchValue = (e, payload) =>
    dispatch({ type: "update-enabled", payload });

  const setInputValue = (event) => {
    const { name, value } = event.target;
    dispatch({
      type: "update-input",
      payload: { name, value },
    });
  };

  const validateNumber = (event) => {
    const { name, value } = event.target;
    const isValid = /^\d+$/.test(value);
    if (!isValid) {
      dispatch({
        type: "raise-input-error",
        payload: { name, error: "Enter valid whole numbers" },
      });
    }
  };

  const convertToThreshold = () => {
    const { hours, mins, id, enabled } = state;
    const hoursAsMinutes = parseInt(hours.value, 10) * 60;
    const minutes = parseInt(mins.value, 10);
    const threshold = hoursAsMinutes + minutes;
    const alert = Object.values(alertTypes).find((type) => type.value === id);
    if (threshold < alert.threshold) {
      dispatch({
        type: "raise-input-error",
        payload: {
          name: "mins",
          error: `Cannot be less than ${alert.threshold} minutes`,
        },
      });
      return;
    }

    return {
      id,
      enabled,
      threshold,
    };
  };

  return {
    alertState: state,
    convertToThreshold,
    reset,
    setInputValue,
    setSwitchValue,
    validateNumber,
  };
};

export default useAlertMangement;
