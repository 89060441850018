import React, { useState } from "react";
import { useQuery } from "react-query";
import Typography from "@mui/material/Typography";
import { getPensProductivitySummary } from "../../services/pen";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import PrintButton from "../../../CommonComponents/UI/PrintButton";
import Header from "../../../CommonComponents/UI/Header";
import GroupFilter from "../../../CommonComponents/UI/GroupFilter";
import TimePeriodToggle from "../../../CommonComponents/UI/TimePeriodToggle";
import Pens from "./Pens";
import KPICharts from "./KPICharts/KPICharts";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "24px 48px",
    flexGrow: 1,
    backgroundColor: "#fff",
    minHeight: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "16px 0 0 0",
    },
  },
  contentInner: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1360px",
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignSelf: "flex-end",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      width: 600,
      height: 48,
      justifyContent: "flex-end",
      marginBottom: 10,
      "& > div:first-of-type": {
        margin: "0 14px",
      },
      "& > div:nth-of-type(3)": {
        marginRight: 60,
      },
    },
    "@media print": {
      width: 600,
      justifyContent: "flex-end",

      "& > div:first-of-type": {
        margin: "0 14px",
      },
      "& > div:nth-of-type(3)": {
        marginRight: 60,
      },
    },
  },
  groupContainer: {
    width: "50%",
    [theme.breakpoints.up("md")]: {
      width: 146,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  caption: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const timePeriods = {
  last7Days: "last7Days",
  last30Days: "last30Days",
};

const allGroups = "All Groups";

const HerdProductivity = () => {
  const classes = useStyles();
  const [selectedPeriod, setSelectedPeriod] = useState(timePeriods.last7Days);
  const [selectedGroup, setSelectedGroup] = useState(allGroups);
  const { t } = useTranslation();

  const { data } = useQuery(
    ["herdProductivityPens", { period: selectedPeriod }],
    getPensProductivitySummary
  );

  const groups = [
    ...new Set(data?.pens.map(({ group }) => group).filter(Boolean)),
    allGroups,
  ];

  const changeTimePeriod = (value) => setSelectedPeriod(timePeriods[value]);

  const selectGroup = (event) => setSelectedGroup(event.target.value);

  const filteredData =
    selectedGroup === allGroups
      ? data?.pens
      : data?.pens.filter((pen) => pen.group === selectedGroup);

  return (
    <>
      <Header title={t("herd-productivity")} />
      <section className={classes.content}>
        <div className={classes.contentInner}>
          <div className={classes.toolbar}>
            <Typography className={classes.caption} variant="caption">
              {data.dates}
            </Typography>
            <TimePeriodToggle
              changeTimePeriod={changeTimePeriod}
              selectedPeriod={selectedPeriod}
            />

            <GroupFilter
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              groups={groups}
            />
            <PrintButton />
          </div>
          <KPICharts
            selectedPeriod={selectedPeriod}
            selectedGroup={selectedGroup}
          />
          <Pens data={filteredData} />
        </div>
      </section>
    </>
  );
};

export default HerdProductivity;
