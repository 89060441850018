import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { useAccountDetails } from "../App/useAccountDetails";
import { useActiveModules } from "../App/useActiveModules";

import CCIcon from "../CommonComponents/Icons/CowComfort";
import PeopleIcon from "../CommonComponents/Icons/People";
import FeedIcon from "../CommonComponents/Icons/FeedOverTwentyFourHours";
import WelfareIcon from "../CommonComponents/Icons/LyingTime";
import Header from "../CommonComponents/UI/Header";
import HerdWelfare from "./Cards/HerdWelfare";
import HerdProductivity from "./Cards/HerdProductivity";
import PeopleProtocols from "./Cards/PeopleProtocols";
import FeedbunkManagement from "./Cards/FeedbunkManagement";
import DashboardCard from "./DashboardCard";
import LiveFeed from "./Cards/LiveFeed";
import Circle from "../CommonComponents/Icons/Circle";

const PREFIX = "FarmDashboard";

const classes = {
  text: `${PREFIX}-text`,
  productivityCard: `${PREFIX}-productivityCard`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: 20,
  [`& .${classes.text}`]: {
    marginLeft: "auto",
    textTransform: "uppercase",
  },
  [`& .${classes.productivityCard}`]: {
    [theme.breakpoints.up("sm")]: {
      height: 550,
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: "auto",
  textTransform: "uppercase",
  display: "none",

  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}));

const FarmDashboard = () => {
  const { t } = useTranslation();
  const { flags } = useActiveModules();
  const { info } = useAccountDetails();
  const [feedbunkSubtitle, setFeedbunkSubtitle] = useState("");

  const setSubtitle = (dates) => {
    const newSubtitle = dates.yesterday ? t("yesterday") : dates.date;
    setFeedbunkSubtitle(newSubtitle);
  };

  return (
    <>
      <Header title={t("dashboard")}>
        <StyledTypography className={classes.desktop} variant="h4">
          {info.farm.name}
        </StyledTypography>
      </Header>
      <StyledGrid container spacing={2}>
        <Grid
          container
          alignContent="flex-start"
          item
          spacing={2}
          xs={12}
          md={7}
        >
          <Grid item xs={12}>
            <DashboardCard
              title="feedbunk-management"
              subtitle={feedbunkSubtitle}
              url="/feedbunk-management/pen-summary"
              icon={FeedIcon}
            >
              <FeedbunkManagement setSubtitle={setSubtitle} />
            </DashboardCard>
          </Grid>
          {flags.herdProductivity && (
            <Grid item xs={12}>
              <DashboardCard
                title={"herd-productivity"}
                subtitle={t("30-day-average")}
                url="/herd-productivity"
                icon={WelfareIcon}
                className={classes.productivityCard}
              >
                <HerdProductivity />
              </DashboardCard>
            </Grid>
          )}
          {flags.milkingOperations && (
            <Grid item xs={12}>
              <DashboardCard
                title={"people-and-protocols"}
                subtitle={t("7-day-average")}
                url="/people-and-protocols/milking-operations"
                icon={PeopleIcon}
              >
                <PeopleProtocols />
              </DashboardCard>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          item
          xs={12}
          md={5}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <DashboardCard
              title={"live-feed-status"}
              subtitle={t("last-24-hours")}
              url="/feedbunk-management/live-feed-status"
              icon={Circle}
            >
              <LiveFeed />
            </DashboardCard>
          </Grid>
          {flags.herdWelfare && (
            <Grid item xs={12}>
              <DashboardCard
                title={"herd-welfare"}
                subtitle={t("30-day-average")}
                url="/herd-welfare"
                icon={CCIcon}
                className={classes.welfareCard}
              >
                <HerdWelfare />
              </DashboardCard>
            </Grid>
          )}
        </Grid>
      </StyledGrid>
    </>
  );
};

export default FarmDashboard;
