import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import ExpandArrow from "../../../../CommonComponents/UI/ExpandArrow";
import NavigationIcon from "@mui/icons-material/Navigation";
import Collapse from "@mui/material/Collapse";
import FeedStatusRow from "../FeedStatusRow";

const PREFIX = "FeedStatusExpandableRow";

const classes = {
  dailyStatusContainer: `${PREFIX}-dailyStatusContainer`,
  headerContainer: `${PREFIX}-headerContainer`,
  statusWrapper: `${PREFIX}-statusWrapper`,
  expandableRow: `${PREFIX}-expandableRow`,
  breakdownRowContainer: `${PREFIX}-breakdownRowContainer`,
  statusHeaderContainer: `${PREFIX}-statusHeaderContainer`,
  feedStatusBreakdown: `${PREFIX}-feedStatusBreakdown`,
  breakdownRowsContainer: `${PREFIX}-breakdownRowsContainer`,
  orientationLabelsContainer: `${PREFIX}-orientationLabelsContainer`,
  orientationContainer: `${PREFIX}-orientationContainer`,
  icon: `${PREFIX}-icon`,
  rotatedIcon: `${PREFIX}-rotatedIcon`,
  axisText: `${PREFIX}-axisText`,
  text: `${PREFIX}-text`,
  textBold: `${PREFIX}-textBold`,
  arrow: `${PREFIX}-arrow`,
  arrowActive: `${PREFIX}-arrowActive`,
  arrowIcon: `${PREFIX}-arrowIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.dailyStatusContainer}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerContainer}`]: {
    flex: "none",
    width: 74,
    marginRight: 5,
    textAlign: "right",
    lineHeight: "18px",
    marginLeft: 15,
    [theme.breakpoints.up("md")]: {
      width: 105,
      marginRight: 15,
      marginLeft: 0,
    },
  },

  [`& .${classes.statusWrapper}`]: {
    flex: "0 0 auto",
    width: `calc(100% - 94px)`,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${TIMELINE_OFFSET})`,
    },
  },

  [`& .${classes.expandableRow}`]: {
    width: `calc(100% - ${TIMELINE_OFFSET_MOBILE})`,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${TIMELINE_OFFSET})`,
    },
  },

  [`& .${classes.breakdownRowContainer}`]: {
    display: "flex",
    alignItems: "center",
    paddingTop: 4,
    marginLeft: -48,
  },

  [`& .${classes.statusHeaderContainer}`]: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 1,
    },
  },

  [`& .${classes.feedStatusBreakdown}`]: {
    display: "flex",
    flexDirection: "row",
    maxHeight: 5000,
    backgroundColor: "#f8f8f8",
    marginLeft: 25,
    paddingLeft: 8,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.11)",
    paddingBottom: "4px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginLeft: 48,
      paddingLeft: 0,
    },
  },

  [`& .${classes.breakdownRowsContainer}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      padding: "8px 0",
    },
  },

  [`& .${classes.orientationLabelsContainer}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    fontWeight: "bold",
    margin: "4px 0",
    height: "calc(100% - 8px)",
    [theme.breakpoints.up("md")]: {
      margin: " 0 0 0 20px",
      height: "100%",
    },
  },

  [`& .${classes.orientationContainer}`]: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    color: "#9d9d9d",
    textAlign: "center",
  },

  [`& .${classes.icon}`]: {
    display: "inline-block",
    height: 10,
    fontSize: 10,
  },

  [`& .${classes.rotatedIcon}`]: {
    transform: "rotate(180deg)",
  },

  [`& .${classes.axisText}`]: {
    marginLeft: -5,
    textAlign: "left",
  },

  [`& .${classes.text}`]: {
    fontSize: 14,
    color: "#595959",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },

  [`& .${classes.textBold}`]: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#9d9d9d",
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },

  [`& .${classes.arrow}`]: {
    display: "inline-block",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
    height: 24,
    [theme.breakpoints.down("md")]: {
      marginRight: -5,
      height: 22,
      width: 22,
    },
  },

  [`& .${classes.arrowActive}`]: {
    display: "inline-block",
    transform: "rotate(-90deg)",
  },

  [`& .${classes.arrowIcon}`]: {
    [theme.breakpoints.down("md")]: {
      height: 28,
      width: 28,
    },
  },
}));

const TIMELINE_OFFSET = "120px";
const TIMELINE_OFFSET_MOBILE = "80px";

const orientationLabels = {
  start: (orientation, classes) => (
    <div className={classes.orientationContainer}>
      <span className={classes.icon}>
        <NavigationIcon fontSize="inherit" />
      </span>
      {orientation}
    </div>
  ),
  end: (orientation, classes) => (
    <div className={classes.orientationContainer}>
      {orientation}
      <span className={`${classes.icon} ${classes.rotatedIcon}`}>
        <NavigationIcon fontSize="inherit" />
      </span>
    </div>
  ),
};

const formatOrientation = (orientation) => orientation.charAt(0).toUpperCase();

const StatusRow = (props) => {
  const { barHeight, modifier, windows, className } = props;

  return (
    <div
      className={classNames(classes.statusWrapper, className)}
      style={{ height: barHeight }}
    >
      <FeedStatusRow
        barHeight={barHeight}
        rowWidth={1200}
        modifier={modifier}
        windows={windows}
      />
    </div>
  );
};

const FeedStatusBreakdown = (props) => {
  const { children } = props;

  return (
    <Collapse in={props.active} data-cy="feed-status-row-expandable-section">
      <div className={classes.feedStatusBreakdown}>{children}</div>
    </Collapse>
  );
};

const DailyStatusContainer = (props) => {
  const {
    accessTime,
    breakdownActive,
    onClick,
    date,
    hasBreakdown,
    windows,
  } = props;

  return (
    <div
      className={classes.dailyStatusContainer}
      data-cy="feed-status-row"
      onClick={onClick}
      style={{ cursor: `${hasBreakdown ? "pointer" : "default"}` }}
    >
      <div
        className={`${classes.headerContainer} ${classes.statusHeaderContainer}`}
        style={{
          justifyContent: `${hasBreakdown ? "space-between" : "flex-end"}`,
        }}
      >
        {hasBreakdown ? <ExpandArrow isActive={breakdownActive} /> : null}
        <div className={classes.axisText}>
          <span className={classes.textBold}>{date}</span>
          <br />
          <span className={classes.text}>{accessTime}</span>
        </div>
      </div>
      <StatusRow
        barHeight="40px"
        modifier={0.83}
        windows={windows}
        className={classes.expandableRow}
      />
    </div>
  );
};

const FeedStatusExpandableRow = ({
  accessTime,
  date,
  windows,
  penId,
  isBreakdownOpen,
  barnOrientation,
  sectionBreakdown,
}) => {
  const [breakdownActive, setBreakdownActive] = React.useState(isBreakdownOpen);
  const hasBreakdown = sectionBreakdown.length > 0;
  const [orientationStart = "", orientationEnd = ""] = barnOrientation.split(
    "-"
  );

  const shouldShowOrientation = orientationStart && sectionBreakdown.length > 1;

  useEffect(() => {
    setBreakdownActive(isBreakdownOpen);
  }, [penId, isBreakdownOpen]);

  return (
    <Root style={{ padding: "8px 0 4px 0" }}>
      <DailyStatusContainer
        hasBreakdown={hasBreakdown}
        accessTime={accessTime}
        windows={windows}
        date={date}
        breakdownActive={breakdownActive}
        onClick={() => setBreakdownActive(!breakdownActive)}
      />
      {hasBreakdown ? (
        <FeedStatusBreakdown active={breakdownActive}>
          {shouldShowOrientation && (
            <div className={classes.orientationLabelsContainer}>
              {orientationLabels.start(
                formatOrientation(orientationStart),
                classes
              )}
              {orientationLabels.end(
                formatOrientation(orientationEnd),
                classes
              )}
            </div>
          )}
          <div className={classes.breakdownRowsContainer}>
            {sectionBreakdown.map((section) => (
              <div
                className={classes.breakdownRowContainer}
                key={section.label}
                data-cy="feed-status-row-expandable-section-row"
              >
                <div className={classes.headerContainer}>
                  <span className={classes.text}>{section.label}</span>
                </div>
                <StatusRow
                  barHeight="16px"
                  modifier={0.83}
                  windows={section.windows}
                />
              </div>
            ))}
          </div>
        </FeedStatusBreakdown>
      ) : null}
    </Root>
  );
};

export default FeedStatusExpandableRow;
