import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import PrintButton from "../../../../CommonComponents/UI/PrintButton";
import classNames from "classnames";
import { useMilkingOperationsContext } from "../utils/MilkingOperationsContext";
import MoPensTable from "./MoPensTable";
import TimePeriodToggle from "../../../../CommonComponents/UI/TimePeriodToggle";
import OpsTrendGroup from "../../../../CommonComponents/UI/Operations/OpsTrendGroup";
// import GroupFilter from "../../../../CommonComponents/UI/GroupFilter";
import MoSmartInsights from "./MoSmartInsights";
import MoSmartInsightsProvider from "../utils/MoSmartInsightsProvider";
import MoPenDetailsOverview from "./MoPenDetailsOverview";

import kpiInfo from "../utils/kpiInfo";
import StyledPensOverview, {
  classes,
} from "../../../../CommonComponents/UI/Operations/StyledPensOverview";

const MoPensOverview = () => {
  const {
    // groups,
    overview,
    selectDateRange,
    selectedDateRange,
    // selectGroup,
    // selectedGroup,
    selectedPrintSection,
    selectPrintSection,
  } = useMilkingOperationsContext();

  const onPrint = useCallback(() => {
    selectPrintSection({
      printPensOverview: true,
      printInsightsOverview: false,
    });
    setTimeout(() => {
      window.print();
    });
  }, []);

  return (
    <StyledPensOverview
      data-cy="Milking Ops Pens Overview Wrapper"
      className={classNames(classes.wrapper, {
        [classes.print]: selectedPrintSection.printPensOverview,
      })}
    >
      <div className={classes.content}>
        <div className={classes.toolbar} data-cy="toolbar">
          <Typography className={classes.caption} variant="caption">
            {overview.dates}
          </Typography>
          <TimePeriodToggle
            changeTimePeriod={selectDateRange}
            selectedPeriod={selectedDateRange}
          />
          {/* <GroupFilter
            selectGroup={selectGroup}
            selectedGroup={selectedGroup}
            groups={groups}
          /> */}
          <PrintButton onChartPrint={onPrint} />
        </div>
        <OpsTrendGroup kpiInfo={kpiInfo} trends={overview.trends} />

        <div
          className={classes.insights}
          data-cy="Pens Overview Mobile OpsSmartInsights"
        >
          <MoSmartInsightsProvider>
            <MoSmartInsights
              shouldPrint={selectedPrintSection.printPensOverview}
              dataCyPrefix="Pens Overview Mobile OpsSmartInsights Card"
            />
          </MoSmartInsightsProvider>
          <MoPenDetailsOverview></MoPenDetailsOverview>
        </div>

        <MoPensTable></MoPensTable>
      </div>
    </StyledPensOverview>
  );
};

export default MoPensOverview;
