import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import FeedingOps from "./Pages/FeedingOperations";
import MilkingOperations from "./Pages/MilkingOperations";

function PeopleProtocols() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/feeding-operations`} component={FeedingOps} exact />
      <Route
        path={`${path}/milking-operations`}
        component={MilkingOperations}
        exact
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default PeopleProtocols;
