import React from "react";
import { styled } from "@mui/material/styles";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import dayjs from "dayjs";

const PREFIX = "DatepickerCustomHeader";

const classes = {
  headerContainer: `${PREFIX}-headerContainer`,
  headerContainerArrow: `${PREFIX}-headerContainerArrow`,
  headerContainerMonth: `${PREFIX}-headerContainerMonth`,
  headerContainerYear: `${PREFIX}-headerContainerYear`,
};

const Root = styled("div")(() => {
  return {
    [`&.${classes.headerContainer}`]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #d9d9d9",
      margin: "0 12px 12px 12px",
    },
    [`& .${classes.headerContainerArrow}`]: (props) => ({
      userSelect: "none",
      color: props.disabled ? "#cccccc" : "#595959",
      cursor: props.disabled ? "default" : "pointer",
    }),
    [`& .${classes.headerContainerMonth}`]: {
      fontWeight: "bold",
      color: "#595959",
      fontSize: 24,
      display: "flex",
      marginBottom: 6,
    },
    [`& .${classes.headerContainerYear}`]: {
      fontWeight: "normal",
      color: "#595959",
      marginLeft: 6,
    },
  };
});

const DatepickerCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const month = dayjs(date).format("MMM");
  const year = dayjs(date).format("YYYY");
  return (
    <Root className={classes.headerContainer}>
      <ArrowBack
        className={classes.headerContainerArrow}
        onClick={() => !prevMonthButtonDisabled && decreaseMonth()}
        disabled={prevMonthButtonDisabled}
      />
      <div className={classes.headerContainerMonth}>
        {month}
        <div className={classes.headerContainerYear}> {year}</div>
      </div>
      <ArrowForward
        className={classes.headerContainerArrow}
        onClick={() => !nextMonthButtonDisabled && increaseMonth()}
        disabled={nextMonthButtonDisabled}
      />
    </Root>
  );
};

export default DatepickerCustomHeader;
