import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Cleanout from "../../../../CommonComponents/Icons/Cleanouts";
import Pushup from "../../../../CommonComponents/Icons/PushUps";
import Delivery from "../../../../CommonComponents/Icons/Deliveries";
import { timestampToMinutesInDay } from "../../../../CommonComponents/utils/time";
import ActualEventTime from "./ActualEventTime";
import { colors } from "../../../../CommonComponents/UI/theme";

const PREFIX = "EventBar";

const classes = {
  eventsFlexOuter: `${PREFIX}-eventsFlexOuter`,
  eventsFlexInner: `${PREFIX}-eventsFlexInner`,
};

const Root = styled("div")(() => ({
  [`&.${classes.eventsFlexOuter}`]: {
    display: "flex",
    height: 30,
    marginBottom: -8,
    zIndex: 1,
  },

  [`& .${classes.eventsFlexInner}`]: {
    cursor: "pointer",
    position: "relative",
    height: "100%",
  },
}));

const ICON_MAP = {
  deliveries: () => (
    <Delivery
      htmlColor={colors.delivery}
      fontSize="inherit"
      stroke={colors.delivery}
      strokeWidth={0.5}
    />
  ),
  cleanouts: () => (
    <Cleanout
      htmlColor={colors.cleanout}
      fontSize="inherit"
      stroke={colors.cleanout}
      strokeWidth={0.5}
    />
  ),
  pushups: () => (
    <Pushup
      htmlColor={colors.pushups}
      stroke={colors.pushups}
      fontSize="inherit"
      strokeWidth={0.5}
    />
  ),
};

const EventBar = ({ coordinates, data }) => {
  return (
    <Root
      className={classes.eventsFlexOuter}
      style={{ width: coordinates.canvasWidth }}
      data-thirdparty="flex-outer"
    >
      <div
        className={classes.eventsFlexInner}
        style={{ width: coordinates.chartWidth, marginLeft: coordinates.start }}
      >
        {data.aggregations.map((day) => {
          const eventKeys = Object.keys(day.events.actual);

          return eventKeys.map((eventkey, index) => {
            const actual = day.events.actual[eventkey];

            return (
              <React.Fragment key={`Events-${eventkey}-${index}`}>
                {actual.map((timestamp) => (
                  <ActualEventTime
                    type={eventkey}
                    icon={ICON_MAP[eventkey]}
                    key={timestamp}
                    time={timestampToMinutesInDay(timestamp)}
                  />
                ))}
              </React.Fragment>
            );
          });
        })}
      </div>
    </Root>
  );
};

export default EventBar;

EventBar.propTypes = {
  coordinates: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
