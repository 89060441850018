import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LineChart from "../../HerdProductivity/Pages/Dashboard/KPICharts/LineChart";
import LoadingSpinner from "../../CommonComponents/UI/LoadingSpinner";
import kpiInfo from "../../HerdProductivity/utils/kpiInfo";
import { getHerdProductivityTrends } from "../../HerdProductivity/services/trends";
import ErrorView from "./ErrorView";
import KPI from "./KPI";

const StyledBox = styled(Box)({
  display: "flex",
  padding: 10,
  flexWrap: "wrap",
});

const ChartWrapper = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
    height: 280,
  },
}));

const period = "last30Days";
const checkboxes = {
  feedbunk: true,
  lying: true,
  standing: true,
  emptyPen: true,
  uncategorized: true,
};

const dataErrorView = () => (
  <ErrorView messageKey="waiting-for-data-to-process" />
);

const HerdProductivity = () => {
  const { data } = useQuery(
    ["herdProductivityTrends", { period, includeWorstPens: true }],
    getHerdProductivityTrends,
    { refetchOnWindowFocus: false }
  );

  const trends = data.summaries.farm;

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary FallbackComponent={dataErrorView}>
        <StyledBox>
          {trends.filter((trend) => trend.kpi !== "uncategorized").map((trend) => (
            <KPI trend={trend} kpiInfo={kpiInfo} />
          ))}
        </StyledBox>
        <ChartWrapper>
          <LineChart
            selectedGroup={"All Groups"}
            selectedPeriod={period}
            checkboxes={checkboxes}
            legend={{
              maxWidth: 630,
              markerMargin: 5,
              type: "square",
              fontFamily: "Noto",
              fontWeight: "normal",
              horizontalAlign: "center",
              itemWidth: 130,
            }}
          />
        </ChartWrapper>
      </ErrorBoundary>
    </Suspense>
  );
};

export default HerdProductivity;
