import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useMoSmartInsightsContext } from "../utils/MoSmartInsightsContext";
import { useMilkingOperationsContext } from "../utils/MilkingOperationsContext";
import OpsSmartInsights from "../../../../CommonComponents/UI/Operations/OpsSmartInsights";
import { insightsCssMixIn } from "../../../../CommonComponents/utils/Operations/insightsCssMixin";
import { colors } from "../../../../CommonComponents/UI/theme";

const PREFIX = "MoSmartInsights";

const classes = {
  insights: `${PREFIX}-insights`,
  link: `${PREFIX}-link`,
  button: `${PREFIX}-button`,
  print: `${PREFIX}-print`,
};

const Insight = styled("div")({
  ...insightsCssMixIn,

  [`& .${classes.button}`]: {
    color: colors.green,
    padding: 0,
    fontSize: 16,
    font: "inherit",
    background: "none",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
});

const StyledOpsSmartInsights = styled(OpsSmartInsights)({
  "@media print": {
    display: "none",
  },

  [`&.${classes.print}`]: {
    "@media print": {
      display: "block",
    },
  },
});

const MoSmartInsights = ({ dataCyPrefix, shouldPrint }) => {
  const insights = useMoSmartInsightsContext();
  const { t } = useTranslation();

  const { selectPenId } = useMilkingOperationsContext();

  insights.forEach((insight) => {
    insight.template = (
      <Insight className={classes.insight}>
        <div>
          <button
            variant="text"
            title={insight.penName}
            className={classes.button}
            onClick={() => selectPenId({ selectedPenId: insight.penId })}
          >
            {insight.penName}
          </button>
          &nbsp;
          {t("" + insight.name + "", {
            penId: insight.penId,
            penName: insight.penName,
            milkingTime: insight.milkingTime,
            onTime: t(insight.onTime),
            period: insight.period,
            periodReference: insight.periodReference,
            periodUnits: t(insight.periodUnits),
            name: insight.name,
            type: insight.type,
            value: insight.value,
            valueUnits: t(insight.valueUnits),
            referenceValue: insight.referenceValue,
            day: t(insight.day.toLowerCase()),
          })}
        </div>
      </Insight>
    );
  });

  return (
    <StyledOpsSmartInsights
      className={classNames({
        [classes.print]: shouldPrint,
      })}
      insights={insights}
      dataCyPrefix={dataCyPrefix}
    />
  );
};

MoSmartInsights.propTypes = {
  shouldPrint: PropTypes.bool,
  dataCyPrefix: PropTypes.string.isRequired,
};

MoSmartInsights.defaultProps = {
  shouldPrint: false,
};

export default MoSmartInsights;
