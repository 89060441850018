import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import Table from "../../../CommonComponents/UI/Table";
import { fetchLog } from "../../services/alerts";

const PREFIX = "Log";

const classes = {
  tableWrapper: `${PREFIX}-tableWrapper`,
  table: `${PREFIX}-table`,
};

const StyledTable = styled(Table)(() => ({
  [`& .${classes.table}`]: {
    minWidth: 540,
  },
}));

const Log = () => {
  const { t } = useTranslation();

  const headers = [
    {
      id: "alertType",
      label: t("alert-type"),
    },
    {
      id: "time",
      label: t("time-sent"),
    },
    {
      id: "pen",
      label: t("pen-capitalised"),
    },
    {
      id: "sentTo",
      label: t("sent-to"),
    },
  ];

  const { data } = useQuery(["alerts", "log"], fetchLog);

  const rows = data?.map(({ alert, pen, recipient }) => ({
    alertType: alert.label,
    key: `${alert.label}-${pen.id}-${recipient.id}`,
    time: dayjs(alert.sent_at).format("h:mm A, MMM D"),
    pen: pen.name,
    sentTo: recipient.name,
  }));

  return <StyledTable data={{ headers, rows }} className={classes.table} />;
};

export default Log;
