import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import formatDataWindows from "./feedStatus/formatDataWindows";
import { useAccountDetails } from "../../App/useAccountDetails";
import { get24hrFeedStatus } from "../services/pen";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const use24HourData = () => {
  const { info } = useAccountDetails();
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [intervalMs, setIntervalMs] = useState(3600000);
  let timer = useRef(null);

  const { data: fetched } = useQuery(["feedStatus24hr"], get24hrFeedStatus, {
    refetchInterval: intervalMs,
  });

  const localTime = dayjs().tz(info.farm.timezone).format();
  let hour = parseInt(
    dayjs(fetched.range.end).tz(info.farm.timezone).format("h"),
    10
  );
  let mins = parseInt(
    dayjs(fetched.range.end).tz(info.farm.timezone).format("m"),
    10
  );
  let ampm = dayjs(fetched.range.end).tz(info.farm.timezone).format("A");

  useEffect(() => {
    if (fetched && isFirstFetch) {
      const diff = dayjs(localTime).diff(fetched.range.end, "millisecond");
      setIsFirstFetch(false);
      // call the api at the next scheduled update and every hour after that.
      // The api is updated every hour.
      // 3600000 = number of miliseconds in 1 hour
      setIntervalMs(3600000 - diff);
      timer.current = setTimeout(() => {
        setIntervalMs(3600000);
      }, 3600000 - diff);
    }
  }, [fetched, isFirstFetch, localTime]);

  //clearing setTimeout on unmount
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const groups = fetched.groups.map((group) => ({
    name: group.name,
    target: group.targets.minutesNotOK,
    pens: group.pens
      .map(({ id, label, barnOrientation, windows }) =>
        formatDataWindows({
          pen: {
            id,
            barnOrientation,
            label,
            summary: windows.summary,
            isBreakdownOpen: false,
          },
          windows: [
            {
              ...windows,
              range: fetched.range,
            },
          ],
          timezone: info.farm.timezone,
        })
      )
      .flat(),
  }));

  return {
    hour,
    mins,
    ampm,
    groups,
  };
};

export default use24HourData;
