import React, { useCallback, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAccountDetails } from "../../../App/useAccountDetails";
import useDatesForPen from "../../../CommonComponents/utils/useDatesForPen";
import Datepicker from "../../../CommonComponents/UI/Datepicker/Datepicker.jsx";
import LCTile from "../../../CommonComponents/UI/LineChartTile";
import MilkingTile from "./MilkingChart";
import { getPenBehaviorBreakdown } from "../../services/pen";
import dayjs from "dayjs";
import OOPIcon from "../../../CommonComponents/Icons/OutOfPen";
import MilkingIcon from "../../../CommonComponents/Icons/Milking";

import {
  minutesToHours,
  hoursMinFormatter,
} from "../../../CommonComponents/utils/time";

const PREFIX = "OutOfPen";

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  kpiSection: `${PREFIX}-kpiSection`,
  datePickerSection: `${PREFIX}-datePickerSection`,
  chart: `${PREFIX}-chart`,
  milkingChart: `${PREFIX}-milkingChart`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    "@media print": {
      padding: `16px 0 0 0`,
    },
  },

  [`& .${classes.grid}`]: {
    height: "100%",
    "@media print": {
      "&.show-milking-chart > div:nth-of-type(2)": {
        display: "none",
      },
      "&.show-out-of-pen-chart > div:nth-of-type(3)": {
        display: "none",
      },
    },
  },

  [`& .${classes.kpiSection}`]: {
    minHeight: 232,
  },

  [`& .${classes.datePickerSection}`]: {
    height: 42,
    marginBottom: 16,
    maxHeight: 42,
  },

  [`& .${classes.chart}`]: {
    position: "relative",
    height: 688,
    marginBottom: 20,
    "@media print": {
      marginBottom: 0,
    },
  },

  [`& .${classes.milkingChart}`]: {
    position: "relative",
    height: 1000,
    marginBottom: 20,
  },
}));

const formatForChart = ({ value }) => minutesToHours(value, hoursMinFormatter);

const OutOfPen = () => {
  const { t } = useTranslation();
  const { info } = useAccountDetails();
  const { id } = useParams();
  const initialRange = 30;
  const excludeToday = true;
  const [dates, setDates] = useDatesForPen(
    info,
    id,
    initialRange,
    excludeToday
  );

  const queryStartDate = dayjs(dates.start).format("YYYY-MM-DD");
  const queryEndDate = dayjs(dates.end).format("YYYY-MM-DD");
  const gridRef = useRef(null);

  const onChartPrint = useCallback((e) => {
    gridRef.current.className = `${gridRef.current.className} show-${e.currentTarget.offsetParent.id}`;
    window.print();
  }, []);

  useEffect(() => {
    const removeClass = () => {
      const regEx = new RegExp(/show-.*$/g);
      const updatedClass = gridRef.current.className.replace(regEx, "");
      gridRef.current.className = updatedClass;
    };
    window.addEventListener("afterprint", removeClass);
    return () => window.removeEventListener("afterprint", removeClass);
  }, []);

  return (
    <Root className={classes.root}>
      <Grid
        container
        spacing={0}
        ref={gridRef}
        className={classes.grid}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item className={classes.datePickerSection}>
          <Datepicker
            startDate={dates.start}
            endDate={dates.end}
            minimumDate={dates.minimum}
            maximumDate={dates.maximum}
            maxRange={90}
            hook="cow-comfort-index"
            onApplyPressed={(startDate, endDate) => {
              setDates({ start: startDate, end: endDate });
            }}
          />
        </Grid>
        <Grid
          xs={12}
          item
          className={classes.milkingChart}
          id="out-of-pen-chart"
        >
          <MilkingTile
            title={t("out-of-pen-v-milking-schedule-title")}
            icon={MilkingIcon}
            printClass="excludeOutOfPen"
            query={{
              key: "empty-pen",
              params: {
                penId: id,
                type: "details",
                startDate: queryStartDate,
                endDate: queryEndDate,
              },
              service: getPenBehaviorBreakdown,
              validation: () => true,
            }}
            onChartPrint={onChartPrint}
          />
        </Grid>
        <Grid xs={12} item className={classes.chart} id="milking-chart">
          <LCTile
            title={t("out-of-pen")}
            subtitle={t("avg-duration-cows-away-from-pen-message")}
            icon={OOPIcon}
            config={{
              "7drollingAverage": {
                lineDashType: "dash",
                markerSize: 1,
                legendMarkerType: "square",
                legendMarkerColor: "white",
              },
              tooltipFormatter: formatForChart,
              axisY: {
                labelFormatter: formatForChart,
                title: t("duration-capitalised"),
                interval: 60,
              },
            }}
            query={{
              key: "empty-pen",
              params: {
                penId: id,
                type: "summary",
                startDate: queryStartDate,
                endDate: queryEndDate,
              },
              service: getPenBehaviorBreakdown,
              validation: () => true,
            }}
            onChartPrint={onChartPrint}
          />
        </Grid>
        <Grid style={{ height: "2px" }}></Grid>
      </Grid>
    </Root>
  );
};

export default OutOfPen;
