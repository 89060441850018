import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { colors } from "../theme";
import {
  flexCenterColumnMixIn,
  flexColumnMixIn,
  flexRowMixIn,
} from "../../utils/Operations/cssMixins";
import { xxlPadding } from "../../utils/Operations/css.const";
import OpsNoInsightsCard from "./OpsNoInsightsCard";
import InsightsIcon from "../../Icons/SmarterInsights";

const PREFIX = "OpsPatterns";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  content: `${PREFIX}-content`,
  insightCol: `${PREFIX}-insightCol`,
  insight: `${PREFIX}-insight`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  hr: `${PREFIX}-hr`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("xxl")]: {
      padding: `20px ${xxlPadding}`,
    },
  },

  [`& .${classes.content}`]: {
    ...flexColumnMixIn,
    justifyContent: "center",
    width: "100%",
    background: colors.white,
    paddingBottom: "30px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.33)",
  },
  [`& .${classes.insightCol}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.insight}`]: {
    ...flexRowMixIn,
    padding: "10px 20px",
    alignItems: "center",
  },

  [`& .${classes.icon}`]: {
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
  },

  [`& .${classes.title}`]: {
    ...flexCenterColumnMixIn,
    fontSize: "20px",
    fontWeight: "500",
    padding: "20px 0 10px 0",
  },

  [`& .${classes.text}`]: {
    fontSize: "16px",
    marginLeft: "10px",
  },

  [`& .${classes.hr}`]: {
    display: "flex",
    flexDirection: "row",
    width: "calc(85% - 27px)",
    opacity: ".3",
    marginLeft: "57px",
    borderBottomWidth: "0",
    borderTopWidth: "1px",
    borderLeftWidth: "0",
    borderRightWidth: "0",
  },
}));

const OpsSmartInsights = ({ insights, dataCyPrefix, className }) => {
  const { t } = useTranslation();

  return (
    <Root className={className}>
      {insights && insights.length ? (
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <div className={classes.title} data-cy={dataCyPrefix + " Title"}>
              {t("smart-insights")}
            </div>
            {insights.map((insight, index) => {
              return (
                <div key={insight.penId} className={classes.insightCol}>
                  <div className={classes.insight}>
                    <div className={classes.icon}>
                      <InsightsIcon
                        data-cy={dataCyPrefix + " Insight Row Icon " + index}
                        fontSize="medium"
                        htmlColor={colors.iconOrange}
                        stroke={colors.iconOrange}
                        fill={colors.iconOrange}
                        strokeWidth={0.5}
                      />
                    </div>
                    <div
                      className={classes.text}
                      data-cy={dataCyPrefix + " Insight Row Text " + index}
                    >
                      {insight?.template || insight}
                    </div>
                  </div>
                  <hr className={classes.hr} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <OpsNoInsightsCard />
      )}
    </Root>
  );
};

export default OpsSmartInsights;

OpsSmartInsights.propTypes = {
  dataCyPrefix: PropTypes.string.isRequired,
  className: PropTypes.string,
};
