import React from "react";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import MuiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { colors } from "./theme";

const PREFIX = "Tabs";

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
  tab: `${PREFIX}-tab`,
};

const StyledTabs = styled(MuiTabs)(({ theme, type }) => ({
  [`&.${classes.root}`]: {
    minHeight: 44,
    boxShadow:
      type === "flat"
        ? "none"
        : "0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 2px 2px 3px 0px rgba(0, 0, 0, 0.2)",
    "& button": {
      minHeight: 44,
    },
  },

  [`& .${classes.tab}`]: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 160,
    },
  },

  [`& .${classes.selected}`]: {
    color: colors.green,
  },
}));

const Tabs = (props) => {
  const {
    className,
    onChange,
    tabs,
    value,
    dataCyPrefix,
    type,
    ...muiTabProps
  } = props;

  return (
    <StyledTabs
      {...muiTabProps}
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      classes={{ root: classNames(classes.root, className) }}
      type={type}
    >
      {tabs.map((tab) => (
        <Tab
          label={tab}
          key={tab}
          classes={{ root: classes.tab, selected: classes.selected }}
          data-cy={dataCyPrefix + tab}
        />
      ))}
    </StyledTabs>
  );
};

export default Tabs;

Tabs.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  type: PropTypes.oneOf(["boxShadow", "flat"]),
  value: PropTypes.number.isRequired,
  dataCyPrefix: PropTypes.string,
};

Tabs.defaultProps = {
  className: null,
  type: "boxShadow",
  dataCyPrefix: "",
};
