import React from "react";
import Settings from "../Settings";
import Reports from "../Reports";
import HerdProductivity from "../HerdProductivity";
import HerdWelfare from "../HerdWelfare";
import FeedbunkManagement from "../FeedbunkManagement";
import PeopleProtocols from "../PeopleProtocols";
import FarmDashboard from "../FarmDashboard";

const components = {
  Settings,
  Reports,
  FeedbunkManagement,
  HerdProductivity,
  HerdWelfare,
  PeopleProtocols,
  FarmDashboard,
};

const TestClient = ({ clientName }) => {
  const Component = components[clientName];
  return <Component />;
};
export default TestClient;
