import React, { Suspense, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { ErrorBoundary } from "react-error-boundary";
import HeaderLargeSingleTabLevel from "../../../CommonComponents/UI/HeaderLarge/HeaderLargeSingleTabLevel";
import Tabs from "../../../CommonComponents/UI/Tabs";
import LoadingSpinner from "../../../CommonComponents/UI/LoadingSpinner";
import ErrorFallback from "../../../CommonComponents/UI/ErrorFallback";
import sections from "./sections";
import ExpandedProvider from "../../../CommonComponents/utils/useExpandedState";
import usePenDetails from "../../../CommonComponents/utils/usePenDetails";
import PenNavigator from "../../../CommonComponents/UI/PenNavigator";

const PREFIX = "Pen";

const classes = {
  secondaryTab: `${PREFIX}-secondaryTab`,
  outer: `${PREFIX}-outer`,
  content: `${PREFIX}-content`,
  grid: `${PREFIX}-grid`,
};

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "hideOverflow",
})(({ hideOverflow, theme }) => ({
  [`& .${classes.toolbar}`]: {
    height: "auto",
  },
  [`& .${classes.secondaryTab}`]: {
    paddingLeft: theme.spacing(7),
    position: "fixed",
    width: "100%;",
    zIndex: 4,
    backgroundColor: "#fafafa",
    "& button": {
      textTransform: "none",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - 256px)`,
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - 296px)`,
    },
    "@media print": {
      display: "none",
    },
  },

  [`&.${classes.outer}`]: {
    height: "100%",
    overflow: "hidden",
    "@media print": {
      border: "1px solid transparent",
      overflow: "visible",
    },
  },

  [`& .${classes.content}`]: {
    //header height + tabs height = 110
    height: "calc(100% - 110px)",
    marginTop: 48,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      padding: "20px 40px",
      overflow: `${hideOverflow ? "hidden" : "auto"}`,
    },
    "@media print": {
      overflow: "visible",
    },
  },

  [`& .${classes.grid}`]: {
    height: "100%",
    width: "100%",
  },
}));

const Pen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    params: { id, sectionId },
  } = useRouteMatch();
  const hideOverflow = sectionId === "feed-events";

  const pen = usePenDetails(id);
  const secondaryTabs = sections.map((tab) =>
    tab.translationKey ? t(tab.translationKey) : tab.name
  );
  const [secondaryTab, setSecondaryTab] = useState(0);

  useEffect(() => {
    if (sectionId) {
      const tabIndex = sections.findIndex(
        (section) => section.route === `/${sectionId}`
      );
      setSecondaryTab(tabIndex);
    }
  }, [sectionId]);

  const handleSecondaryTabChange = (event, newValue) => {
    history.push(`/feedbunk-management/pens/${id}${sections[newValue].route}`);
    setSecondaryTab(newValue);
  };

  return (
    <Root className={classes.outer} hideOverflow={hideOverflow}>
      <ExpandedProvider>
        <HeaderLargeSingleTabLevel
          title={pen.label}
          penGroup={pen.group}
          pen={pen}
          renderHeaderTools={() => <PenNavigator />}
        ></HeaderLargeSingleTabLevel>
        <Tabs
          tabs={secondaryTabs}
          onChange={handleSecondaryTabChange}
          value={secondaryTab}
          dataCyPrefix="Feedbunk Management Pen Secondary Tabs "
          className={classes.secondaryTab}
        />
        <div className={classes.content}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Grid
              className={classes.grid}
              direction="column"
              container
              wrap="nowrap"
              spacing={0}
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Suspense
                fallback={
                  <div style={{ margin: "auto", height: "50%" }}>
                    <LoadingSpinner />
                  </div>
                }
              >
                <Switch>
                  {sections.map((section) => {
                    return (
                      <Route
                        exact
                        key={section.id}
                        path={`/feedbunk-management/pens/${id}${section.route}`}
                        render={() => <section.component id={id} />}
                      />
                    );
                  })}
                </Switch>
              </Suspense>
            </Grid>
          </ErrorBoundary>
        </div>
      </ExpandedProvider>
    </Root>
  );
};

export default Pen;
