import Feedbunk from "./Feedbunk";
import Lying from "./Lying";
import Standing from "./Standing";
import OutOfPen from "./OutOfPen";
import LockupTime from "./LockupTime";

const sections = [
  {
    name: "Lying in bed",
    id: "lying",
    translationKey: "lying-in-bed",
    component: Lying,
    route: "/lying",
  },
  {
    name: "Feedbunk Time",
    id: "feedbunk",
    translationKey: "feedbunk-time",
    component: Feedbunk,
    route: "/feedbunk",
  },
  {
    name: "Standing",
    id: "standing",
    translationKey: "standing",
    component: Standing,
    route: "/standing",
  },
  {
    name: "Out of Pen",
    id: "outOfPen",
    translationKey: "out-of-pen",
    component: OutOfPen,
    route: "/out-of-pen",
  },
  {
    name: "Lock-up Time",
    id: "lockupTime",
    translationKey: "lockup-time",
    component: LockupTime,
    route: "/lockup-time",
  },
];

export default sections;
