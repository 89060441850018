import dayjs from "dayjs";

const padZeros = (value) => value.toString().padStart(2, "0");

export const hoursMinFormatter = (hours, min) => {
  return `${hours}h ${min}m`;
};

export const hoursMinPaddedFormatter = (hours, min) => {
  return `${padZeros(hours)}h ${padZeros(min)}m`;
};

export const twelveHoursFormatter = (hours, minutes) => {
  const mins = minutes.toString().padStart(2, "0");
  if (hours === 0 || hours === 24) return `12:${mins} AM`;
  if (hours === 12) return `${hours}:${mins} PM`;
  if (hours < 12) return `${hours}:${mins} AM`;
  return `${hours - 12}:${mins} PM`;
};

export const timeOfDayFormatter = (hours, minutes) => {
  let hoursOfDay = hours % 12;
  hoursOfDay = hoursOfDay === 0 ? 12 : hoursOfDay;
  let minutesOfDay = Math.floor(minutes % 60)
    .toString()
    .padStart(2, "0");
  return `${hoursOfDay}:${minutesOfDay}`;
};

export const minutesToHours = (min, formatter, startMins = 0) => {
  const hours = Math.floor((min + startMins) / 60);
  const minutes = (min + startMins) % 60;

  if (typeof formatter === "function") {
    return formatter(hours, minutes);
  }
  return {
    hours,
    minutes,
  };
};

export const timestampToMinutesInDay = (timestamp) =>
  dayjs(timestamp).hour() * 60 + dayjs(timestamp).minute();

export const getTimeForDisplay = (minutes) => {
  const parsedMinutes = parseInt(minutes, 10);
  if (Number.isInteger(parsedMinutes)) {
    return minutesToHours(parsedMinutes, hoursMinPaddedFormatter);
  }
  return "--h --m";
};
