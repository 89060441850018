import React from "react";
import { styled } from "@mui/material/styles";
import PrintButton from "../../../../CommonComponents/UI/PrintButton";
import FeedStatusExpandableRow from "./FeedStatusExpandableRow";
import times from "../../../utils/feedStatus/times";
import { colors } from "../../../../CommonComponents/UI/theme";
import { useExpandedState } from "../../../../CommonComponents/utils/useExpandedState";

const PREFIX = "TimelineWindows";

const classes = {
  outerContainer: `${PREFIX}-outerContainer`,
  innerContainer: `${PREFIX}-innerContainer`,
  timeContainer: `${PREFIX}-timeContainer`,
  timesInner: `${PREFIX}-timesInner`,
  timesContainer: `${PREFIX}-timesContainer`,
  daysStatusWrapper: `${PREFIX}-daysStatusWrapper`,
  timeShort: `${PREFIX}-timeShort`,
  timeItem: `${PREFIX}-timeItem`,
  contentContainer: `${PREFIX}-contentContainer`,
  contentBackground: `${PREFIX}-contentBackground`,
  timeDelimitter: `${PREFIX}-timeDelimitter`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "isExpanded",
})(({ isExpanded, theme }) => ({
  [`&.${classes.outerContainer}`]: {
    position: "relative",
    background: "white",
    padding: "0 10px 0 0",
    boxShadow: "0px 2px 4px 0px #cccccc",
    marginBottom: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "24px 20px",
      marginBottom: "20px",
    },
    "@media print": {
      boxShadow: "none",
      display: "flex",
      flexDirection: "row",
      padding: 0,
      margin: 0,
    },
  },

  [`& .${classes.innerContainer}`]: {
    width: "100%",
    display: "inline-block",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },

  [`& .${classes.timeContainer}`]: {
    width: "100%",
    marginTop: "16px",
    marginBottom: "8px",
    marginLeft: "-50%",
    "@media print": {
      marginTop: 0,
    },
  },

  [`& .${classes.timesInner}`]: {
    display: "inline-block",
    whiteSpace: "nowrap",
    width: "100%",
    borderBottom: "2px solid #e9e9e9",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#fff",
      width: `calc(100% - 90px)`,
      marginLeft: `${TIMELINE_OFFSET_MOBILE}`,
      borderBottom: "none",
      zIndex: 10,
    },
    "@media print": {
      borderBottom: "2px solid #e9e9e9",
      width: `calc(100% - 82px)`,
    },
  },

  [`& .${classes.timesContainer}`]: {
    display: "inline-block",
    width: `calc(100% - ${TIMELINE_OFFSET})`,
    marginLeft: `${TIMELINE_OFFSET}`,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#fff",
      marginLeft: 0,
      zIndex: 10,
      boxShadow: "0px 2px 4px 0px #cccccc",
      position: "sticky",
      top: "0",
      width: "100vw",
    },
    "@media print": {
      top: 95,
      boxShadow: "none",
    },
  },

  [`& .${classes.daysStatusWrapper}`]: {
    width: "100%",
    height: "100%",
    flex: "none",
    marginLeft: "-100%",
    maxHeight: `${isExpanded ? "calc(100vh - 318px)" : "calc(100vh - 175px)"}`,
    [theme.breakpoints.up("md")]: {
      marginLeft: "-100%",
      maxHeight: "calc(100vh - 318px)",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "auto",
    },
    [theme.breakpoints.down("md")]: {
      overflow: "scroll",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "visible",
      height: "100%",
      maxHeight: "none",
    },
    "@media print": {
      marginLeft: "-100%",
    },
  },

  [`& .${classes.timeShort}`]: {
    display: "none",
    borderLeft: `2px solid ${colors.mercuryGray}`,
    height: 8,
    zIndex: 1,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },

  [`& .${classes.timeItem}`]: {
    display: "inline-block",
    width: "calc(100% / 24)",
    height: "100%",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      "&:nth-child(2n + 1)": {
        visibility: "hidden",
      },
    },
    "@media print": {
      "&:nth-child(2n + 1)": {
        visibility: "visible",
      },
    },
  },

  [`& .${classes.contentContainer}`]: {
    display: "flex",
    width: "100%",
    // marginTop: 60,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },

  [`& .${classes.contentBackground}`]: {
    width: `calc(100% - ${TIMELINE_OFFSET_MOBILE})`,
    marginLeft: `${TIMELINE_OFFSET_MOBILE}`,
    flex: "none",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${TIMELINE_OFFSET})`,
      marginLeft: `${TIMELINE_OFFSET}`,
    },
  },

  [`& .${classes.timeDelimitter}`]: {
    borderLeft: "2px solid #e9e9e9",
    height: "calc(100% - 8px)",
    width: "calc(100% / 24)",
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    top: 10,
    right: 12,
    "@media print": {
      display: "none",
    },
  },
}));

const TIMELINE_OFFSET = "120px";
const TIMELINE_OFFSET_MOBILE = "80px";

const TimelineWindows = ({ days = [] }) => {
  const { isExpanded } = useExpandedState();
  return (
    <Root
      isExpanded={isExpanded}
      className={classes.outerContainer}
      data-cy="Timeline-Weekly"
    >
      <PrintButton />
      <div className={classes.innerContainer}>
        <div className={classes.timesContainer}>
          <div className={classes.timesInner}>
            {times.map((time, index) => (
              <div
                className={classes.timeItem}
                key={`FeedStatus-${time}-${index}`}
              >
                <div className={classes.timeContainer}>
                  <div>{time}</div>
                  {index < 12 ? <span>AM</span> : <span>PM</span>}
                </div>
                <div className={classes.timeShort} />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.contentContainer}>
          <div id="content-background" className={classes.contentBackground}>
            {times.map((time, index) => (
              <div
                className={classes.timeDelimitter}
                key={`FeedStatus-delimitter-${time}-${index}`}
              />
            ))}
          </div>
          <div className={classes.daysStatusWrapper}>
            {days.map((day) => (
              <FeedStatusExpandableRow {...day} key={day.key} />
            ))}
          </div>
        </div>
      </div>
    </Root>
  );
};

export default TimelineWindows;
