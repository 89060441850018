import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "TooltipContent";

const classes = {
  styledWindowTooltipContainer: `${PREFIX}-styledWindowTooltipContainer`,
  messageText: `${PREFIX}-messageText`,
  messageTextBold: `${PREFIX}-messageTextBold`,
  messageDivider: `${PREFIX}-messageDivider`,
  messageTextMarginTop: `${PREFIX}-messageTextMarginTop`,
};

const Root = styled("div")(() => ({
  [`&.${classes.styledWindowTooltipContainer}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "rgb(55, 72, 81)",
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
  },

  [`& .${classes.messageText}`]: {
    textAlign: "center",
    fontWeight: "normal",
    color: "#f4f4f4",
  },

  [`& .${classes.messageTextBold}`]: {
    fontWeight: "bold",
    color: "white",
  },

  [`& .${classes.messageDivider}`]: {
    border: 0,
    borderTop: "1px solid #5d7b84",
    margin: "8px 0",
    width: "100%",
  },

  [`& .${classes.messageTextMarginTop}`]: {
    marginTop: 10,
  },
}));

export const TooltipContent = (props) => {
  return (
    <Root className={classes.styledWindowTooltipContainer}>
      <span className={classes.messageText}> {props.status} </span>
      <hr className={classes.messageDivider} />
      <span className={`${classes.messageText} ${classes.messageText}`}>
        {props.duration}
      </span>
      <div className={`${classes.messageText} ${classes.messageTextMarginTop}`}>
        {props.startTime} - {props.endTime}
      </div>
      <hr className={classes.messageDivider} />
      <span className={classes.messageText}>{props.date}</span>
    </Root>
  );
};

export default TooltipContent;
