import React from "react";
import { styled } from "@mui/material/styles";

const StyledCircle = styled("div")(() => ({
  transform: "translateX(-50%) translateY(-50%)",
  width: 20,
  height: 20,

  margin: "20px 0 0 20px",

  "&:before": {
    content: '""',
    position: "relative",
    display: "block",
    width: "250%",
    height: "250%",
    boxSizing: "border-box",
    marginLeft: "-75%",
    marginTop: "-75%",
    borderRadius: 45,
    backgroundColor: "#75daad",
    animation: "pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite",
  },

  "&:after": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    display: "block",
    width: "100%",
    height: "100%",
    backgroundColor: "#75daad",
    borderRadius: 50,
    animation:
      "circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite",
  },

  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.33)",
    },
    "80%, 100%": {
      opacity: 0,
    },
  },

  "@keyframes circle": {
    "0%": {
      transform: "scale(0.8)",
    },
    "50%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0.8)",
    },
  },
}));

const Circle = () => <StyledCircle />;

export default Circle;
