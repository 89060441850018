import React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const PREFIX = "TimeFieldDisplay";

const classes = {
  timeFieldDisplayContainer: `${PREFIX}-timeFieldDisplayContainer`,
  timeFieldDisplayButton: `${PREFIX}-timeFieldDisplayButton`,
  timeFieldDisplayTime: `${PREFIX}-timeFieldDisplayTime`,
};

const Root = styled("div")(() => ({
  [`&.${classes.timeFieldDisplayContainer}`]: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  [`& .${classes.timeFieldDisplayButton}`]: {
    width: 24,
    height: 25,
    color: "#999999",
    cursor: "pointer",
  },

  [`& .${classes.timeFieldDisplayTime}`]: {
    height: 40,
    padding: "0 8px",
    fontSize: 20,
    lineHeight: "40px",
    color: "#595959",
  },
}));

const TimeFieldDisplay = ({
  showCancelCTA = true,
  time,
  tag,
  onClick: onClickHandler,
}) => {
  const active = typeof time === "string";

  return (
    <Root className={classes.timeFieldDisplayContainer}>
      <div
        className={classes.timeFieldDisplayTime}
        data-cy="time-display-field"
        style={{
          background: active ? "#f9f9f9" : "#e9e9e9",
          width: showCancelCTA ? "calc(100% - 24px)" : "100%",
        }}
      >
        {active ? time : ""}
      </div>
      {active && showCancelCTA ? (
        <div
          className={classes.timeFieldDisplayButton}
          data-thirdparty={tag}
          data-cy={tag}
          onClick={onClickHandler}
        >
          <CloseIcon />
        </div>
      ) : (
        ""
      )}
    </Root>
  );
};

export default TimeFieldDisplay;
