import React from "react";
import PropTypes from "prop-types";

const IContextProps = {
  state: PropTypes.StateType,
  dispatch: PropTypes.Dispatch,
};

const MilkingOperationsContext = React.createContext(IContextProps);

export function useMilkingOperationsContext() {
  return React.useContext(MilkingOperationsContext);
}

export default MilkingOperationsContext;
