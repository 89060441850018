import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Pen from "./Pages/Pen";
function HerdWelfare() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={Dashboard} exact />
      <Route path={`${path}/pens/:id/:sectionId?`} component={Pen} />
      <Redirect to={path} />
    </Switch>
  );
}

export default HerdWelfare;
