import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useMilkingOperationsContext } from "../utils/MilkingOperationsContext";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import Switch from "@mui/material/Switch";
import OpsTable from "../../../../CommonComponents/UI/Operations/OpsTable";
import {
  flexColumnMixIn,
  flexRowMixIn,
} from "../../../../CommonComponents/utils/Operations/cssMixins";
import { xxlPadding } from "../../../../CommonComponents/utils/Operations/css.const";
import { colors } from "../../../../CommonComponents/UI/theme";
import {
  createCellTemplate,
  createHeaderCellTemplate,
  sortTableColumns,
} from "../../../../CommonComponents/utils/Operations/opsTable.common.const";

const PREFIX = "MoPensTable";

const classes = {
  switchSection: `${PREFIX}-switchSection`,
  switchBase: `${PREFIX}-switchBase`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  table: `${PREFIX}-table`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.switchSection}`]: {
    ...flexRowMixIn,
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "500",
    marginTop: `10px`,
    position: "relative",
  },
  [`& .${classes.switchBase}`]: {
    "&$checked": {
      backgroundColor: colors.iconGreen,
    },
    "&$checked + $track": {
      backgroundColor: colors.iconGreen,
      opacity: 1,
      border: "none",
    },
  },
  [`&.${classes.tableWrapper}`]: {
    ...flexColumnMixIn,
    padding: `10px 10px 10px 40px`,
    marginBottom: "60px",
    overflowX: "scroll",
    [theme.breakpoints.up("xs")]: {
      padding: `10px 10px 10px 10px`,
    },
    [theme.breakpoints.up("sm")]: {
      padding: `10px 10px 10px 40px`,
    },
    [theme.breakpoints.up("xxl")]: {
      padding: `10px ${xxlPadding} 10px 40px`,
    },
  },

  [`& .${classes.table}`]: {
    width: "100%",
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "rgba(0, 0, 0, 0.3)",
    "@media print": {
      display: "none",
    },
  },
}));

const MoPensTable = () => {
  const { t } = useTranslation();

  const headers = [
    {
      id: "pen",
      template: createHeaderCellTemplate(
        t("pens-capitalised"),
        "Mo Pens Table Pens Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("pen"),
    },
    {
      id: "group",
      template: createHeaderCellTemplate(
        t("groups-capitalised"),
        "Mo Pens Table Group Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("group"),
    },
    {
      id: "milkingDuration",
      template: createHeaderCellTemplate(
        t("milking-duration"),
        "Mo Pens Table MilkingDuration Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("milkingDuration"),
      align: "right",
    },
    {
      id: "milkingsOnTime",
      template: createHeaderCellTemplate(
        t("milkings-on-time"),
        "Mo Pens Table % MilkingsOnTime Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("milkingsOnTime"),
      align: "right",
    },
    {
      id: "diffFromStartTime",
      template: createHeaderCellTemplate(
        t("difference-from-start-time"),
        "Mo Pens Table DiffFromStartTime Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("diffFromStartTime"),
      align: "right",
    },
  ];

  const {
    overview,
    selectedPen,
    selectPrintSection,
    selectPenId,
  } = useMilkingOperationsContext();

  // clicking a table row either selects a pen or deselects it,
  // if deselected the right-side view switches back to All Pens
  const toggleSelectedPen = (penRow) => {
    const pen = isPenToggledOn(penRow)
      ? null
      : overview.summaries.find((pen) => pen.id === penRow.id);
    selectPenId({ selectedPenId: pen?.id });
  };

  // // passed to Table to highlight a selected row
  const isPenToggledOn = (pen) => {
    return selectedPen.selectedPenId && pen?.id === selectedPen.selectedPenId;
  };

  const [highlightMissedTargets, setHighlightMissedTargets] = useState(false);
  const toggleMissedTargets = () =>
    setHighlightMissedTargets(
      (highlightMissedTargets) => !highlightMissedTargets
    );

  const rows = overview.summaries?.map((pen) => ({
    pen: {
      showMissedTargets: false,
      template: createCellTemplate(pen.name, pen.id, "group", "milking-ops"),
    },
    group: {
      showMissedTargets: false,
      template: createCellTemplate(pen.group, pen.id, "pen", "milking-ops"),
    },
    milkingsOnTime: {
      showMissedTargets: true,
      highlightMissedTargets,
      targetMet: pen.onTime.target_met,
      align: "right",
      template: createCellTemplate(
        pen.onTime.value,
        pen.id,
        "milkingsOnTime",
        "milking-ops"
      ),
    },
    diffFromStartTime: {
      showMissedTargets: true,
      highlightMissedTargets,
      targetMet: pen.difference.target_met,
      align: "right",
      template: createCellTemplate(
        pen.difference.value,
        pen.id,
        "diffFromStartTime",
        "milking-ops"
      ),
    },
    milkingDuration: {
      showMissedTargets: true,
      highlightMissedTargets,
      targetMet: pen.duration.target_met,
      align: "right",
      template: createCellTemplate(
        pen.duration.value,
        pen.id,
        "milkingDuration",
        "milking-ops"
      ),
    },
    key: pen.id,
    onClick: () => toggleSelectedPen(pen),
    hover: true,
    selected: isPenToggledOn(pen),
    dataCy: `milking-ops-table-row-${pen.id}`,
  }));

  return (
    <Root className={classes.tableWrapper}>
      {overview.summaries ? (
        <div>
          <div className={classes.switchSection}>
            <div data-cy="mo-pens-table-targets-switch-label">
              {t("highlight-missed-targets")}
            </div>
            <Switch
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
              }}
              data-cy="mo-pens-table-targets-switch"
              color="primary"
              checked={highlightMissedTargets}
              onChange={toggleMissedTargets}
              name="highlightMissedTargets"
              inputProps={{ "aria-label": "highlight missed targets switch" }}
            />
          </div>

          <OpsTable
            data={{ headers, rows }}
            className={classes.table}
            dataCyAtt="milking-ops"
          />
        </div>
      ) : null}
    </Root>
  );
};

export default MoPensTable;
