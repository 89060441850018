import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderLargeSingleTabLevel from "../../../CommonComponents/UI/HeaderLarge/HeaderLargeSingleTabLevel";
import Tabs from "../../../CommonComponents/UI/Tabs";
import sections from "./sections";
import usePenDetails from "../../../CommonComponents/utils/usePenDetails";
import PenNavigator from "../../../CommonComponents/UI/PenNavigator";
import ExpandedProvider from "../../../CommonComponents/utils/useExpandedState";

const PREFIX = "Pen";

const classes = {
  primaryTab: `${PREFIX}-primaryTab`,
  secondaryTab: `${PREFIX}-secondaryTab`,
  content: `${PREFIX}-content`,
  scrollDisabled: `${PREFIX}-scrollDisabled`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.primaryTab}`]: {
    marginLeft: theme.spacing(5),
    position: "absolute",
    bottom: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(6),
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.secondaryTab}`]: {
    paddingLeft: theme.spacing(7.5),
    position: "fixed",
    width: "100%;",
    zIndex: 4,
    backgroundColor: "#fafafa",
    "& button": {
      textTransform: "none",
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - 256px)`,
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - 296px)`,
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.content}`]: {
    marginTop: 48,
    "@media print": {
      marginTop: 0,
      padding: 0,
      margin: 0,
    },
  },

  [`& .${classes.scrollDisabled}`]: {
    display: "none",
  },
}));

const Pen = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    params: { id, sectionId },
  } = useRouteMatch();

  const pen = usePenDetails(id);

  const secondaryTabs = sections.map((tab) =>
    tab.translationKey ? t(tab.translationKey) : tab.name
  );

  const [secondaryTab, setSecondaryTab] = useState(0);

  useEffect(() => {
    if (sectionId) {
      const tabIndex = sections.findIndex(
        (section) => section.route === `/${sectionId}`
      );
      setSecondaryTab(tabIndex);
    }
  }, [sectionId]);

  const handleSecondaryTabChange = (event, newValue) => {
    history.push(`/herd-welfare/pens/${id}${sections[newValue].route}`);
    setSecondaryTab(newValue);
  };

  return (
    <ExpandedProvider>
      <Root>
        <HeaderLargeSingleTabLevel
          title={pen.label}
          penGroup={pen.group}
          pen={pen}
          renderHeaderTools={() => <PenNavigator />}
        />
        <Tabs
          tabs={secondaryTabs}
          onChange={handleSecondaryTabChange}
          value={secondaryTab}
          variant="scrollable"
          scrollButtons
          TabScrollButtonProps={{
            classes: { disabled: classes.scrollDisabled },
          }}
          className={classes.secondaryTab}
          allowScrollButtonsMobile
        />
        <div className={classes.content}>
          <Switch>
            {sections.map((section) => {
              return (
                <Route
                  exact
                  key={section.id}
                  path={`/herd-welfare/pens/:id${section.route}`}
                  component={section.component}
                />
              );
            })}
          </Switch>
        </div>
      </Root>
    </ExpandedProvider>
  );
};

export default Pen;
