import Typography from "@mui/material/Typography";
import Button from "./Button";

const ErrorFallback = ({ onClick }) => {
  const hasOnClick = typeof onClick === "function";

  return (
    <div>
      <Typography>Something went wrong. Please try again.</Typography>
      {hasOnClick ? <Button title="Retry" onClick={onClick} /> : null}
    </div>
  );
};

export default ErrorFallback;
