import React from "react";
import { useTranslation } from "react-i18next";
import { useAccountDetails } from "../../App/useAccountDetails";
import { useHistory, useLocation } from "react-router-dom";
import Navigator from "./ListNavigator";
import { useParams } from "react-router-dom";

const LOCKUP_TIME = "lockup-time";
const PenNavigator = () => {
  const { t } = useTranslation();
  const { info } = useAccountDetails();
  const { id, sectionId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { pens } = info.farm;

  const options = pens
    .map(({ id, name, useHeadlock }) => ({
      id,
      title: name,
      unavailable: !useHeadlock && sectionId === LOCKUP_TIME,
    }))
    .sort((a, b) => {
      return a.title.localeCompare(b.title, "en", {
        sensitivy: "base",
        numeric: true,
      });
    });

  const onPenSelected = (id, unavailable) => {
    const [, currentId] = location.pathname.match(/pens\/([0-9]+)/);

    let newPath = `${location.pathname.replace(currentId, id)}${
      location.search
    }`;

    if (unavailable && sectionId === LOCKUP_TIME) {
      newPath = `/herd-productivity/pens/${id}/cow-comfort`;
    }

    history.push(newPath);
  };

  return (
    <Navigator
      buttonText={t("change-pen")}
      options={options}
      defaultOption={parseInt(id, 10)}
      onOptionSelected={onPenSelected}
    />
  );
};

export default PenNavigator;
