import React from "react";
import { useQuery } from "react-query";
import { fetchMilkingOperations } from "../../../services/fetchMilkingOperations";
import MilkingOperationsContext from "../utils/MilkingOperationsContext";
import useMilkingOperations from "./milkingOperationsManagement";
import setProvider from "../../../../CommonComponents/utils/Operations/providers.common.util";

const MilkingOperationsProvider = (props) => {
  const { data } = useQuery("milkingOperations", fetchMilkingOperations, {
    refetchOnWindowFocus: false,
  });

  const { last7Days, last30Days } = data;

  const {
    overview,
    groups,
    selectGroup,
    selectedGroup,
    selectedPen,
    selectedDateRange,
    selectedPrintSection,
    selectDateRange,
    selectPrintSection,
    selectPenId,
  } = setProvider(useMilkingOperations, last7Days, last30Days);

  return (
    <MilkingOperationsContext.Provider
      value={{
        overview,
        groups,
        selectGroup,
        selectedGroup,
        selectedPen,
        selectedDateRange,
        selectedPrintSection,
        selectDateRange,
        selectPrintSection,
        selectPenId,
      }}
      {...props}
    >
      {props.children}
    </MilkingOperationsContext.Provider>
  );
};

export default MilkingOperationsProvider;
