import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { getHerdProductivityKpis } from "../../../services/kpis";
import LoadingSpinner from "../../../../CommonComponents/UI/LoadingSpinner";
import CanvasJSReact from "../../../../CommonComponents/Vendor/canvasjs.react";
import {
  minutesToHours,
  hoursMinFormatter,
} from "../../../../CommonComponents/utils/time";
import kpiInfo from "../../../utils/kpiInfo";

const { CanvasJSChart } = CanvasJSReact;

const centerMixIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const useStyles = makeStyles(() => ({
  root: {
    ...centerMixIn,
    justifyContent: "space-around",
    height: "calc(100% - 70px)",
    marginTop: 10,
    "@media print": {
      padding: "10px 0 0 0",
      boxShadow: "none",
    },
  },
  errorRoot: {
    ...centerMixIn,
    padding: 25,
    marginTop: 10,
    height: "calc(100% - 70px)",
  },
  disabled: {
    opacity: "20%",
  },
  errorMessage: {
    flex: 1,
    ...centerMixIn,
  },
}));

const formatForChart = ({ value }) =>
  minutesToHours(value, (hours) => `${hours}h`);

const formatForToolip = ({ value }) => minutesToHours(value, hoursMinFormatter);

const ErrorView = ({ title }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={3} classes={{ root: classes.errorRoot }}>
      <Typography variant="h5" className={classes.disabled}>
        {title}
      </Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {t("card-loading-error-message")}
        </Typography>
      </Box>
    </Paper>
  );
};

const NoDataView = ({ title }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper elevation={3} classes={{ root: classes.errorRoot }}>
      <Typography variant="h5" className={classes.disabled}>
        {title}
      </Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {t("no-data-to-display-chart")}
        </Typography>
      </Box>
    </Paper>
  );
};

const getTooltipContent = (e, title, yFormatter) => {
  const { label, y } = e.entries[0].dataPoint;
  const value = typeof yFormatter === "function" ? yFormatter({ value: y }) : y;
  const name = e.entries[0].dataSeries.name;
  return `
    <div style="min-width: 200px; height: 145px; display: flex;  align-items: center; flex-direction: column; justify-content: space-between; padding: 20px;">
      <span>${name}</span>
      <hr style="flex: none; border: none; height:2px; width: 100%; background-color:#5d7b84;">
      <span><bold>${value}</bold></span>

      
      <hr style="flex: none; border: none; height:2px; width: 100%; background-color:#5d7b84;">
      <span>${label}</span>
    </div>
  `;
};

const allGroups = "All Groups";

const KPILineChart = ({
  title,
  onChartPrint,
  selectedPeriod,
  selectedGroup,
  checkboxes,
  legend,
}) => {
  const params =
    selectedGroup === allGroups
      ? { period: selectedPeriod }
      : { period: selectedPeriod, group: selectedGroup };

  const { data } = useQuery(
    ["herdProductivityKpis", params],
    getHerdProductivityKpis
  );
  const showInLegend = !!legend;

  if (!data) return <NoDataView title={title} />;

  const displayData = Object.entries(data.summaries).map(([key, data]) => {
    const dp = data.map(({ value, date }) => {
      const label = dayjs(date).format("MMM D");
      return {
        label,
        y: value,
        name: key,
      };
    });

    return {
      dataPoints: dp,
      id: key,
      type: "line",
      name: kpiInfo[key].label,
      markerSize: 0,
      lineThickness: 4,
      color: kpiInfo[key].color,
      showInLegend,
    };
  });

  const currentData = displayData.filter((item) => checkboxes[item.id]);

  return (
    <CanvasJSChart
      options={{
        animationEnabled: true,
        toolTip: {
          backgroundColor: "#374851",
          borderColor: "#374851",
          borderThickness: 2,
          fontSize: 16,
          fontColor: "#ffffff",
          fontFamily: "Noto",
          contentFormatter: (e) => getTooltipContent(e, title, formatForToolip),
        },
        axisY: {
          lineThickness: 0,
          tickThickness: 0,
          labelFontSize: 12,
          labelFontFamily: "Noto",
          labelFontColor: "#8c8c8c",
          titleFontSize: 16,
          titleFontWeight: 300,
          titleFontFamily: "Noto",
          titleFontColor: "#595959",
          gridColor: "#e9e9e9",
          lineColor: "#e9e9e9",
          tickColor: "#e9e9e9",
          interval: 120,
          labelFormatter: formatForChart,
        },
        axisX: {
          lineColor: "#e9e9e9",
          tickThickness: 0,
          tickLength: 15,
          labelFontSize: 12,
          labelFontFamily: "Noto",
          labelFontColor: "#8c8c8c",
        },

        data: currentData,
        legend,
      }}
      containerProps={{
        height: "85%",
        width: "100%",
        position: "relative",
      }}
      onChartPrint={onChartPrint}
    />
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary
      FallbackComponent={(fbprops) => (
        <ErrorView {...fbprops} title={props.title} />
      )}
    >
      <KPILineChart {...props} />
    </ErrorBoundary>
  </Suspense>
);
