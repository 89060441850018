import { useEffect, useRef } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PrintButton from "../UI/PrintButton";

var React = require("react");
var CanvasJS = require("./canvasjs.min");
CanvasJS = CanvasJS.Chart ? CanvasJS : window.CanvasJS;

const defaultContainerProps = {
  height: "100%",
  width: "100%",
  position: "relative",
};

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

const CanvasJSChart = ({
  containerProps = defaultContainerProps,
  options = {},
  onChartPrint,
  setCoordinates,
}) => {
  const cjsContainerId = useRef(null);
  if (cjsContainerId.current === null) {
    cjsContainerId.current = getUniqueId();
  }
  let chart = useRef(null);

  let chartContainerId =
    "canvasjs-react-chart-container-" + cjsContainerId.current;

  if (containerProps.id) {
    chartContainerId = "canvasjs-react-chart-container-" + containerProps.id;
  }

  const handleClickAway = () => chart.current.toolTip?.hide();

  useEffect(() => {
    chart.current = new CanvasJS.Chart(chartContainerId, {});

    return () => {
      chart.current.destroy();
    };
  }, [chartContainerId]);

  useDeepCompareEffect(() => {
    chart.current.options = options;
    chart.current.render();
    window.dispatchEvent(new Event("resize"));

    if (setCoordinates) {
      function updateSize() {
        if (chart.current.data[0]?.dataPoints?.length) {
          const start = chart.current.axisX[0].convertValueToPixel(
            chart.current.data[0].dataPoints[0].x
          );
          const chartWidth =
            chart.current.axisX[0].convertValueToPixel(
              chart.current.data[0].dataPoints[
                chart.current.data[0].dataPoints.length - 1
              ].x
            ) - start;
          const canvasWidth = chart.current.width;

          setCoordinates({ start, canvasWidth, chartWidth });
        }
      }

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }
  }, [options]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div id={chartContainerId} style={containerProps} />
      </ClickAwayListener>
      {onChartPrint && <PrintButton onChartPrint={onChartPrint} />}
    </>
  );
};

var CanvasJSReact = {
  CanvasJSChart: CanvasJSChart,
  CanvasJS: CanvasJS,
};

export default CanvasJSReact;
