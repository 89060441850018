import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

const PREFIX = "Legend";

const classes = {
  legendColumn: `${PREFIX}-legendColumn`,
  legendItem: `${PREFIX}-legendItem`,
  legendItemDisabled: `${PREFIX}-legendItemDisabled`,
  legendBox: `${PREFIX}-legendBox`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.legendColumn}`]: {
    "@media print": {
      padding: "0  12px !important",
    },
  },

  [`& .${classes.legendItem}`]: {
    ...centerMixIn,
    cursor: "pointer",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 8,
    "@media print": {
      marginBottom: 0,
    },
  },

  [`& .${classes.legendItemDisabled}`]: {
    opacity: 0.2,
  },

  [`& .${classes.legendBox}`]: {
    display: "inline-block",

    width: 15,
    height: 15,
    marginRight: 10,
  },
}));

const centerMixIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const generateGroupsFromEntries = (config) => {
  let iteration = Object.entries(config);
  const grouped = iteration.reduce((acc, iter) => {
    const [key, value] = iter;
    if (acc[value.group]) acc[value.group].push([key, value]);
    else acc[value.group] = [[key, value]];
    return acc;
  }, {});

  return Object.entries(grouped);
};

const Legend = ({ data, config, onClick }) => {
  const groups = generateGroupsFromEntries(config);

  return (
    <StyledGrid container spacing={3} justifyContent="center">
      {groups.map((group) => {
        const [key, g] = group;
        return (
          <Grid item key={key} className={classes.legendColumn}>
            {g.map((entry) => {
              const [key] = entry;
              const [value] = data.filter((d) => d.id === key);
              return (
                <div
                  className={`${classes.legendItem} ${
                    !value.isActive && classes.legendItemDisabled
                  }`}
                  key={key}
                  onClick={() => onClick(value.id)}
                >
                  <span
                    className={classes.legendBox}
                    style={{
                      background: value.color,
                    }}
                  />
                  <Typography variant="subtitle1">{value.name}</Typography>
                </div>
              );
            })}
          </Grid>
        );
      })}
    </StyledGrid>
  );
};

export default Legend;

Legend.propTypes = {
  config: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};
