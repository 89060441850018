import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const getDisplayData = (series, timezone) =>
  series.map(({ data: entry, id, label }) => {
    const dataPoints = entry.map(({ value, date }) => {
      return {
        x: new Date(dayjs(date).tz(timezone)),
        y: value,
        name: id,
      };
    });
    return { label, dataPoints };
  });

export default getDisplayData;
