import React from "react";
import { useQuery } from "react-query";
import { fetchFeedingOps } from "../../../services/fetchFeedingOps";
import FeedingOpsContext from "../utils/FeedingOpsContext";
import useFeedingOps from "./FeedingOpsManagement";
import setProvider from "../../../../CommonComponents/utils/Operations/providers.common.util";

const FeedingOpsProvider = (props) => {
  const { data } = useQuery("feedingOperations", fetchFeedingOps, {
    refetchOnWindowFocus: false,
  });

  const { last7Days, last30Days } = data;

  const {
    overview,
    groups,
    selectGroup,
    selectedGroup,
    selectedPen,
    selectedDateRange,
    selectedPrintSection,
    selectDateRange,
    selectPrintSection,
    selectPenId,
  } = setProvider(useFeedingOps, last7Days, last30Days);

  return (
    <FeedingOpsContext.Provider
      value={{
        overview,
        selectedPen,
        selectedDateRange,
        selectedPrintSection,
        selectDateRange,
        selectPrintSection,
        selectPenId,
        groups,
        selectGroup,
        selectedGroup,
      }}
      {...props}
    >
      {props.children}
    </FeedingOpsContext.Provider>
  );
};

export default FeedingOpsProvider;
