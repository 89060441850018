import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import LoadingSpinner from "./LoadingSpinner";
import CanvasJSReact from "../Vendor/canvasjs.react";
import translateLabelCategories from "../utils/categoryLabelsTranslations.const";
import { minutesToHours, hoursMinFormatter } from "../utils/time";

const { CanvasJSChart } = CanvasJSReact;

const centerMixIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const useStyles = makeStyles(() => ({
  root: {
    ...centerMixIn,
    justifyContent: "space-around",
    padding: 10,
    height: "100%",
    position: "relative",
    "@media print": {
      boxShadow: "none",
    },
  },
  chartContainer: {
    position: "relative",
    height: "85%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      marginTop: -25,
      marginRight: -10,
    },
  },
  donutIcon: {
    position: "absolute",
    pointerEvents: "none",
    marginTop: -5,
    height: 110,
    width: 110,
    color: "#cccccc",
    "@media print": {
      display: "none",
    },
  },
  errorRoot: {
    ...centerMixIn,
    padding: 25,
    height: "100%",
  },
  disabled: {
    opacity: "20%",
  },
  errorMessage: {
    flex: 1,
    ...centerMixIn,
  },
  printIcon: {
    top: -12,
  },
  link: {
    "@media print": {
      display: "none",
    },
  },
}));

const ErrorView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={3} classes={{ root: classes.errorRoot }}>
      <Typography variant="h5" className={classes.disabled}>
        {t("cow-time-budget")}
      </Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {t("card-loading-error-message")}
        </Typography>
      </Box>
    </Paper>
  );
};

const NoDataView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={3} classes={{ root: classes.errorRoot }}>
      <Typography variant="h5" className={classes.disabled}>
        {t("cow-time-budget")}
      </Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {t("no-data-to-compute-cow-time-budget")}
        </Typography>
      </Box>
    </Paper>
  );
};

const contentFormatter = (e) => {
  const { y, name } = e.entries[0].dataPoint;
  const total = e.entries[0].dataSeries.dataPoints.reduce(
    (acc, cur) => acc + cur.y,
    0
  );
  const percentage = Math.round((y / total) * 100);
  const hourValue = minutesToHours(y, hoursMinFormatter);
  return `<b>${name}:</b> ${hourValue} ${percentage}%`;
};

const DonutChartTile = (props) => {
  const { config, averages, icon: Icon, onChartPrint } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (averages.some((average) => average.raw_value === null)) {
    return <NoDataView />;
  }

  translateLabelCategories.forEach((translationKey) => {
    const avgMatch = averages.filter((avg) => avg.kpi === translationKey);
    if (avgMatch.length) {
      return Object.assign(avgMatch[0], { translationKey: translationKey });
    }
  });

  translateLabelCategories.forEach((translationKey) => {
    const avgMatch = averages.filter((avg) => avg.kpi === translationKey);
    if (avgMatch.length) {
      Object.assign(avgMatch[0], { translationKey: translationKey });
    }
  });

  const displayDataPoints = averages
    .sort((averageA, averageB) => {
      return config[averageA.kpi].order - config[averageB.kpi].order;
    })
    .map((average) => ({
      y: average.raw_value,
      name: average.translationKey ? t(average.translationKey) : average.label,
      color: config[average.kpi].color,
    }));

  return (
    <>
      <div id="donut-chart" className={classes.chartContainer}>
        <CanvasJSChart
          options={{
            animationEnabled: true,
            title: {
              text: t("cow-time-budget"),
              fontFamily: "Noto",
              fontSize: 24,
            },
            legend: {
              maxWidth: 285,
              itemWidth: 130,
              markerMargin: 5,
              type: "circle",
              fontFamily: "Noto",
              fontWeight: "normal",
              horizontalAlign: "center",
              fontSize: 14,
            },
            toolTip: {
              contentFormatter,
            },
            data: [
              {
                legendMarkerType: "circle",
                type: "doughnut",
                startAngle: 270,
                innerRadius: "80%",
                showInLegend: true,
                dataPoints: displayDataPoints,
              },
            ],
          }}
          containerProps={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
          onChartPrint={onChartPrint}
        />
        {Icon ? <Icon className={classes.donutIcon} color="inherit" /> : null}
      </div>
    </>
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <DonutChartTile {...props} />
    </ErrorBoundary>
  </Suspense>
);

DonutChartTile.propTypes = {
  config: PropTypes.object.isRequired,
  icon: PropTypes.elementType,
};
