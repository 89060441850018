import React from "react";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFilterLabel from "../TableFilterLabel";
import { colors } from "../theme";

const PREFIX = "OpsTable";

const classes = {
  problem: `${PREFIX}-problem`,
  parentCell: `${PREFIX}-parentCell`,
  headCell: `${PREFIX}-headCell`,
  tableRow: `${PREFIX}-tableRow`,
  stickyCell: `${PREFIX}-stickyCell`,
  tableContainer: `${PREFIX}-tableContainer`,
  table: `${PREFIX}-table`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.tableContainer}`]: {
    position: "relative",
    backgroundColor: colors.white,
    [theme.breakpoints.down("md")]: {
      "&:before": {
        content: '""',
        position: "absolute",
        right: -10,
        top: 12,
        zIndex: 1,
        background: "linear-gradient(to right, transparent, white)",
        height: "calc(100% - 12px)",
        width: 80,
        pointerEvents: "none",
      },
    },
    "@media print": {
      "&:before": {
        content: "none",
      },
    },
  },
  [`& .${classes.table}`]: {
    width: "100%",
  },
  [`& .${classes.tableRow}`]: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: colors.selectedGreen,
    },
  },

  [`& .${classes.headCell}`]: {
    height: 34,
    [theme.breakpoints.down("xxl")]: {
      padding: 8,
      "&:first-child": {
        padding: "8px 0px 8px 8px",
      },
      "&:nth-child(2)": {
        padding: "8px 0px 8px 8px",
      },
      "&:last-child": {
        padding: "8px 0px 8px 8px",
      },
    },
  },
  [`& .${classes.stickyCell}`]: {
    width: 80,
    [theme.breakpoints.down("md")]: {
      position: "sticky",
      zIndex: 100,
      left: 0,
      backgroundColor: "#fafafa",
      boxShadow: "inset -7px 0 6px -7px rgba(0,0,0,0.4)",
    },
  },

  [`& .${classes.parentCell}`]: {
    padding: "8px",
    borderSpacing: 0,
    height: "32px",
    fontSize: "14px",

    "&:first-of-type": {
      color: "#008389",
      cursor: "pointer",

      "&:hover": {
        textDecoration: "underline",
      },
    },
  },

  [`& .${classes.problem}`]: {
    backgroundColor: colors.tableProblem,
    padding: "8px",
    borderSpacing: 0,
    height: "32px",
    fontSize: "14px",
    color: colors.white,
  },
}));

const OpsTable = ({ data, defaultOrderBy, className, dataCyAtt }) => {
  const { headers, rows } = data;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [filterOption, setFilterOption] = React.useState(null);

  const onRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onRequestFilter = (column) => (selectedOption) => {
    setFilterOption({ column, selectedOption });
  };

  const onRequestClearFilter = () => setFilterOption(null);

  const filteredRows =
    filterOption !== null
      ? rows.filter(
          (row) => row[filterOption.column] === filterOption.selectedOption
        )
      : rows;

  const sortedRows = orderBy
    ? headers
        .find((header) => header.id === orderBy)
        .sortFn(filteredRows, order)
    : rows;

  return (
    <StyledGrid container item classes={{ item: classes.tableContainer }}>
      <Grid item xs={12} style={{ overflowX: "auto", paddingBottom: 6 }}>
        <MuiTable className={className}>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={header.id}
                  align={header.align || "left"}
                  className={classNames(classes.headCell, {
                    [classes.stickyCell]: index === 0,
                  })}
                  sortDirection={orderBy === header.id ? order : false}
                  data-cy={header.dataCy}
                >
                  {header.sortable ? (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : "asc"}
                      onClick={() => onRequestSort(header.id)}
                    >
                      {header.template}
                    </TableSortLabel>
                  ) : header.filterable ? (
                    <TableFilterLabel
                      text={header.label}
                      onSelected={onRequestFilter(header.id)}
                      onClear={onRequestClearFilter}
                      options={[...new Set(rows.map((row) => row[header.id]))]}
                    />
                  ) : (
                    header.template
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row) => {
              return (
                <TableRow
                  key={row.key}
                  hover={row.hover}
                  onClick={row.onClick}
                  selected={row.selected}
                  className={classes.tableRow}
                  data-cy={row.dataCy}
                >
                  {headers.map((header, index) => (
                    <TableCell
                      align={row[header.id].align || "left"}
                      className={classNames(classes.parentCell, {
                        [classes.problem]:
                          row[header.id].showMissedTargets &&
                          row[header.id].highlightMissedTargets &&
                          !row[header.id].targetMet,
                        [classes.stickyCell]: index === 0,
                      })}
                      key={`${header.id}-${row.key}`}
                      data-cy={
                        dataCyAtt +
                        "-table-parent-cell-" +
                        header.id +
                        "-" +
                        row.key +
                        "-pen"
                      }
                    >
                      {row[header.id].template}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </Grid>
    </StyledGrid>
  );
};

export default OpsTable;

OpsTable.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        // optional attributes
        onClick: PropTypes.func,
        hover: PropTypes.bool,
        selected: PropTypes.bool,
      })
    ).isRequired,
  }),
  defaultOrderBy: PropTypes.string,
};

OpsTable.defaultProps = {
  defaultOrderBy: null,
};
