export const reportImageUrls = {
  "Cow Comfort": "cow-comfort",
  "Feedbunk Time": "push-ups",
  "Lying time": "lying-time",
  "Out of pen": "out-of-pen",
  "Time on feet": "cow-comfort",
  "Day Of Week Analysis": "analysis",
  "Feed Availability": "feed-availability",
  "Feed Distribution Analysis": "analysis",
  "Push ups": "push-ups",
};

export const reportsTabs = [
  {
    name: "Reports",
    id: "reports",
    baseRoute: "/all-reports",
    translationKey: "all-reports",
    flagId: "reports",
    show: true,
    reportsModules: {},
  },
  {
    name: "FeedbunkManagement",
    id: "feedbunkManagement",
    baseRoute: "/feedbunk-management",
    translationKey: "feedbunk-management",
    flagId: "feedbunkManagement",
    show: false,
    reportsModules: { module: "feedbunkManagement" },
  },
  {
    name: "HerdProductivity",
    id: "herdProductivity",
    baseRoute: "/herd-productivity",
    translationKey: "herd-productivity",
    flagId: "herdProductivity",
    show: false,
    reportsModules: { module: "herdProductivity" },
  },
  {
    name: "PeopleProtocols",
    id: "peopleProtocols",
    baseRoute: "/people-and-protocols",
    translationKey: "people-and-protocols",
    flagId: "peopleProtocols",
    show: false,
    reportsModules: { module: "peopleProtocols" },
  },

  {
    name: "HerdWelfare",
    id: "herdWelfare",
    baseRoute: "/herd-welfare",
    translationKey: "herd-welfare",
    flagId: "herdWelfare",
    show: false,
    reportsModules: { module: "herdWelfare" },
  },
];
