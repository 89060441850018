import axios from "axios";

export async function fetchFeedingOps() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/nutrition/feeding-operations`
  );
  return response.data;
}

export async function fetchFeedingOpsByPenId(query) {
  const penId = query.queryKey[1];

  const response = await axios.get(
    `${window._env_.API_URL}/private/nutrition/feeding-operations/${penId}`
  );
  return response.data;
}

export async function fetchFeedingOpsSmartInsights() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/nutrition/feeding-operations/smart-insights`
  );
  return response.data;
}
