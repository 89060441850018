import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Cleanout from "../../../CommonComponents/Icons/Cleanouts";
import Milking from "../../../CommonComponents/Icons/Milking";
import Pushup from "../../../CommonComponents/Icons/PushUps";
import Delivery from "../../../CommonComponents/Icons/Deliveries";
import { colors } from "../../../CommonComponents/UI/theme";

const PREFIX = "DetailsTabItem";

const classes = {
  detailsTabItemContainer: `${PREFIX}-detailsTabItemContainer`,
  detailsTabItemContainerActive: `${PREFIX}-detailsTabItemContainerActive`,
  detailsTabItemDetails: `${PREFIX}-detailsTabItemDetails`,
  detailsTabItemTitle: `${PREFIX}-detailsTabItemTitle`,
  detailsTabItemInfo: `${PREFIX}-detailsTabItemInfo`,
  detailsTabItemIcon: `${PREFIX}-detailsTabItemIcon`,
};

const Root = styled("div")(() => ({
  [`&.${classes.detailsTabItemContainer}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 64,
    padding: "16px 20px",
    borderBottom: "2px solid #e9e9e9",
    cursor: "pointer",
    flex: "1 1 240px",
  },

  [`&.${classes.detailsTabItemContainerActive}`]: {
    cursor: "initial",
    background: "#fefefe",
    border: "2px solid #e9e9e9",
    borderBottom: "none",
  },

  [`& .${classes.detailsTabItemDetails}`]: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },

  [`& .${classes.detailsTabItemTitle}`]: {
    fontSize: 20,
    color: "#595959",
  },

  [`& .${classes.detailsTabItemInfo}`]: {
    fontSize: 16,
    color: "#888888",
  },

  [`& .${classes.detailsTabItemIcon}`]: {
    width: 40,
    height: 40,
    fontSize: 40,
    // fill: ({ active, activeColor = "blue" }) =>
    //   `${active ? activeColor : "#999999"}`,
  },
}));

const DeliveryIcon = styled((props) => (
  <Delivery
    htmlColor={props.active ? colors.delivery : "#cccccc"}
    fontSize="inherit"
    stroke={props.active ? colors.delivery : "#cccccc"}
    strokeWidth={0.5}
  />
))({});

const CleanoutIcon = styled((props) => (
  <Cleanout
    htmlColor={props.active ? colors.cleanout : "#cccccc"}
    fontSize="inherit"
    stroke={props.active ? colors.cleanout : "#cccccc"}
    strokeWidth={0.5}
  />
))({});

const PushupIcon = styled((props) => (
  <Pushup
    htmlColor={props.active ? colors.pushups : "#cccccc"}
    stroke={props.active ? colors.pushups : "#cccccc"}
    fontSize="inherit"
    strokeWidth={0.5}
  />
))({});

const MilkingIcon = styled((props) => (
  <Milking
    htmlColor={props.active ? colors.milking : "#cccccc"}
    stroke={props.active ? colors.milking : "#cccccc"}
    fontSize="inherit"
    strokeWidth={0.5}
  />
))({});

const ICON_MAP = {
  delivery: (props) => <DeliveryIcon {...props} />,
  cleanout: (props) => <CleanoutIcon {...props} />,
  pushup: (props) => <PushupIcon {...props} />,
  milking: (props) => <MilkingIcon {...props} />,
};

const DetailsTabItem = ({
  active,
  activeColor,
  // Icon,
  iconKey,
  tag,
  id,
  info,
  onClick: onClickCallback,
  title,
}) => {
  const [subInfo, setSubInfo] = useState(info);

  useEffect(() => {
    setSubInfo(info);
  }, [info]);

  const containerClass = active
    ? `${classes.detailsTabItemContainer} ${classes.detailsTabItemContainerActive}`
    : classes.detailsTabItemContainer;

  const Icon = ICON_MAP[iconKey];

  return (
    <Root
      className={containerClass}
      data-thirdparty={`${tag}-item-${id}`}
      data-cy={`${tag}-item-${id}`}
      active={active}
      onClick={() => {
        onClickCallback(id);
      }}
    >
      <div className={classes.detailsTabItemIcon}>
        <Icon active={active} />
      </div>
      <div className={classes.detailsTabItemDetails}>
        <span className={classes.detailsTabItemTitle}>{title}</span>
        <span className={classes.detailsTabItemInfo}>{subInfo}</span>
      </div>
    </Root>
  );
};

export default DetailsTabItem;
