import { styled } from "@mui/material/styles";
import { colors } from "../theme";
import {
  flexRowMixIn,
  flexColumnMixIn,
} from "../../utils/Operations/cssMixins";

const PREFIX = "InsightsOverview";

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
};

const StyledInsightsOverview = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    height: "100%",
    background: colors.whiteSmoke,
    padding: "0",
    borderLeftWidth: "4px",
    borderLeftStyle: "solid",
    borderLeftColor: colors.gray88,
    width: "30%",
    [theme.breakpoints.up("xs")]: {
      minWidth: "0",
      padding: "0",
      borderLeft: "none",
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      borderLeftColor: colors.gray88,
      display: "flex",
      width: "40%",
    },
    [theme.breakpoints.up("xl")]: {
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      borderLeftColor: colors.gray88,
      display: "flex",
      width: "30%",
    },
    "@media print": {
      display: "block",
      width: "100%",
    },
  },
}));

export default StyledInsightsOverview;

export const insightsCssMixIn = {
  insights: {
    ...flexRowMixIn,
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
};
