import dayjs from "dayjs";
import {
  STATUS_COLOR_MAP,
  STATUS_COLOR_MAP_LIGHT,
  STATUS_TEXT_MAP,
} from "./statusMaps";
import {
  hoursMinFormatter,
  minutesToHours,
  twelveHoursFormatter,
} from "../../../CommonComponents/utils/time";

const convertWindowForDisplay = (rawWindow, date, colorMap, start) => ({
  color: colorMap[rawWindow.status.code],
  width: rawWindow.endTimeMinutes - rawWindow.startTimeMinutes,
  duration: minutesToHours(
    rawWindow.endTimeMinutes - rawWindow.startTimeMinutes,
    hoursMinFormatter
  ),
  startTime: minutesToHours(
    rawWindow.startTimeMinutes,
    twelveHoursFormatter,
    start
  ),
  endTime: minutesToHours(
    rawWindow.endTimeMinutes,
    twelveHoursFormatter,
    start
  ),
  status: STATUS_TEXT_MAP[rawWindow.status.code],
  date,
});

const getMinutesSinceMidnight = (d) => {
  const e = new Date(d);
  return (d - e.setHours(0, 0, 0, 0)) / 60000;
};

const getDate = (
  startTimeMinutes,
  minutesSinceMidnight,
  endDate,
  startDate
) => {
  const startTime = startTimeMinutes === 1440 ? 0 : startTimeMinutes;
  const isEndDay = startTime >= 0 && startTime < minutesSinceMidnight;
  const date = isEndDay ? endDate : startDate;
  return date;
};

const formatDataWindows = (data, startMins = 0) => {
  const { barnOrientation = "", id, label } = data.pen;
  const windowsData = [...data.windows].reverse();
  const {
    minutelyWindows,
    minutelyWindowsPerSection = [],
    summary,
    range,
  } = windowsData[0];
  const startDate = dayjs(range.start).format("MMM D");
  const endDate = dayjs(range.end).format("MMM D");
  const formattedEndTime = dayjs(range.end)
    .tz(data.timezone)
    .format("YYYY-MM-DTHH:mm:ss");
  const minutesSinceMidnight = getMinutesSinceMidnight(
    new Date(formattedEndTime)
  );

  const day = {
    key: id,
    date: startDate,
    summary,
    label,
    accessTime: `${Math.floor(summary.minutesNotOK / 60)}h ${(
      summary.minutesNotOK % 60
    )
      .toString()
      .padStart(2, "0")}m`,
    windows: minutelyWindows.map((raw) => {
      const date = getDate(
        raw.startTimeMinutes,
        minutesSinceMidnight,
        endDate,
        startDate
      );
      return convertWindowForDisplay(
        raw,
        date,
        STATUS_COLOR_MAP("FEED_STATUS"),
        startMins
      );
    }),
    penId: id,
    barnOrientation,
    sectionBreakdown: minutelyWindowsPerSection.map((rawBreakdown) => {
      return {
        label: parseInt(rawBreakdown.sectionNumber, 10),
        windows: rawBreakdown.windows.map((raw) => {
          const date = getDate(
            raw.startTimeMinutes,
            minutesSinceMidnight,
            endDate,
            startDate
          );

          return convertWindowForDisplay(
            raw,
            date,
            STATUS_COLOR_MAP_LIGHT,
            startMins
          );
        }),
      };
    }),
  };
  return day;
};
export default formatDataWindows;
