import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { colors } from "../theme";
import {
  flexCenterColumnMixIn,
  flexColumnMixIn,
  flexRowMixIn,
} from "../../utils/Operations/cssMixins";

const PREFIX = "OpsNoInsightsCard";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      padding: "0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px",
    },
  },

  [`& .${classes.content}`]: {
    ...flexColumnMixIn,
    justifyContent: "center",
    width: "100%",
    background: colors.white,
    paddingBottom: "30px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.33)",
  },

  [`& .${classes.title}`]: {
    ...flexRowMixIn,
    justifyContent: "center",
    fontSize: "20px",
    color: colors.black,
    lineHeight: "37px",
    fontWeight: "500",
    padding: "20px 50px",
  },

  [`& .${classes.section}`]: {
    ...flexCenterColumnMixIn,
    minWidth: "150px",
    padding: "0 20px",
  },

  [`& .${classes.sectionTitle}`]: {
    fontSize: "16px",
    color: colors.gray35,
    padding: "0 20px",
  },
}));

const OpsNoInsightsCard = () => {
  const { t } = useTranslation();

  return (
    <Root className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.title}>{t("smart-insights")}</div>

        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            {t("no-insights-to-report")}
          </div>
        </div>
      </div>
    </Root>
  );
};

export default OpsNoInsightsCard;
