import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Toast from "../../../CommonComponents/UI/Toast";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

import {
  NutrtionTargetGroupsConfig,
  BehaviorTargetGroupsConfig,
} from "./TargetGroupsConfig.js";
import InputFactory from "./InputFactory";

import {
  getGroupTargetSettings,
  setGroupTargetSettings,
} from "../../services/targets";
import Header from "../../../CommonComponents/UI/Header";
import { useAccountDetails } from "../../../App/useAccountDetails";

const PREFIX = "TargetSettingsPage";

const classes = {
  content: `${PREFIX}-content`,
  rowDescription: `${PREFIX}-rowDescription`,
  descriptionTitle: `${PREFIX}-descriptionTitle`,
  descriptionExplantion: `${PREFIX}-descriptionExplantion`,
  rowTitles: `${PREFIX}-rowTitles`,
  rowEntries: `${PREFIX}-rowEntries`,
  columnFieldTarget: `${PREFIX}-columnFieldTarget`,
  columnFieldKPI: `${PREFIX}-columnFieldKPI`,
  toolbar: `${PREFIX}-toolbar`,
};

const Content = styled("section")(({ theme }) => ({
  [`&.${classes.content}`]: {
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
    display: "inline-block",
  },

  [`& .${classes.rowDescription}`]: {
    display: "inline-block",
    width: "305px",
    marginRight: "50px",
    fontSize: "20px",
    flexShrink: 0,
  },

  [`& .${classes.descriptionTitle}`]: {
    display: "inline-block",
    color: "#595959",
    marginBottom: "8px",
    whiteSpace: "break-spaces",
  },

  [`& .${classes.descriptionExplantion}`]: {
    color: "#939393",
    whiteSpace: "break-spaces",
  },

  [`& .${classes.rowTitles}`]: {
    margin: "20px 0",
    padding: "0 20px",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.rowEntries}`]: {
    marginBottom: "10px",
    padding: "16px 20px",
    borderTop: "2px solid #e9e9e9",
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "space-between",
  },

  [`& .${classes.columnFieldTarget}`]: {
    width: "135px",
    marginRight: "220px",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },

  [`& .${classes.columnFieldKPI}`]: {
    width: "135px",
    marginRight: "25px",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },

  [`& .${classes.toolbar}`]: {
    height: 96,
  },
}));

const conversionForPage = (dataArray) => {
  return dataArray.reduce((acc, curr) => {
    const { groupId, key } = curr;
    acc[`${key}_${groupId}`] = { hasError: false, ...curr };
    return acc;
  }, {});
};

const conversionForSave = (dataObject) => {
  const values = Object.values(dataObject);
  return values.map(({ hasError, value, ...rest }) => ({
    value: parseInt(value, 10),
    ...rest,
  }));
};

// not the fastest way to clone, but good enough for our purposes
const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

// this  is also limited but will work for this situation since neither object has functions in it
const deepCompare = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

const combine = (key, group) => `${key}_${group}`;

const RowDescription = (props) => {
  const { t } = useTranslation();
  return (
    <div className={classes.rowDescription}>
      <Typography variant="h5" className={classes.descriptionTitle}>
        {t(props.titleTranslationKey)}
      </Typography>
      <br />
      <Typography variant="subtitle1" className={classes.descriptionExplantion}>
        {t(props.descriptionTranslationKey)}
      </Typography>
    </div>
  );
};

const TargetSettingsPage = () => {
  const { t } = useTranslation();
  const { permissions } = useAccountDetails();
  const hasBehaviorEnabled = permissions.includes("farm-user:ui-show-behavior");
  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  let TargetGroupsConfig = NutrtionTargetGroupsConfig;

  if (hasBehaviorEnabled) {
    TargetGroupsConfig = [...TargetGroupsConfig, ...BehaviorTargetGroupsConfig];
  }

  const cache = useQueryClient();

  const { data } = useQuery("group-target-settings", getGroupTargetSettings, {
    refetchOnWindowFocus: false,
  });

  const { groups = [], targets = [] } = data;
  const converted = conversionForPage(targets);

  const [pageState, setPageState] = React.useState(converted);
  const [previousPageState, setPreviousPageState] = React.useState(converted);
  const display = Object.values(pageState);

  const { mutate, status } = useMutation(setGroupTargetSettings, {
    onSuccess: (data) => {
      cache.setQueryData("group-target-settings", data);
      const conversion = conversionForPage(data.targets);
      setPageState(conversion);
      setPreviousPageState(conversion);

      setToast({
        show: true,
        type: "success",
        message: t("successfully-updated"),
      });
    },
    onError: () =>
      setToast({
        show: true,
        type: "error",
        message: t("problem-on-our-end-message"),
      }),
  });

  const handleOnChange = React.useCallback((update) => {
    const { name, value, isValid } = update;
    setPageState((prevState) => {
      const toUpdate = deepClone(prevState);
      toUpdate[name].value = value;
      toUpdate[name].hasError = !isValid;
      return toUpdate;
    });
  }, []);

  // button states
  const statesAreSame = deepCompare(pageState, previousPageState);
  const buttonsDisbled = status === "loading" || statesAreSame;
  const cancelDisabled = buttonsDisbled;
  const saveDisabled =
    buttonsDisbled || display.some((entry) => entry.hasError);

  const handleSavePressed = () => {
    mutate({ targets: conversionForSave(pageState) });
  };

  const handleCancelPressed = () => {
    cache.invalidateQueries("group-target-settings");
    setPageState(previousPageState);
  };

  return (
    <>
      <Header title={t("target-settings")} />
      <Content className={classes.content}>
        <Toolbar className={classes.toolbar}>
          <div style={{ flexGrow: 1 }} />
          <Button disabled={cancelDisabled} onClick={handleCancelPressed}>
            {t("cancel")}
          </Button>
          <Button disabled={saveDisabled} onClick={handleSavePressed}>
            {t("save-changes")}
          </Button>
        </Toolbar>
        <Paper
          elevation={3}
          style={{
            display: "inline-block",
          }}
        >
          <div id="header" className={classes.rowTitles}>
            <Typography
              variant="subtitle1"
              className={classes.columnFieldTarget}
            >
              {t("targets")}
            </Typography>
            {groups
              .sort((a, b) => a.id - b.id)
              .map((cell) => (
                <Typography
                  variant="subtitle1"
                  className={classes.columnFieldKPI}
                  key={cell.id}
                  title={cell.name}
                >
                  {cell.name}
                </Typography>
              ))}
          </div>
          {TargetGroupsConfig.map((config) => (
            <div key={`row-${config.key}`} className={classes.rowEntries}>
              <RowDescription {...config} />

              {display
                .filter((item) => item.key === config.key)
                .sort((a, b) => a.groupId - b.groupId)
                .map((cell) => (
                  <div
                    key={combine(cell.key, cell.groupId)}
                    className={classes.columnFieldKPI}
                  >
                    <InputFactory
                      enabled={cell.enabled ? 1 : 0}
                      type={cell.key}
                      name={combine(cell.key, cell.groupId)}
                      value={cell.value}
                      showError={cell.hasError}
                      onChange={handleOnChange}
                    />
                  </div>
                ))}
            </div>
          ))}
        </Paper>
        <Toast toast={toast} closeToast={closeToast} />
      </Content>
    </>
  );
};

export default TargetSettingsPage;
