import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeedingOpsContext } from "../utils/FeedingOpsContext";
import makeStyles from "@mui/styles/makeStyles";
import Switch from "@mui/material/Switch";
import OpsTable from "../../../../CommonComponents/UI/Operations/OpsTable";
import {
  flexColumnMixIn,
  flexRowMixIn,
} from "../../../../CommonComponents/utils/Operations/cssMixins";
import { xxlPadding } from "../../../../CommonComponents/utils/Operations/css.const";
import {
  createCellTemplate,
  createHeaderCellTemplate,
  sortTableColumns,
} from "../../../../CommonComponents/utils/Operations/opsTable.common.const";
import { colors } from "../../../../CommonComponents/UI/theme";

const useStyles = makeStyles((theme) => ({
  switchSection: {
    ...flexRowMixIn,
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "500",
    marginTop: `10px`,
    position: "relative",
  },
  switchBase: {
    "&$checked": {
      backgroundColor: colors.iconGreen,
    },
    "&$checked + $track": {
      backgroundColor: colors.iconGreen,
      opacity: 1,
      border: "none",
    },
  },
  tableWrapper: {
    ...flexColumnMixIn,
    padding: `10px 10px 10px 40px`,
    marginBottom: "60px",
    overflowX: "scroll",
    [theme.breakpoints.up("xs")]: {
      padding: `10px 10px 10px 10px`,
    },
    [theme.breakpoints.up("sm")]: {
      padding: `10px 10px 10px 40px`,
    },
    [theme.breakpoints.up("xxl")]: {
      padding: `10px ${xxlPadding} 10px 40px`,
    },
  },
  table: {
    width: "100%",
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "rgba(0, 0, 0, 0.3)",
    "@media print": {
      display: "none",
    },
  },
}));

const FoPensTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const headers = [
    {
      id: "pen",
      template: createHeaderCellTemplate(
        t("pens-capitalised"),
        "Fo Pens Table Pens Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("pen"),
    },
    {
      id: "group",
      template: createHeaderCellTemplate(
        t("groups-capitalised"),
        "Fo Pens Table Group Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("group"),
    },
    {
      id: "deliveriesOnTime",
      template: createHeaderCellTemplate(
        t("1st-deliveries-on-time"),
        "Fo Pens Table % DeliveriesOnTime Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("deliveriesOnTime"),
    },
    {
      id: "cleanBunkTime",
      template: createHeaderCellTemplate(
        t("clean-bunk-time"),
        "Fo Pens Table AvgCleanBunkTime Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("cleanBunkTime"),
    },
    {
      id: "pushUpsCompleted",
      template: createHeaderCellTemplate(
        t("push-ups-completed"),
        "Fo Pens Table PushUpsCompleted Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("pushUpsCompleted"),
    },
    {
      id: "lowFeedTime",
      template: createHeaderCellTemplate(
        t("low-feed-time-avg"),
        "Fo Pens Table AvgLowFeedTime Header"
      ),
      sortable: true,
      sortFn: sortTableColumns("lowFeedTime"),
    },
  ];

  const { overview, selectedPen, selectPenId } = useFeedingOpsContext();

  // clicking a table row either selects a pen or deselects it,
  // if deselected the right-side view switches back to All Pens
  const toggleSelectedPen = (penRow) => {
    const pen = isPenToggledOn(penRow)
      ? null
      : overview.summaries.find((pen) => pen.id === penRow.id);
    selectPenId({ selectedPenId: pen?.id });
  };

  // // passed to Table to highlight a selected row
  const isPenToggledOn = (pen) => {
    return selectedPen.selectedPenId && pen?.id === selectedPen.selectedPenId;
  };

  const [highlightMissedTargets, setHighlightMissedTargets] = useState(false);
  const toggleMissedTargets = () =>
    setHighlightMissedTargets(
      (highlightMissedTargets) => !highlightMissedTargets
    );

  const rows = overview.summaries?.map((pen) => ({
    pen: {
      showMissedTargets: false,
      template: createCellTemplate(pen.name, pen.id, "group", "feeding-ops"),
    },
    group: {
      showMissedTargets: false,
      template: createCellTemplate(pen.group, pen.id, "pen", "feeding-ops"),
    },
    deliveriesOnTime: {
      showMissedTargets: true,
      highlightMissedTargets,
      targetMet: pen.deliveriesOnTime.target_met,
      align: "center",
      template: createCellTemplate(
        pen.deliveriesOnTime.value,
        pen.id,
        "deliveriesOnTime",
        "feeding-ops"
      ),
    },
    cleanBunkTime: {
      highlightMissedTargets,
      targetMet: pen.cleanBunkTime.target_met,
      showMissedTargets: true,
      align: "center",
      template: createCellTemplate(
        pen.cleanBunkTime.value,
        pen.id,
        "cleanBunkTime",
        "feeding-ops"
      ),
    },
    lowFeedTime: {
      highlightMissedTargets,
      targetMet: pen.lowFeedTime.target_met,
      showMissedTargets: true,
      align: "center",
      template: createCellTemplate(
        pen.lowFeedTime.value,
        pen.id,
        "lowFeedTime",
        "feeding-ops"
      ),
    },
    pushUpsCompleted: {
      showMissedTargets: true,
      highlightMissedTargets,
      align: "center",
      targetMet: pen.pushUpsCompleted.target_met,
      template: createCellTemplate(
        pen.pushUpsCompleted.value,
        pen.id,
        "pushUpsCompleted",
        "feeding-ops"
      ),
    },
    key: pen.id,
    onClick: () => toggleSelectedPen(pen),
    hover: true,
    selected: isPenToggledOn(pen),
    dataCy: `feeding-ops-table-row-${pen.id}`,
  }));

  return (
    <div className={classes.tableWrapper}>
      {overview.summaries ? (
        <div>
          <div className={classes.switchSection}>
            <div data-cy="fo-pens-table-targets-switch-label">
              {t("highlight-missed-targets")}
            </div>
            <Switch
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
              }}
              data-cy="fo-pens-table-targets-switch"
              color="primary"
              checked={highlightMissedTargets}
              onChange={toggleMissedTargets}
              name="highlightMissedTargets"
              inputProps={{ "aria-label": "highlight missed targets switch" }}
            />
          </div>

          <OpsTable
            data={{ headers, rows }}
            className={classes.table}
            dataCyAtt="feeding-ops"
          />
        </div>
      ) : null}
    </div>
  );
};

export default FoPensTable;
