import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import Tabs from "../../../CommonComponents/UI/Tabs";
import Header from "../../../CommonComponents/UI/Header";
import sections from "./sections";

const PREFIX = "Alerts";

const classes = {
  content: `${PREFIX}-content`,
};

const Content = styled("section")(({ theme }) => ({
  [`&.${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    },
  },
}));

const Alerts = () => {
  const { t } = useTranslation();
  const { sectionId } = useParams();
  const history = useHistory();
  const tabNames = [t("recipients"), t("set-alerts"), t("alert-log")];
  const [tabValue, setTabValue] = useState(2);

  useEffect(() => {
    if (sectionId) {
      const tabIndex = sections.findIndex(
        (section) => section.route === `/${sectionId}`
      );
      setTabValue(tabIndex);
    } else {
      history.push(`/settings/alerts/log`);
    }
  }, [history, sectionId]);

  const onTabChange = (event, newValue) => {
    history.push(`/settings/alerts${sections[newValue].route}`);
    setTabValue(newValue);
  };

  return (
    <>
      <Header title={t("alerts")} />
      <Tabs tabs={tabNames} onChange={onTabChange} value={tabValue} />
      <Content className={classes.content}>
        <Switch>
          {sections.map((section) => (
            <Route
              exact
              key={section.id}
              path={`/settings/alerts${section.route}`}
              component={section.component}
            />
          ))}
        </Switch>
      </Content>
    </>
  );
};

export default Alerts;
