import React from "react";
import { useTranslation } from "react-i18next";
import Link from "../../CommonComponents/UI/Link";
import Table from "../../CommonComponents/UI/TableBasic";

const Wrap = (kpi, url) => {
  return <Link text={kpi.value || kpi} url={url} />;
};

const links = {
  lowFeedTime: (id) => `feedbunk-management/pens/${id}/feed-status`,
  feedbunk: (id) => `herd-productivity/pens/${id}/feedbunk`,
  lying: (id) => `herd-productivity/pens/${id}/lying`,
  standing: (id) => `herd-productivity/pens/${id}/standing`,
  emptyPen: (id) => `herd-productivity/pens/${id}/out-of-pen`,
  pushups: (id) => `feedbunk-management/pens/${id}/feed-events`,
  protocolPushups: () => `people-and-protocols/feeding-operations`,
  deliveries: (id) => `feedbunk-management/pens/${id}/feed-events`,
  duration: () => `people-and-protocols/milking-operations`,
  onTime: () => `people-and-protocols/milking-operations`,
  cowComfortIndex: (id) => `herd-welfare/pens/${id}/cow-comfort`,
};

const generateConversion = (kpi) => (pen) => {
  const { name, id, value } = pen;

  return {
    penName: (kpi in links ? Wrap(name, links[kpi](id)) : name),
    key: id,
    value,
  };
};

const KPI = ({ kpi, worstPens }) => {
  const { t } = useTranslation();
  const convertForDisplay = generateConversion(kpi);
  const rows = worstPens ? worstPens.map(convertForDisplay) : [];
  const title = t("worst-pens");

  const headers = [
    { id: "penName", align: "left" },
    { id: "value", align: "right" },
  ];

  return <Table data={{ title, headers, rows }} />;
};

export default KPI;
