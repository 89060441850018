import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { performanceCssMixIn } from "../../../../CommonComponents/utils/Operations/performanceCssMixins";

const useStyles = makeStyles(() => ({
  ...performanceCssMixIn,
}));

const FoPerformance = ({ averageLabel, summary }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { avgValue, changeVsPrevious, worstDay } = summary;

  return (
    <div className={classes.performance} data-cy="FO Performance">
      <div className={classes.titleRow}>
        <div className={classes.title} data-cy="FO Performance Heading">
          {t("performance-for-last-30-days")}
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.columnFullWidth}>
          <div
            className={classes.subTitle}
            data-cy="fo-performance-average-title"
          >
            {t(averageLabel)}
          </div>
          <div className={classes.text} data-cy="fo-performance-average-text">
            {avgValue}
          </div>
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.column}>
          <div
            className={classes.subTitle}
            data-cy="FO Clean Bunk Time Performance ChangeVsPrev Subheading"
          >
            {t("change-vs-previous-30-days")}
          </div>
          <div
            className={classes.text}
            data-cy="FO Clean Bunk Time Performance ChangeVsPrev Text"
          >
            {changeVsPrevious}
          </div>
        </div>

        <div className={classes.column}>
          <div
            className={classes.subTitle}
            data-cy="FO Clean Bunk Time Performance WorstDay Subheading"
          >
            {t("worst-day-on-average")}
          </div>
          <div
            className={classes.text}
            data-cy="FO Clean Bunk Time Performance WorstDay Text"
          >
            {worstDay}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoPerformance;
