import React, { useCallback, useState, Suspense } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { useParams, useHistory, useLocation } from "react-router-dom";
import useProductivityData from "../../../utils/useProductivityData";
import { getPenProductivityByHour } from "../../../services/timeBudget";
import { getPenEvents } from "../../../services/pen";
import LoadingSpinner from "../../../../CommonComponents/UI/LoadingSpinner";
import useDatesforPen from "../../../../CommonComponents/utils/useDatesForPen";
import { useAccountDetails } from "../../../../App/useAccountDetails";
import TimeBudgetIcon from "../../../../CommonComponents/Icons/TimeBudget";
import Link from "../../../../CommonComponents/UI/Link";
import Legend from "./Legend";
import CanvasJSReact from "../../../../CommonComponents/Vendor/canvasjs.react";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import EventBar from "./EventBar";
import DateBar from "./DateBar";
import config from "./config";

const { CanvasJSChart } = CanvasJSReact;
dayjs.extend(utc);
dayjs.extend(timezone);

const centerMixin = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const PREFIX = "ProductivityByHour";

const classes = {
  chart: `${PREFIX}-chart`,
  chartInner: `${PREFIX}-chartInner`,
  chartTitle: `${PREFIX}-chartTitle`,
  chartIcon: `${PREFIX}-chartIcon`,
  subtitle: `${PREFIX}-subtitle`,
  arrow: `${PREFIX}-arrow`,
  link: `${PREFIX}-link`,
  errorRoot: `${PREFIX}-errorRoot`,
  disabled: `${PREFIX}-disabled`,
  errorMessage: `${PREFIX}-errorMessage`,
};

const Root = styled("div")(({ disabled }) => ({
  [`& .${classes.chart}`]: {
    ...centerMixin,
    margin: "40px 0",
    height: 530,
    width: "100%",
    "@media print": {
      boxShadow: "none",
      margin: 0,
      height: 520,
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  [`& .${classes.chartInner}`]: {
    ...centerMixin,
    flexDirection: "row",
    height: "100%",
    width: "100%",
  },
  [`& .${classes.chartTitle}`]: {
    ...centerMixin,
    flexDirection: "row",
    "@media print": {
      marginTop: 6,
    },
  },
  [`& .${classes.chartIcon}`]: {
    height: 40,
    width: 40,
    marginRight: 8,
  },
  [`& .${classes.subtitle}`]: {
    maxWidth: 500,
    textAlign: "center",
  },
  [`& .${classes.link}`]: {
    "@media print": {
      display: "none",
    },
  },
  [`& .${classes.arrow}`]: {
    userSelect: "none",
    color: disabled ? "#cccccc" : "#595959",
    cursor: disabled ? "default" : "pointer",
    "@media print": {
      display: "none",
    },
  },
  [`& .${classes.errorRoot}`]: {
    ...centerMixin,
    padding: 25,
    height: "100%",
  },
  [`& .${classes.disabled}`]: {
    opacity: "20%",
  },
  [`& .${classes.errorMessage}`]: {
    flex: 1,
    ...centerMixin,
  },
}));

const contentFormatter = (e) => {
  const { tooltipTime, y } = e.entries[0].dataPoint;
  const value = `${Math.round(y)}%`;
  const name = e.entries[0].dataSeries.name;
  return `
    <div style="min-width: 200px; height: 145px; display: flex;  align-items: center; flex-direction: column; justify-content: space-between; padding: 20px;">
      <span>${name}</span>
      <hr style="flex: none; border: none; height:2px; width: 100%; background-color:#5d7b84;">
      <div style="width: 100%; display: flex; justify-content: center;">
        <span><bold>${value}</bold></span>
      </div>
      
      <hr style="flex: none; border: none; height:2px; width: 100%; background-color:#5d7b84;">
      <span>${tooltipTime}</span>
    </div>
  `;
};

const ErrorView = ({ msg }) => {
  return (
    <>
      <Typography variant="h5">Cow Activity</Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {msg ||
            "Something went wrong with the loading of this chart, please try again later."}
        </Typography>
      </Box>
    </>
  );
};

const getDisplayData = (data, config, timezone) => {
  return data.aggregations
    .map((agg) => {
      const dps = agg.series.map((series) => ({
        x: dayjs(series.date).unix() * 1000,
        y: series.value,
        label: dayjs(series.date).tz(timezone).format("h A"),
        tooltipTime: dayjs(series.date).tz(timezone).format("h mm A"),
      }));

      return {
        type: "stackedArea",
        xValueType: "dateTime",
        id: agg.id,
        color: config[agg.id].color,
        name: agg.label,
        dataPoints: dps,
      };
    })
    .sort((dpA, dpB) => {
      return config[dpA.id].order - config[dpB.id].order;
    });
};

const DrillDownChart = () => {
  const history = useHistory();
  const { id, sectionId } = useParams();
  const [coordinates, setCoordinates] = useState({});
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const currentDate = params.get("date");
  const { info } = useAccountDetails();

  const queryStartDate = dayjs(currentDate).tz(info.farm.timezone).format();
  const queryEndDate = dayjs(currentDate)
    .add(23, "hour")
    .add(59, "minute")
    .add(59, "second")
    .tz(info.farm.timezone)
    .format();

  const [{ minimum, maximum }] = useDatesforPen(info, id);

  const { data } = useQuery(
    [`drill-down`, { id, startDate: queryStartDate, endDate: queryEndDate }],
    getPenProductivityByHour
  );

  const { data: eventsData } = useQuery(
    [
      "eventsWithFeedStatus",
      {
        id,
        startDate: queryStartDate,
        endDate: queryEndDate,
        showSectionBreakdown: false,
      },
    ],
    getPenEvents
  );

  const { displayData, legendData, onLegendSelection } = useProductivityData(
    data,
    config,
    getDisplayData,
    info.farm.timezone,
    sectionId
  );

  const formatXAxisContent = useCallback(
    ({ value }) => dayjs(value).tz(info.farm.timezone).format("h A"),
    [info.farm.timezone]
  );

  if (dayjs(currentDate).isBefore(dayjs("2021-06-30"))) {
    return <ErrorView msg="Data not available before June 30th" />;
  }

  const increaseDate = () => {
    history.replace(
      `${pathname}?date=${dayjs(currentDate)
        .add(24, "hour")
        .tz(info.farm.timezone)
        .format()}`
    );
  };

  const decreaseDate = () => {
    history.replace(
      `${pathname}?date=${dayjs(currentDate)
        .subtract(24, "hour")
        .tz(info.farm.timezone)
        .format()}`
    );
  };

  const isBackDisabled = dayjs(currentDate)
    .subtract(24, "hour")
    .isBefore(dayjs(minimum));

  const isForwardDisabled = dayjs(currentDate)
    .add(48, "hour")
    .isAfter(dayjs(maximum));

  return (
    <Root
      style={{
        ...centerMixin,
        height: "100%",
        width: "100%",
      }}
    >
      <div>
        <div className={classes.chartTitle}>
          <TimeBudgetIcon color="primary" className={classes.chartIcon} />
          <Typography variant="h4">Cow Activity</Typography>
        </div>
        <Typography variant="subtitle1" className={classes.subtitle}>
          % of cows performing each activity over 24 hours
        </Typography>
      </div>
      <div className={classes.chartInner}>
        <IconButton
          onClick={decreaseDate}
          aria-label="back"
          className={classes.arrow}
          disabled={isBackDisabled}
          size="large"
        >
          <ArrowBack />
        </IconButton>
        <Paper elevation={3} classes={{ root: classes.chart }}>
          <EventBar coordinates={coordinates} data={eventsData} />

          <CanvasJSChart
            options={{
              animationEnabled: true,
              toolTip: {
                backgroundColor: "#374851",
                borderColor: "#374851",
                borderThickness: 2,
                fontSize: 16,
                fontColor: "#ffffff",
                fontFamily: "Noto",
                contentFormatter,
              },
              axisX: {
                lineColor: "#e9e9e9",
                tickThickness: 0,
                tickLength: 15,
                labelFontSize: 16,
                labelFontFamily: "Noto",
                labelFontColor: "#595959",
                labelFormatter: formatXAxisContent,
              },
              axisY: {
                title: "% of Cows",
                minimum: 0,
                maximum: 100,
                interval: 25,
                suffix: "%",
                gridColor: "#e9e9e9",
                lineThickness: 0,
                tickThickness: 0,
                labelFontSize: 16,
                labelFontFamily: "Noto",
                labelFontColor: "#595959",
                titleFontSize: 16,
                titleFontWeight: 300,
                titleFontFamily: "Noto",
                titleFontColor: "#595959",
              },
              dataPointMaxWidth: 18,
              data: displayData,
            }}
            containerProps={{
              height: "85%",
              width: "90%",
              position: "relative",
            }}
            setCoordinates={setCoordinates}
          />
          <DateBar
            startDate={queryStartDate}
            endDate={queryEndDate}
            coordinates={coordinates}
            timezone={info.farm.timezone}
          />
        </Paper>
        <IconButton
          onClick={increaseDate}
          aria-label="forward"
          className={classes.arrow}
          disabled={isForwardDisabled}
          size="large"
        >
          <ArrowForward />
        </IconButton>
      </div>
      <Legend data={legendData} config={config} onClick={onLegendSelection} />
      <Link
        className={classes.link}
        url="https://cainthus-alus.stonly.com/kb/guide/en/the-cow-time-budget-WOkdem1ici/Steps/338681"
        text={"Learn what these terms mean"}
        external
      />
    </Root>
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <DrillDownChart {...props} />
    </ErrorBoundary>
  </Suspense>
);
