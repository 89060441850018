import React from "react";
import { useQuery } from "react-query";
import { fetchFeedingOpsSmartInsights } from "../../../services/fetchFeedingOps";
import FoSmartInsightsContext from "./FoSmartInsightsContext";

const FoSmartInsightsProvider = (props) => {
  const { data } = useQuery(
    ["feedingOpsSmartInsights"],
    fetchFeedingOpsSmartInsights,
    {
      refetchOnWindowFocus: false,
    }
  );

  const insights = data.trends;

  return (
    <FoSmartInsightsContext.Provider value={insights}>
      {props.children}
    </FoSmartInsightsContext.Provider>
  );
};

export default FoSmartInsightsProvider;
