import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import MuiSwitch from "@mui/material/Switch";
import theme from "./theme";

const Switch = ({ checked, name, disabled, onChange }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiSwitch
          name={name}
          color="primary"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Switch;

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
  disabled: false,
};
