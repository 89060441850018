import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const PREFIX = "ActualEventTime";

const classes = {
  eventIcon: `${PREFIX}-eventIcon`,
  absoluteContainer: `${PREFIX}-absoluteContainer`,
  background: `${PREFIX}-background`,
  relativeContainer: `${PREFIX}-relativeContainer`,
  actualEventTime: `${PREFIX}-actualEventTime`,
};

const Root = styled("div")(() => ({
  [`& .${classes.eventIcon}`]: {
    fontSize: 28,
  },

  [`& .${classes.absoluteContainer}`]: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    left: 0,
  },

  [`& .${classes.background}`]: {
    background: "white",
    opacity: "0.8",
  },

  [`& .${classes.relativeContainer}`]: {
    height: "100%",
    width: "100%",
    position: "relative",
  },

  [`&.${classes.actualEventTime}`]: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    flexDirection: "row",
    justifyContent: "center",
    top: 0,
  },
}));

const EventIcon = ({ children }) => {
  return <Root className={classes.eventIcon}>{children}</Root>;
};

const OFFSETS_BY_TYPE = {
  cleanouts: 25,
  deliveries: 65,
  pushups: 90,
};

const TransparentBackgroundContainer = ({ children, ...rest }) => {
  return (
    <div className={classes.relativeContainer}>
      <div className={`${classes.absoluteContainer} ${classes.background}`} />
      <div className={classes.absoluteContainer}>{children}</div>
    </div>
  );
};

const ActualEventTime = ({
  icon: Icon = () => <div>Forget To Render an Icon</div>,
  time,
  type,
}) => {
  return (
    <Root
      className={classes.actualEventTime}
      sx={{ left: `calc(${time - 32} /1440 * 100%)` }}
      y={`calc(${OFFSETS_BY_TYPE[type]}% - 20px)`}
    >
      <TransparentBackgroundContainer>
        <EventIcon>
          <Icon />
        </EventIcon>
      </TransparentBackgroundContainer>
    </Root>
  );
};

export default ActualEventTime;

ActualEventTime.propTypes = {
  icon: PropTypes.func.isRequired,
  time: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
