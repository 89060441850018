import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";

const PREFIX = "PrintButton";

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.icon}`]: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },
}));

const PrintButton = ({ onChartPrint, printButtonClass }) => {
  return (
    <StyledIconButton
      aria-label="print chart"
      onClick={onChartPrint}
      edge="start"
      className={printButtonClass || classes.icon}
      title="Print chart"
      size="large"
    >
      <PrintIcon color="inherit" />
    </StyledIconButton>
  );
};

PrintButton.propTypes = {
  onChartPrint: PropTypes.func,
  printButtonClass: PropTypes.string,
};

PrintButton.defaultProps = {
  onChartPrint: window.print,
};

export default PrintButton;
