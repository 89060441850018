import FeedEvents from "./FeedEvents";
import FeedStatus from "./FeedStatus";
import Trends from "./Trends";

const sections = [
  {
    name: "Feed Status",
    id: "feedStatus",
    translationKey: "feed-status",
    component: FeedStatus,
    route: "/feed-status",
  },
  {
    name: "Feed Events",
    id: "feedEvents",
    translationKey: "feed-events",
    component: FeedEvents,
    route: "/feed-events",
  },
  {
    name: "Trends",
    id: "trends",
    translationKey: "trends",
    component: Trends,
    route: "/trends",
  },
];

export default sections;
