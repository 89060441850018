import { colors } from "../../CommonComponents/UI/theme";

const kpiInfo = {
  pushups: {
    translationKey: "push-ups-completed",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
  deliveries: {
    translationKey: "deliveries-on-time",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
  lowFeedTime: {
    translationKey: "low-feed-time",
    up: colors.valenciaRed,
    down: colors.silverTreeGreen,
  },
};

export default kpiInfo;
