import React from "react";
import { useMilkingOperationsContext } from "../utils/MilkingOperationsContext";
import MoPenDetails from "./PenDetails";
import PenDetailsProvider from "../utils/PenDetailsProvider";
import StyledSlide, {
  classes,
} from "../../../../CommonComponents/UI/Operations/StyledSlide";

const MoPenDetailsOverview = () => {
  const { selectedPen } = useMilkingOperationsContext();

  return (
    <StyledSlide
      direction="left"
      className={classes.slide}
      in={selectedPen.isPenSelected && !selectedPen.isPenLoading}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <div>
        {selectedPen.isPenSelected && (
          <PenDetailsProvider>
            <MoPenDetails></MoPenDetails>
          </PenDetailsProvider>
        )}
      </div>
    </StyledSlide>
  );
};

export default MoPenDetailsOverview;
