import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import MuiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { colors } from "../theme";
import { flexRowMixIn } from "../../utils/Operations/cssMixins";

const PREFIX = "OpsTabs";

const classes = {
  tab: `${PREFIX}-tab`,
  selected: `${PREFIX}-selected`,
  indicator: `${PREFIX}-indicator`,
  indicator1: `${PREFIX}-indicator1`,
};

const StyledTabs = styled(MuiTabs)(() => ({
  ...flexRowMixIn,
  justifyContent: "space-between",
  width: "100%",

  [`& .${classes.tab}`]: {
    display: "flex",
    width: "50%",
    minWidth: "0",
    maxWidth: "50%",
    fontSize: "16px",
  },

  [`& .${classes.selected}`]: {
    color: colors.green,
  },

  // added indicator styling to ensure correct placement for printing also
  [`& .${classes.indicator}`]: {
    width: "50% !important",
    left: "0",
  },

  [`& .${classes.indicator1}`]: {
    width: "50% !important",
    left: "50% !important",
  },
}));

const OpsTabs = (props) => {
  const { onChange, tabs, value, ...muiTabProps } = props;
  const { t } = useTranslation();

  return (
    <StyledTabs
      {...muiTabProps}
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      classes={{
        indicator: value === 0 ? classes.indicator : classes.indicator1,
      }}
    >
      {tabs.map((tab) => (
        <Tab
          label={t(tab.translationKey)}
          key={tab.label}
          classes={{ selected: classes.selected }}
          className={classes.tab}
          data-cy={tab.dataCyAtt}
        />
      ))}
    </StyledTabs>
  );
};

export default OpsTabs;

OpsTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
