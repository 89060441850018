import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import ArrowForward from "@mui/icons-material/ArrowForwardSharp";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { colors } from "../theme";
import {
  flexColumnMixIn,
  flexRowMixIn,
} from "../../utils/Operations/cssMixins";

const PREFIX = "OpsTrendTile";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  heading: `${PREFIX}-heading`,
  subHeading: `${PREFIX}-subHeading`,
  trend: `${PREFIX}-trend`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  icon: `${PREFIX}-icon`,
  hidden: `${PREFIX}-hidden`,
  arrow: `${PREFIX}-arrow`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.title}`]: {
    ...flexColumnMixIn,
  },

  [`& .${classes.heading}`]: {
    color: colors.labelGray,
    fontSize: "14px",
    height: "20px",
    lineHeight: "20px",
  },

  [`& .${classes.subHeading}`]: {
    fontSize: "20px",
    color: colors.gray35,
    fontWeight: 500,
    height: "27px",
    marginRight: "5px",
  },

  [`& .${classes.content}`]: {
    ...flexRowMixIn,
    alignItems: "center",
  },

  [`& .${classes.text}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },

  [`& .${classes.icon}`]: {
    display: "flex",
    alignItems: "center",
    paddingTop: "6px",
  },

  [`& .${classes.hidden}`]: {
    display: "none",
  },

  [`& .${classes.arrow}`]: {
    verticalAlign: "middle",
    margin: "3px 12px 0 0",
  },
}));

const OpsTrendTile = ({ item, kpiInfo, dataCyPrefix }) => {
  const { t } = useTranslation();
  const { trend, change, value } = item;
  const textColor = kpiInfo[trend];

  return (
    <Root>
      <div className={classes.title}>
        <Typography
          data-cy={dataCyPrefix + "-title"}
          className={classes.heading}
        >
          {t(kpiInfo.translationKey)}
        </Typography>
      </div>

      <div className={classes.content}>
        {trend && (
          <ArrowForward
            className={classes.arrow}
            style={{
              color: textColor,
              transform: trend === "up" ? "rotate(-45deg)" : "rotate(45deg)",
            }}
          />
        )}
        <Typography className={classes.subHeading}>{value}</Typography>
      </div>
      {trend && (
        <Typography variant="subtitle2" style={{ color: textColor }}>
          {change}
        </Typography>
      )}
    </Root>
  );
};

export default OpsTrendTile;

OpsTrendTile.propTypes = {
  kpiInfo: PropTypes.object,
  item: PropTypes.object,
  dataCyPrefix: PropTypes.string.isRequired,
};
