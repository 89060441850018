import { useState, useEffect, useCallback } from "react";

const getLegendData = (data, config) => {
  return data.aggregations.map((agg) => {
    return {
      id: agg.id,
      color: config[agg.id].color,
      name: agg.label,
      isActive: true,
    };
  });
};

const getFilteredIds = (data) => data.aggregations.map((agg) => agg.id);

const useProductivityData = (
  data,
  config,
  getDisplayData,
  timezone,
  sectionId
) => {
  const [filteredIds, setFilteredIds] = useState(getFilteredIds(data));
  const [legendData, setLegendData] = useState(getLegendData(data, config));
  const [displayData, setDisplayData] = useState(
    getDisplayData(data, config, timezone)
  );

  useEffect(() => {
    setDisplayData(getDisplayData(data, config, timezone));
  }, [data, config, getDisplayData, timezone]);

  useEffect(() => {
    const selectedId = Object.keys(config).find(
      (key) => config[key].sectionId === sectionId
    );
    if (selectedId) {
      setFilteredIds([selectedId]);

      setLegendData((legendData) =>
        legendData.map((item) => {
          if (item.id === selectedId) {
            return item;
          }

          return { ...item, isActive: !item.isActive };
        })
      );
    }
  }, [sectionId, config]);

  const onLegendSelection = useCallback(
    (selectedId) => {
      const hasSelectedId = filteredIds.includes(selectedId);

      if (hasSelectedId) {
        setFilteredIds((filteredIds) =>
          filteredIds.filter((id) => id !== selectedId)
        );
      } else {
        setFilteredIds((filteredIds) => [...filteredIds, selectedId]);
      }

      const updatedLegendData = legendData.map((item) => {
        if (item.id === selectedId) {
          return { ...item, isActive: !item.isActive };
        }

        return item;
      });

      setLegendData(updatedLegendData);
    },
    [legendData, filteredIds]
  );
  return {
    displayData: displayData.filter((data) => filteredIds.includes(data.id)),
    legendData,
    onLegendSelection,
  };
};

export default useProductivityData;
