import { useState } from "react";

const useEditableRow = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleEditMode = () => setIsEditMode((isEditMode) => !isEditMode);
  const toggleModalOpen = () => setIsModalOpen((isModalOpen) => !isModalOpen);

  return {
    isEditMode,
    isModalOpen,
    toggleEditMode,
    toggleModalOpen,
  };
};

export default useEditableRow;
