import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "TimeField";

const classes = {
  timefieldInput: `${PREFIX}-timefieldInput`,
  TimeFieldContainer: `${PREFIX}-TimeFieldContainer`,
  timeFieldPeriodSelect: `${PREFIX}-timeFieldPeriodSelect`,
};

const Root = styled("div")(() => ({
  [`& .${classes.timefieldInput}`]: {
    boxSizing: "border-box",
    width: "calc(100% - 32px)",
    height: 40,
    padding: 20,
    color: "#646464",
    border: (props) => `2px solid ${props.error ? "#ff5050" : "#cccccc"}`,
    fontSize: 16,
    textAlign: "center",

    "&:focus": {
      paddingBottom: "calc(24px- 2px)",
      outline: "none",
      borderBottom: "4px solid #e69e6c",
    },
    "&:disabled": {
      color: "#cccccc",
    },
  },

  [`& .${classes.TimeFieldContainer}`]: {
    width: "100%",
  },

  [`& .${classes.timeFieldPeriodSelect}`]: {
    width: 24,
    fontSize: 16,
    marginLeft: 8,
    display: "inline-block",
    color: (props) => `${props.disabled ? "#cccccc" : "#da7025"}`,
    fontWeight: "bold",
    cursor: (props) => `${props.disabled ? "default" : "pointer"}`,
  },
}));

const updateAtPosition = (value, newValue, position) => {
  let temp = [...value];
  temp[position] = newValue;
  return temp.join("");
};

function isNumber(value) {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
}

const ruleSetShortTime = (
  addedCharacter,
  removedCharacter,
  replacedSingleCharacter,
  cursorCharacter,
  inputValue,
  position,
  oldValue
) => {
  let newValue = oldValue;
  let newPosition = position;
  if (addedCharacter !== null) {
    if (isNumber(addedCharacter)) {
      if (position === 1) {
        newValue = updateAtPosition(oldValue, addedCharacter, position - 1);
        newPosition = position + 1;
      }
      // currently pointing at ':'
      if (position === 2) {
        newValue = updateAtPosition(oldValue, addedCharacter, position + 1);
      }
      if (position === 3) {
        newValue = updateAtPosition(oldValue, addedCharacter, position - 1);
      }
      if (position === 4) {
        newValue = updateAtPosition(oldValue, addedCharacter, position - 1);
      }
      if (position === 5) {
        let [oldH, oldM] = oldValue.split(":");
        newValue = `${oldH}${oldM[0]}:${oldM[1]}${addedCharacter}`;
      }
    } else {
      // if user typed NOT a number, then keep old value & position
      newPosition = position - 1;
    }
  } else if (replacedSingleCharacter !== null) {
    // user replaced only a single character
    if (isNumber(cursorCharacter)) {
      if (position - 1 === 1 || position - 1 === 4) {
        newValue = `${inputValue.substr(0, position - 1)}:${inputValue.substr(
          position
        )}`;
      } else {
        newValue = inputValue;
      }
    } else {
      // user replaced a number on some non-number character
      newValue = oldValue;
      newPosition = position - 1;
    }
  } else if (
    typeof cursorCharacter !== "undefined" &&
    cursorCharacter !== ":" &&
    !isNumber(cursorCharacter)
  ) {
    // set of characters replaced by non-number
    newValue = oldValue;
    newPosition = position;
  } else if (removedCharacter !== null) {
    if (position === 1 && removedCharacter === ":") {
      newValue = updateAtPosition(oldValue, 0, position - 1);
      newPosition = 0;
    } else if (position === 0) {
      newValue = updateAtPosition(oldValue, 0, position);
    } else if (position === 2) {
      newValue = updateAtPosition(oldValue, 0, position);
      newPosition = position - 1;
    } else if (position === 3) {
      newValue = updateAtPosition(oldValue, 0, position);
    }
  }
  return [newValue, newPosition];
};

const ruleSetLongTime = (
  addedCharacter,
  removedCharacter,
  position,
  oldValue
) => {
  let newValue = oldValue;
  let newPosition = position;
  if (addedCharacter !== null) {
    if (isNumber(addedCharacter)) {
      if (position === 1) {
        newValue = updateAtPosition(oldValue, addedCharacter, position - 1);
        newPosition = position;
      }
      if (position === 2) {
        newValue = updateAtPosition(oldValue, addedCharacter, position - 1);
        newPosition = position;
      }
      // currently pointing at ':'
      if (position === 3) {
        newValue = updateAtPosition(oldValue, addedCharacter, position);
        newPosition = position + 1;
      }
      if (position === 4) {
        newValue = updateAtPosition(oldValue, addedCharacter, position - 1);
        newPosition = position;
      }
      if (position === 5) {
        newValue = updateAtPosition(oldValue, addedCharacter, position - 1);
        newPosition = position;
      }
    } else {
      // if user typed NOT a number, then keep old value & position
      newPosition = position - 1;
    }
  } else if (removedCharacter !== null) {
    if (position === 0) {
      newValue = oldValue.substring(1, oldValue.length);
    } else if (position === 1) {
      newValue = updateAtPosition(oldValue, 0, position);
    } else if (position === 2 && removedCharacter === ":") {
      newValue = updateAtPosition(oldValue, 0, position - 1);
      newPosition = position - 1;
    } else if (position === 3) {
      newValue = updateAtPosition(oldValue, 0, position);
    } else if (position === 4) {
      let [oldH, oldM] = oldValue.split(":");
      newValue = `${oldH[0]}:${oldH[1]}${oldM[0]}`;
    }
  }
  return [newValue, newPosition];
};

export const isValidTime = (timeStringToValidate) => {
  const pattern = "^(1[0-2]|[1-9]):[0-5][0-9] (am|pm)";
  const regex = RegExp(pattern, "g");
  return regex.test(timeStringToValidate);
};

const TimeField = (props) => {
  const inputRef = useRef();

  const [state, setState] = useState({
    value: "0:00",
    cursorPosition: 0,
    period: "am",
  });

  // layout effect watches the state object because if it just watched
  // the cursorPosition there are scenarios where it won't change and the caret
  // will be placed at the end of the input
  useLayoutEffect(() => {
    inputRef.current.selectionStart = state.cursorPosition;
    inputRef.current.selectionEnd = state.cursorPosition;
  }, [state]);

  useEffect(() => {
    const [value, period] = props.value.split(" ");
    setState({
      ...state,
      value,
      period,
    });
  }, [props.value]);

  const onInputChange = (event, callback) => {
    const oldValue = state.value;
    const inputEl = event.target;
    const inputValue = inputEl.value;
    const position = inputEl.selectionEnd;
    const isTyped = inputValue.length > oldValue.length;
    const cursorCharacter = inputValue[position - 1];
    const addedCharacter = isTyped ? cursorCharacter : null;
    const removedCharacter = isTyped ? null : oldValue[position];
    const replacedSingleCharacter =
      inputValue.length === oldValue.length ? oldValue[position - 1] : null;

    let [newValue, newPosition] =
      oldValue.length === 4
        ? ruleSetShortTime(
            addedCharacter,
            removedCharacter,
            replacedSingleCharacter,
            cursorCharacter,
            inputValue,
            position,
            oldValue
          )
        : ruleSetLongTime(addedCharacter, removedCharacter, position, oldValue);

    const newState = {
      ...state,
      value: newValue,
      cursorPosition: newPosition,
    };
    setState(newState);
    callback(event, `${newValue} ${state.period}`);
  };

  const onPeriodChange = (event, callback) => {
    const newPeriod = state.period === "am" ? "pm" : "am";
    const newState = {
      ...state,
      period: newPeriod,
    };
    setState(newState);
    callback(event, `${state.value} ${newPeriod}`);
  };

  const { value, period } = state;
  const { onChange, disabled, ...extraProps } = props;

  const onChangeHandler = (event) =>
    onInputChange(event, (e, v) => onChange && onChange(e, v));
  const onPeriodChangeHandler = (event) =>
    onPeriodChange(event, (e, v) => onChange && onChange(e, v));

  return (
    <Root className={classes.timeFieldContainer}>
      <input
        className={classes.timefieldInput}
        ref={inputRef}
        type="text"
        disabled={disabled}
        value={value}
        onChange={onChangeHandler}
      />
      <div
        className={classes.timeFieldPeriodSelect}
        disabled={disabled}
        onClick={!disabled ? onPeriodChangeHandler : () => {}}
      >
        {period}
      </div>
    </Root>
  );
};

export default TimeField;
