import React from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

const PREFIX = "GroupFilter";

const classes = {
  dropdown: `${PREFIX}-dropdown`,
};
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.up("md")]: {
    width: 146,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  [`& .${classes.dropdown}`]: {
    height: 36,
    boxSizing: "border-box",
    padding: 8,
    textTransform: "uppercase",
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      marginLeft: 5,
    },
  },
}));

const GroupFilter = ({ selectedGroup, selectGroup, groups }) => {
  return (
    <StyledFormControl>
      <Select
        value={selectedGroup}
        onChange={selectGroup}
        data-cy="group-select"
        className={classes.dropdown}
      >
        {groups.map((group) => (
          <MenuItem data-cy={group} key={group} value={group}>
            {group}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default GroupFilter;

GroupFilter.propTypes = {
  selectGroup: PropTypes.func.isRequired,
  selectedGroup: PropTypes.string,
  groups: PropTypes.array.isRequired,
};
