import { colors } from "../../UI/theme";
import { flexColumnMixIn } from "../../utils/Operations/cssMixins";

import { styled } from "@mui/material/styles";

const PREFIX = "PensOverview";

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  content: `${PREFIX}-content`,
  hidden: `${PREFIX}-hidden`,
  print: `${PREFIX}-print`,
  insights: `${PREFIX}-insights`,
  caption: `${PREFIX}-caption`,
  toolbar: `${PREFIX}-toolbar`,
};

const StyledPensOverview = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    background: colors.white,
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
    "@media print": {
      display: "none",
    },
  },
  [`& .${classes.content}`]: {
    ...flexColumnMixIn,
    backgroundColor: colors.white,
    [theme.breakpoints.down("md")]: {
      padding: 10,
    },
  },
  [`&.${classes.print}`]: {
    "@media print": {
      display: "flex",
    },
  },
  [`& .${classes.insights}`]: {
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
      ...flexColumnMixIn,
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(5)}`,
      ...flexColumnMixIn,
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  [`& .${classes.caption}`]: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  [`& .${classes.toolbar}`]: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignSelf: "flex-end",
    alignItems: "center",
    position: "relative",

    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      width: 600,
      height: 48,
      justifyContent: "flex-end",
      marginBottom: 10,
      marginTop: 20,

      "& > div:first-of-type": {
        // margin: "0 14px",
        margin: "0 60px 0 14px",
      },
      // put this back in when the group filter is put back in
      // "& > div:nth-of-type(3)": {
      //   marginRight: 60,
      // },
    },
    "@media print": {
      width: "100%",
      justifyContent: "flex-end",

      "& > div:first-of-type": {
        margin: "0 14px",
      },
      "& > div:nth-of-type(3)": {
        marginRight: 60,
      },
    },
  },
}));

export default StyledPensOverview;
