import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LoadingSpinner from "../../CommonComponents/UI/LoadingSpinner";
import kpiInfo from "../../FeedbunkManagement/utils/kpiInfo";
import { getTrends } from "../../FeedbunkManagement/services/pen";
import ErrorView from "./ErrorView";
import KPI from "./KPI";

const StyledBox = styled(Box)({
  display: "flex",
  padding: 10,
  flexWrap: "wrap",
});

const dataErrorView = () => (
  <ErrorView messageKey="waiting-for-data-to-process" />
);

const FeedbunkManagement = ({ setSubtitle }) => {
  const { data } = useQuery(
    ["feedbunkTrends", { period: "yesterday", includeWorstPens: true }],
    getTrends,
    {
      onSuccess: (data) => setSubtitle(data.dates),
      refetchOnWindowFocus: false,
    }
  );
  const trends = data.summaries.farm.filter((farm) => farm.worst_pens);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary FallbackComponent={dataErrorView}>
        <StyledBox>
          {trends.map((trend) => (
            <KPI trend={trend} kpiInfo={kpiInfo} />
          ))}
        </StyledBox>
      </ErrorBoundary>
    </Suspense>
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <FeedbunkManagement {...props} />
    </ErrorBoundary>
  </Suspense>
);
