import React from "react";
import { styled } from "@mui/material/styles";
import {
  minutesToHours,
  timeOfDayFormatter,
  timestampToMinutesInDay,
} from "../../../../CommonComponents/utils/time";
import theme from "../../../../CommonComponents/UI/theme";

const PREFIX = "EventsRow";

const classes = {
  eventsFlexOuter: `${PREFIX}-eventsFlexOuter`,
  eventsFlexInner: `${PREFIX}-eventsFlexInner`,
  text: `${PREFIX}-text`,
  eventIcon: `${PREFIX}-eventIcon`,
  absoluteContainer: `${PREFIX}-absoluteContainer`,
  background: `${PREFIX}-background`,
  relativeContainer: `${PREFIX}-relativeContainer`,
  expectedEventTime: `${PREFIX}-expectedEventTime`,
  actualEventTime: `${PREFIX}-actualEventTime`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.eventsFlexOuter}`]: {
    display: "flex",
    height: 124,
    borderBottom: "1px solid #F5F5F5",
    paddingLeft: 34,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 40,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 60,
    },
    "@media print": {
      height: 80,
    },
  },

  [`& .${classes.eventsFlexInner}`]: {
    cursor: "pointer",
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },

  [`& .${classes.eventIcon}`]: {
    fontSize: 20,
    [theme.breakpoints.up("sm")]: {
      fontSize: 28,
    },
  },

  [`& .${classes.background}`]: {
    background: "white",
    opacity: "0.8",
  },

  [`& .${classes.relativeContainer}`]: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
}));

const StyledActualEventTime = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== "smallOffset" &&
    prop !== "time" &&
    prop !== "largeOffset" &&
    prop !== "leftDesktop",
})(({ leftDesktop, largeOffset, smallOffset, time, theme }) => ({
  [`& .${classes.text}`]: {
    fontSize: 8,
    backgroundColor: "white",
    fontWeight: 300,
    [theme.breakpoints.up("sm")]: {
      fontSize: 11,
    },
  },

  [`&.${classes.actualEventTime}`]: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    flexDirection: "row",
    justifyContent: "center",
    top: `calc(${smallOffset}% - 22px)`,
    left: `calc(${time - 28} /1440 * 100%)`,
    [theme.breakpoints.up("sm")]: {
      left: `${leftDesktop}`,
      top: `calc(${largeOffset}% - 22px)`,
    },
  },
}));

const StyledExpectedEventTime = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== "deemphasize" &&
    prop !== "time" &&
    prop !== "type" &&
    prop !== "color" &&
    prop !== "height",
})(({ color, height, time, theme }) => ({
  [`&.${classes.expectedEventTime}`]: {
    position: "absolute",
    width: 1,
    height: "100%",
    top: 0,
    left: `calc(${time} / 1440 * 100%)`,
    borderTop: 0,
    backgroundSize: "2px 10px",
    backgroundRepeat: "repeat-y",
    backgroundImage: `
        linear-gradient(to bottom, ${color} ${height}%, rgba(255, 255, 255, 0) 0%); 
        `,
    [theme.breakpoints.up("sm")]: {
      width: 3,
    },
  },
}));

const StyledTransparentBackgroundContainer = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive, theme }) => ({
  [`& .${classes.absoluteContainer}`]: {
    position: "absolute",
    bottom: isActive ? -12 : 0,
    height: "100%",
    width: "100%",
    left: 0,
    [theme.breakpoints.up("sm")]: {
      bottom: isActive ? -23 : 0,
    },
  },
}));

const ExpectedEventTime = ({ deemphasize, type, time }) => {
  const color = !deemphasize ? theme.palette.events[type] : "#cccccc";
  // deliveries 100, cleanouts 50, pushups 30
  const height = type === "deliveries" ? 100 : type === "cleanouts" ? 70 : 30;
  return (
    <StyledExpectedEventTime
      color={color}
      height={height}
      time={time}
      className={classes.expectedEventTime}
      data-cy={`expected-event-${type}`}
    />
  );
};

const EventIcon = ({ children }) => {
  return <div className={classes.eventIcon}>{children}</div>;
};

const OFFSETS_BY_TYPE = {
  cleanouts: {
    small: 14,
    large: 14,
  },
  deliveries: {
    small: 56,
    large: 48,
  },
  pushups: {
    small: 90,
    large: 80,
  },
};

const TransparentBackgroundContainer = ({ children, isActive }) => {
  return (
    <StyledTransparentBackgroundContainer
      isActive={isActive}
      className={classes.relativeContainer}
    >
      <div className={`${classes.absoluteContainer} ${classes.background}`} />
      <div className={classes.absoluteContainer}>{children}</div>
    </StyledTransparentBackgroundContainer>
  );
};

const ActualEventTime = ({
  icon: Icon = () => <div>Forget To Render an Icon</div>,
  isActive = false,
  deemphasize,
  time,
  type,
}) => {
  return (
    <StyledActualEventTime
      className={classes.actualEventTime}
      time={time}
      smallOffset={OFFSETS_BY_TYPE[type].small}
      largeOffset={OFFSETS_BY_TYPE[type].large}
      leftDesktop={`calc(${time - 18} /1440 * 100%)`}
      data-cy={`actual-event-${type}`}
    >
      <TransparentBackgroundContainer>
        <EventIcon>
          <Icon deemphasize={deemphasize} />
        </EventIcon>
      </TransparentBackgroundContainer>
      {isActive ? (
        <TransparentBackgroundContainer isActive>
          <span className={classes.text}>
            {minutesToHours(time, timeOfDayFormatter)}
          </span>
        </TransparentBackgroundContainer>
      ) : (
        ""
      )}
    </StyledActualEventTime>
  );
};

const EventsRow = ({
  events,
  icons,
  selected,
  deemphasize,
  onClick = () => {},
  hook,
}) => {
  // Assumption that actual and expected events have the same keys
  const event_keys = Object.keys(events.actual);
  return (
    <Root
      className={classes.eventsFlexOuter}
      data-cy="events-row"
      data-thirdparty="flex-outer"
    >
      <div
        className={classes.eventsFlexInner}
        data-thirdparty={hook}
        data-cy={hook}
        onClick={onClick}
      >
        {event_keys.map((e_key, index) => {
          const expected = events.expected[e_key];
          const actual = events.actual[e_key];
          return (
            <React.Fragment key={`Events-${e_key}-${index}`}>
              {expected.map((timestamp) => (
                <ExpectedEventTime
                  type={e_key}
                  key={timestamp}
                  deemphasize={deemphasize}
                  time={timestampToMinutesInDay(timestamp)}
                />
              ))}
              {actual.map((timestamp) => (
                <ActualEventTime
                  type={e_key}
                  icon={icons[e_key]}
                  isActive={selected}
                  deemphasize={deemphasize}
                  key={timestamp}
                  time={timestampToMinutesInDay(timestamp)}
                />
              ))}
            </React.Fragment>
          );
        })}
      </div>
    </Root>
  );
};

export default EventsRow;
