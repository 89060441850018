import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "../../../CommonComponents/UI/Card/Card";
import Toast from "../../../CommonComponents/UI/Toast";
import translateLabelCategories from "../../../CommonComponents/utils/categoryLabelsTranslations.const";
import SetAlertsRow from "./SetAlertsRow";
import { fetchSettings } from "../../services/alerts";

const PREFIX = "SetAlerts";

const classes = {
  tableWrapper: `${PREFIX}-tableWrapper`,
  table: `${PREFIX}-table`,
};

const TableWrapper = styled("div")(({ theme }) => ({
  [`&.${classes.tableWrapper}`]: {
    overflowX: "auto",
  },

  [`& .${classes.table}`]: {
    margin: `0 ${theme.spacing(5)} ${theme.spacing(4)} ${theme.spacing(5)}`,
    width: `calc(100% - ${theme.spacing(10)})`,
    minWidth: 680,
  },
}));

const headers = [
  {
    id: "label",
    label: "Alert Type",
    translationKey: "alert-type",
  },
  {
    id: "threshold",
    label: "Trigger alert after",
    translationKey: "trigger-alert-after",
  },
  {
    id: "enabled",
    label: "Status",
    translationKey: "status",
  },
  {
    id: "edit",
    label: "",
  },
];

const SetAlerts = () => {
  const { t } = useTranslation();
  const { data } = useQuery("alerts/set", fetchSettings, {
    refetchOnWindowFocus: false,
  });

  const alertTypes = data.filter((alertType) =>
    translateLabelCategories.includes(alertType.id)
  );

  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  return (
    <>
      <Card title={t("set-alerts")}>
        <TableWrapper className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header.id}>
                    {t(header.translationKey)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {alertTypes?.map((item) => (
                <SetAlertsRow
                  key={item.id}
                  item={item}
                  headers={headers}
                  setToast={setToast}
                />
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Card>
      <Toast toast={toast} closeToast={closeToast} />
    </>
  );
};
export default SetAlerts;
