import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import Chart from "./Chart";
import DeliveriesIcon from "../../../../CommonComponents/Icons/Deliveries";
import Feed24Icon from "../../../../CommonComponents/Icons/FeedOverTwentyFourHours";
import PushupIcon from "../../../../CommonComponents/Icons/PushUps";
import Datepicker from "../../../../CommonComponents/UI/Datepicker/Datepicker";
import { useAccountDetails } from "../../../../App/useAccountDetails";
import { useActiveModules } from "../../../../App/useActiveModules";
import { useExpandedState } from "../../../../CommonComponents/utils/useExpandedState";
import useDatesforPen from "../../../../CommonComponents/utils/useDatesForPen";

import { getPenEvents, getPenFeedStatusWindows } from "../../../services/pen";

const PREFIX = "Trends";

const classes = {
  chartSection: `${PREFIX}-chartSection`,
  chartSpacer: `${PREFIX}-chartSpacer`,
  chartSpacerPrint: `${PREFIX}-chartSpacerPrint`,
  datePickerSection: `${PREFIX}-datePickerSection`,
};

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isExpanded",
})(({ isExpanded, theme }) => ({
  [`&.${classes.chartSection}`]: {
    width: "100%",
    // maxHeight: "calc(100vh - 185px)",

    maxHeight: `${isExpanded ? "calc(100vh - 238px)" : "calc(100vh - 140px)"}`,
    overflow: " scroll",
    [theme.breakpoints.up("md")]: {
      maxHeight: "none",
      overflow: "initial",
    },
    "@media print": {
      "&.show-feed-low-chart > :not(div:nth-of-type(1))": {
        display: "none",
      },
      "&.show-deliveries-chart > :not(div:nth-of-type(2))": {
        display: "none",
      },
      "&.show-pushups-chart > :not(div:nth-of-type(3))": {
        display: "none",
      },
    },
  },

  [`& .${classes.chartSpacer}`]: {
    height: "352px",
    padding: "20px 40px 28px 40px",
  },

  [`& .${classes.chartSpacerPrint}`]: {
    "@media print": {
      breakAfter: "page",
    },
  },

  [`&.${classes.datePickerSection}`]: {
    display: "none",
    height: 42,
    marginBottom: 16,
    maxHeight: 42,
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "@media print": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
}));

const parseInsufficientAccessData = (data) => {
  // if the maximum is greater than 5hrs i.e 300 minutes then we need to use the local maximum + 1
  const INITIAL_MAX = 300;

  const feedStatusData = data.windows.reverse();

  const maxMinutes =
    Math.floor(
      (Math.max(
        INITIAL_MAX,
        ...feedStatusData.map(({ summary }) => summary.minutesNotOK)
      ) +
        60) /
        60
    ) * 60;

  const totalAccessData = feedStatusData.map(({ summary, range }) => ({
    x: new Date(dayjs(range.start).format("YYYY-MM-DD")),
    y: summary.minutesNotOK,
  }));

  return { maximumY: maxMinutes, data: [totalAccessData.reverse()] };
};

const transformPushupData = (data) => {
  const { aggregations } = data;
  const missed = aggregations.map(({ events, range }) => {
    const missingScheduleFlag =
      typeof events.expected.uses_scheduled_pushups === "undefined";
    const usePushupSchedule = events.expected.uses_scheduled_pushups;
    const expectedPushupsCount = missingScheduleFlag
      ? events.expected["pushups"].length
      : usePushupSchedule
      ? events.expected.pushups_count
      : events.expected["pushups"].length;
    return {
      x: new Date(dayjs(range.start).format("YYYY-MM-DD")),
      y: Math.max(expectedPushupsCount - events.actual["pushups"].length, 0),
    };
  });
  const onTime = aggregations.map(({ events, range }) => ({
    x: new Date(dayjs(range.start).format("YYYY-MM-DD")),
    y: events.actual["pushups"].length,
  }));

  return {
    maximumY: Math.max(
      ...aggregations.map(({ events }) => events.expected["pushups"].length)
    ),
    data: [onTime, missed],
  };
};

const transformEventData = (eventType, data) => {
  const { aggregations } = data;
  const missed = aggregations.map(({ events, range }) => ({
    x: new Date(dayjs(range.start).format("YYYY-MM-DD")),
    y: Math.max(
      events.expected[eventType].length - events.actual[eventType].length,
      0
    ),
  }));
  const onTime = aggregations.map(({ events, range }) => ({
    x: new Date(dayjs(range.start).format("YYYY-MM-DD")),
    y: events.actual[eventType].length,
  }));

  return {
    maximumY: Math.max(
      ...aggregations.map(({ events }) => events.expected[eventType].length)
    ),
    data: [onTime, missed],
  };
};

const generateTooltipContent = (value, color, name, date) => {
  return `
      <div style="min-width: 120px; padding: 8px;">
      <div style="font-weight: bold; margin-left: 24px; margin-bottom: 8px;">${value}</div>
      <div style="margin-bottom: 12px;display: flex; flex: 0;">
        <div style="background:${color};width:16px;height:16px;"></div>
        <span style="margin-left:8px">${name}</span>
      </div>
      <div style="margin-left: 24px">${dayjs(date).format("MMM D")}</div>
      <div>
  `;
};

const tooltipFormatter = ({ entries }) => {
  // The tooltip lives outside react so we can't use react within it
  const { color, name } = entries[0].dataSeries;
  const { x: date, y: value } = entries[0].dataPoint;

  return generateTooltipContent(value, color, name, date);
};

// Special case where formatter has to format minutes in data
const tooltipFormatterWithMinutes = ({ entries }) => {
  // The tooltip lives outside react so we can't use react within it
  const { color, name } = entries[0].dataSeries;
  const { x: date, y: value } = entries[0].dataPoint;
  const convertedMinutes = `${Math.floor(value / 60)}h ${value % 60}m`;

  return generateTooltipContent(convertedMinutes, color, name, date);
};

const TooltipConfiguration = {
  backgroundColor: "#666666",
  borderColor: "#666666",
  fontSize: 16,
  fontColor: "#ffffff",
  fontFamily: "Noto",
  contentFormatter: tooltipFormatter,
};

const Trends = ({ id }) => {
  const { t } = useTranslation();
  const { info } = useAccountDetails();
  const { features } = useActiveModules();
  const { isExpanded } = useExpandedState();
  const displayPushups = features.includes("pushups");
  const initialRange = 6;
  const excludeToday = true;

  const [interval, setInterval] = useState(1);

  const [dates, setDates] = useDatesforPen(
    info,
    id,
    initialRange,
    excludeToday
  );

  const queryStartDate = dayjs(dates.start).format("YYYY-MM-DD");
  const queryEndDate = dayjs(dates.end).format("YYYY-MM-DD");

  const [range, setRange] = useState(
    `?startDate=${queryStartDate}&endDate=${queryEndDate}`
  );

  const feedStatusEndpoint = `/pens/${id}/feed-status-windows${range}&hideMinutelyWindows=true`;

  const chartSectionRef = useRef(classes.chartSection);

  const onChartPrint = useCallback((e) => {
    chartSectionRef.current.className = `${chartSectionRef.current.className} show-${e.currentTarget.offsetParent.id}`;
    window.print();
  }, []);

  useEffect(() => {
    const removeClass = () => {
      const regEx = new RegExp(/show-.*$/g);
      const updatedClass = chartSectionRef.current.className.replace(regEx, "");
      chartSectionRef.current.className = updatedClass;
    };
    window.addEventListener("afterprint", removeClass);
    return () => window.removeEventListener("afterprint", removeClass);
  }, []);

  return (
    <>
      <StyledGrid
        item
        isExpanded={isExpanded}
        className={classes.datePickerSection}
      >
        <Datepicker
          startDate={dates.start}
          endDate={dates.end}
          minimumDate={dates.minimum}
          maximumDate={dates.maximum}
          maxRange={90}
          hook="trends"
          onApplyPressed={(startDate, endDate) => {
            const start = dayjs(startDate);
            const end = dayjs(endDate);
            const interval = end.diff(start, "day") < 14 ? 1 : null;
            setInterval(interval);
            setRange(
              `?startDate=${start.format("YYYY-MM-DD")}&endDate=${end.format(
                "YYYY-MM-DD"
              )}`
            );
            setDates({ start: startDate, end: endDate });
          }}
        />
      </StyledGrid>
      <StyledGrid
        xs={12}
        item
        className={classes.chartSection}
        style={{ width: "100%" }}
        ref={chartSectionRef}
      >
        <div
          className={classNames(classes.chartSpacer, classes.chartSpacerPrint)}
        >
          <Chart
            onChartPrint={onChartPrint}
            chartId="feed-low-chart"
            queryKey={[
              "feed-status-chart",
              {
                id,
                startDate: queryStartDate,
                endDate: queryEndDate,
                showSectionBreakdown: false,
              },
            ]}
            queryFn={getPenFeedStatusWindows}
            endpoint={feedStatusEndpoint}
            formatFunction={parseInsufficientAccessData.bind(null)}
            options={{
              title: t("feed-low"),
              icon: Feed24Icon,
              config: {
                toolTip: {
                  enabled: true,
                  ...TooltipConfiguration,
                  contentFormatter: tooltipFormatterWithMinutes,
                },
                axisY: {
                  labelFormatter: ({ value: min }) =>
                    `${Math.floor(min / 60)}h`,
                  interval: 2 * 60,
                },
                axisX: {
                  interval,
                  intervalType: "day",
                },
                data: [
                  {
                    name: t("feed-low-time-capitalised"),
                    type: "area",
                    showInLegend: true,
                    legendMarkerType: "square",
                    markerSize: 0,
                    color: "#ff5050",
                    fillOpacity: 1,
                  },
                ],
              },
            }}
          />
        </div>
        <div className={classes.chartSpacer}>
          <Chart
            chartId="deliveries-chart"
            onChartPrint={onChartPrint}
            queryKey={[
              "events",
              { id, startDate: queryStartDate, endDate: queryEndDate },
            ]}
            queryFn={getPenEvents}
            endpoint={`/pens/${id}/events${range}`}
            formatFunction={transformEventData.bind(null, "deliveries")}
            dataAvailable={() => true}
            options={{
              title: t("deliveries"),
              icon: DeliveriesIcon,
              config: {
                toolTip: {
                  enabled: true,
                  ...TooltipConfiguration,
                },
                axisX: {
                  interval,
                  intervalType: "day",
                },
                dataPointMaxWidth: 80,
                data: [
                  {
                    name: t("completed-capitalised"),
                    type: "stackedColumn",
                    markerType: "none",
                    showInLegend: true,
                    legendMarkerType: "square",
                    markerSize: 0,
                  },
                  {
                    name: t("missed"),
                    type: "stackedColumn",
                    markerType: "none",
                    showInLegend: true,
                    legendMarkerType: "square",
                    markerSize: 0,
                  },
                ],
              },
            }}
          />
        </div>
        {displayPushups ? (
          <div className={classes.chartSpacer}>
            <Chart
              chartId="pushups-chart"
              onChartPrint={onChartPrint}
              queryKey={[
                "events",
                { id, startDate: queryStartDate, endDate: queryEndDate },
              ]}
              queryFn={getPenEvents}
              endpoint={`/pens/${id}/events${range}`}
              formatFunction={transformPushupData}
              dataAvailable={() => true}
              options={{
                title: t("push-ups"),
                icon: PushupIcon,
                config: {
                  toolTip: {
                    enabled: true,
                    ...TooltipConfiguration,
                  },
                  axisX: {
                    interval,
                    intervalType: "day",
                  },
                  dataPointMaxWidth: 80,
                  data: [
                    {
                      name: t("completed-capitalised"),
                      type: "stackedColumn",
                      markerType: "none",
                      showInLegend: true,
                      legendMarkerType: "square",
                      markerSize: 0,
                    },
                    {
                      name: t("missed"),
                      type: "stackedColumn",
                      markerType: "none",
                      showInLegend: true,
                      legendMarkerType: "square",
                      markerSize: 0,
                    },
                  ],
                },
              }}
            />
          </div>
        ) : (
          ""
        )}
      </StyledGrid>
    </>
  );
};

export default Trends;
