import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import LoadingSpinner from "../../CommonComponents/UI/LoadingSpinner";
import ErrorView from "./ErrorView";
import LiveFeedChart from "../../FeedbunkManagement/Pages/LiveFeedStatus/Chart";

const LiveFeed = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <LiveFeedChart />
      </ErrorBoundary>
    </Suspense>
  );
};

export default LiveFeed;
