import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "react-query";
import { Box } from "@mui/material";
import Link from "../../../CommonComponents/UI/Link";
import { ErrorBoundary } from "react-error-boundary";
import dayjs from "dayjs";
import { useAccountDetails } from "../../../App/useAccountDetails";
import { minutesToHours } from "../../../CommonComponents/utils/time";
import LoadingSpinner from "../../../CommonComponents/UI/LoadingSpinner";

import CanvasJSReact from "../../../CommonComponents/Vendor/canvasjs.react";
import getDisplayData from "../../utils/getDisplayData";
const { CanvasJSChart } = CanvasJSReact;

const centerMixIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const useStyles = makeStyles(() => ({
  root: {
    ...centerMixIn,
    justifyContent: "space-around",
    padding: 10,
    height: "100%",
    "@media print": {
      boxShadow: "none",
    },
  },
  errorRoot: {
    ...centerMixIn,
    padding: 25,
    height: "100%",
  },
  disabled: {
    opacity: "20%",
  },
  errorMessage: {
    flex: 1,
    ...centerMixIn,
  },
  chartTitle: {
    ...centerMixIn,
    flexDirection: "row",
    "@media print": {
      marginTop: 6,
    },
  },
  chartIcon: {
    height: 40,
    width: 40,
    marginRight: 8,
  },
}));

const ErrorView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={3} classes={{ root: classes.errorRoot }}>
      <Typography variant="h5" className={classes.disabled}>
        {t("time-budget-compared-to-group-and-targets")}
      </Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {t("card-loading-error-message")}
        </Typography>
      </Box>
    </Paper>
  );
};

export const AddMilkingScheduleError = ({ icon: Icon, title, penId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={3} classes={{ root: classes.root }}>
      <div>
        <div className={classes.chartTitle}>
          <Icon color="primary" className={classes.chartIcon} />
          <Typography variant="h4">{title}</Typography>
        </div>
      </div>
      <div>
        <Typography variant="h3">{t("milking-schedule-needed")}</Typography>
        <Typography variant="subtitle1">
          {t("you-need-to-add-the-schedule")} <br /> {t("to-show-this-chart")}
        </Typography>
        <Link url={`/settings/schedules/${penId}/milking`}>
          {t("add-schedule")}
        </Link>
      </div>
    </Paper>
  );
};

const PEN_LINE_COLOR = "#186490";
const MILKING_RANGE_COLOR = "#f9c0eb";

const formatToTwelveHours = (withMinutes) => {
  return (hours, minutes) => {
    const paddedMinutes = minutes.toString().padStart(2, "0");
    const minutesString = withMinutes ? `:${paddedMinutes}` : "";
    if (hours === 0 || hours === 24) return `12${minutesString} AM`;
    if (hours === 12) return `12${minutesString} PM`;
    if (hours < 12) return `${hours}${minutesString} AM`;
    return `${hours - 12}${minutesString} PM`;
  };
};

const formatTooltipContent = ({ value }) =>
  minutesToHours(value, formatToTwelveHours(true));

const formatYAxisContent = ({ value }) =>
  minutesToHours(value, formatToTwelveHours(false));

const formatXAxisContent = ({ value }) => dayjs(value).format("MMM D");

const getTooltipContent = (e, yFormatter, t) => {
  if (e.entries[0].dataPoint.name === "milking") return null;
  let {
    x: date,
    y: [start, end],
  } = e.entries[0].dataPoint;

  start =
    typeof yFormatter === "function" ? yFormatter({ value: start }) : start;
  end = typeof yFormatter === "function" ? yFormatter({ value: end }) : end;

  return `
    <div style="width: 200px; height: 160px; display: flex;  align-items: center; flex-direction: column; justify-content: space-between; padding: 20px;">
      <span>${t("out-of-pen")}</span>
      <hr style="width: 100%">
      <div style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 10px">
        <span>Left</span>
        <span><bold>${start}</bold></span>
      </div>
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <span>Back</span>
        <span><bold>${end}</bold></span>
      </div>
      <hr style="width: 100%">
      <span>${dayjs(date).format("MMM D")}</span>
    </div>
  `;
};

const MilkingChart = (props) => {
  const { title, subtitle, icon: Icon, query, penId, onChartPrint } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    info: {
      farm: { timezone },
    },
  } = useAccountDetails();

  const { data } = useQuery([query.key, query.params], query.service);

  if (data.message && data.message === "no schedules set") {
    return <AddMilkingScheduleError penId={penId} title={title} icon={Icon} />;
  }

  const { series } = data;
  const displayData = getDisplayData(series, timezone);

  const [penData, ...milkingTimes] = displayData;
  return (
    <Paper elevation={3} classes={{ root: classes.root }}>
      <div>
        <div className={classes.chartTitle}>
          <Icon color="primary" className={classes.chartIcon} />
          <Typography variant="h4">{title}</Typography>
        </div>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </div>
      <CanvasJSChart
        options={{
          toolTip: {
            enabled: true,
            backgroundColor: "#374851",
            borderColor: "#374851",
            borderThickness: 2,
            fontSize: 16,
            fontColor: "#ffffff",
            fontFamily: "Noto",
            contentFormatter: (e) =>
              getTooltipContent(e, formatTooltipContent, t),
          },
          axisX: {
            labelFontSize: 16,
            labelFontWeight: 300,
            labelFontFamily: "Noto",
            labelFontColor: "#595959",
            titleFontSize: 16,
            titleFontWeight: 300,
            titleFontFamily: "Noto",
            titleFontColor: "#595959",
            lineColor: "#e9e9e9",
            tickColor: "#e9e9e9",
            labelFormatter: formatXAxisContent,
          },
          axisY: {
            labelFontSize: 16,
            labelFontWeight: 300,
            labelFontFamily: "Noto",
            labelFontColor: "#595959",
            titleFontSize: 16,
            titleFontWeight: 300,
            titleFontFamily: "Noto",
            titleFontColor: "#595959",
            reversed: true,
            title: "Time",
            maximum: 1440,
            minimum: 0,
            interval: 60,
            labelFormatter: formatYAxisContent,
            gridColor: "#e9e9e9",
            lineColor: "#e9e9e9",
            tickColor: "#e9e9e9",
          },
          legend: {
            verticalAlign: "bottom",
            horizontalAlign: "center",
            dockInsidePlotArea: false,
            fontSize: 16,
            fontFamily: "Noto",
            fontColor: "#595959",
            reversed: true,
          },
          data: [
            ...milkingTimes.map((series, index) => ({
              type: "rangeArea",
              name: t("milking-schedule"),
              color: MILKING_RANGE_COLOR,
              markerSize: 0,
              fillOpacity: 0.4,
              toolTipContent: null,
              highlightEnabled: false,
              lineThickness: 0,
              dataPoints: series.dataPoints,
              showInLegend: index === 0,
            })),
            {
              type: "error",
              name: penData.label,
              xValueFormatString: "DD MMM, YYYY",
              whiskerLength: 10,
              markerSize: 0,
              color: PEN_LINE_COLOR,
              dataPoints: penData.dataPoints,
              showInLegend: true,
            },
          ],
        }}
        onChartPrint={onChartPrint}
      />
    </Paper>
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <MilkingChart {...props} />
    </ErrorBoundary>
  </Suspense>
);
