import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SVG } from "./SVG/timebudget.svg";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    "& #clock-solid, & #clock-dashed": {
      fill: "currentColor",
      stroke: "currentColor",
    },
    "& #cow": {
      fill: "currentColor",
    },
  },
});

const Icon = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <SvgIcon
      {...props}
      classes={{
        ...props.classes,
        root: classes.root,
      }}
      ref={ref}
      component={SVG}
    />
  );
});

export default Icon;
