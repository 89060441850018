import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Header from "../../../CommonComponents/UI/Header";
import Chart from "./Chart";
import theme from "../../../CommonComponents/UI/theme";
import PrintButton from "../../../CommonComponents/UI/PrintButton";
import FileCopy from "../../../CommonComponents/UI/FileCopy";
import use24HourData from "../../utils/use24HourData";
import GroupFilter from "../../../CommonComponents/UI/GroupFilter";
import Circle from "../../../CommonComponents/Icons/Circle";

const PREFIX = "FeedAvailability";

const classes = {
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  timelineContainer: `${PREFIX}-timelineContainer`,
  outerContainer: `${PREFIX}-outerContainer`,
  toolbar: `${PREFIX}-toolbar`,
  printButton: `${PREFIX}-printButton`,
  fileCopyButton: `${PREFIX}-fileCopyButton`,
};

const Root = styled("section")(() => ({
  [`&.${classes.content}`]: {
    padding: 48,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0 0 0",
    },
    "@media print": {
      "& header": {
        display: "none",
      },
    },
  },

  [`& .${classes.toolbar}`]: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    width: "100%",
    position: "relative",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",

      "& > div:first-of-type": {
        marginRight: 14,
      },
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.printButton}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    color: "rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.fileCopyButton}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    color: "rgba(0, 0, 0, 0.3)",
    fontSize: 22,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.title}`]: {
    margin: "12px 0 6px 0",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },

  [`& .${classes.timelineContainer}`]: {
    maxWidth: "1360px",
    width: "100%",
    margin: "0 auto",
  },

  [`& .${classes.outerContainer}`]: {
    position: "relative",
    background: "white",
    padding: "5px 10px 20px 10px",
    boxShadow: "0px 2px 4px 0px #cccccc",
    marginBottom: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: " 24px 0",
    },
    "@media print": {
      boxShadow: "none",
      padding: 0,
      marginTop: -76,
    },
  },
}));

const allGroups = "All Groups";
const LiveFeedStatus = () => {
  const { groups } = use24HourData();

  const shouldShowGroups = groups.length > 1 || groups[0].name !== "No group";

  const groupNames = [...groups.map((group) => group.name), allGroups];
  const [selectedGroup, setSelectedGroup] = useState(allGroups);
  const [filteredGroups, setFilteredGroups] = useState(groups);

  const chartRef = useRef(null);
  const { t } = useTranslation();

  const selectGroup = (e) => {
    setSelectedGroup(e.target.value);
    const filterGroups =
      e.target.value === allGroups
        ? groups
        : groups.filter((group) => group.name && group.name === e.target.value);

    setFilteredGroups(filterGroups);
  };

  return (
    <Root className={classes.content}>
      <div className={classes.timelineContainer}>
        <Header title={t("live-feed-status")}>
          <Circle />
        </Header>
        <div className={classes.outerContainer}>
          <div className={classes.toolbar}>
            <GroupFilter
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              groups={groupNames}
            />
            <FileCopy
              domNode={chartRef.current}
              fileCopyClass={classes.fileCopyButton}
            />
            <PrintButton printButtonClass={classes.printButton} />
          </div>

          <Chart
            filteredGroups={filteredGroups}
            chartRef={chartRef}
            shouldShowGroups={shouldShowGroups}
            isResponsive={true}
          />
        </div>
      </div>
    </Root>
  );
};

export default LiveFeedStatus;
