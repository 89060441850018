import { useReducer } from "react";
import {
  initialOpsState,
  opsReducer,
} from "../../../../CommonComponents/utils/Operations/stateManagement.common.const";

const useMilkingOperations = (statePayload) => {
  const [state, dispatch] = useReducer(opsReducer, {
    selectedDateRange: initialOpsState.selectedDateRange,
    selectedPrintSection: {
      ...initialOpsState.selectedPrintSection,
    },
    last7Days: { ...initialOpsState.last7Days, ...statePayload.last7Days },
    last30Days: { ...initialOpsState.last30Days, ...statePayload.last30Days },
    overview: { ...initialOpsState.overview, ...statePayload.last7Days },
    selectedPen: { ...initialOpsState.selectedPen },
    selectedGroup: initialOpsState.selectedGroup,
    groups: [
      ...initialOpsState.groups,
      ...statePayload.last7Days.summaries
        .map((summary) => summary.group)
        .filter(Boolean),
    ],
  });

  const loadPen = () => dispatch({ type: "LOADING_PEN" });

  const selectGroup = (event) =>
    dispatch({ type: "SELECT_GROUP", payload: event.target.value });

  const selectPenId = (payload) => {
    loadPen();
    // setTimeout is ensuring faster render of PenDetails & its charts
    setTimeout(() => {
      dispatch({ type: "SELECT_PEN_ID", payload });
    }, 100);
  };

  const selectDateRange = (payload) => {
    selectPenId({ selectedPenId: null });
    dispatch({ type: "SELECT_DATE_RANGE", payload });
  };

  const selectPrintSection = (payload) => {
    dispatch({ type: "SELECT_PRINT_SECTION", payload });
  };

  return {
    overview: state.overview,
    selectedPen: state.selectedPen,
    selectedDateRange: state.selectedDateRange,
    selectedPrintSection: state.selectedPrintSection,
    selectedGroup: state.selectedGroup,
    groups: state.groups,
    selectGroup,
    selectDateRange,
    selectPrintSection,
    selectPenId,
    loadPen,
  };
};

export default useMilkingOperations;
