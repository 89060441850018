const alertTypes = {
  lockupTime: {
    value: "LOCK_UP_TIME",
    label: "Lockup Time",
    translationKey: "lockup-time",
    threshold: 60,
  },
  lowFeed: {
    value: "LOW_FEED",
    label: "Low Feed",
    translationKey: "low-feed",
    threshold: 0,
  },
  missedPushup: {
    value: "MISSED_PUSHUPS",
    label: "Missed Push-up",
    translationKey: "missed-pushup",
    threshold: 0,
  },
};

export default alertTypes;
