import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import FeedAvailabilityRow from "./FeedAvailabilityRow";
import ChartHeader from "./ChartHeader";
import times from "../../utils/feedStatus/times";
import { colors } from "../../../CommonComponents/UI/theme";
import use24HourData from "../../utils/use24HourData";
import { TIMELINE_OFFSET, TIMELINE_OFFSET_MOBILE } from "./constants";

const PREFIX = "FeedAvailability";

const classes = {
  content: `${PREFIX}-content`,
  timelineContainer: `${PREFIX}-timelineContainer`,
  outerContainer: `${PREFIX}-outerContainer`,
  innerContainer: `${PREFIX}-innerContainer`,
  daysStatusWrapper: `${PREFIX}-daysStatusWrapper`,
  currentTime: `${PREFIX}-currentTime`,
  contentContainer: `${PREFIX}-contentContainer`,
  contentBackground: `${PREFIX}-contentBackground`,
  timesWrapper: `${PREFIX}-timesWrapper`,
  timeDelimitter: `${PREFIX}-timeDelimitter`,
  timeShort: `${PREFIX}-timeShort`,
  icon: `${PREFIX}-icon`,
  group: `${PREFIX}-group`,
  groupLabel: `${PREFIX}-groupLabel`,
};

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "isResponsive",
})(({ isResponsive, theme }) => ({
  [`&.${classes.content}`]: {
    flexGrow: 1,
    padding: "16px 0 0 0",

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      padding: 48,
    },
    "@media print": {
      "& header": {
        display: "none",
      },
    },
  },

  [`& .${classes.timelineContainer}`]: {
    maxWidth: "1360px",
    width: "100%",
    margin: "0 auto",
  },

  [`& .${classes.outerContainer}`]: {
    position: "relative",
    background: "white",
    padding: " 24px 0",
    boxShadow: "0px 2px 4px 0px #cccccc",
    marginBottom: "20px",
    width: "100%",
    [isResponsive ? theme.breakpoints.up("md") : null]: {
      padding: "5px 14px 20px 20px",
    },

    "@media print": {
      boxShadow: "none",
      padding: 0,
      marginTop: -76,
    },
  },

  [`& .${classes.innerContainer}`]: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    display: "inline-block",
    "@media print": {
      marginTop: 30,
    },
  },

  [`& .${classes.daysStatusWrapper}`]: {
    position: "relative",
    width: "100%",
    height: "100%",
    flex: "none",
    marginLeft: "calc(-100% + 60px)",

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      marginLeft: "calc(-100% + 130px)",
    },
    "@media print": {
      marginLeft: "calc(-100% + 60px)",
    },
  },

  [`& .${classes.currentTime}`]: {
    position: "absolute",
    height: "100%",
    pointerEvents: "none",
    border: "2px dashed #ecb13a",
    zIndex: 1,
    right: 60,
    width: `calc((100% - ${TIMELINE_OFFSET_MOBILE}) / 24)`,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      right: 130,
      // 24 * 4 = 96
      // 96 15-min periods per day
      width: `calc((100% - ${TIMELINE_OFFSET}) / 96)`,
    },

    "@media print": {
      width: `calc((100% - ${TIMELINE_OFFSET}) / 96)`,
    },
  },

  [`& .${classes.contentContainer}`]: {
    display: "flex",
    width: "100%",
  },

  [`& .${classes.contentBackground}`]: {
    flex: "none",
    display: "flex",
    marginLeft: 60,
    width: `calc(100% - ${TIMELINE_OFFSET_MOBILE})`,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      marginLeft: 73,
      width: `calc(100% - ${TIMELINE_OFFSET})`,
    },
  },

  [`& .${classes.timesWrapper}`]: {
    width: "100%",
    display: "flex",
    borderRight: "1px solid #e9e9e9",
    borderLeft: "1px solid #e9e9e9",
  },

  [`& .${classes.timeDelimitter}`]: {
    borderRight: "1px solid #e9e9e9",
    height: "100%",
    width: "calc(100% / 24)",

    "&:first-of-type": {
      width: (props) => `calc((100% / 24) * (60 - ${props.mins})/60)`,
    },
  },

  [`& .${classes.timeShort}`]: {
    borderRight: `1px solid ${colors.black}`,
    marginRight: -1,
    height: 8,
    zIndex: 1,
    position: "relative",
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    top: 10,
    right: 12,
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.group}`]: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 44,
    margin: "0 60px 0 60px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "50%",
      display: "block",
      left: 0,
      borderTop: "1px solid #578ab6",
      background: "#578ab6",
      width: "100%",
      transform: "translateY(-50%)",
    },

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      margin: "0 130px 0 73px",
    },

    "&:nth-of-type(2)": {
      marginTop: 20,
    },
  },

  [`& .${classes.groupLabel}`]: {
    border: "1px solid #578ab6",
    borderRadius: 4,
    padding: "0 20px",
    backgroundColor: "#fff",
    zIndex: 1,
    fontSize: 14,

    [theme.breakpoints.down("md")]: {
      padding: "0 10px",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1",
      wordBreak: "break-all",
      textAlign: "center",
      maxWidth: 190,
    },
  },

  [`& .${classes.circle}`]: {
    transform: "translateX(-50%) translateY(-50%)",
    width: 20,
    height: 20,

    margin: "20px 0 0 20px",

    "&:before": {
      content: '""',
      position: "relative",
      display: "block",
      width: "250%",
      height: "250%",
      boxSizing: "border-box",
      marginLeft: "-75%",
      marginTop: "-75%",
      borderRadius: 45,
      backgroundColor: "#75daad",
      animation: "pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite",
    },

    "&:after": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: "#75daad",
      borderRadius: 50,
      animation:
        "circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite",
    },
  },

  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.33)",
    },
    "80%, 100%": {
      opacity: 0,
    },
  },

  "@keyframes circle": {
    "0%": {
      transform: "scale(0.8)",
    },
    "50%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0.8)",
    },
  },
}));

const Chart = ({ chartRef, filteredGroups, isResponsive }) => {
  const [activePen, setActivePen] = useState(null);
  const { hour, mins, ampm, groups } = use24HourData();
  const shouldShowGroups = groups.length > 1 || groups[0].name !== "No group";
  const currentGroups = filteredGroups || groups;

  return (
    <Root
      isResponsive={isResponsive}
      ref={chartRef}
      className={classes.innerContainer}
    >
      <ChartHeader
        hour={hour}
        mins={mins}
        ampm={ampm}
        isResponsive={isResponsive}
      />
      <div className={classes.contentContainer}>
        <div id="content-background" className={classes.contentBackground}>
          <div className={classes.timesWrapper}>
            {times.map((time, index) => (
              <div
                className={classes.timeDelimitter}
                key={`FeedStatus-delimitter-${time}-${index}`}
              >
                <div className={classes.timeShort} />
              </div>
            ))}
          </div>
        </div>

        <div className={classes.daysStatusWrapper}>
          <div className={classes.currentTime} />
          {currentGroups.map(({ name, pens, target }) => (
            <Fragment key={name}>
              {shouldShowGroups && (
                <div className={classes.group} data-cy="group-label">
                  <div className={classes.groupLabel}> {name} </div>
                </div>
              )}
              {pens.map((pen) => (
                <FeedAvailabilityRow
                  {...pen}
                  key={pen.key}
                  target={target}
                  setActivePen={setActivePen}
                  activePen={activePen}
                  isResponsive={isResponsive}
                />
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </Root>
  );
};

Chart.propTypes = {
  isResponsive: PropTypes.bool,
};

Chart.defaultProps = {
  isResponsive: false,
};

export default Chart;
