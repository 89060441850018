import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Header from "../../../CommonComponents/UI/Header";
import FeedingOpsProvider from "./utils/FeedingOpsProvider";
import FoInsightsOverview from "./components/FoInsightsOverview";
import {
  flexColumnMixIn,
  flexRowMixIn,
  flexSpaceBetweenMixIn,
} from "../../../CommonComponents/utils/Operations/cssMixins";
import FoPensOverview from "./components/FoPensOverview";

const PREFIX = "FeedingOps";

const classes = {
  content: `${PREFIX}-content`,
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    ...flexRowMixIn,
    ...flexSpaceBetweenMixIn,
    width: "100%",
    height: "120vh",
    [theme.breakpoints.up("xs")]: {
      ...flexColumnMixIn,
    },
    [theme.breakpoints.up("lg")]: {
      ...flexRowMixIn,
    },
  },

  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "flex-start",
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      ...flexColumnMixIn,
    },
    [theme.breakpoints.up("sm")]: {
      overflowX: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      overflowX: "hidden",
    },
  },
}));

const FeedingOps = () => {
  const { t } = useTranslation();

  return (
    <FeedingOpsProvider>
      <Header
        title={t("feeding-operations")}
        dataCyAtt="Ops Header Feeding Operations"
      />
      <Root className={classes.wrapper}>
        <div className={classes.content}>
          <FoPensOverview></FoPensOverview>

          <FoInsightsOverview></FoInsightsOverview>
        </div>
      </Root>
    </FeedingOpsProvider>
  );
};

export default FeedingOps;
