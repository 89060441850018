import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useHistory, useRouteMatch } from "react-router-dom";
import Button from "../../../CommonComponents/UI/Button";
import ProductivityByHour from "./Productivity/ProductivityByHour";

const PREFIX = "DrillDown";

const classes = {
  section: `${PREFIX}-section`,
  grid: `${PREFIX}-grid`,
  tile: `${PREFIX}-tile`,
  back: `${PREFIX}-back`,
  chartSection: `${PREFIX}-chartSection`,
};

const Root = styled("section")(({ theme }) => ({
  [`&.${classes.section}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    "@media print": {
      padding: 20,
    },
  },

  [`& .${classes.grid}`]: {
    height: "100%",
  },

  [`& .${classes.tile}`]: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
    height: "100%",
    width: "100%",
    "@media print": {
      boxShadow: "none",
    },
  },

  [`& .${classes.back}`]: {
    marginBottom: theme.spacing(1.5),
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.chartSection}`]: {
    position: "relative",
    height: 785,
    "@media print": {
      height: 690,
    },
  },
}));

const DrillDown = () => {
  const history = useHistory();
  const { params, path } = useRouteMatch();
  const { id, sectionId } = params;
  const rootUrl = path.split("/")[1];

  const navigateBack = () =>
    history.replace(`/${rootUrl}/pens/${id}/${sectionId}`);

  return (
    <Root className={classes.section}>
      <Grid
        container
        spacing={0}
        className={classes.grid}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Button
          title="Back"
          variant="outlined"
          onClick={navigateBack}
          className={classes.back}
        />
        <Grid xs={12} item className={classes.chartSection} data-cy="chart">
          <Paper elevation={3} classes={{ root: classes.tile }}>
            <ProductivityByHour />
          </Paper>
        </Grid>
      </Grid>
    </Root>
  );
};

export default DrillDown;
