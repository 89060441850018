import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

const PREFIX = "DateBar";

const classes = {
  dateBar: `${PREFIX}-dateBar`,
  dayOne: `${PREFIX}-dayOne`,
  barOne: `${PREFIX}-barOne`,
  dayTwo: `${PREFIX}-dayTwo`,
  barTwo: `${PREFIX}-barTwo`,
};

const Root = styled("div")(() => ({
  [`& .${classes.dateBar}`]: {
    display: "flex",
    height: 20,
  },

  [`& .${classes.dayOne}`]: {
    height: "100%",
    textAlign: "center",
  },

  [`& .${classes.barOne}`]: {
    height: 4,
    backgroundColor: "#a9d5e0",
    width: "100%",
  },

  [`& .${classes.dayTwo}`]: {
    flex: 1,
    textAlign: "center",
  },

  [`& .${classes.barTwo}`]: {
    height: 4,
    backgroundColor: "#bfbfbf",
    width: "100%",
  },
}));

dayjs.extend(timezone);

const DateBar = ({ coordinates, startDate, endDate, timezone }) => {
  const endOfDay = dayjs(startDate).tz(timezone).endOf("date");
  const widthOne = dayjs(endOfDay).diff(startDate, "minute");
  //1440 minutes in a day
  const widthTwo = 1440 - widthOne;

  return (
    <Root style={{ width: coordinates.canvasWidth }}>
      <div
        className={classes.dateBar}
        style={{ width: coordinates.chartWidth, marginLeft: coordinates.start }}
      >
        <div
          className={classes.dayOne}
          style={{ width: `${(widthOne / 1440) * 100}%` }}
        >
          <div className={classes.barOne} />
          {widthOne > 200 && dayjs(startDate).tz(timezone).format("ddd MMM D")}
        </div>
        <div
          className={classes.dayTwo}
          style={{ width: `${(widthTwo / 1440) * 100}%` }}
        >
          <div className={classes.barTwo} />
          {widthTwo > 200 && dayjs(endDate).tz(timezone).format("ddd MMM D")}
        </div>
      </div>
    </Root>
  );
};

export default DateBar;

DateBar.propTypes = {
  coordinates: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};
