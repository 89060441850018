import React, { Suspense } from "react";
import { styled } from "@mui/material/styles";
import merge from "deepmerge";
import Typography from "@mui/material/Typography";
import CanvasJSReact from "../../../../CommonComponents/Vendor/canvasjs.react";
import { useQuery } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import Paper from "@mui/material/Paper";
import LoadingSpinner from "../../../../CommonComponents/UI/LoadingSpinner";
import { colors } from "../../../../CommonComponents/UI/theme";
const PREFIX = "Chart";

const classes = {
  headerInner: `${PREFIX}-headerInner`,
  text: `${PREFIX}-text`,
  container: `${PREFIX}-container`,
  iconContainer: `${PREFIX}-iconContainer`,
  chartContainer: `${PREFIX}-chartContainer`,
  styledIcon: `${PREFIX}-styledIcon`,
  h5: `${PREFIX}-h5`,
  errorMessage: `${PREFIX}-errorMessage`,
};

const containerStyle = {
  height: "100%",
  background: "#ffffff",
  padding: "24px 16px 16px 16px",
  boxShadow: "0px 2px 4px 0px #cccccc",
  position: "relative",

  "@media print": {
    width: "100%",
    padding: "0",
    boxShadow: "none",
  },
};

const Root = styled("div")(() => ({
  [`& .${classes.headerInner}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    marginBottom: "8px",
    height: "40px",
    "@media print": {
      borderBottom: "none",
    },
  },

  [`& .${classes.text}`]: {
    fontSize: "16px",
  },

  [`&.${classes.container}`]: {
    ...containerStyle,
  },

  [`& .${classes.iconContainer}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.chartContainer}`]: {
    height: "calc(100% - 48px)",
  },

  [`& .${classes.styledIcon}`]: {
    fill: colors.green,
    width: "34px",
    height: "34px",
    marginRight: "8px",
  },

  [`& .${classes.h5}`]: {
    color: colors.gray35,
  },

  [`& .${classes.errorMessage}`]: {
    height: "100%",
    flex: 1,
    ...centerMixIn,
  },
}));

const { CanvasJSChart, CanvasJS } = CanvasJSReact;

const centerMixIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const Header = ({ icon: Icon, title }) => {
  return (
    <div className={classes.headerInner}>
      <div className={classes.iconContainer}>
        {Icon && <Icon className={classes.styledIcon} />}
        <Typography variant="h5" className={classes.h5}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

// Will need to generate a custom color id that way each one can use it if necessary
CanvasJS.addColorSet("baseColorSet", ["#c7e7d2", "#ff5050"]);

const AxisTypography = {
  labelFontSize: 16,
  labelFontWeight: 300,
  labelFontFamily: "Noto",
  labelFontColor: "#595959",
};

const BASE_CONFIGURATION = {
  animationEnabled: true,
  toolTip: {
    enabled: false,
  },
  legend: {
    fontSize: 16,
    fontFamily: "Noto",
    fontWeight: 300,
    horizontalAlign: "center",
    itemWidth: 200,
    markerMargin: 16,
  },
  axisX: {
    ...AxisTypography,
    lineThickness: 0,
    tickLength: 20,
    tickThickness: 0,
    valueFormatString: "MMM DD",
  },
  axisY: {
    ...AxisTypography,
    lineThickness: 0,
    gridThickness: 1,
    gridColor: "#d9d9d9",
    tickLength: 5,
    tickThickness: 0,
  },
  colorSet: "baseColorSet",
};

const LifeCycleWrapper = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Paper style={containerStyle}>
          <LoadingSpinner />
        </Paper>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>
    </Suspense>
  );
};

const ErrorView = () => {
  return (
    <Paper elevation={3} classes={{ root: classes.errorMessage }}>
      <Typography variant="h5" align="center">
        Something went wrong with the loading of this chart, please try again
        later.
      </Typography>
    </Paper>
  );
};

const Chart = ({
  options,
  queryKey,
  queryFn,
  onChartPrint,
  chartId,
  formatFunction = () => {},
  dataAvailable = () => true,
}) => {
  let { config: userOptions, ...headerOptions } = options;
  const formatData = (data) => {
    if (!userOptions.data)
      throw new Error("Chart must be provided with data configuration");
    let maximumY = null;
    if (dataAvailable(data)) {
      const { tempMaximumY = null, data: formattedData = [] } = formatFunction(
        data
      );
      maximumY = tempMaximumY;
      userOptions.data.forEach((entry, index) => {
        entry.dataPoints = formattedData[index];
        return entry;
      });
    } else {
      userOptions.subtitles = [
        {
          text: "No Data Available",
          verticalAlign: "center",
        },
      ];
    }

    const finalConfiguration = merge(BASE_CONFIGURATION, userOptions);
    // if there is no override coming from the transform function then don't do anything to preset
    if (maximumY) finalConfiguration.axisY.maximum = maximumY;
    return finalConfiguration;
  };

  const { data } = useQuery(queryKey, queryFn);

  const displayData = formatData(data);

  return (
    <Root id={chartId} className={classes.container}>
      <div className={classes.chartContainer}>
        <Header {...headerOptions} />

        <CanvasJSChart
          containerProps={{
            height: "100%",
            position: "relative",
          }}
          onChartPrint={onChartPrint}
          options={displayData}
        />
      </div>
    </Root>
  );
};

export default (props) => (
  <LifeCycleWrapper {...props}>
    <Chart {...props} />
  </LifeCycleWrapper>
);
