import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";

const PREFIX = "Filter";

const classes = {
  openButton: `${PREFIX}-openButton`,
  filterFixedBackground: `${PREFIX}-filterFixedBackground`,
  filterContent: `${PREFIX}-filterContent`,
  filterIcon: `${PREFIX}-filterIcon`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")(() => ({
  [`& .${classes.openButton}`]: {
    cursor: "pointer",
    borderRadius: "50%",
    width: 26,
    height: 26,
    background: "#e69e6c",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // The section that opens when the filter button is clicked
  [`& .${classes.filterFixedBackground}`]: {
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: 11,
    height: "calc(100% - 146px)",
    background: "#f8f8f8",
    transition: "max-width 0.5s",
    boxShadow: "0 3px 9px 0 #888888",
  },

  [`& .${classes.filterContent}`]: {
    padding: "24px 48px",
    position: "relative",
  },

  [`& .${classes.filterIcon}`]: {
    fill: "white",
    height: 24,
    "@media screen and (min-width: 1024px)": {
      height: 32,
    },
  },

  [`& .${classes.icon}`]: {
    top: 8,
    right: 8,
    cursor: "pointer",
    color: "#666666",
    position: "absolute",
    "@media screen and (min-width: 1024px)": {
      top: 24,
      right: 24,
    },
  },
}));

const Filter = ({ hook, children }) => {
  const [active, setActive] = useState(false);

  return (
    <Root>
      <div
        className={classes.openButton}
        data-cy={`${hook}-filter-button-open`}
        data-thirdparty={`${hook}-filter-button-open`}
        onClick={() => setActive(true)}
      >
        <FilterListIcon className={classes.filterIcon} />
      </div>
      <div
        className={classes.filterFixedBackground}
        style={{ maxWidth: `${active ? "250px" : 0}` }}
      >
        <div className={classes.filterContent}>
          <CloseIcon
            className={classes.icon}
            onClick={() => setActive(false)}
            data-cy={`${hook}-filter-button-close`}
            data-thirdparty={`${hook}-filter-button-close`}
          />
          {children}
        </div>
      </div>
    </Root>
  );
};

export default Filter;
