import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ReactDatePicker from "react-datepicker";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import DatepickerHeader from "./DatepickerCustomHeader";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import { Typography } from "@mui/material";

const PREFIX = "DatePicker";

const classes = {
  container: `${PREFIX}-container`,
  daterange: `${PREFIX}-daterange`,
  delimitter: `${PREFIX}-delimitter`,
  input: `${PREFIX}-input`,
};

const Root = styled("div")(() => ({
  [`&.${classes.container}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 380,
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.daterange}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.delimitter}`]: {
    margin: "0 20px",
  },

  [`& .${classes.input}`]: {
    maxWidth: 120,
    textAlign: "center",
    cursor: "pointer",
  },
}));

dayjs.extend(minMax);

const CustomPicker = (props) => {
  const dateFormat = "MMM dd, yyyy";
  return (
    <ReactDatePicker
      showPopperArrow={false}
      renderCustomHeader={(props) => <DatepickerHeader {...props} />}
      useWeekdaysShort={true}
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "-100px",
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: "viewport",
        },
      }}
      dayClassName={() => "CainthusDatePicker"}
      dateFormat={dateFormat}
      {...props}
    />
  );
};

const CalendarInput = React.forwardRef(({ outerRef, ...props }, _ref) => {
  return (
    <Input
      {...props}
      ref={outerRef}
      classes={{ input: classes.input }}
      readOnly
    />
  );
});

const DatePicker = ({
  startDate: intialS = null,
  endDate: intialE = null,
  minimumDate = new Date(2019, 7, 1),
  maximumDate = new Date(),
  maxRange,
  onApplyPressed,
  hook,
}) => {
  const [startDate, setStartDate] = useState(intialS);
  const [endDate, setEndDate] = useState(intialE);
  const [preventApply, setPreventApply] = useState(true);

  useEffect(() => {
    setStartDate(intialS);
    setEndDate(intialE);
  }, [intialS, intialE]);

  const applyActive = !preventApply && startDate !== null && endDate !== null;

  return (
    <Root className={classes.container}>
      <div className={classes.daterange}>
        <CustomPicker
          selectsStart
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          customInput={
            <CalendarInput
              data-cy={`${hook}-datepicker-input-start`}
              data-thirdparty={`${hook}-datepicker-input-start`}
            />
          }
          minDate={
            endDate !== null
              ? dayjs
                  .max([
                    dayjs(minimumDate),
                    dayjs(endDate).subtract(maxRange, "days"),
                  ])
                  .toDate()
              : minimumDate
          }
          maxDate={maximumDate}
          onChange={(date) => {
            if (dayjs(date).isAfter(endDate, "day")) {
              setEndDate(date);
            }
            setStartDate(date);
            setPreventApply(false);
          }}
        />
        <Typography className={classes.delimitter}>to</Typography>
        <CustomPicker
          selectsEnd
          showPopperArrow={false}
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          disabled={startDate === null}
          customInput={
            <CalendarInput
              data-cy={`${hook}-datepicker-input-end`}
              data-thirdparty={`${hook}-datepicker-input-end`}
            />
          }
          dayClassName={() => "CainthusDatePicker CainthusDatePicker__EndDate"}
          minDate={minimumDate}
          maxDate={
            startDate !== null
              ? dayjs
                  .min([
                    dayjs(maximumDate),
                    dayjs(startDate).add(maxRange, "day"),
                  ])
                  .toDate()
              : maximumDate
          }
          onChange={(date) => {
            if (dayjs(date).isBefore(startDate, "day")) {
              setStartDate(date);
            }
            setEndDate(date);
            setPreventApply(false);
          }}
        />
      </div>
      <Button
        disabled={!applyActive}
        onClick={() => {
          if (applyActive) {
            onApplyPressed(startDate, endDate);
            setPreventApply(true);
          }
        }}
        data-cy={`${hook}-datepicker-button-apply`}
        data-thirdparty={`${hook}-datepicker-button-apply`}
      >
        Apply
      </Button>
    </Root>
  );
};

export default DatePicker;
