import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NavigationIcon from "@mui/icons-material/Navigation";
import Collapse from "@mui/material/Collapse";
import {
  minutesToHours,
  hoursMinPaddedFormatter,
} from "../../../CommonComponents/utils/time";
import classNames from "classnames";
import Link from "../../../CommonComponents/UI/Link";
import FeedStatusRow from "../Pen/FeedStatusRow";
import { colors } from "../../../CommonComponents/UI/theme";
import ExpandArrow from "../../../CommonComponents/UI/ExpandArrow";
import { TIMELINE_OFFSET } from "./constants";

const PREFIX = "FeedAvailabilityRow";

const classes = {
  dailyStatusOuter: `${PREFIX}-dailyStatusOuter`,
  dailyStatusContainer: `${PREFIX}-dailyStatusContainer`,
  dailyStatusInner: `${PREFIX}-dailyStatusInner`,
  headerContainer: `${PREFIX}-headerContainer`,
  statusWrapper: `${PREFIX}-statusWrapper`,
  breakdownRowContainer: `${PREFIX}-breakdownRowContainer`,
  statusHeaderContainer: `${PREFIX}-statusHeaderContainer`,
  feedStatusBreakdown: `${PREFIX}-feedStatusBreakdown`,
  breakdownRowsContainer: `${PREFIX}-breakdownRowsContainer`,
  orientationLabelsContainer: `${PREFIX}-orientationLabelsContainer`,
  orientationContainer: `${PREFIX}-orientationContainer`,
  icon: `${PREFIX}-icon`,
  rotatedIcon: `${PREFIX}-rotatedIcon`,
  text: `${PREFIX}-text`,
  textBold: `${PREFIX}-textBold`,
  arrow: `${PREFIX}-arrow`,
  arrowActive: `${PREFIX}-arrowActive`,
  arrowIcon: `${PREFIX}-arrowIcon`,
  penWrapper: `${PREFIX}-penWrapper`,
  column: `${PREFIX}-column`,
  time: `${PREFIX}-time`,
  overTarget: `${PREFIX}-overTarget`,
  link: `${PREFIX}-link`,
};

const Root = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== "hasBreakdown" && prop !== "isResponsive",
})(({ hasBreakdown, isResponsive, theme }) => ({
  [`&.${classes.dailyStatusOuter}`]: {
    borderTop: "1px solid #e9e9e9",
    borderBottom: "1px solid #e9e9e9",
  },

  [`&.${classes.dailyStatusContainer}`]: {
    display: "flex",
    alignItems: "center",
    cursor: `${hasBreakdown ? "pointer" : "default"}`,
    width: "100%",
    paddingLeft: 5,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      paddingLeft: 0,
    },
  },

  [`& .${classes.dailyStatusInner}`]: {
    paddingTop: 7,
    borderRight: "1px solid #e9e9e9",
    width: `calc(100% - 105px)`,
    [isResponsive ? theme.breakpoints.up("md") : null]: {
      width: `calc(100% - ${TIMELINE_OFFSET})`,
    },
  },

  [`& .${classes.headerContainer}`]: {
    flex: "none",
    width: 50,

    marginRight: 5,
    textAlign: "right",
    lineHeight: "18px",

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      width: 68,
    },
  },

  [`& .${classes.statusWrapper}`]: {
    flex: "0 0 auto",
    width: `calc(100% - 115px)`,
    "&:first-of-type": {
      width: "100%",
    },

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      width: `calc(100% - ${TIMELINE_OFFSET})`,
    },
  },

  [`& .${classes.breakdownRowContainer}`]: {
    display: "flex",
    alignItems: "center",
    paddingTop: 4,
    marginLeft: -30,
  },

  [`& .${classes.statusHeaderContainer}`]: {
    display: "flex",
    justifyContent: `${hasBreakdown ? "space-between" : "flex-end"}`,
    alignItems: "center",
  },

  [`& .${classes.feedStatusBreakdown}`]: {
    display: "flex",
    flexDirection: "row",
    maxHeight: 5000,
    backgroundColor: "#f8f8f8",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.11)",
    position: "relative",
    paddingLeft: 35,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      paddingLeft: 30,
    },
  },

  [`& .${classes.breakdownRowsContainer}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  [`& .${classes.orientationLabelsContainer}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute",
    height: "100%",
    padding: "2px 0",
    marginLeft: -25,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      marginLeft: -10,
    },
  },

  [`&.${classes.orientationContainer}`]: {
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    color: "#9d9d9d",
    textAlign: "center",
  },

  [`& .${classes.icon}`]: {
    display: "inline-block",
    height: 10,
    fontSize: 10,
  },

  [`& .${classes.rotatedIcon}`]: {
    transform: "rotate(180deg)",
  },

  [`& .${classes.text}`]: {
    fontSize: 10,
    color: "#9d9d9d",
  },

  [`& .${classes.textBold}`]: {
    overflow: "hidden",
    fontWeight: "bold",
    color: "#595959",
    width: 60,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
    wordBreak: "break-word",
    textAlign: "center",
    fontSize: 11,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      fontSize: 12,
    },
  },

  [`& .${classes.arrow}`]: {
    display: "inline-block",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
    marginRight: -5,
    height: 22,
    width: 22,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      marginRight: 0,
      height: 24,
      width: "auto",
    },
  },

  [`& .${classes.arrowActive}`]: {
    display: "inline-block",
    transform: "rotate(180deg)",
  },

  [`& .${classes.arrowIcon}`]: {
    height: 22,
    width: 22,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      height: "auto",
      width: "auto",
    },
  },

  [`& .${classes.penWrapper}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  [`& .${classes.column}`]: {
    whiteSpace: "nowrap",
    fontSize: 11,
    textAlign: "center",
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.time}`]: {
    width: 60,
    borderRight: "none",

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      width: 70,
      borderRight: "1px solid #e9e9e9",
    },
  },

  [`& .${classes.overTarget}`]: {
    color: colors.red,
  },

  [`& .${classes.link}`]: {
    width: 60,
    display: "none",
    [isResponsive ? theme.breakpoints.up("md") : null]: {
      display: "flex",
    },
  },
}));

const OrientationContainer = styled("div")(() => ({
  [`&.${classes.orientationContainer}`]: {
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    color: "#9d9d9d",
    textAlign: "center",
  },

  [`& .${classes.icon}`]: {
    display: "inline-block",
    height: 10,
    fontSize: 10,
  },

  [`& .${classes.rotatedIcon}`]: {
    transform: "rotate(180deg)",
  },
}));

const orientationLabels = {
  start: (orientation, classes) => (
    <OrientationContainer className={classes.orientationContainer}>
      <span className={classes.icon}>
        <NavigationIcon fontSize="inherit" />
      </span>
      {orientation}
    </OrientationContainer>
  ),
  end: (orientation, classes) => (
    <OrientationContainer className={classes.orientationContainer}>
      {orientation}
      <span className={`${classes.icon} ${classes.rotatedIcon}`}>
        <NavigationIcon fontSize="inherit" />
      </span>
    </OrientationContainer>
  ),
};

const formatOrientation = (orientation) => orientation.charAt(0).toUpperCase();

const StatusRow = (props) => {
  const { barHeight, modifier, windows } = props;

  return (
    <div className={classes.statusWrapper} sx={{ heigt: barHeight }}>
      <FeedStatusRow
        barHeight={barHeight}
        rowWidth={1200}
        modifier={modifier}
        windows={windows}
      />
    </div>
  );
};

const FeedStatusBreakdown = (props) => {
  const { children, active } = props;

  return (
    <Collapse in={active} data-cy="feed-status-row-expandable-section">
      <div
        className={classes.feedStatusBreakdown}
        sx={{ paddingBottom: `${active ? "4px" : "0"}` }}
      >
        {children}
      </div>
    </Collapse>
  );
};

const DailyStatusContainer = (props) => {
  const {
    breakdownActive,
    onClick,
    isOverTarget,
    hasBreakdown,
    penId,
    label,
    summary,
    windows,
    isResponsive,
  } = props;
  const { t } = useTranslation();

  return (
    <Root
      className={classes.dailyStatusContainer}
      data-cy="feed-status-row"
      onClick={onClick}
      hasBreakdown={hasBreakdown}
      isResponsive={isResponsive}
    >
      <div
        className={`${classes.headerContainer} ${classes.penWrapper}`}
        data-cy="row-header"
      >
        <span className={classes.textBold}>{label}</span>
        {hasBreakdown ? <ExpandArrow isActive={breakdownActive} /> : null}
        <br />
      </div>
      <div
        className={classes.dailyStatusInner}
        sx={{ paddingBottom: `${breakdownActive ? "2px" : "7px"}` }}
      >
        <StatusRow barHeight="34px" modifier={0.833} windows={windows} />
      </div>
      <div
        className={classNames(classes.column, classes.time, {
          [classes.overTarget]: isOverTarget,
        })}
      >
        {minutesToHours(summary.minutesNotOK, hoursMinPaddedFormatter)}
      </div>
      <div className={`${classes.column} ${classes.link}`}>
        <Link
          url={`/feedbunk-management/pens/${penId}/feed-status`}
          text={t("view-pen")}
        />
      </div>
    </Root>
  );
};

const FeedAvailabilityRow = ({
  windows,
  penId,
  label,
  summary,
  target,
  barnOrientation,
  sectionBreakdown,
  setActivePen,
  activePen,
  isResponsive,
}) => {
  const hasBreakdown = sectionBreakdown.length > 0;
  const [orientationStart = "", orientationEnd = ""] = barnOrientation.split(
    "-"
  );
  const shouldShowOrientation = orientationStart && sectionBreakdown.length > 1;

  const isOverTarget = summary.minutesNotOK > target;
  const breakdownActive = activePen === penId;

  const onRowClick = () => {
    const newActiveValue = breakdownActive ? null : penId;
    setActivePen(newActiveValue);
  };

  return (
    <Root isResponsive={isResponsive} className={classes.dailyStatusOuter}>
      <DailyStatusContainer
        hasBreakdown={hasBreakdown}
        windows={windows}
        penId={penId}
        label={label}
        summary={summary}
        breakdownActive={breakdownActive}
        isOverTarget={isOverTarget}
        onClick={onRowClick}
        isResponsive={isResponsive}
      />
      {hasBreakdown ? (
        <FeedStatusBreakdown active={breakdownActive}>
          {shouldShowOrientation && (
            <div className={classes.orientationLabelsContainer}>
              {orientationLabels.start(
                formatOrientation(orientationStart),
                classes
              )}
              {orientationLabels.end(
                formatOrientation(orientationEnd),
                classes
              )}
            </div>
          )}
          <div className={classes.breakdownRowsContainer}>
            {sectionBreakdown.map((section) => (
              <div
                className={classes.breakdownRowContainer}
                key={section.label}
                data-cy="feed-status-row-expandable-section-row"
              >
                <div className={classes.headerContainer}>
                  <span className={classes.text}>{section.label}</span>
                </div>
                <StatusRow
                  barHeight="16px"
                  modifier={0.833}
                  windows={section.windows}
                />
              </div>
            ))}
          </div>
        </FeedStatusBreakdown>
      ) : null}
    </Root>
  );
};

export default FeedAvailabilityRow;

FeedAvailabilityRow.propTypes = {
  windows: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  penId: PropTypes.number.isRequired,
  summary: PropTypes.shape({
    minutesMissing: PropTypes.number.isRequired,
    minutesNotOK: PropTypes.number.isRequired,
    minutesOK: PropTypes.number.isRequired,
    noVisibility: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.number.isRequired,
  setActivePen: PropTypes.func.isRequired,
  activePen: PropTypes.number,
  barnOrientation: PropTypes.string.isRequired,
  sectionBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.number.isRequired,
      windows: PropTypes.array.isRequired,
    })
  ).isRequired,
};

FeedAvailabilityRow.defaultProps = {
  activePen: null,
};
