import React from "react";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "./Button";

const PREFIX = "DeleteModal";

const classes = {
  modal: `${PREFIX}-modal`,
  title: `${PREFIX}-title`,
  information: `${PREFIX}-information`,
  buttons: `${PREFIX}-buttons`,
  strong: `${PREFIX}-strong`,
  closeButton: `${PREFIX}-closeButton`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledModal = styled(Modal)(() => ({
  [`& .${classes.modal}`]: {
    width: 320,
    height: 440,
    padding: 20,
    display: "flex",
    position: "absolute",
    top: "calc(50% - 220px)",
    left: "calc(50% - 160px)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    "&:focus": {
      outline: "none",
    },
  },

  [`& .${classes.title}`]: {
    fontSize: 27,
    margin: 0,
    color: "#595959",
  },

  [`& .${classes.information}`]: {
    fontSize: 16,
    textAlign: "center",
  },

  [`& .${classes.buttons}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "& > button:first-of-type": {
      marginBottom: 5,
    },
  },

  [`& .${classes.strong}`]: {
    color: "#595959",
    whiteSpace: "nowrap",
  },

  [`& .${classes.closeButton}`]: {
    alignSelf: "flex-end",
  },

  [`& .${classes.deleteIcon}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 40,
    textAlign: "center",
    width: 110,
    height: 110,
    backgroundColor: "rgb(0, 131, 137, 0.25)",
    borderRadius: "50%",
  },
}));

const DeleteModal = ({
  onConfirmDelete: handleDelete,
  onCancel: handleCancel,
  isOpen,
  children,
}) => {
  return (
    <StyledModal open={isOpen} onClose={handleCancel}>
      <div className={classes.modal}>
        <IconButton
          className={classes.closeButton}
          onClick={handleCancel}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.deleteIcon}>
          <DeleteIcon htmlColor="#008389" fontSize="inherit" />
        </div>
        {children}
        <div className={classes.buttons}>
          <Button title="Remove" variant="outlined" onClick={handleDelete} />
          <Button title="Cancel" onClick={handleCancel} />
        </div>
      </div>
    </StyledModal>
  );
};

export default DeleteModal;
