import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQueryClient } from "react-query";
import {
  fetchSettings,
  fetchRecipients,
  removeRecipient,
} from "../../../services/alerts";
import Card from "../../../../CommonComponents/UI/Card/Card";
import Toast from "../../../../CommonComponents/UI/Toast";
import RecipientsRow from "./RecipientsRow";
import AddRecipients from "./AddRecipients";
import alertTypes from "./alertTypes";

const PREFIX = "Recipients";

const classes = {
  tableWrapper: `${PREFIX}-tableWrapper`,
  table: `${PREFIX}-table`,
  tableBody: `${PREFIX}-tableBody`,
  headerCell: `${PREFIX}-headerCell`,
  tableHeader: `${PREFIX}-tableHeader`,
};

const StyledTable = styled("div")(({ theme }) => ({
  [`&.${classes.tableWrapper}`]: {
    overflowY: "visible",
  },

  [`& .${classes.table}`]: {
    margin: `0 ${theme.spacing(2.5)} ${theme.spacing(4)} ${theme.spacing(2.5)}`,
    width: `calc(100% - ${theme.spacing(5)})`,
    [theme.breakpoints.up("lg")]: {
      margin: `0 ${theme.spacing(5)} ${theme.spacing(4)} ${theme.spacing(5)}`,
      width: `calc(100% - ${theme.spacing(10)})`,
      minWidth: 730,
    },
  },

  [`& .${classes.tableBody}`]: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },

  [`& .${classes.headerCell}`]: {
    padding: "16px 8px",
    [theme.breakpoints.up("lg")]: {
      padding: 16,
    },
  },

  [`& .${classes.tableHeader}`]: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "table-header-group",
    },
  },
}));

const Recipients = () => {
  const { t } = useTranslation();

  const alertHeaders = [
    {
      id: "lockupTime",
      label: t("lockup-time"),
      cellClass: "checkboxCell",
      align: "center",
    },
    {
      id: "lowFeed",
      label: t("low-feed"),
      cellClass: "checkboxCell",
      align: "center",
    },
    {
      id: "missedPushup",
      label: t("missed-push-up"),
      cellClass: "checkboxCell",
      align: "center",
    },
  ];

  const queryClient = useQueryClient();
  const { data } = useQuery("alerts/recipients", fetchRecipients);
  const { data: settingsData } = useQuery("alerts/set", fetchSettings, {
    refetchOnWindowFocus: false,
  });

  const availableAlertsTypes = settingsData.map((setting) => setting.id);
  const availableAlerts = Object.keys(alertTypes)
    .filter((key) => availableAlertsTypes.includes(alertTypes[key].value))
    .reduce((obj, key) => {
      obj[key] = alertTypes[key];
      return obj;
    }, {});

  const availableHeaders = [
    {
      id: "name",
      label: "Recipients",
      translationKey: "recipients",
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      translationKey: "phone-number",
    },
    ...alertHeaders.filter((header) => availableAlerts[header.id]),
    {
      id: "alertsTime",
      label: "Send alerts between",
      translationKey: "send-alerts-between",
      cellClass: "timeCell",
    },
    {
      id: "edit",
    },
  ];

  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  const { mutate: deleteRecipient } = useMutation(removeRecipient, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData("alerts/recipients", (oldData) => {
        return oldData.filter(({ id }) => id !== variables.id);
      });
    },
    onError: () =>
      setToast({
        show: true,
        type: "error",
        message: "We had a problem deleting the user, try again",
      }),
  });

  return (
    <>
      <AddRecipients setToast={setToast} availableAlerts={availableAlerts} />
      <Card title="Current Recipients">
        <StyledTable className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {availableHeaders.map((header) => (
                  <TableCell
                    className={classes.headerCell}
                    key={header.id}
                    align={header.align}
                  >
                    {header.translationKey
                      ? t(header.translationKey)
                      : header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {data?.map((item) => (
                <RecipientsRow
                  key={item.id}
                  item={item}
                  availableAlerts={availableAlerts}
                  deleteRecipient={deleteRecipient}
                  headers={availableHeaders}
                  setToast={setToast}
                />
              ))}
            </TableBody>
          </Table>
        </StyledTable>
      </Card>
      <Toast toast={toast} closeToast={closeToast} />
    </>
  );
};

export default Recipients;
