import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import Cleanout from "../../../../CommonComponents/Icons/Cleanouts";
import Milking from "../../../../CommonComponents/Icons/Milking";
import Pushup from "../../../../CommonComponents/Icons/PushUps";
import Delivery from "../../../../CommonComponents/Icons/Deliveries";
import EventsToolbar from "./EventsToolbar";
import { useAccountDetails } from "../../../../App/useAccountDetails";
import { useActiveModules } from "../../../../App/useActiveModules";
import useDatesforPen from "../../../../CommonComponents/utils/useDatesForPen";
import { colors } from "../../../../CommonComponents/UI/theme";
import PrintButton from "../../../../CommonComponents/UI/PrintButton";
import {
  hoursMinFormatter,
  minutesToHours,
  twelveHoursFormatter,
} from "../../../../CommonComponents/utils/time";
import { getFeedStatusWindowsAndEvents } from "../../../services/pen";
import times from "../../../utils/feedStatus/times";
import { useExpandedState } from "../../../../CommonComponents/utils/useExpandedState";
import FeedStatusRow from "../FeedStatusRow";
import EventsRow from "./EventsRow";

import {
  STATUS_COLOR_MAP,
  STATUS_TEXT_MAP,
} from "../../../utils/feedStatus/statusMaps";
import { useLocation } from "react-router-dom";

const PREFIX = "FeedEvents";

const classes = {
  events: `${PREFIX}-events`,
  days: `${PREFIX}-days`,
  card: `${PREFIX}-card`,
  wrapper: `${PREFIX}-wrapper`,
  eventDayWrapper: `${PREFIX}-eventDayWrapper`,
  text: `${PREFIX}-text`,
  feedStatusContainer: `${PREFIX}-feedStatusContainer`,
  emptyBlock: `${PREFIX}-emptyBlock`,
  timeMarker: `${PREFIX}-timeMarker`,
  container: `${PREFIX}-container`,
  contentContainer: `${PREFIX}-contentContainer`,
  timeDelimitter: `${PREFIX}-timeDelimitter`,
  timeContainer: `${PREFIX}-timeContainer`,
  timesInner: `${PREFIX}-timesInner`,
  timesContainer: `${PREFIX}-timesContainer`,
  daysStatusWrapper: `${PREFIX}-daysStatusWrapper`,
  timeShort: `${PREFIX}-timeShort`,
  timeItem: `${PREFIX}-timeItem`,
  periodContainer: `${PREFIX}-periodContainer`,
  periodText: `${PREFIX}-periodText`,
  iconWrapper: `${PREFIX}-iconWrapper`,
  icon: `${PREFIX}-icon`,
  scrollPadding: `${PREFIX}-scrollPadding`,
  contentBackground: `${PREFIX}-contentBackground`,
};

const Card = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isExpanded",
})(({ isExpanded, theme }) => ({
  [`& .${classes.events}`]: {
    width: "100vw",
    height: "100%",
    zIndex: 2,
    marginLeft: -50,
    [theme.breakpoints.up("md")]: {
      marginLeft: -60,
    },
    "@media print": {
      overflow: "visible",
    },
  },

  [`& .${classes.days}`]: {
    height: "100%",
    width: 60,
    flexShrink: 0,
    marginLeft: "calc(-100% - 12px)",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      marginLeft: "-100%",
    },
    "@media print": {
      overflow: "visible",
    },
  },

  [`&.${classes.card}`]: {
    position: "relative",
    width: "100%",
    maxWidth: 1656,
    background: "#ffffff",
    display: "flex",
    boxShadow: "0px 2px 4px 0px #cccccc",
    margin: "0 auto",
    padding: "12px 36px 12px 0",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      overflow: "scroll",
      height: "100%",
      padding: 0,
    },
    "@media print": {
      margin: "25px 0 0 0",
      padding: 0,
      boxShadow: "none",
      overflow: "visible",
    },
  },

  [`& .${classes.wrapper}`]: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    "@media print": {
      overflow: "visible",
    },
  },

  [`& .${classes.eventDayWrapper}`]: {
    display: "flex",
    width: "100%",
    height: 127,
    background: "white",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    transform: "rotate(-90deg)",
    "@media print": {
      height: 83,
    },
  },

  [`& .${classes.text}`]: {
    fontSize: 16,
    fontWeight: 600,
  },
  [`& .${classes.feedStatusContainer}`]: {
    position: "relative",
    display: "flex",
    flex: "none",
    textAlign: "right",
    overflow: "initial",
    width: "calc(100% - 34px)",
    height: 8,
    backgroundColor: colors.white,
    marginTop: -5,
    marginLeft: 34,

    [theme.breakpoints.up("md")]: {
      marginLeft: 60,
      width: "calc(100% - 60px)",
    },
  },

  [`& .${classes.emptyBlock}`]: {
    marginRight: 8,
    width: 0,
    height: 16,
  },

  [`& .${classes.timeMarker}`]: {
    borderLeft: "2px solid #e9e9e9",
    borderTop: "2px solid #e9e9e9",
    position: "relative",
    right: 0,
    width: "100%",
    height: 8,
  },

  [`& .${classes.container}`]: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    width: "100%",
    paddingRight: 8,
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      paddingRight: 0,
    },
    "@media print": {
      overflow: "visible",
    },
  },

  [`& .${classes.contentContainer}`]: {
    display: "flex",
    overflowY: "scroll",
    position: "relative",
    maxHeight: `${isExpanded ? "calc(100vh - 300px)" : "calc(100vh - 279px)"}`,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflowY: "scroll",
      height: `${isExpanded ? "calc(100vh - 300px)" : "calc(100vh - 208px)"}`,
      maxHeight: `${
        isExpanded ? "calc(100vh - 300px)" : "calc(100vh - 208px)"
      }`,
    },
    "@media print": {
      maxHeight: "100%",
      overflow: "visible",
      height: "100%",
    },
  },

  [`& .${classes.timeDelimitter}`]: {
    borderLeft: "1px solid #e9e9e9",
    height: "calc(100% - 8px)",
    width: "calc(100% / 24)",

    "&:last-of-type": {
      borderRight: "1px solid #e9e9e9",
    },
  },

  [`& .${classes.timeContainer}`]: {
    width: "100%",
    marginTop: "16px",
    marginBottom: "8px",
    marginLeft: "-50%",
    "@media print": {
      marginTop: 0,
    },
  },

  [`& .${classes.timesInner}`]: {
    display: "inline-block",
    whiteSpace: "nowrap",
    borderBottom: "2px solid #e9e9e9",
    marginLeft: `${TIME_ITEM_MOBILE_WIDTH}`,
    width: `calc(100% - ${TIME_ITEM_MOBILE_WIDTH} - 8px)`,

    [theme.breakpoints.down("md")]: {
      backgroundColor: "#fff",
      borderBottom: "none",
      zIndex: 10,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: `${TIME_ITEM_DESKTOP_WIDTH}`,
      width: `calc(100% - ${TIME_ITEM_DESKTOP_WIDTH})`,
    },
    "@media print": {
      borderBottom: "2px solid #e9e9e9",
      width: `calc(100% - 43px)`,
      marginLeft: `${TIME_ITEM_MOBILE_WIDTH}`,
    },
  },

  [`& .${classes.timesContainer}`]: {
    display: "inline-block",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#fff",
      marginLeft: 0,
      zIndex: 10,
      boxShadow: "0px 2px 4px 0px #cccccc",
      position: "sticky",
      top: "0",
      width: "100vw",
    },
    "@media print": {
      top: 0,
      boxShadow: "none",
    },
  },
  [`& .${classes.timeShort}`]: {
    display: "none",
    borderLeft: `2px solid ${colors.mercuryGray}`,
    height: 8,
    zIndex: 1,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },

  [`& .${classes.timeItem}`]: {
    display: "inline-block",
    width: "calc(100% / 24)",
    height: "100%",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      "&:nth-child(2n + 1)": {
        visibility: "hidden",
      },
    },
    "@media print": {
      "&:nth-child(2n + 1)": {
        visibility: "visible",
      },
    },
  },

  [`& .${classes.periodContainer}`]: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  [`& .${classes.periodText}`]: {
    color: "#888888",
    display: "block",
    margin: 0,
  },

  [`& .${classes.iconWrapper}`]: {
    display: "none",
    position: "absolute",
    width: 24,
    height: 24,
    left: -35,
    top: -10,
    zIndex: 1,

    "@media screen and (min-width: 1024px)": {
      display: "block",
    },
  },

  [`& .${classes.icon}`]: {
    position: "absolute",
    top: 10,
    right: 12,
    "@media print": {
      display: "none",
    },
  },

  // bottom padding on scrollable container is ignored
  // adding this as a workaround
  [`& .${classes.scrollPadding}`]: {
    paddingBottom: theme.spacing(2),
    backgroundColor: colors.white,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(4),
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.contentBackground}`]: {
    width: `calc(100% - ${TIME_ITEM_MOBILE_WIDTH})`,
    marginLeft: `${TIME_ITEM_MOBILE_WIDTH}`,
    flex: "none",
    display: "flex",
    height: "100%",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: colors.white,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${TIME_ITEM_DESKTOP_WIDTH})`,
      marginLeft: `${TIME_ITEM_DESKTOP_WIDTH}`,
    },
  },
}));

const DeliveryIcon = styled((props) => (
  <Delivery
    htmlColor={!props.deemphasize ? colors.delivery : "#cccccc"}
    fontSize="inherit"
    data-cy="actual-event-deliveries-icon"
    stroke={!props.deemphasize ? colors.delivery : "#cccccc"}
    strokeWidth={0.5}
  />
))({});

const CleanoutIcon = styled((props) => (
  <Cleanout
    htmlColor={!props.deemphasize ? colors.cleanout : "#cccccc"}
    fontSize="inherit"
    data-cy="actual-event-cleanouts-icon"
    stroke={!props.deemphasize ? colors.cleanout : "#cccccc"}
    strokeWidth={0.5}
  />
))({});

const PushupIcon = styled((props) => (
  <Pushup
    htmlColor={!props.deemphasize ? colors.pushups : "#cccccc"}
    stroke={!props.deemphasize ? colors.pushups : "#cccccc"}
    fontSize="inherit"
    data-cy="actual-event-pushups-icon"
    strokeWidth={0.5}
  />
))({});

const MilkingIcon = styled((props) => (
  <Milking
    htmlColor={!props.deemphasize ? colors.milking : "#cccccc"}
    stroke={!props.deemphasize ? colors.milking : "#cccccc"}
    data-cy="actual-event-milkings-icon"
    fontSize="inherit"
    strokeWidth={0.5}
  />
))({});

const DeliveryFilterConfiguration = {
  name: "delivery",
  display: "Delivery",
  icon: () => DeliveryIcon,
  isActive: true,
  lookup: "deliveries",
};

const PushupFilterConfiguration = {
  name: "pushup",
  display: "Push Up",
  icon: () => PushupIcon,
  isActive: true,
  lookup: "pushups",
};

const CleanoutFilterConfiguration = {
  name: "cleanout",
  display: "Cleanout",
  icon: () => CleanoutIcon,
  isActive: true,
  lookup: "cleanouts",
};

const MilkingFilterConfiguration = {
  name: "milking",
  display: "Milking",
  icon: () => MilkingIcon,
  isActive: true,
  lookup: "milking",
};

const TIME_ITEM_MOBILE_WIDTH = "32px";
const TIME_ITEM_DESKTOP_WIDTH = "59px";

const EventDay = ({ date, deemphasize, timezone }) => {
  return (
    <div className={classes.eventDayWrapper} data-cy="event-day">
      <div
        className={classes.text}
        style={{
          color: deemphasize ? "#cccccc" : "rgba(0, 0, 0, 0.87)",
        }}
      >
        {dayjs(date).tz(timezone).format("ddd D")}
      </div>
    </div>
  );
};

const ICON_MAP = {
  deliveries: (props) => <DeliveryIcon {...props} />,
  cleanouts: (props) => <CleanoutIcon {...props} />,
  pushups: (props) => <PushupIcon {...props} />,
};

const filterAggregations = (aggregations, filters) => {
  return aggregations.map((agg) => {
    const filteredAgg = JSON.parse(JSON.stringify(agg));
    filters.forEach((filter) => {
      if (!filteredAgg.events.actual[filter.lookup] || !filter.isActive)
        filteredAgg.events.actual[filter.lookup] = [];
      if (!agg.events.expected[filter.lookup] || !filter.isActive)
        filteredAgg.events.expected[filter.lookup] = [];
    });
    return filteredAgg;
  });
};

const formatFeedStatusDataWindows = (data, t) => {
  const windowsData = [...data.windows].reverse();
  const days = windowsData.map(({ minutelyWindows, summary, range }) => {
    const date = dayjs(range.start).format("MMM D");
    return {
      key: range.start,
      date: date,
      accessTime: `${Math.floor(summary.minutesNotOK / 60)}h ${(
        summary.minutesNotOK % 60
      )
        .toString()
        .padStart(2, "0")}m`,
      windows: minutelyWindows.map((rawWindow) => {
        return {
          color: STATUS_COLOR_MAP("FEED_EVENTS")[rawWindow.status.code],
          width: rawWindow.endTimeMinutes - rawWindow.startTimeMinutes,
          duration: minutesToHours(
            rawWindow.endTimeMinutes - rawWindow.startTimeMinutes,
            hoursMinFormatter
          ),
          startTime: minutesToHours(
            rawWindow.startTimeMinutes,
            twelveHoursFormatter
          ),
          endTime: minutesToHours(
            rawWindow.endTimeMinutes,
            twelveHoursFormatter
          ),
          status: t(STATUS_TEXT_MAP[rawWindow.status.code]),
          date,
        };
      }),
    };
  });
  return days;
};

const FeedEvents = ({ id, history }) => {
  const { t } = useTranslation();
  const { isExpanded } = useExpandedState();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const initialFilter = params.get("include") || "all";

  const { info } = useAccountDetails();
  const { flags, features } = useActiveModules();

  const displayPushups = features.includes("pushups");

  let eventConfiguration = [DeliveryFilterConfiguration];
  if (displayPushups) {
    eventConfiguration = [...eventConfiguration, PushupFilterConfiguration];
  }
  eventConfiguration = [
    ...eventConfiguration,
    CleanoutFilterConfiguration,
    MilkingFilterConfiguration,
  ];

  let startingConfiguration = eventConfiguration;
  if (initialFilter !== "all") {
    startingConfiguration = eventConfiguration.map((config) => {
      // Turn off filters except the initial
      if (config.name !== initialFilter) {
        return { ...config, isActive: false };
      }
      return config;
    });
  }

  const [filters, setFilters] = useState(startingConfiguration);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredAggregations, setFilteredAggregations] = useState([]);
  const initialRange = 7;
  const [dates, setDates] = useDatesforPen(
    info,
    id,
    initialRange
  );

  const queryStartDate = dayjs(dates.start).format("YYYY-MM-DD");
  const queryEndDate = dayjs(dates.end).format("YYYY-MM-DD");

  const setRows = (index) => {
    if (index === selectedRow) setSelectedRow(null);
    else setSelectedRow(index);
  };

  const { data } = useQuery(
    [
      "eventsWithFeedStatus",
      {
        id,
        startDate: queryStartDate,
        endDate: queryEndDate,
        showSectionBreakdown: false,
      },
    ],
    getFeedStatusWindowsAndEvents
  );

  const onCheckboxClicked = (name) => {
    const updated = filters.map((filter) => {
      return filter.name === name
        ? { ...filter, isActive: !filter.isActive }
        : filter;
    });
    setFilters(updated);
  };

  useEffect(() => {
    if (!data?.length) {
      setFilteredAggregations([]);
      return;
    }
    let [eventsData] = data;
    let activeFilters = filters;
    if (!displayPushups) {
      // always exclude pushups if disabled
      activeFilters = [
        ...activeFilters,
        { ...PushupFilterConfiguration, isActive: false },
      ];
    }
    let filteredAgg = filterAggregations(
      [...eventsData.aggregations].reverse(),
      activeFilters
    );
    setFilteredAggregations(filteredAgg);
  }, [data, filters, displayPushups]);

  const [, feedStatusResults] = data;

  let feedStatusData = formatFeedStatusDataWindows(feedStatusResults, t);
  feedStatusData = [
    ...feedStatusData,
    ...feedStatusData,
    ...feedStatusData,
    feedStatusData[0],
  ];

  return (
    <>
      <EventsToolbar
        filters={filters}
        onCheckboxClicked={onCheckboxClicked}
        history={history}
        penId={id}
        dates={dates}
        setDates={setDates}
      />
      <>
        <Typography
          data-cy="Feed Events Title"
          variant="h5"
          style={{
            display: "none",
            "@media print": {
              display: "inline-flex",
              marginRight: "auto",
            },
          }}
        >
          {t("feed-events")}
        </Typography>
        <Card isExpanded={isExpanded} className={classes.card}>
          <PrintButton />
          <div className={classes.wrapper}>
            <div className={classes.container}>
              <div className={classes.timesContainer}>
                <div className={classes.timesInner}>
                  {times.map((time, index) => (
                    <div
                      className={classes.timeItem}
                      key={`FeedStatus-${time}-${index}`}
                    >
                      <div className={classes.timeContainer}>
                        <div>{time}</div>
                        {index < 12 ? <span>AM</span> : <span>PM</span>}
                      </div>
                      <div className={classes.timeShort} />
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.contentContainer}>
                <div
                  id="content-background"
                  className={classes.contentBackground}
                >
                  {times.map((time, index) => (
                    <div
                      className={classes.timeDelimitter}
                      key={`FeedStatus-delimitter-${time}-${index}`}
                    />
                  ))}
                </div>
                <div className={classes.days}>
                  {filteredAggregations.map((day, index) => (
                    <EventDay
                      key={day.range.start}
                      deemphasize={
                        selectedRow !== null && selectedRow !== index ? 1 : 0
                      }
                      timezone={info.farm.timezone}
                      date={day.range.start}
                    />
                  ))}
                </div>
                <div className={classes.events}>
                  {filteredAggregations.map((day, index) => {
                    const deemphasizeRow =
                      selectedRow !== null && selectedRow !== index ? 1 : 0;
                    return (
                      <React.Fragment key={index}>
                        <EventsRow
                          hook={`events-table-row-${index}`}
                          selected={selectedRow === index}
                          deemphasize={deemphasizeRow}
                          onClick={() => setRows(index)}
                          icons={ICON_MAP}
                          {...day}
                          key={`EventRow-${index}`}
                        />
                        <div className={classes.feedStatusContainer}>
                          <FeedStatusRow
                            key={index}
                            windows={feedStatusData[index].windows.map(
                              (window) => ({
                                ...window,
                                color: deemphasizeRow
                                  ? "#cccccc"
                                  : window.color,
                              })
                            )}
                            rowWidth={1440}
                            barHeight="8px"
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                  <div className={classes.scrollPadding}></div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </>
    </>
  );
};

export default FeedEvents;
