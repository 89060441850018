import { colors } from "../../CommonComponents/UI/theme";

const kpiInfo = {
  feedbunk: {
    color: "#186490",
    label: "Feedbunk Time",
    translationKey: "feedbunk-time",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
  lying: {
    color: "#fcc334",
    label: "Lying Time",
    translationKey: "lying-time",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
  standing: {
    color: "#00bfff",
    label: "Standing Time",
    translationKey: "standing-time",
    up: colors.valenciaRed,
    down: colors.silverTreeGreen,
  },
  emptyPen: {
    color: "#b792fb",
    label: "Out of Pen",
    translationKey: "out-of-pen",
    up: colors.valenciaRed,
    down: colors.silverTreeGreen,
  },
  uncategorized: {
    color: "#cccccc",
    label: "Unknown",
    translationKey: "uncategorized",
    up: colors.valenciaRed,
    down: colors.silverTreeGreen,
  },
};

export default kpiInfo;
