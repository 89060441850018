import axios from "axios";

export async function getHerdWelfareTrends({ queryKey }) {
  const [, params] = queryKey;
  const response = await axios.get(
    `${window._env_.API_URL}/private/herd-welfare/trends`,
    {
      params,
    }
  );
  return response.data;
}

export async function getOpsTrends({ queryKey }) {
  const [, params] = queryKey;
  const response = await axios.get(
    `${window._env_.API_URL}/private/people-and-protocols/trends`,
    {
      params,
    }
  );
  return response.data;
}
