import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useFoSmartInsightsContext } from "../utils/FoSmartInsightsContext";
import { useFeedingOpsContext } from "../utils/FeedingOpsContext";
import OpsSmartInsights from "../../../../CommonComponents/UI/Operations/OpsSmartInsights";
import { insightsCssMixIn } from "../../../../CommonComponents/utils/Operations/insightsCssMixin";
import { colors } from "../../../../CommonComponents/UI/theme";

const PREFIX = "FoSmartInsights";

const classes = {
  print: `${PREFIX}-print`,
};

const Insight = styled("div")({
  ...insightsCssMixIn,
});

const StyledButton = styled("button")({
  color: colors.green,
  padding: 0,
  fontSize: 16,
  font: "inherit",
  background: "none",
  border: "none",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
    backgroundColor: "transparent",
  },
});

const StyledOpsSmartInsights = styled(OpsSmartInsights)({
  "@media print": {
    display: "none",
  },

  [`&.${classes.print}`]: {
    "@media print": {
      display: "block",
    },
  },
});

const FoSmartInsights = ({ dataCyPrefix, shouldPrint }) => {
  const insights = useFoSmartInsightsContext();
  const { t } = useTranslation();

  const { selectPenId } = useFeedingOpsContext();

  insights.forEach((insight) => {
    insight.template = (
      <Insight>
        <span>
          <StyledButton
            variant="text"
            title={insight.penName}
            onClick={() => selectPenId({ selectedPenId: insight.penId })}
            data-thirdparty="smart-insights-pen-name"
          >
            {insight.penName}
          </StyledButton>
          &nbsp;
          {t("" + insight.name + "", {
            penId: insight.penId,
            penName: insight.penName,
            milkingTime: insight.milkingTime,
            onTime: t(insight.ontime),
            period: insight.period,
            periodReference: insight.periodReference,
            periodUnits: t(insight.periodUnits),
            name: insight.name,
            type: insight.type,
            value: insight.value,
            valueUnits: t(insight.valueUnits),
            referenceValue: insight.referenceValue,
            day: t(insight.day.toLowerCase()),
            interpolation: { escapeValue: false },
          })}
        </span>
      </Insight>
    );
  });

  return (
    <StyledOpsSmartInsights
      className={classNames({
        [classes.print]: shouldPrint,
      })}
      insights={insights}
      dataCyPrefix={dataCyPrefix}
    />
  );
};

FoSmartInsights.propTypes = {
  shouldPrint: PropTypes.bool,
  dataCyPrefix: PropTypes.string.isRequired,
};

FoSmartInsights.defaultProps = {
  shouldPrint: false,
};

export default FoSmartInsights;
