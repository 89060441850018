import React from "react";
import TimeInput from "../../../CommonComponents/UI/TimeInput";
import PercentageInput from "../../../CommonComponents/UI/PercentageInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const validateMinutes = (minutes) => minutes >= 0 && minutes <= 1440;
const validatePercentage = (percentage) => percentage >= 0 && percentage <= 100;
const validateNumber = (number) => number >= 0 && number <= 99;

const IntegerInput = (props) => {
  const { onChange: onChangeHandler, ...rest } = props;
  const onChange = (event) =>
    onChangeHandler({
      target: {
        name: event.target.name,
        value: event.target.value,
      },
    });
  return (
    <TextField
      {...rest}
      variant="standard"
      onChange={onChange}
      type="number"
      pattern="\d*"
      min={0}
      max={99}
    />
  );
};

const InputFactory = (props) => {
  const {
    type,
    onChange: onChangeHandler,
    onValidation: onValidationHandler,
    showError,
    ...rest
  } = props;
  let Component = null;
  let validator = () => true;
  let errorMessage = null;

  switch (props.type) {
    case "feed_availability_target":
    case "feed_availability_post_milking":
    case "lying_in_bed_target":
    case "out_of_pen_target":
    case "standing_other_target":
    case "standing_feedbunk_target":
      Component = TimeInput;
      validator = validateMinutes;
      errorMessage = "0 - 24, 0 - 59m";
      break;
    case "cow_comfort_index_target":
    case "push_up_completed_target":
      Component = PercentageInput;
      validator = validatePercentage;
      errorMessage = "0% - 100% only";
      break;
    case "late_feed_deliveries_target":
      Component = IntegerInput;
      validator = validateNumber;
      errorMessage = "0 - 99 only";
      break;
    default:
      Component = () => <div>n/a</div>;
      break;
  }

  const onChange = React.useCallback(
    (event) => {
      const { name, value } = event.target;
      const isValid = validator(value);
      onChangeHandler({ name, value, isValid });
    },
    [onChangeHandler]
  );

  if (!props.enabled) {
    return <Typography variant="subtitle1">n/a</Typography>;
  }

  return (
    <Component
      {...rest}
      error={showError}
      helperText={showError ? errorMessage : null}
      onChange={onChange}
    />
  );
};

export default React.memo(InputFactory);
