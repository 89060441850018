import React from "react";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

const PREFIX = "Breadcrumb";

const classes = {
  container: `${PREFIX}-container`,
  linkItem: `${PREFIX}-linkItem`,
  breadcrumbItem: `${PREFIX}-breadcrumbItem`,
  seperator: `${PREFIX}-seperator`,
};

const Root = styled("div")(() => ({
  [`&.${classes.container}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.linkItem}`]: {
    textDecoration: "none",
    color: "inherit",
    cursor: "inherit",
  },

  [`& .${classes.breadcrumbItem}`]: {
    fontSize: 24,
    color: "#888888",
    cursor: "pointer",
    whiteSpace: "nowrap",
    listStyle: "none",
    "&:nth-child(2n)": {
      cursor: "default",
      margin: "0 4px",
      verticalAlign: "middle",
    },

    "&:last-child": {
      cursor: "default",
      color: "#595959",
    },
    "@media print": {
      display: "none",
      "&:last-child": {
        display: "block",
        fontSize: 28,
      },
    },
  },

  [`& .${classes.seperator}`]: {
    verticalAlign: "middle",
    display: "inline",
  },
}));

const Breadcrumb = (props) => {
  const last = props.routes.length - 1;
  return (
    <Root
      className={classes.container}
      data-cy="app-page-breadcrumb"
      data-thirdparty="app-page-breadcrumb"
    >
      {props.routes.map((route, index) => {
        if (index === last)
          return (
            <li className={classes.breadcrumbItem} key={route.name}>
              <span>{route.name}</span>
            </li>
          );
        return (
          <React.Fragment key={route.name}>
            <li className={classes.breadcrumbItem} key={route.name}>
              <Link className={classes.linkItem} to={route.to}>
                {route.name}
              </Link>
            </li>
            <li
              className={classes.breadcrumbItem}
              key={`chevron-right-${index}`}
            >
              <ChevronRightIcon className={classes.seperator} />
            </li>
          </React.Fragment>
        );
      })}
    </Root>
  );
};

export default Breadcrumb;
