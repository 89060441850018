import React from "react";
import FoPenDetailsOverview from "./FoPenDetailsOverview";
import FoSmartInsightsProvider from "../utils/FoSmartInsightsProvider";
import FoSmartInsights from "./FoSmartInsights";
import StyledInsightsOverview, {
  classes,
} from "../../../../CommonComponents/UI/Operations/StyledInsightsOverview";

const FoInsightsOverview = () => {
  return (
    <StyledInsightsOverview
      data-cy="Feeding Ops Insights Overview Wrapper"
      className={classes.wrapper}
    >
      <FoSmartInsightsProvider>
        <FoSmartInsights dataCyPrefix="Feeding Ops Insights Overview OpsSmartInsights Card"></FoSmartInsights>
      </FoSmartInsightsProvider>
      <FoPenDetailsOverview />
    </StyledInsightsOverview>
  );
};

export default FoInsightsOverview;
