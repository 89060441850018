const setProvider = (UseQueryFn, last7Days, last30Days) => {
  const {
    overview,
    groups,
    selectedGroup,
    selectGroup,
    selectedPen,
    selectedDateRange,
    selectedPrintSection,
    selectDateRange,
    selectPrintSection,
    selectPenId,
  } = UseQueryFn({
    last7Days,
    last30Days,
  });

  const providerState = {
    overview,
    groups,
    selectedGroup,
    selectGroup,
    selectedPen,
    selectedDateRange,
    selectedPrintSection,
    selectDateRange,
    selectPrintSection,
    selectPenId,
  };

  return providerState;
};

export default setProvider;
