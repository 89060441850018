import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Toast from "../../../CommonComponents/UI/Toast";
import TimeField, { isValidTime } from "./TimeField";
import TimeFieldDisplay from "./TimeFieldDisplay";
import Button from "./Button";
import Schedule from "@mui/icons-material/Schedule";
import { ERROR_ALREADY_ADDED, ERROR_INVALID_TIME } from "./errorMessages";

const PREFIX = "ScheduleSettingsView";

const classes = {
  SSVPenIcon: `${PREFIX}-SSVPenIcon`,
  SSVScheduleTitle: `${PREFIX}-SSVScheduleTitle`,
  SSVScheduleTitleText: `${PREFIX}-SSVScheduleTitleText`,
  SSVScheduleInput: `${PREFIX}-SSVScheduleInput`,
  warningText: `${PREFIX}-warningText`,
  SSVScheduleInputInner: `${PREFIX}-SSVScheduleInputInner`,
  SSVScheduleTimeFieldInput: `${PREFIX}-SSVScheduleTimeFieldInput`,
  SSVScheduleDisplay: `${PREFIX}-SSVScheduleDisplay`,
  SSVScheduleTimeDualFieldDisplayText: `${PREFIX}-SSVScheduleTimeDualFieldDisplayText`,
  SSVScheduleTimeDualFieldDisplay: `${PREFIX}-SSVScheduleTimeDualFieldDisplay`,
  SSVScheduleTimeFieldDisplay: `${PREFIX}-SSVScheduleTimeFieldDisplay`,
};

const Root = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "left",
})(({ left }) => ({
  [`& .${classes.SSVPenIcon}`]: {
    display: "flex",
    height: 38,
    width: 38,
    fontSize: 38,
  },

  [`& .${classes.SSVScheduleTitle}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 24,
  },

  [`& .${classes.SSVScheduleTitleText}`]: {
    fontSize: 20,
    marginLeft: 12,
    color: "#595959",
  },

  [`& .${classes.SSVScheduleInput}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    marginBottom: 40,
  },

  [`& .${classes.warningText}`]: {
    color: "#ff5d5a",
    fontSize: 16,
  },

  [`& .${classes.SSVScheduleInputInner}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: `${({ direction = "row" }) => direction}`,
  },

  [`& .${classes.SSVScheduleTimeFieldInput}`]: {
    marginRight: 36,
    minWidth: 120,
  },

  [`& .${classes.SSVScheduleDisplay}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  [`& .${classes.SSVScheduleTimeDualFieldDisplayText}`]: {
    width: 16,
    margin: "0 16px",
    fontSize: 16,
    color: "#595959",
  },

  [`& .${classes.SSVScheduleTimeDualFieldDisplay}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 36,
    marginBottom: 16,
    width: "calc(50% - 36px)",
  },

  [`& .${classes.SSVScheduleTimeFieldDisplay}`]: {
    /* Need to account for the text between, and also the gap that is lost with no cancel button on the left item */
    flex: `1 1 calc(50% - ${left ? "10rem" : "7rem"})`,
  },
}));

const ScheduleIcon = styled(() => (
  <Schedule htmlColor={"#999999"} fontSize="inherit" />
))({});

const addTime = (times, newTimes) => {
  const found = times.find(
    (time) => time.start === newTimes.start && time.end === newTimes.end
  );
  if (found) return times;
  return [...times, newTimes];
};

const DualTimeFieldDisplay = ({ startTime, endTime, onClick }) => {
  return (
    <div className={classes.SSVScheduleTimeDualFieldDisplay}>
      <div className={classes.SSVScheduleTimeFieldDisplay}>
        <TimeFieldDisplay time={startTime} showCancelCTA={false} />
      </div>
      <div className={classes.SSVScheduleTimeDualFieldDisplayText}>to</div>
      <div className={classes.SSVScheduleTimeFieldDisplay}>
        <TimeFieldDisplay time={endTime} onClick={onClick} />
      </div>
    </div>
  );
};

const ScheduleSettingsView = ({ initialTimes, onChange }) => {
  const [times, setTimes] = useState({ start: "0:00 am", end: "0:00 am" });
  const { t } = useTranslation();

  let initialTimesCopy = initialTimes;
  if (initialTimesCopy.length > 4) {
    initialTimesCopy = [...initialTimesCopy].slice(0, 4);
  }

  const [savedTimes, setSavedTimes] = useState(initialTimesCopy);
  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  const [errorState, setErrorState] = useState({
    hasError: false,
    errorMessage: "",
  });

  useEffect(() => {
    setSavedTimes(initialTimesCopy);
  }, [initialTimesCopy]);

  const createError = (message) =>
    setErrorState({ hasError: true, errorMessage: message });

  const removeError = () =>
    setErrorState({ hasError: false, errorMessage: "" });

  const onSaveClick = () => {
    removeError();
    const { start, end } = times;
    if (!start || !isValidTime(start) || !end || !isValidTime(end)) {
      createError(ERROR_INVALID_TIME);
      return;
    }
    if (savedTimes.find((time) => time.start === start && time.end === end)) {
      createError(ERROR_ALREADY_ADDED);
      return;
    }
    const updated = addTime(savedTimes, times);
    setSavedTimes(updated);
    setTimes({ start: "0:00 am", end: "0:00 am" });
    onChange({ updatedTimes: updated });

    setToast({
      show: true,
      type: "success",
      message: t("successfully-added"),
    });
  };

  const disableEntry = savedTimes.length === 4;

  return (
    <Root>
      <div className={classes.SSVScheduleTitle} data-cy="milking-schedule">
        <div className={classes.SSVPenIcon}>
          <ScheduleIcon />
        </div>
        <span className={classes.SSVScheduleTitleText}>
          {t("scheduled-times")}
        </span>
      </div>
      <div className={classes.SSVScheduleInput}>
        <div className={classes.SSVScheduleInputInner} direction="column">
          <div className={classes.SSVScheduleInputInner}>
            <div className={classes.SSVScheduleTimeFieldInput}>
              <TimeField
                error={errorState.hasError}
                disabled={disableEntry}
                value={times.start}
                onChange={(event, value) =>
                  setTimes({ ...times, start: value })
                }
              />
            </div>
            <div className={classes.SSVScheduleTimeFieldInput}>
              <TimeField
                error={errorState.hasError}
                disabled={disableEntry}
                value={times.end}
                onChange={(event, value) => setTimes({ ...times, end: value })}
              />
            </div>
          </div>
          {errorState.hasError ? (
            <span className={classes.warningText}>
              {errorState.errorMessage}
            </span>
          ) : (
            ""
          )}
        </div>
        <Button
          data-thirdparty={`schedule-settings-button-milking`}
          data-cy={`schedule-settings-button-milking`}
          disabled={disableEntry}
          onClick={onSaveClick}
        >
          {t("add-time")}
        </Button>
      </div>
      <div className={classes.SSVScheduleDisplay}>
        {savedTimes.map(({ id, start, end }, index) => {
          return (
            <DualTimeFieldDisplay
              key={`StartEnd-${start}-${end}`}
              tag={`schedule-settings-button-remove-milking-${index}`}
              startTime={start}
              endTime={end}
              onClick={() => {
                const updated = savedTimes.filter(
                  (time) => time && (time.start !== start || time.end !== end)
                );
                setSavedTimes(updated);
                onChange({ updatedTimes: updated });
              }}
            />
          );
        })}
        {Array.from(Array(4 - savedTimes.length)).map((_, index) => (
          <DualTimeFieldDisplay key={index} />
        ))}
      </div>
      <Toast toast={toast} closeToast={closeToast} />
    </Root>
  );
};

export default ScheduleSettingsView;
