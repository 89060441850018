import React from "react";
import MoSmartInsightsProvider from "../utils/MoSmartInsightsProvider";
import MoSmartInsights from "./MoSmartInsights";
import StyledInsightsOverview, {
  classes,
} from "../../../../CommonComponents/UI/Operations/StyledInsightsOverview";
import MoPenDetailsOverview from "./MoPenDetailsOverview";

const MoInsightsOverview = () => {
  return (
    <StyledInsightsOverview
      data-cy="Milking Ops Insights Overview Wrapper"
      className={classes.wrapper}
    >
      <MoSmartInsightsProvider>
        <MoSmartInsights dataCyPrefix="Insights Overview OpsSmartInsights Card" />
      </MoSmartInsightsProvider>
      <MoPenDetailsOverview />
    </StyledInsightsOverview>
  );
};

export default MoInsightsOverview;
