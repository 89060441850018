import React from "react";
import PropTypes from "prop-types";

const IContextProps = {
  state: PropTypes.StateType,
  dispatch: PropTypes.Dispatch,
};

const FoSmartInsightsContext = React.createContext(IContextProps);

export function useFoSmartInsightsContext() {
  return React.useContext(FoSmartInsightsContext);
}

export default FoSmartInsightsContext;
