import React from "react";
import { styled } from "@mui/material/styles";
import { flexColumnMixIn } from "../../utils/Operations/cssMixins";
import { xxlPadding } from "../../utils/Operations/css.const";
import { colors } from "../../UI/theme";

const PREFIX = "FoPenDetails";

const classes = {
  container: `${PREFIX}-container`,
  tabContentWrapper: `${PREFIX}-tabContentWrapper`,
  tabContainer: `${PREFIX}-tabContainer`,
};

const Root = styled("div")(({ theme }) => ({
  ...flexColumnMixIn,
  alignItems: "center",
  width: "100%",

  [`& .${classes.tabContentWrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "flex-start",
    background: colors.whiteSmoke,
    width: "100%",
    padding: "15px",
    [theme.breakpoints.up("xxl")]: {
      padding: `${xxlPadding}`,
    },
  },

  [`& .${classes.tabContainer}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "center",
    background: colors.white,
    width: "100%",
    padding: "10px 0 0 0",
    borderRadius: "4px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.33)",
    paddingRight: "5px",
  },
}));

const OpsTabbedContent = ({ charts }) => {
  return (
    <Root>
      {charts.map((chart) => (
        <div key={chart.props.name} className={classes.tabContentWrapper}>
          <div className={classes.tabContainer}>{chart}</div>
        </div>
      ))}
    </Root>
  );
};

export default OpsTabbedContent;
