import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { performanceCssMixIn } from "../../../../CommonComponents/utils/Operations/performanceCssMixins";

const useStyles = makeStyles(() => ({
  ...performanceCssMixIn,
}));

const MoPerformance = ({ summary, averageLabel }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { avgValue, changeVsPrevious, worstDay, worstMilking } = summary;

  return (
    <div className={classes.performance} data-cy="MO Performance">
      <div className={classes.titleRow}>
        <div className={classes.title} data-cy="MO Performance Heading">
          {t("performance-for-last-30-days")}
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.column}>
          <div
            className={classes.subTitle}
            data-cy="MO Performance Avg Subheading"
          >
            {t(averageLabel)}
          </div>
          <div className={classes.text} data-cy="MO Performance Avg Text">
            {avgValue}
          </div>
        </div>

        <div className={classes.column}>
          <div
            className={classes.subTitle}
            data-cy="MO Performance ChangeVsPrev Subheading"
          >
            {t("change-vs-previous-30-days")}
          </div>
          <div
            className={classes.text}
            data-cy="MO Performance ChangeVsPrev Text"
          >
            {changeVsPrevious}
          </div>
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.column}>
          <div
            className={classes.subTitle}
            data-cy="MO Performance WorstDay Subheading"
          >
            {t("worst-day-on-average")}
          </div>
          <div className={classes.text} data-cy="MO Performance WorstDay Text">
            {worstDay}
          </div>
        </div>

        <div className={classes.column}>
          <div
            className={classes.subTitle}
            data-cy="MO Performance WorstMilking Subheading"
          >
            {t("worst-milking-on-average")}
          </div>
          <div
            className={classes.text}
            data-cy="MO Performance WorstMilking Text"
          >
            {worstMilking}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoPerformance;
