import React from "react";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import classNames from "classnames";
import { useExpandedState } from "../../utils/useExpandedState";
import { useActiveModules } from "../../../App/useActiveModules";
import PenInfo from "./PenInfo";
import ExpandArrow from "../ExpandArrow";
import { colors } from "../theme";

const PREFIX = "Header";

const classes = {
  appbarRoot: `${PREFIX}-appbarRoot`,
  toolbar: `${PREFIX}-toolbar`,
  placeholder: `${PREFIX}-placeholder`,
  toolbarInner: `${PREFIX}-toolbarInner`,
  titleSection: `${PREFIX}-titleSection`,
  pageTitle: `${PREFIX}-pageTitle`,
  chip: `${PREFIX}-chip`,
  renderTools: `${PREFIX}-renderTools`,
  arrow: `${PREFIX}-arrow`,
  penInfoMobile: `${PREFIX}-penInfoMobile`,
  penInfoDesktop: `${PREFIX}-penInfoDesktop`,
  expanded: `${PREFIX}-expanded`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.appbarRoot}`]: {
    zIndex: 5,
    height: theme.spacing(8),
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 256,
      width: `calc(100% - 256px)`,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 296,
      width: `calc(100% - 296px)`,
    },
    "@media print": {
      boxShadow: "none",
      height: theme.spacing(8),
      minHeight: theme.spacing(8),
    },
  },

  [`& .${classes.toolbar}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    marginLeft: 15,

    paddingLeft: 0,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 0,
    },
    "@media print": {
      padding: 0,
      paddingTop: 0,
      margin: 0,
      height: 40,
      minHeight: 40,
    },
  },

  [`& .${classes.placeholder}`]: {
    height: 65,
    minHeight: 15,
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.expanded}`]: {
    height: theme.spacing(25.5),
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(12),
    },
    "@media print": {
      height: theme.spacing(5),
    },
  },

  [`& .${classes.toolbarInner}`]: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(5),
    width: "100%",
    height: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    "@media print": {
      padding: 0,
      height: "auto",
    },
  },

  [`& .${classes.titleSection}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },

  [`& .${classes.pageTitle}`]: {
    marginRight: theme.spacing(2),
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    maxWidth: 256,
    [theme.breakpoints.up("md")]: {
      alignSelf: "flex-start",
      marginTop: 9,
    },
    [theme.breakpoints.up("lg")]: {
      alignSelf: "center",
      marginTop: 0,
    },
  },

  [`& .${classes.chip}`]: {
    backgroundColor: colors.gray88,
    height: 20,
    alignSelf: "center",
  },

  [`& .${classes.renderTools}`]: {
    alignSelf: "center",
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.arrow}`]: {
    backgroundColor: colors.selectedGreen,
    borderRadius: "50%",
    width: 24,
    height: 24,
    minWidth: 24,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },
  [`& .${classes.penInfoMobile}`]: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },
  [`& .${classes.penInfoDesktop}`]: {
    display: "none",
    borderLeft: `1px solid ${colors.mercuryGray}`,
    paddingLeft: 26,
    marginLeft: 8,
    [theme.breakpoints.up("md")]: {
      display: "inline-flex",
      marginTop: theme.spacing(1.5),
    },
    "@media print": {
      display: "inline-flex",
    },
  },
}));

const usePenInfoStyles = makeStyles((theme) => ({
  penField: {
    "&:last-of-type": {
      marginRight: 0,
    },
    flexBasis: "33%",
    marginBottom: theme.spacing(2),
    "&:first-of-type": {
      flexBasis: (props) => `${props.isLongName ? "66%" : "33%"}`,
      overflow: "hidden",
      [theme.breakpoints.up("md")]: {
        // If the ternary is removed, the earlier flexBasis declaration takes precedence over the media query
        flexBasis: (props) => `${props.isLongName ? "auto" : "auto"}`,
      },
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 16,
      flexBasis: "auto",
    },
    "@media print": {
      flexBasis: "auto !important",
      marginRight: 16,
    },
  },
}));

const HeaderLargeSingleTabLevel = ({
  children,
  title,
  renderHeaderTools,
  pen,
}) => {
  const useRender = typeof renderHeaderTools === "function";
  const { flags } = useActiveModules();
  const { isExpanded, setIsExpanded } = useExpandedState();
  const toggleExpanded = () => setIsExpanded((isExpanded) => !isExpanded);

  return (
    <Root>
      <AppBar
        position="fixed"
        color="secondary"
        elevation={1}
        className={classNames(classes.appbarRoot, {
          [classes.expanded]: isExpanded,
        })}
      >
        <Toolbar
          className={classNames(classes.toolbar, {
            [classes.expanded]: isExpanded,
          })}
        >
          <div className={classes.toolbarInner}>
            <div className={classes.titleSection}>
              <Typography className={classes.pageTitle} variant="h5">
                {title}
              </Typography>
              {flags.cowCountStockingDensity ? (
                <>
                  <div className={classes.arrow} onClick={toggleExpanded}>
                    <ExpandArrow isActive={isExpanded} />
                  </div>
                  <div className={classes.penInfoDesktop}>
                    <PenInfo pen={pen} usePenInfoStyles={usePenInfoStyles} />
                  </div>
                </>
              ) : (
                <Chip label={pen.group} className={classes.chip} />
              )}
            </div>
            <div className={classes.renderTools}>
              {useRender ? renderHeaderTools() : null}
            </div>
          </div>
          {flags.cowCountStockingDensity && (
            <Collapse
              className={classes.penInfoMobile}
              in={isExpanded}
              data-cy="header-large-expandable-section"
            >
              <PenInfo pen={pen} usePenInfoStyles={usePenInfoStyles} />
            </Collapse>
          )}
          {children}
        </Toolbar>
      </AppBar>
      <div
        className={classNames(classes.toolbar, classes.placeholder, {
          [classes.expanded]: isExpanded,
        })}
      />
    </Root>
  );
};

export default HeaderLargeSingleTabLevel;

HeaderLargeSingleTabLevel.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string.isRequired,
  pen: PropTypes.object,
  renderHeaderTools: PropTypes.func,
};
