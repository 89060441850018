import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "../CommonComponents/UI/Header";
import useReports from "./useReports";
import { reportImageUrls } from "./reports.const";

const PREFIX = "Reports";

const classes = {
  content: `${PREFIX}-content`,
  root: `${PREFIX}-root`,
  titleCell: `${PREFIX}-titleCell`,
  descriptionCell: `${PREFIX}-descriptionCell`,
  reportsContent: `${PREFIX}-reportsContent`,
  reportContainer: `${PREFIX}-reportContainer`,
  reportImageCont: `${PREFIX}-reportImageCont`,
  reportImage: `${PREFIX}-reportImage`,
  reportDetailsCont: `${PREFIX}-reportDetailsCont`,
  reportTitle: `${PREFIX}-reportTitle`,
  reportDescrip: `${PREFIX}-reportDescrip`,
};

const Content = styled("div")(({ theme }) => ({
  [`&.${classes.content}`]: {
    padding: theme.spacing(1.5),
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    },
  },

  [`& .${classes.root}`]: {
    minWidth: 350,
    "& tbody tr:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      cursor: "pointer",
      userSelect: "none",
    },
  },

  [`& .${classes.reportsContent}`]: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.up("xs")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },

  [`& .${classes.reportContainer}`]: {
    display: "flex",
    width: "22%",
    height: 320,
    flexDirection: "column",
    boxShadow: "6px 6px 20px 0px rgba(50, 59, 66, 0.12)",
    cursor: "pointer",
    marginTop: 20,
    borderRadius: "0px 0px 2px 2px",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginRight: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: "45%",
    },
    [theme.breakpoints.up("md")]: {
      width: "22%",
      marginRight: 20,
    },
  },

  [`& .${classes.reportImageCont}`]: {
    display: "flex",
    width: "100%",
    height: 120,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  [`& .${classes.reportImage}`]: {
    display: "flex",
    width: "100%",
    height: "auto",
    flexDirection: "column",
  },

  [`& .${classes.reportDetailsCont}`]: {
    display: "flex",
    width: "100%",
    height: "auto",
    flexDirection: "column",
    padding: 10,
  },

  [`& .${classes.reportTitle}`]: {
    flexDirection: "column",
    width: "97%",
    fontSize: "18px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  [`& .${classes.reportDescrip}`]: {
    flexDirection: "column",
    width: "100%",
    fontSize: "12px",
  },
}));

const ReportsList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { reports } = useReports();

  const selectReport = (id) => {
    return history.push(`/reports/${id}`);
  };

  return (
    <>
      <Header title={t("reports")} />
      <Content className={classes.content}>
        <div className={classes.reportsContent}>
          {reports.map((report) => {
            return (
              <div
                key={report.id}
                className={classes.reportContainer}
                onClick={() => selectReport(report.id)}
              >
                <div className={classes.reportImageCont}>
                  <img
                    className={classes.reportImage}
                    alt={report.title}
                    src={`/images/reports/${
                      reportImageUrls[report.title] || "analysis"
                    }.png`}
                  ></img>
                </div>
                <div className={classes.reportDetailsCont}>
                  <div data-cy="report-title" className={classes.reportTitle}>
                    {report.title}
                  </div>
                  <div className={classes.reportDescrip}>
                    {report.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Content>
    </>
  );
};

export default ReportsList;
