import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "../../../CommonComponents/UI/Switch";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import LoadingSpinner from "../../../CommonComponents/UI/LoadingSpinner";
import useAlertManagement from "../../utils/useAlertManagement";
import {
  minutesToHours,
  hoursMinFormatter,
} from "../../../CommonComponents/utils/time";
import { editAlert } from "../../services/alerts";

const PREFIX = "SetAlertsRow";

const classes = {
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  table: `${PREFIX}-table`,
  input: `${PREFIX}-input`,
  iconsWrapper: `${PREFIX}-iconsWrapper`,
  timeWrapper: `${PREFIX}-timeWrapper`,
  text: `${PREFIX}-text`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.title}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
  },

  [`& .${classes.table}`]: {
    margin: `0 ${theme.spacing(5)} ${theme.spacing(4)} ${theme.spacing(5)}px`,
    width: `calc(100% - ${theme.spacing(10)})`,
  },

  [`& .${classes.input}`]: {
    width: 80,
    marginRight: 8,
  },

  [`& .${classes.iconsWrapper}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 96,
    textAlign: "center",
    margin: "-16px 0",
  },

  [`& .${classes.timeWrapper}`]: {
    minWidth: 100,
    position: "relative",
  },

  [`& .${classes.text}`]: {
    position: "static",
    "& div.Mui-error": {
      marginBottom: 10,
    },
    "& p.Mui-error": {
      marginLeft: 0,
      position: "absolute",
      top: 40,
    },
  },
}));

const SetAlertsRow = ({ item, headers, setToast }) => {
  const { t } = useTranslation();

  const { id, threshold } = item;
  const label = t(id) || item.label;

  const [isEditMode, setIsEditMode] = useState(false);
  const queryClient = useQueryClient();

  const toggleEditMode = () => setIsEditMode((isEdit) => !isEdit);
  const {
    alertState,
    reset,
    setInputValue,
    setSwitchValue,
    validateNumber,
    convertToThreshold,
  } = useAlertManagement(item);

  const { enabled, hours, mins } = alertState;

  const { isLoading, mutate: updateAlert } = useMutation(editAlert, {
    onSuccess: (_, mutationParams) => {
      queryClient.setQueryData("alerts/set", (oldData) => {
        const indexToUpdate = oldData?.findIndex(
          ({ id }) => id === mutationParams.id
        );
        let newData = [...oldData];
        newData[indexToUpdate] = {
          ...oldData[indexToUpdate],
          ...mutationParams,
        };
        return newData;
      });
      toggleEditMode();
    },
    onError: () => {
      setToast({
        show: true,
        type: "error",
        message: "We had a problem updating the alert, try again",
      });
    },
  });

  const onUpdate = () => {
    const formattedThreshold = convertToThreshold();
    formattedThreshold && updateAlert(formattedThreshold);
  };

  const onCancel = () => {
    reset();
    toggleEditMode();
  };

  const renderSaveIcon = () => (
    <>
      {isLoading ? (
        <LoadingSpinner className={classes.loadingSpinner} size={30} />
      ) : (
        <IconButton
          onClick={onUpdate}
          aria-label="save"
          disabled={!!hours.error || !!mins.error}
          size="large"
        >
          <CheckIcon data-cy="save-icon" />
        </IconButton>
      )}
    </>
  );

  const row = {
    label,
    key: id,
    threshold: (
      <>
        {isEditMode ? (
          <div className={classes.timeWrapper}>
            <TextField
              name="hours"
              id="hours"
              label="Hours"
              variant="outlined"
              size="small"
              value={hours.value}
              onBlur={validateNumber}
              onChange={setInputValue}
              InputProps={{
                className: classes.input,
              }}
              inputProps={{
                "data-cy": "hours-field",
              }}
              className={classes.text}
              helperText={hours.error || mins.error}
              error={!!hours.error || !!mins.error}
            />
            <TextField
              name="mins"
              id="mins"
              label="Minutes"
              variant="outlined"
              size="small"
              value={mins.value}
              onBlur={validateNumber}
              onChange={setInputValue}
              InputProps={{
                className: classes.input,
              }}
              inputProps={{
                "data-cy": "minutes-field",
              }}
              error={!!mins.error}
            />
          </div>
        ) : (
          minutesToHours(threshold, hoursMinFormatter)
        )}
      </>
    ),
    enabled: (
      <>
        {isEditMode ? (
          <FormControlLabel
            labelPlacement="end"
            style={{ width: 86 }}
            control={
              <Switch
                checked={enabled}
                name="toggle-alert-enabled"
                label="Admin"
                onChange={setSwitchValue}
              />
            }
            label={enabled ? t("on") : t("off")}
          />
        ) : (
          <span>{enabled ? t("on") : t("off")}</span>
        )}
      </>
    ),
    edit: (
      <div className={classes.iconsWrapper}>
        {isEditMode ? (
          <>
            {renderSaveIcon()}
            <IconButton aria-label="cancel" onClick={onCancel} size="large">
              <CloseIcon data-cy="cancel-icon" />
            </IconButton>
          </>
        ) : (
          <IconButton
            aria-label="edit"
            onClick={toggleEditMode}
            className={classes.editIcon}
            size="large"
          >
            <CreateIcon />
          </IconButton>
        )}
      </div>
    ),
  };

  return (
    <StyledTableRow
      key={row.key}
      onClick={row.onClick}
      data-cy="set-alerts-row"
    >
      {headers.map((header) => (
        <TableCell className={header.cellClass} key={`${header.id}-${row.key}`}>
          {row[header.id]}
        </TableCell>
      ))}
    </StyledTableRow>
  );
};
export default SetAlertsRow;

SetAlertsRow.propTypes = {
  item: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  setToast: PropTypes.func.isRequired,
};
