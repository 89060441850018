import React from "react";
import MuiTooltip from "@mui/material/Tooltip";

const Tooltip = ({ children, content }) => {
  return (
    <MuiTooltip arrow title={content}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
