import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { useFeedingOpsContext } from "../utils/FeedingOpsContext";
import {
  flexCenterColumnMixIn,
  flexColumnMixIn,
  flexRowMixIn,
} from "../../../../CommonComponents/utils/Operations/cssMixins";
import { colors } from "../../../../CommonComponents/UI/theme";
import OpsBarChart from "../../../../CommonComponents/UI/Operations/OpsBarChart";
import FoPerformance from "./FoPerformance";

const PREFIX = "FoBarChart";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  titleRow: `${PREFIX}-titleRow`,
  title: `${PREFIX}-title`,
  subTitle: `${PREFIX}-subTitle`,
  subTitleRow: `${PREFIX}-subTitleRow`,
  chartArea: `${PREFIX}-chartArea`,
  printIcon: `${PREFIX}-printIcon`,
};

const Root = styled("div")(() => ({
  ...flexColumnMixIn,
  width: "100%",

  [`& .${classes.titleRow}`]: {
    ...flexRowMixIn,
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 10px",
    position: "relative",
    width: "100%",
  },

  [`& .${classes.subTitleRow}`]: {
    ...flexRowMixIn,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },

  [`& .${classes.subTitle}`]: {
    fontSize: "14px",
    color: colors.gray64,
  },

  [`& .${classes.title}`]: {
    fontSize: "16px",
    color: colors.black,
    height: "28px",
    fontWeight: "500",
  },

  [`& .${classes.chartArea}`]: {
    padding: "0 0 0 5px",
    ...flexCenterColumnMixIn,
    width: "100%",
  },

  [`& .${classes.printIcon}`]: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "rgba(0, 0, 0, 0.3)",
    "@media print": {
      display: "none",
    },
  },
}));

const kpis = {
  firstDeliveryDifference: {
    label: "deliveries",
    title: "diff-1st-delivery-from-schedule",
    averageLabel: "avg-percent-diff-from-schedule",
    unit: "m",
  },
  cleanBunkDuration: {
    label: "clean-bunk-time",
    title: "clean-bunk-time",
    subtitle: "time-between-cleanout-and-subsequent-delivery",
    averageLabel: "avg-clean-bunk-time",
    unit: "m",
  },
  completed: {
    label: "push-ups",
    title: "push-ups-completed",
    averageLabel: "avg-push-ups-completed",
    unit: "%",
  },
  onTime: {
    label: "push-ups",
    title: "percent-push-ups-on-time",
    averageLabel: "avg-push-ups-on-time",
    unit: "%",
  },
};

const FoBarChart = ({ data, name }) => {
  const { t } = useTranslation();
  const { selectPrintSection } = useFeedingOpsContext();
  const { label, title, subtitle, averageLabel, unit } = kpis[name];

  const onBarChartPrint = () => {
    selectPrintSection({
      printPensOverview: false,
      printInsightsOverview: true,
    });
    setTimeout(() => {
      window.print();
    });
  };

  return (
    <Root>
      <div className={classes.titleRow}>
        <div className={classes.title} data-cy={title}>
          {t(title)}
        </div>
        <IconButton
          aria-label="print chart"
          onClick={onBarChartPrint}
          edge="start"
          className={classes.printIcon}
          size="large"
        >
          <PrintIcon color="inherit" />
        </IconButton>
      </div>

      {subtitle && (
        <div className={classes.subTitleRow}>
          <div className={classes.subTitle}>{t(subtitle)}</div>
        </div>
      )}

      <div className={classes.chartArea} data-cy="fo-chart-area">
        {data.error ? (
          <p>{data.error}</p>
        ) : (
          <>
            <OpsBarChart
              barChartData={data.barChartData}
              valueUnit={unit}
              chartHeight="medium"
              label={t(label)}
            />
            <FoPerformance averageLabel={averageLabel} summary={data.summary} />
          </>
        )}
      </div>
    </Root>
  );
};

export default FoBarChart;
