import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  flexColumnMixIn,
  flexRowMixIn,
} from "../../utils/Operations/cssMixins";
import { colors } from "../theme";
import CanvasJSReact from "../../Vendor/canvasjs.react";
const PREFIX = "OpsBarChart";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  titleRow: `${PREFIX}-titleRow`,
  title: `${PREFIX}-title`,
  dates: `${PREFIX}-dates`,
  content: `${PREFIX}-content`,
  chart: `${PREFIX}-chart`,
  flexEnd: `${PREFIX}-flexEnd`,
  smallWidthChart: `${PREFIX}-smallWidthChart`,
  mediumWidthChart: `${PREFIX}-mediumWidthChart`,
  mediumHeightChart: `${PREFIX}-mediumHeightChart`,
  largeHeightChart: `${PREFIX}-largeHeightChart`,
  xlargeHeightChart: `${PREFIX}-xlargeHeightChart`,
  tooltipArea: `${PREFIX}-tooltipArea`,
  tooltipTitle: `${PREFIX}-tooltipTitle`,
  tooltipHr: `${PREFIX}-tooltipHr`,
  message: `${PREFIX}-message`,
  arrowUp: `${PREFIX}-arrowUp`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "center",
    background: colors.white,
    width: "100%",
    padding: "10px 0 0 0",
    position: "relative",
  },

  [`& .${classes.titleRow}`]: {
    ...flexRowMixIn,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },

  [`& .${classes.title}`]: {
    fontSize: "14px",
    color: colors.black,
    fontWeight: "500",
  },

  [`& .${classes.dates}`]: {
    fontSize: "6px",
    color: colors.black,
    marginLeft: "0",
    "@media print": {
      marginLeft: "-50px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "11px",
    },
  },

  [`& .${classes.content}`]: {
    ...flexRowMixIn,
    justifyContent: "space-between",
    width: "100%",
  },

  [`& .${classes.chart}`]: {
    ...flexColumnMixIn,
    justifyContent: "center",
    paddingBottom: "30px",
    minHeight: "120px",
    minWidth: "72px",
    width: "20%",
    "& .MuiIconButton-root": {
      display: "none",
    },
    "& .canvasjs-chart-tooltip": {
      width: "212px",
      position: "absolute",
      left: "-70px !important",
      bottom: "-254px !important",
      right: "auto !important",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "55px",
      width: "21%",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "72px",
      width: "20%",
    },
  },

  [`& .${classes.flexEnd}`]: {
    justifyContent: "flex-end",
    paddingRight: "15px",
    "@media print": {
      marginLeft: "-25px",
    },
  },

  [`& .${classes.firstChart}`]: {
    width: "24%",
    [theme.breakpoints.up("xs")]: {
      minWidth: "55px",
      width: "29%",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "72px",
      width: "24%",
    },
  },
  [`& .${classes.smallWidthChart}`]: {
    minWidth: "53px",
    width: "16%",
    [theme.breakpoints.up("xs")]: {
      minWidth: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "53px",
    },
  },

  [`& .${classes.mediumWidthChart}`]: {
    minWidth: "63px",
    width: "17%",
    [theme.breakpoints.up("xs")]: {
      minWidth: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "63px",
    },
  },

  [`& .${classes.mediumHeightChart}`]: {
    minHeight: chartheights.medium.chart,
  },

  [`& .${classes.largeHeightChart}`]: {
    minHeight: chartheights.large.chart,
  },

  [`& .${classes.xlargeHeightChart}`]: {
    minHeight: chartheights.xlarge.chart,
  },

  [`& .${classes.tooltipArea}`]: {
    width: "202px",
    height: "147px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px",
  },

  [`& .${classes.tooltipTitle}`]: {
    fontSize: "16px",
  },

  [`& .${classes.tooltipHr}`]: {
    flex: "none",
    border: "none",
    height: "2px",
    width: "100%",
    backgroundColor: colors.tooltipHrBlue,
  },

  [`& .${classes.message}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.arrowUp}`]: {
    width: 0,
    height: 0,
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    borderBottom: `15px solid ${colors.atomic}`,
    position: "absolute",
    top: "-14px",
  },
}));

const { CanvasJSChart } = CanvasJSReact;

const chartheights = {
  small: {
    maxValue: 30,
    column: 90,
    chart: 120,
  },
  medium: {
    maxValue: 50,
    column: 120,
    chart: 155,
  },
  large: {
    maxValue: 90,
    column: 180,
    chart: 210,
  },
  xlarge: {
    maxValue: null,
    column: 210,
    chart: 240,
  },
};

const OpsBarChart = ({ chartTitle, barChartData, valueUnit, label }) => {
  const { t } = useTranslation();

  let maxYaxis;
  let maxYaxisArray = [];
  barChartData.map((chart, index) => {
    const maxValue = Math.max(...chart.dataPoints.map((point) => point.value));
    maxYaxis = maxYaxis && maxValue < maxYaxis ? maxYaxis : maxValue;
    maxYaxisArray.push(maxYaxis);
    if (index === 0) return (chart.first = true);
    if (index === barChartData.length - 1)
      return (maxYaxis = Math.max(...maxYaxisArray));
  });

  const calcBarColumnHeight = () => {
    switch (true) {
      case maxYaxis <= chartheights.small.maxValue:
        return chartheights.small.column;
      case maxYaxis > chartheights.small.maxValue &&
        maxYaxis <= chartheights.medium.maxValue:
        return chartheights.medium.column;
      case maxYaxis > chartheights.medium.maxValue &&
        maxYaxis <= chartheights.large.maxValue:
        return chartheights.large.column;
      case maxYaxis > chartheights.large.maxValue:
        return chartheights.xlarge.column;
      default:
        return chartheights.small.column;
    }
  };

  const columnHeight = calcBarColumnHeight();

  const getTooltipContent = (e) => {
    let eDataPoint = e?.entries[0]?.dataPoint;

    return (
      eDataPoint &&
      eDataPoint.date &&
      `
      <div class="${classes.tooltipArea}">
      <div class="${classes.arrowUp}"></div>
        <div class="${classes.tooltipTitle}">${label}</div>
        <hr class="${classes.tooltipHr}">
        <div class="${classes.message}">
          <div>${eDataPoint.message}</div>
          ${
            label === t("milking-duration") || label === t("difference")
              ? `<div class="${classes.tooltipTitle}">${eDataPoint.window}</div>`
              : ""
          }
          
        </div>
        
        <hr class="${classes.tooltipHr}">
        <div class="${classes.tooltipTitle}">${eDataPoint.date}</div>
      </div>
    `
    );
  };

  const displayData = (dataPoints) => {
    dataPoints.map((dataPoint) => {
      Object.assign(dataPoint, {
        y: dataPoint.value,
        label: dataPoint.day,
        color: dataPoint.target_met
          ? colors.chartColumnGreen
          : colors.chartColumnRed,
      });
      return dataPoint;
    });

    const chartData = [
      {
        type: "column",
        color: "red",
        dataPoints: dataPoints,
      },
    ];

    return chartData;
  };

  const labelYFormatter = (chart, value) => {
    return chart.dates === barChartData[0].dates ? value + valueUnit : " ";
  };

  return (
    <Root className={classes.wrapper}>
      <div className={classes.titleRow}>
        <div className={classes.title} data-cy={chartTitle}>
          {chartTitle}
        </div>
      </div>

      <div className={classes.content}>
        {barChartData.map((chart, index) => {
          return (
            chart.dataPoints && (
              <div
                key={index}
                className={classNames(classes.chart, {
                  [classes.smallWidthChart]: chart.dataPoints.length < 3,
                  [classes.mediumWidthChart]: chart.dataPoints.length === 3,
                  [classes.firstChart]: chart.first,
                  [classes.mediumHeightChart]: columnHeight === 120,
                  [classes.largeHeightChart]: columnHeight === 180,
                  [classes.xlargeHeightChart]: columnHeight === 210,
                })}
              >
                <CanvasJSChart
                  options={{
                    dataPointWidth: 5,
                    animationEnabled: true,
                    height: columnHeight,
                    axisX: {
                      lineColor: colors.dataMissing,
                      tickThickness: 0,
                      tickLength: 15,
                      labelFontSize: 8,
                      labelFontFamily: "Noto",
                      labelFontColor: colors.gray35,
                      margin: 10,
                    },
                    axisY: {
                      minimum: 0,
                      margin: chart.dates === barChartData[0].dates ? 0 : -11,
                      maximum: maxYaxis,
                      labelFormatter: (e) => labelYFormatter(chart, e.value),
                      gridColor: colors.dataMissing,
                      lineThickness: 0,
                      tickThickness: 0,
                      labelFontFamily: "Noto",
                      labelFontColor: colors.gray35,
                      titleFontSize: 16,
                      labelWrap: false,
                      labelMaxWidth: 30,
                      titleFontWeight: 300,
                      titleFontFamily: "Noto",
                      titleFontColor: colors.gray35,
                      interval: maxYaxis < 30 ? maxYaxis / 2 : 15,
                    },
                    toolTip: {
                      enabled: true,
                      animationEnabled: true,
                      shared: true,
                      backgroundColor: colors.atomic,
                      borderColor: colors.atomic,
                      borderThickness: 2,
                      fontSize: 16,
                      fontColor: colors.white,
                      fontFamily: "Noto",
                      contentFormatter: (e) => getTooltipContent(e),
                    },
                    data: displayData(chart.dataPoints),
                  }}
                />
                <div
                  className={classNames(classes.titleRow, {
                    [classes.flexEnd]: chart.dates === barChartData[0].dates,
                  })}
                >
                  <div className={classes.dates}>{chart.dates}</div>
                </div>
              </div>
            )
          );
        })}
      </div>
    </Root>
  );
};

export default OpsBarChart;

OpsBarChart.propTypes = {
  chartTitle: PropTypes.string,
  barChartData: PropTypes.array.isRequired,
  valueUnit: PropTypes.string,
  chartHeight: PropTypes.string,
};
