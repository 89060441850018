import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const PREFIX = "TableBasic";

const classes = {
  headCell: `${PREFIX}-headCell`,
  cell: `${PREFIX}-cell`,
  tableContainer: `${PREFIX}-tableContainer`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`,
  tableInner: `${PREFIX}-tableInner`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.tableContainer}`]: {
    marginTop: 10,
    position: "relative",
    "@media print": {
      "&:before": {
        content: "none",
      },
    },
  },
  [`& .${classes.tableInner}`]: {
    overflowX: "auto",
    paddingBottom: 6,

    [theme.breakpoints.up("md")]: {
      marginTop: 10,
    },
  },
  [`& .${classes.title}`]: {
    fontSize: 10,
  },
  [`& .${classes.table}`]: {
    tableLayout: "fixed",
    marginTop: -6,
  },
  [`& .${classes.cell}`]: {
    fontSize: 12,
    padding: "2px 0px",
    whiteSpace: "nowrap",
    height: 34,

    "&:first-child": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: 75,
    },

    "&:last-child": {
      padding: "2px 4px",
    },
  },
  [`& .${classes.headCell}`]: {
    height: 34,
    [theme.breakpoints.down("xxl")]: {
      padding: "8px 0px 8px 8px",
    },
  },
}));

const TableBasic = ({ data }) => {
  const { headers, rows, title } = data;

  return (
    <StyledGrid container item classes={{ item: classes.tableContainer }}>
      <Grid item xs={12} className={classes.tableInner}>
        <Typography variant="caption">{title}</Typography>
        <Table className={classes.table}>
          <TableBody>
            {rows.length > 0
              ? rows.map((row) => {
                  return (
                    <TableRow
                      key={row.key}
                      onClick={row.onClick}
                      data-cy="table-row"
                    >
                      {headers.map((header) => (
                        <TableCell
                          align={header.align}
                          className={classes.cell}
                          key={`${header.id}-${row.key}`}
                        >
                          {row[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              : [0, 1, 2].map((num) => (
                  <TableRow key={num} data-cy="table-row">
                    <TableCell key={num} align="left" className={classes.cell}>
                      -
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Grid>
    </StyledGrid>
  );
};

export default TableBasic;

TableBasic.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
      })
    ).isRequired,
  }),
  defaultOrderBy: PropTypes.string,
};

TableBasic.defaultProps = {
  defaultOrderBy: null,
};
