import { colors } from "../../../../CommonComponents/UI/theme";

const kpiInfo = {
  duration: {
    translationKey: "milking-duration",
    up: colors.valenciaRed,
    down: colors.silverTreeGreen,
  },
  onTime: {
    translationKey: "milkings-on-time",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
};

export default kpiInfo;
