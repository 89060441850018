import { useAccountDetails } from "../../App/useAccountDetails";

const usePenDetails = (id) => {
  const penId = parseInt(id, 10);
  const { info } = useAccountDetails();
  const pens = info?.farm?.pens;
  if (!pens) throw new Error("No pens assigned to farm");

  const pen = pens.find((pen) => pen.id === penId);
  if (!pen) throw new Error("No pen found for given id");

  const {
    group_name,
    name,
    start_date,
    use_headlock,
    bed_capacity,
    stocking_density_yesterday,
    cow_count_yesterday,
    headspace_capacity,
  } = pen;

  return {
    group: group_name,
    label: name,
    startDate: start_date,
    useHeadlock: use_headlock,
    bedCapacity: bed_capacity,
    stockingDensity: stocking_density_yesterday,
    cowCount: cow_count_yesterday,
    headlocks: headspace_capacity,
  };
};

export default usePenDetails;
