import React from "react";
import { useQuery } from "react-query";
import { fetchFeedingOpsByPenId } from "../../../services/fetchFeedingOps";
import FoPenDetailsContext from "./FoPenDetailsContext";
import { useFeedingOpsContext } from "./FeedingOpsContext";

const FoPenDetailsProvider = (props) => {
  const { selectedPen } = useFeedingOpsContext();

  const { data } = useQuery(
    ["FeedingOpsByPenId", selectedPen.selectedPenId],
    fetchFeedingOpsByPenId,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { penDetails, pushups, deliveries } = data;

  const providerState = { penDetails, pushups, deliveries };

  return (
    <FoPenDetailsContext.Provider value={providerState}>
      {props.children}
    </FoPenDetailsContext.Provider>
  );
};

export default FoPenDetailsProvider;
