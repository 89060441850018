import Log from "./Log";
import Recipients from "./Recipients/Recipients";
import SetAlerts from "./SetAlerts";

const sections = [
  {
    name: "Recipients",
    id: "recipients",
    translationKey:"recipients",
    component: Recipients,
    route: "/recipients",
  },
  {
    name: "Set Alerts",
    id: "setAlerts",
    translationKey:"set-alerts",
    component: SetAlerts,
    route: "/set",
  },
  {
    name: "Alert Log",
    id: "log",
    translationKey:"alert-log",
    component: Log,
    route: "/log",
  },
];

export default sections;
