import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";

import theme from "./theme";

const useStyles = makeStyles(() => ({
  arrow: {
    display: "inline-block",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
    height: 24,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginRight: -5,
      height: 22,
      width: 22,
    },
  },
  arrowActive: {
    display: "inline-block",
    transform: "rotate(180deg)",
  },
  arrowIcon: {
    [theme.breakpoints.down("sm")]: {
      height: 22,
      width: 22,
    },
  },
}));

const ExpandArrow = ({ isActive }) => {
  const classes = useStyles();

  return (
    <div
      data-cy="expand-arrow"
      className={classNames(classes.arrow, {
        [classes.arrowActive]: isActive,
      })}
    >
      <ExpandMoreIcon className={classes.arrowIcon} />
    </div>
  );
};

export default ExpandArrow;

ExpandArrow.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
