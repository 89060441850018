import React, { useState } from "react";
import ArrowForward from "@mui/icons-material/ArrowForwardSharp";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "../../CommonComponents/UI/Table";
import Link from "../../CommonComponents/UI/Link";
import { useRouteMatch } from "react-router-dom";
import Header from "../../CommonComponents/UI/Header";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PrintButton from "../../CommonComponents/UI/PrintButton";
import GroupFilter from "../../CommonComponents/UI/GroupFilter";
import Switch from "../../CommonComponents/UI/Switch";
import { useQuery } from "react-query";
import { colors } from "../../CommonComponents/UI/theme";
import { getPens, getTrends } from "../services/pen";
import { useAccountDetails } from "../../App/useAccountDetails";
import kpiInfo from "../utils/kpiInfo";

const PREFIX = "Pens";

const classes = {
  content: `${PREFIX}-content`,
  table: `${PREFIX}-table`,
  hightlight: `${PREFIX}-hightlight`,
  problem: `${PREFIX}-problem`,
  tableToolbar: `${PREFIX}-tableToolbar`,
  tablePeriod: `${PREFIX}-tablePeriod`,
  link: `${PREFIX}-link`,
  linkCell: `${PREFIX}-linkCell`,
  switch: `${PREFIX}-switch`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarItem: `${PREFIX}-toolbarItem`,
  printButton: `${PREFIX}-printButton`,
  caption: `${PREFIX}-caption`,

  trendWrapper: `${PREFIX}-trendWrapper`,
  arrow: `${PREFIX}-arrow`,
  label: `${PREFIX}-label`,
  trendSection: `${PREFIX}-trendSection`,
  text: `${PREFIX}-text`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.content}`]: {
    padding: theme.spacing(1.5),
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    },
    "@media print": {
      padding: 24,
    },
    "& > button": {
      marginTop: theme.spacing(4.5),
    },
  },
  [`& .${classes.toolbar}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
    alignSelf: "flex-end",
    fontSize: 12,
    marginLeft: "auto",
    marginBottom: 20,
    width: 440,
    justifyContent: "flex-end",

    "& > *": {
      marginRight: 14,
    },
  },

  [`& .${classes.toolbarItem}`]: {
    [theme.breakpoints.up("md")]: {
      marginRight: 14,
    },
  },

  [`& .${classes.printButton}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    color: "rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.table}`]: {
    minWidth: 875,
    marginTop: theme.spacing(2),
  },

  [`& .${classes.highlight}`]: {
    [`& .${classes.problem}`]: {
      backgroundColor: colors.tableProblem,
      color: colors.white,
    },
  },

  [`& .${classes.problem}`]: {},

  [`& .${classes.tableToolbar}`]: {
    marginTop: theme.spacing(6),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column-reverse",
      alignItems: "flex-start",
    },
  },

  [`& .${classes.tablePeriod}`]: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  [`& .${classes.link}`]: {
    display: "inline-block",
    padding: "5px 0",
    width: "100%",
    height: "100%",
    paddingLeft: 8,
    [theme.breakpoints.up("xxl")]: {
      padding: 16,
    },
    "@media print": {
      color: colors.black,
    },
  },

  [`& .${classes.linkCell}`]: {
    padding: 0,
  },

  [`& .${classes.switch}`]: {
    marginLeft: 0,
  },

  [`& .${classes.caption}`]: {
    color: colors.black,
    textTransform: "uppercase",
  },

  [`& .${classes.trendWrapper}`]: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0 10px 6px",
    width: "50%",
    [theme.breakpoints.up("md")]: {
      padding: "0 40px 0 20px",
      width: 270,
      "&:first-of-type": {
        paddingLeft: 0,
      },
    },
  },

  [`& .${classes.arrow}`]: {
    verticalAlign: "middle",
    fontSize: 20,
    marginRight: 10,
    [theme.breakpoints.up("md")]: {
      fontSize: 26,
    },
  },

  [`& .${classes.label}`]: {
    color: colors.labelGray,
    fontSize: 14,
  },

  [`& .${classes.trendSection}`]: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 16,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      marginTop: 0,
    },
  },
  [`& .${classes.text}`]: {
    fontSize: 14,
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
      marginLeft: 10,
    },
  },
}));

const sort = (key) => (array, order) => {
  array.sort((a, b) => {
    if (order === "asc") {
      return a[key].props.children.props.text.localeCompare(
        b[key].props.children.props.text,
        "en",
        {
          sensitivy: "base",
          numeric: true,
        }
      );
    }
    return b[key].props.children.props.text.localeCompare(
      a[key].props.children.props.text,
      "en",
      {
        sensitivy: "base",
        numeric: true,
      }
    );
  });
  return array;
};

const convertMinutesToHoursAndMins = (min) => {
  return `${Math.floor(min / 60)}h ${min % 60}m`;
};

// TODO: Remove this function as it's not being used anymore
const createStringAndTargetPercentage = (events, type, scheduleString) => {
  const getPercentage = (actual, expected) =>
    Math.round((actual / expected) * 100);

  const actualVsExpected = events
    ? events.expected[type].length
      ? `${getPercentage(
          events.actual[type].length,
          events.expected[type].length
        )}%`
      : scheduleString
    : "-%";

  const targetReached = events
    ? events.actual[type].length >= events.expected[type].length
    : true;
  return {
    value: actualVsExpected,
    target_reached: targetReached,
  };
};

const createStringAndTarget = (events, type, scheduleString) => {
  const actualVsExpected = events
    ? events.expected[type].length
      ? `${events.actual[type].length} / ${events.expected[type].length}`
      : scheduleString
    : "-/-";
  const targetReached = events
    ? events.actual[type].length >= events.expected[type].length
    : true;
  return {
    value: actualVsExpected,
    target_reached: targetReached,
  };
};

const Wrap = (kpi, url) => {
  const isProblemCell =
    typeof kpi.target_reached === "boolean" && !kpi.target_reached;
  const linkClass = isProblemCell
    ? `${classes.link} ${classes.problem}`
    : classes.link;
  return (
    <>
      {url ? (
        <Link className={linkClass} text={kpi.value || kpi} url={url} />
      ) : (
        <Typography
          variant="body2"
          text={kpi.value || kpi}
          className={linkClass}
        >
          {kpi.value || kpi}
        </Typography>
      )}
    </>
  );
};

const generateConversion = (path, scheduleString) => (row) => {
  const { events, group, id, status } = row;
  const lowFeedTime =
    status && status.minutesWithNoFeed !== null
      ? convertMinutesToHoursAndMins(status.minutesWithNoFeed)
      : "--h --m";

  // // remove the undefined check once the api is actually returning cleanBunkTime
  // const cleanBunkTime =
  //   status &&
  //   status.cleanBunkTime !== null &&
  //   status.cleanBunkTime !== undefined
  //     ? convertMinutesToHoursAndMins(status.cleanBunkTime)
  //     : "--h --m";

  const deliveries = createStringAndTarget(
    events,
    "deliveries",
    scheduleString
  );
  const pushups = createStringAndTarget(events, "pushups", scheduleString);

  return {
    penName: Wrap(row.name, `/feedbunk-management/pens/${id}/feed-status`),
    key: id,
    group,
    lowFeedTime: Wrap(
      {
        value: lowFeedTime,
        target_reached: status ? status.target_reached : true,
      },
      `/feedbunk-management/pens/${id}/feed-status`
    ),
    deliveries: Wrap(
      { value: deliveries.value, target_reached: deliveries.target_reached },
      `/feedbunk-management/pens/${id}/feed-events?include=delivery`
    ),
    pushups: Wrap(
      { value: pushups.value, target_reached: pushups.target_reached },
      `/feedbunk-management/pens/${id}/feed-events?include=pushup`
    ),
    // cleanBunkTime: Wrap({
    //   value: cleanBunkTime,
    //   target_reached: status ? status.target_reached : true,
    // }),
  };
};

const allGroups = "All Groups";

const PenSummary = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { info } = useAccountDetails();
  const [shouldHighlightPens, setShouldHighlightPens] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(allGroups);
  const { data: pensData } = useQuery(
    ["feedbunkPens", { period: "yesterday" }],
    getPens
  );
  const { data: trendsData } = useQuery(
    ["feedbunkTrends", { period: "yesterday" }],
    getTrends
  );

  const { date, yesterday } = pensData.dates;

  const trends =
    selectedGroup === allGroups
      ? trendsData.summaries.farm
      : trendsData.summaries.groups.find(
          (summary) => summary.group === selectedGroup
        )?.summary;

  const pens = info?.farm?.pens || [];

  const groups = [
    ...new Set(pens.map(({ group_name }) => group_name).filter(Boolean)),
    allGroups,
  ];

  const convertForDisplay = generateConversion(match.path, t("no-schedule"));
  const [rows, setRows] = useState(pensData.pens.map(convertForDisplay));

  const selectGroup = (e) => {
    setSelectedGroup(e.target.value);
    const filteredPens =
      e.target.value === allGroups
        ? pensData.pens
        : pensData.pens.filter(
            (pen) => pen.group && pen.group === e.target.value
          );

    setRows(filteredPens.map(convertForDisplay));
  };

  const toggleMissedTargets = () => {
    setShouldHighlightPens((shouldHighlightPens) => !shouldHighlightPens);
  };

  const headers = [
    {
      id: "penName",
      label: t("pens-capitalised"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("penName"),
    },
    {
      id: "group",
      label: t("groups-capitalised"),
      filterable: true,
    },
    {
      id: "lowFeedTime",
      label: t("low-feed-time"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("lowFeedTime"),
    },
    {
      id: "pushups",
      label: t("push-ups-completed"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("pushups"),
    },
    {
      id: "deliveries",
      label: t("deliveries-on-time"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("deliveries"),
    },
    // {
    //   id: "cleanBunkTime",
    //   label: t("clean-bunk-time"),
    //   sortable: true,
    //   cellClass: classes.linkCell,
    //   sortFn: sort("cleanBunkTime"),
    // },
  ];

  return (
    <>
      <Header title={t("pen-summary")} />
      <StyledGrid container className={classes.content}>
        <div className={classes.toolbar}>
          <Typography variant="caption">{date}</Typography>
          {yesterday && (
            <Typography
              className={classes.caption}
              variant="caption"
              data-cy="Feedbunk Management Pens Yesterday"
            >
              {t("yesterday")}
            </Typography>
          )}

          <GroupFilter
            selectGroup={selectGroup}
            selectedGroup={selectedGroup}
            groups={groups}
          />
          <PrintButton printButtonClass={classes.printButton} />
        </div>
        <section className={classes.trendSection}>
          {trends.map(({ difference, kpi, increase, value }) => {
            const direction = increase ? "up" : "down";
            const textColor = kpiInfo[kpi][direction];
            return (
              <div key={kpi} className={classes.trendWrapper}>
                <div
                  className={classes.label}
                  data-cy={kpiInfo[kpi].translationKey}
                >
                  {t(kpiInfo[kpi].translationKey)}
                </div>

                <Typography variant="h5" data-cy={`${kpi}-value`}>
                  {increase !== null && (
                    <ArrowForward
                      className={classes.arrow}
                      style={{
                        color: textColor,
                        transform: increase
                          ? "rotate(-45deg)"
                          : "rotate(45deg)",
                      }}
                    />
                  )}

                  {value}
                  {increase !== null && (
                    <span
                      className={classes.text}
                      style={{
                        color: textColor,
                      }}
                    >
                      {difference}
                    </span>
                  )}
                </Typography>
              </div>
            );
          })}
        </section>
        <Grid container item xs={12} className={classes.tableToolbar}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              labelPlacement="start"
              className={classes.switch}
              data-cy="missed-targets-toggle"
              control={
                <Switch
                  checked={shouldHighlightPens}
                  name="Targets"
                  onChange={toggleMissedTargets}
                />
              }
              label={t("highlight-missed-targets")}
            />
          </Grid>
        </Grid>
        <Table
          data={{ headers, rows }}
          defaultOrderBy="penName"
          className={classNames(classes.table, {
            [classes.highlight]: shouldHighlightPens,
          })}
        />
      </StyledGrid>
    </>
  );
};

export default PenSummary;
