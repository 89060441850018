import { colors } from "../theme";
import { styled } from "@mui/material/styles";
import Slide from "@mui/material/Slide";

const PREFIX = "Slide";

export const classes = {
  slide: `${PREFIX}-slide`,
  print: `${PREFIX}-print`,
};

const StyledSlide = styled(Slide)(({ theme }) => ({
  [`&.${classes.slide}`]: {
    display: "block",
    position: "absolute",
    zIndex: "999",
    top: "0",
    bottom: "0",
    minHeight: "100vh",
    background: colors.whiteSmoke,
    right: 0,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.33)",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 620,
    },
    [theme.breakpoints.up("xxl")]: {
      width: 652,
    },
    "@media print": {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: "1",
      width: "100%",
      height: "100%",
    },
  },
}));

export default StyledSlide;
