import React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Pen from "../../../CommonComponents/Icons/Pen";

const PREFIX = "ScheduleSettingsMainView";

const classes = {
  SSVContainer: `${PREFIX}-SSVContainer`,
  SSVPenDetails: `${PREFIX}-SSVPenDetails`,
  SSVSchedule: `${PREFIX}-SSVSchedule`,
  SSVPenIcon: `${PREFIX}-SSVPenIcon`,
  SSVPenDetailsPenName: `${PREFIX}-SSVPenDetailsPenName`,
  linkText: `${PREFIX}-linkText`,
};

const Root = styled("div")(() => ({
  [`&.${classes.SSVContainer}`]: {
    display: "flex",
    padding: 32,
    height: "100%",
  },

  [`& .${classes.SSVPenDetails}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRight: "2px solid #e9e9e9",
    height: "100%",
    flex: "2 2 240px",
    padding: "32px 40px",
    textAlign: "center",
  },

  [`& .${classes.SSVSchedule}`]: {
    flex: "6 6 700px",
    padding: "0 40px",
  },

  [`& .${classes.SSVPenIcon}`]: {
    display: "flex",
    height: 38,
    width: 38,
    fontSize: 38,
  },

  [`& .${classes.SSVPenDetailsPenName}`]: {
    display: "inline-block",
    borderBottom: "2px solid transparent",
    textDecoration: "none",
    color: "#186490",
    "&:hover": {
      borderBottom: "2px solid #186490",
    },
  },

  [`& .${classes.linkText}`]: {
    color: "#186490",
    fontSize: 32,
    fontWeight: "bold",
  },
}));

const PenIcon = styled(() => (
  <Pen
    htmlColor="#999999"
    fontSize="inherit"
    stroke="#999999"
    strokeWidth={0.75}
  />
))({});

const ScheduleSettingsMainView = ({ pen, children }) => {
  return (
    <Root className={classes.SSVContainer}>
      <div className={classes.SSVPenDetails}>
        <div className={classes.SSVPenIcon}>
          <PenIcon />
        </div>
        <Link
          className={classes.SSVPenDetailsPenName}
          to={`/feedbunk-management/pens/${pen.id}/feed-events`}
        >
          <span className={classes.linkText}>{pen.label}</span>
        </Link>
      </div>
      <div className={classes.SSVSchedule}>{children}</div>
    </Root>
  );
};

export default ScheduleSettingsMainView;
