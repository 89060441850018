import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Toast = ({ toast, closeToast }) => {
  return (
    <>
      <Snackbar
        open={toast.show}
        autoHideDuration={5000}
        onClose={closeToast}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert variant="filled" onClose={closeToast} severity={toast.type}>
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Toast;
