import { useReducer } from "react";
import dayjs from "dayjs";

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const update = (state, name, newValue) => {
  const toUpdate = deepClone(state);
  toUpdate[name] = newValue;
  return toUpdate;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "update-input": {
      const { name, value } = action.payload;
      return update(state, name, value);
    }
    case "raise-input-error": {
      const { name, error } = action.payload;
      const { value } = state[name];
      return update(state, name, { value, error });
    }
    case "reset": {
      return init(action.payload);
    }
    default:
      throw new Error("Edit Recipient Section has entered an unknown state");
  }
};

const convertToAMPM = (time) => dayjs(time, ["HH:mm"]).format("h:mm A");
const convertToISO = (time) => dayjs(time, ["h:mm A"]).format("HH:mm");

const allDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const getDays = (daysEnabled) => {
  const isEveryday =
    daysEnabled.length === allDays.length &&
    daysEnabled.every((day) => allDays.includes(day));

  return isEveryday ? ["everyday"] : daysEnabled;
};

const init = ({
  schedule = {
    start_time: "00:00",
    end_time: "23:59",
    days_enabled: ["everyday"],
  },
  alerts_enabled = [],
  name = "",
  phone_number = "",
  id,
}) => {
  return {
    alerts_enabled,
    days_enabled: getDays(schedule.days_enabled),
    id,
    name,
    phone_number,
    startTime: convertToAMPM(schedule.start_time),
    endTime: convertToAMPM(schedule.end_time),
  };
};

const useRecipientManagement = (initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const reset = () => dispatch({ type: "reset", payload: { ...initialState } });

  const onInputChange = (event) => {
    const { name, value } = event.target;

    dispatch({
      type: "update-input",
      payload: { name, value },
    });
  };

  const onPhoneChange = (value) => {
    dispatch({
      type: "update-input",
      payload: { name: "phone_number", value },
    });
  };

  // get the formatted value
  const onPhoneBlur = (e) => {
    const val = e.target.value;
    dispatch({
      type: "update-input",
      payload: { name: "phone_number", value: val },
    });
  };

  const toggleValues = (selected, type) => {
    const updatedArray = state[type].includes(selected)
      ? state[type].filter((day) => day !== selected)
      : [...state[type], selected];

    dispatch({
      type: "update-input",
      payload: { name: type, value: updatedArray },
    });
  };

  const toggleDays = (event) => toggleValues(event.target.name, "days_enabled");
  const toggleAlerts = (event) =>
    toggleValues(event.target.name, "alerts_enabled");

  const convertToISOTime = () => {
    const { days_enabled, startTime, endTime, ...rest } = state;
    const days = days_enabled.includes("everyday") ? allDays : days_enabled;
    return {
      ...rest,
      alerts_start_time: convertToISO(startTime),
      alerts_end_time: convertToISO(endTime),
      days_enabled: days,
    };
  };

  return {
    recipientState: state,
    convertToISOTime,
    reset,
    onInputChange,
    onPhoneChange,
    onPhoneBlur,
    toggleAlerts,
    toggleDays,
  };
};

export default useRecipientManagement;
