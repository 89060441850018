import axios from "axios";

export async function getAccounts() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/settings/accounts`
  );
  return response.data;
}

export async function createAccount(newAccount) {
  const response = await axios.post(
    `${window._env_.API_URL}/private/settings/accounts`,
    newAccount
  );
  return response.data;
}

export async function updateAccount({ id, ...updatedDetails }) {
  const response = await axios.put(
    `${window._env_.API_URL}/private/settings/accounts/${id}`,
    updatedDetails
  );
  return response.data;
}

export async function deleteAccount({ id }) {
  const response = await axios.delete(
    `${window._env_.API_URL}/private/settings/accounts/${id}`
  );
  return response.data;
}
