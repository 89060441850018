import axios from "axios";

const { API_URL } = window._env_;

export const getTimeBudgetResource = async ({ queryKey }) => {
  const [route, resource, id] = queryKey;
  const response = await axios.get(
    `${API_URL}/private/behavior/pens/${id}/${route}/${resource}`
  );

  return response.data;
};

export const getPenProductivity = async ({ queryKey }) => {
  const [resource, params] = queryKey;
  const response = await axios.get(
    `${API_URL}/private/behavior/pens/${params.penId}/${resource}`,
    {
      params,
    }
  );
  return response.data;
};

export const getPenProductivityByHour = async ({ queryKey }) => {
  const [, params] = queryKey;
  const { id, ...rest } = params;
  const response = await axios.get(
    `${API_URL}/private/behavior/pens/${id}/productivity/details`,
    {
      params: rest,
    }
  );
  return response.data;
};
