import axios from "axios";

export async function getHerdProductivityTrends({ queryKey }) {
  const [_, params] = queryKey;
  const response = await axios.get(
    `${window._env_.API_URL}/private/herd-productivity/trends`,
    {
      params,
    }
  );

  return response.data;
}
