import React from "react";
import { styled } from "@mui/material/styles";
import OpsTrendTile from "./OpsTrendTile";
import {
  flexColumnMixIn,
  flexRowMixIn,
} from "../../utils/Operations/cssMixins";

const PREFIX = "OpsTrendGroup";

const classes = {
  trendGroup: `${PREFIX}-trendGroup`,
  trendItem: `${PREFIX}-trendItem`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.trendGroup}`]: {
    ...flexRowMixIn,
    flexWrap: "wrap",
    alignItems: "flex-start",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
    },
  },

  [`& .${classes.trendItem}`]: {
    ...flexColumnMixIn,
    width: "100%",
    flexGrow: "1",
    [theme.breakpoints.up("xs")]: {
      width: "50%",
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

const OpsTrendGroup = ({ trends, kpiInfo }) => {
  return (
    <Root className={classes.trendGroup}>
      {Object.entries(trends).map(([key, item]) => {
        if (kpiInfo[key]) {
          return (
            <div
              className={classes.trendItem}
              key={key}
              data-cy={`ops-trend-${key}`}
            >
              <OpsTrendTile
                item={item}
                kpiInfo={kpiInfo[key]}
                dataCyPrefix={`ops-trend-${key}`}
              />
            </div>
          );
        }
      })}
    </Root>
  );
};

export default OpsTrendGroup;
