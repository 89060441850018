import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import Checkbox from "@mui/material/Checkbox";
import Datepicker from "../../../../CommonComponents/UI/Datepicker/Datepicker";
import Grid from "@mui/material/Grid";
import Filter from "./Filter";
import useReadOnly from "../../../../CommonComponents/utils/useReadOnly";

const PREFIX = "EventsToolbar";

const classes = {
  settingsIcon: `${PREFIX}-settingsIcon`,
  filters: `${PREFIX}-filters`,
  filterContainer: `${PREFIX}-filterContainer`,
  toolbar: `${PREFIX}-toolbar`,
  datePickerSection: `${PREFIX}-datePickerSection`,
  filterHeaderText: `${PREFIX}-filterHeaderText`,
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  text: `${PREFIX}-text`,
  filterSVG: `${PREFIX}-filterSVG`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.settingsIcon}`]: {
    marginLeft: 16,
    height: 32,
    width: 32,
    fontSize: 32,
    cursor: "pointer",
    fill: "#e69e6c",
    display: "block",
  },

  [`& .${classes.filters}`]: {
    display: "flex",
    flexWrap: "wrap",
    whiteSpace: "nowrap",
    padding: "16px 0",
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.filterContainer}`]: {
    display: "flex",
    alignItems: "center",
    width: 200,
    cursor: "pointer",
  },

  [`& .${classes.toolbar}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginLeft: 25,
  },

  [`&.${classes.datePickerSection}`]: {
    display: "none",
    height: 42,
    marginBottom: 16,
    maxHeight: 42,
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.filterHeaderText}`]: {
    fontSize: 20,
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },

  [`& .${classes.checkboxContainer}`]: {
    display: "inline-block",
    verticalAlign: "middle",
  },

  [`& .${classes.text}`]: {
    fontSize: 16,
  },

  [`& .${classes.filterSVG}`]: {
    height: 32,
    width: 32,
    fontSize: 32,
    margin: "0 8px",
  },
}));

const FilterItem = ({ hook, name, display, isActive, icon, onClick }) => {
  const filterItemHook = `${hook}-filter-item-button-${name}`;
  const SVG = icon();

  return (
    <div
      className={classes.filterContainer}
      onClick={() => onClick(name)}
      key={name}
      data-cy={filterItemHook}
      data-thirdparty={filterItemHook}
    >
      <Checkbox
        color="primary"
        name={name}
        checked={isActive}
        onChange={() => onClick(name)}
      />
      <div className={classes.filterSVG}>
        <SVG />
      </div>
      <span className={classes.text}>{display}</span>
    </div>
  );
};

const EventsToolbar = ({
  dates,
  filters,
  onCheckboxClicked,
  penId,
  setDates,
}) => {
  const isReadOnly = useReadOnly();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <StyledGrid item className={classes.datePickerSection}>
      <Datepicker
        startDate={dates.start}
        endDate={dates.end}
        minimumDate={dates.minimum}
        maximumDate={dates.maximum}
        maxRange={90}
        hook="feed-events"
        onApplyPressed={(startDate, endDate) => {
          setDates({ start: startDate, end: endDate });
        }}
      />
      <div className={classes.toolbar}>
        <Filter hook="events">
          <span className={classes.filterHeaderText}>{t("filter-by")}</span>
          <div className={classes.filters}>
            {filters.map((filter) =>
              FilterItem({
                ...filter,
                hook: "events",
                onClick: onCheckboxClicked,
              })
            )}
          </div>
        </Filter>
        {!isReadOnly && (
          <div
            className={classes.settingsIcon}
            title="Schedule settings"
            data-cy="events-link-to-schedule-settings"
            data-thirdparty="events-link-to-schedule-settings"
            onClick={() => {}}
          >
            <SettingsIcon
              htmlColor="#e69e6c"
              fontSize="inherit"
              onClick={() => {
                history.push(
                  `/feedbunk-management/schedule/pens/${penId}/deliveries`
                );
              }}
            />
          </div>
        )}
      </div>
    </StyledGrid>
  );
};

export default EventsToolbar;
