import { colors } from "../../CommonComponents/UI/theme";

const kpiInfo = {
  cowComfortIndex: {
    label: "Cow Comfort",
    translationKey: "cow-comfort",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
  timeOnFeet: {
    label: "Total Time on Feet",
    translationKey: "total-time-on-feet",
    up: colors.valenciaRed,
    down: colors.silverTreeGreen,
  },
};

export default kpiInfo;
