const COLORS = {
  FEED_STATUS: {
    POSITIVE_COLOR: "#9ad5b4",
    ESTIMATED_POSITIVE_COLOR:
      "repeating-linear-gradient(45deg, #9ad5b4, #bfe4cf 10px)",
    ESTIMATED_POSITIVE_COLOR_LIGHT:
      "repeating-linear-gradient(45deg, #bfe4cf, #cfe9da 10px)",
    POSITIVE_COLOR_LIGHT: "#bfe4cf",
    NEGATIVE_COLOR: "#ed5f5a",
    ESTIMATED_NEGATIVE_COLOR:
      "repeating-linear-gradient(45deg, #ed5f5a, #f39491 10px)",
    ESTIMATED_NEGATIVE_COLOR_LIGHT:
      "repeating-linear-gradient(45deg, #f39491, #f5b6b3 10px)",
    NEGATIVE_COLOR_LIGHT: "#f39491",
    CALCULATING_COLOR: "#e9e9e9",
    MISSING_COLOR: "#888888",
    MISSING_COLOR_LIGHT: "#cccccc",
    LOW_VISIBILITY:
      "repeating-linear-gradient(45deg, #ccc, #D9D9D9 10px,  #ccc 10px,  #D9D9D9 11px)",
  },
  FEED_EVENTS: {
    POSITIVE_COLOR: "#bfe4cf",
    NEGATIVE_COLOR: "#ff5050",
    CALCULATING_COLOR: "#ffffff",
    MISSING_COLOR: "#cccccc",
    LOW_VISIBILITY:
      "repeating-linear-gradient(45deg, #ccc, #D9D9D9 10px,  #ccc 10px,  #D9D9D9 11px)",
    ESTIMATED_POSITIVE_COLOR:
      "repeating-linear-gradient(45deg, #9ad5b4, #bee3cf 10px)",
    ESTIMATED_NEGATIVE_COLOR:
      "repeating-linear-gradient(45deg, #ed5f5a, #f5a6a3 10px)",
  },
};

const STATUS_TEXT_MAP = {
  0: "calculating",
  10: "missing-data",
  100: "feed-available",
  101: "feed-available-estimated-low-visibility",
  200: "feed-low",
  201: "feed-low-estimated-low-visibility",
  300: "low-visibility",
};

const STATUS_COLOR_MAP = (chartType) => ({
  0: COLORS[chartType].CALCULATING_COLOR,
  10: COLORS[chartType].MISSING_COLOR,
  100: COLORS[chartType].POSITIVE_COLOR,
  101: COLORS[chartType].ESTIMATED_POSITIVE_COLOR,
  200: COLORS[chartType].NEGATIVE_COLOR,
  201: COLORS[chartType].ESTIMATED_NEGATIVE_COLOR,
  300: COLORS[chartType].LOW_VISIBILITY,
});

const STATUS_COLOR_MAP_LIGHT = {
  0: COLORS["FEED_STATUS"].CALCULATING_COLOR,
  10: COLORS["FEED_STATUS"].MISSING_COLOR_LIGHT,
  100: COLORS["FEED_STATUS"].POSITIVE_COLOR_LIGHT,
  101: COLORS["FEED_STATUS"].ESTIMATED_POSITIVE_COLOR_LIGHT,
  200: COLORS["FEED_STATUS"].NEGATIVE_COLOR_LIGHT,
  201: COLORS["FEED_STATUS"].ESTIMATED_NEGATIVE_COLOR_LIGHT,
  300: COLORS["FEED_STATUS"].LOW_VISIBILITY,
};

export { STATUS_COLOR_MAP, STATUS_COLOR_MAP_LIGHT, STATUS_TEXT_MAP };
