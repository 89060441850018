import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "../../../CommonComponents/UI/Table";
import Link from "../../../CommonComponents/UI/Link";
import Switch from "../../../CommonComponents/UI/Switch";
import { useRouteMatch } from "react-router-dom";
import { getTimeForDisplay } from "../../../CommonComponents/utils/time";
import Grid from "@mui/material/Grid";
import { colors } from "../../../CommonComponents/UI/theme";

const PREFIX = "Pens";

const classes = {
  table: `${PREFIX}-table`,
  highlight: `${PREFIX}-highlight`,
  highlightLabel: `${PREFIX}-highlightLabel`,
  labelContainer: `${PREFIX}-labelContainer`,
  problem: `${PREFIX}-problem`,
  link: `${PREFIX}-link`,
  linkCell: `${PREFIX}-linkCell`,
  switch: `${PREFIX}-switch`,
};
const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 900,
    marginTop: 16,
    "@media print": {
      marginTop: 65,
      maxWidth: "100%",
      minWidth: "100%",
    },
  },

  [`& .${classes.highlight}`]: {
    color: "red",
    [`& .${classes.problem}`]: {
      backgroundColor: colors.tableProblem,
      color: colors.white,
    },
  },

  [`& .${classes.problem}`]: {},

  [`& .${classes.highlightLabel}`]: {
    fontSize: 12,
  },

  [`& .${classes.labelContainer}`]: {
    [theme.breakpoints.up("md")]: {
      marginTop: 42,
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.link}`]: {
    display: "inline-block",
    padding: "5px 0",
    width: "100%",
    height: "100%",
    paddingLeft: 8,
    [theme.breakpoints.up("xxl")]: {
      padding: 16,
    },
    "@media print": {
      color: colors.black,
    },
  },

  [`& .${classes.linkCell}`]: {
    padding: 0,
  },
}));

const sort = (key) => (array, order) => {
  array.sort((a, b) => {
    if (order === "asc") {
      return a[key].props.children.props.text.localeCompare(
        b[key].props.children.props.text,
        "en",
        {
          sensitivy: "base",
          numeric: true,
        }
      );
    }
    return b[key].props.children.props.text.localeCompare(
      a[key].props.children.props.text,
      "en",
      {
        sensitivy: "base",
        numeric: true,
      }
    );
  });
  return array;
};

const padZeros = (value) => value.toString().padStart(2, "0");

const Wrap = (kpi, url) => {
  const isProblemCell =
    typeof kpi.target_reached === "boolean" && !kpi.target_reached;
  const linkClass = isProblemCell
    ? `${classes.link} ${classes.problem}`
    : classes.link;

  return (
    <>
      {url ? (
        <Link className={linkClass} text={kpi.value || kpi} url={url} />
      ) : (
        <Typography
          variant="body2"
          text={kpi.value || kpi}
          className={linkClass}
        >
          {kpi.value || kpi}
        </Typography>
      )}
    </>
  );
};

const getPercentageForDisplay = (percent) => {
  const parsedPercentage = parseInt(percent, 10);
  if (Number.isInteger(parsedPercentage)) {
    return `${padZeros(parsedPercentage)}%`;
  }
  return "-- %";
};

const generateConversion = (path) => (pen) => {
  const { name, group, id, cowComfortIndex, timeOnFeet } = pen;

  const cci = getPercentageForDisplay(cowComfortIndex.value);
  const tof = getTimeForDisplay(timeOnFeet.value);

  return {
    penName: Wrap(name, `${path}/pens/${id}/cow-comfort`),
    key: id,
    group: group,
    cowComfortIndex: Wrap(
      { value: cci, target_reached: cowComfortIndex.target_reached },
      `${path}/pens/${id}/cow-comfort`
    ),
    timeOnFeet: Wrap(
      { value: tof, target_reached: timeOnFeet.target_reached },
      `${path}/pens/${id}/time-on-feet`
    ),
  };
};

const Pens = ({ data }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [shouldHighlightPens, setShouldHighlightPens] = useState(false);
  const convertForDisplay = generateConversion(match.path);
  const rows = data.map(convertForDisplay);

  const toggleMissedTargets = () => {
    setShouldHighlightPens((shouldHighlightPens) => !shouldHighlightPens);
  };

  const headers = [
    {
      id: "penName",
      label: t("pens-capitalised"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("penName"),
    },
    {
      id: "group",
      label: t("groups-capitalised"),
    },
    {
      id: "cowComfortIndex",
      label: t("cow-comfort"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("cowComfortIndex"),
    },
    {
      id: "timeOnFeet",
      label: t("time-on-feet"),
      sortable: true,
      cellClass: classes.linkCell,
      sortFn: sort("timeOnFeet"),
    },
  ];

  return (
    <StyledGrid>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={6} className={classes.labelContainer}>
          <FormControlLabel
            labelPlacement="start"
            classes={{ label: classes.highlightLabel }}
            style={{ marginLeft: 0 }}
            data-cy="missed-targets-toggle"
            control={
              <Switch
                checked={shouldHighlightPens}
                name="Targets"
                onChange={toggleMissedTargets}
              />
            }
            label={t("highlight-missed-targets")}
          />
        </Grid>
      </Grid>
      <Table
        data={{ headers, rows }}
        defaultOrderBy="penName"
        className={classNames(classes.table, {
          [classes.highlight]: shouldHighlightPens,
        })}
      />
    </StyledGrid>
  );
};

export default Pens;
