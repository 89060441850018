import React from "react";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import { colors } from "./theme";

const PREFIX = "Navigator";

const classes = {
  root: `${PREFIX}-root`,
  navigationIcons: `${PREFIX}-navigationIcons`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.navigationIcons}`]: {
    minWidth: 96,
  },
  [`& .${classes.button}`]: {
    color: colors.black,
    height: 48,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));

const Navigator = ({
  buttonText,
  defaultOption,
  options = [],
  onOptionSelected,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [currentIndex, setCurrentIndex] = React.useState(
    options.findIndex(({ id }) => id === defaultOption)
  );

  const openMenuList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseWithoutSelection = () => {
    setAnchorEl(null);
  };

  const handleCloseWithSelection = (event) => {
    const { itemId } = event.currentTarget.dataset;
    const currentIndex = options.findIndex(
      ({ id }) => id === parseInt(itemId, 10)
    );
    setAnchorEl(null);
    setCurrentIndex(currentIndex);
    setCurrentIndex(options.findIndex(({ id }) => id === parseInt(itemId, 10)));
    onOptionSelected(itemId, options[currentIndex]?.unavailable);
  };

  const changeSelectedOptionIndex = (variation) => {
    const nextIndex = currentIndex + variation;
    setCurrentIndex(nextIndex);
    onOptionSelected(options[nextIndex].id, options[nextIndex].unavailable);
  };

  return (
    <Root className={classes.root}>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Button
          aria-controls="navigator-menu"
          aria-haspopup="true"
          onClick={openMenuList}
          className={classes.button}
        >
          {buttonText}
          <ArrowDropDownIcon className={classes.filterIcon} />
        </Button>
        <Menu
          id="navigator-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseWithoutSelection}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {options.map(({ title, id }, index) => (
            <MenuItem
              key={id}
              data-item-id={id}
              selected={index === currentIndex}
              onClick={(event) => handleCloseWithSelection(event)}
            >
              {title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <div className={classes.navigationIcons}>
        <IconButton
          onClick={() => changeSelectedOptionIndex(-1)}
          className={classes.drawerIcon}
          disabled={currentIndex === 0}
          aria-label="previous"
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => changeSelectedOptionIndex(1)}
          className={classes.drawerIcon}
          disabled={currentIndex === options.length - 1}
          aria-label="next"
          size="large"
        >
          <ChevronRightIcon />
        </IconButton>
      </div>
    </Root>
  );
};

export default Navigator;
