import React from "react";
import { useQuery } from "react-query";
import { fetchMilkingOpsSmartInsights } from "../../../services/fetchMilkingOperations";
import MoSmartInsightsContext from "./MoSmartInsightsContext";

const MoSmartInsightsProvider = (props) => {
  const { data } = useQuery(
    ["milkingOpsSmartInsights"],
    fetchMilkingOpsSmartInsights,
    {
      refetchOnWindowFocus: false,
    }
  );

  const insights = data.trends;

  return (
    <MoSmartInsightsContext.Provider value={insights}>
      {props.children}
    </MoSmartInsightsContext.Provider>
  );
};

export default MoSmartInsightsProvider;
