import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { useFeedingOpsContext } from "../utils/FeedingOpsContext";
import FoPensTable from "./FoPensTable";
import OpsTrendGroup from "../../../../CommonComponents/UI/Operations/OpsTrendGroup";
import TimePeriodToggle from "../../../../CommonComponents/UI/TimePeriodToggle";
import FoSmartInsights from "./FoSmartInsights";
import FoSmartInsightsProvider from "../utils/FoSmartInsightsProvider";
import FoPenDetailsOverview from "./FoPenDetailsOverview";
// import GroupFilter from "../../../../CommonComponents/UI/GroupFilter";
import PrintButton from "../../../../CommonComponents/UI/PrintButton";
import kpiInfo from "../utils/kpiInfo";
import StyledPensOverview, {
  classes,
} from "../../../../CommonComponents/UI/Operations/StyledPensOverview";

const FoPensOverview = () => {
  const {
    // groups,
    overview,
    selectedPen,
    // selectGroup,
    // selectedGroup,
    selectedDateRange,
    selectDateRange,
    selectPrintSection,
    selectedPrintSection,
  } = useFeedingOpsContext();

  const onPrint = useCallback(() => {
    selectPrintSection({
      printPensOverview: true,
      printInsightsOverview: false,
    });
    setTimeout(() => {
      window.print();
    });
  }, []);

  return (
    <StyledPensOverview
      data-cy="Feeding Ops Pens Overview Wrapper"
      className={classNames(classes.wrapper, {
        [classes.hidden]: selectedPen.isPenSelected,
        [classes.print]: selectedPrintSection.printPensOverview,
      })}
    >
      <div className={classes.content}>
        <div className={classes.toolbar} data-cy="toolbar">
          <Typography className={classes.caption} variant="caption">
            {overview.dates}
          </Typography>
          <TimePeriodToggle
            changeTimePeriod={selectDateRange}
            selectedPeriod={selectedDateRange}
          />
          {/* <GroupFilter
            selectGroup={selectGroup}
            selectedGroup={selectedGroup}
            groups={groups}
          /> */}
          <PrintButton onChartPrint={onPrint} />
        </div>
        <OpsTrendGroup kpiInfo={kpiInfo} trends={overview.trends} />
        <div
          className={classes.insights}
          data-cy="Feeding Ops Pens Overview Mobile OpsSmartInsights"
        >
          <FoSmartInsightsProvider>
            <FoSmartInsights
              shouldPrint={selectedPrintSection.printPensOverview}
              dataCyPrefix="Feeding Ops Pens Overview Mobile OpsSmartInsights Card"
            ></FoSmartInsights>
          </FoSmartInsightsProvider>
          <FoPenDetailsOverview></FoPenDetailsOverview>
        </div>

        <FoPensTable></FoPensTable>
      </div>
    </StyledPensOverview>
  );
};

export default FoPensOverview;
