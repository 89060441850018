import React from "react";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { colors } from "./theme";

const PREFIX = "TimePeriodToggle";

const classes = {
  toggleButton: `${PREFIX}-toggleButton`,
  dropdown: `${PREFIX}-dropdown`,
};

const StyledToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },

  [`& .${classes.toggleButton}`]: {
    width: 126,
    height: 36,
    lineHeight: "36px",
    borderColor: colors.green,
    color: colors.green,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },

  [`& .${classes.dropdown}`]: {
    height: 36,
    boxSizing: "border-box",
    padding: 8,
    textTransform: "uppercase",
    fontSize: 14,
  },
}));

const timePeriods = {
  last7Days: "last7Days",
  last30Days: "last30Days",
};

const TimePeriodToggle = ({ changeTimePeriod, selectedPeriod }) => {
  const { t } = useTranslation();

  const onChangeToggle = (e, payload) => payload && changeTimePeriod(payload);

  const onChangeSelect = (e) => changeTimePeriod(e.target.value);

  return (
    <>
      <StyledToggleGroup
        value={selectedPeriod}
        exclusive
        color="primary"
        onChange={onChangeToggle}
        aria-label="text alignment"
      >
        <ToggleButton
          className={classes.toggleButton}
          value={timePeriods.last30Days}
          data-cy="30-days-toggle"
        >
          30 {t("days")}
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value={timePeriods.last7Days}
          data-cy="7-days-toggle"
        >
          7 {t("days")}
        </ToggleButton>
      </StyledToggleGroup>
      <StyledFormControl>
        <Select
          value={selectedPeriod}
          onChange={onChangeSelect}
          className={classes.dropdown}
          data-cy="time-period-toggle"
        >
          <MenuItem value={timePeriods.last30Days}>30 {t("days")}</MenuItem>
          <MenuItem value={timePeriods.last7Days}>7 {t("days")}</MenuItem>
        </Select>
      </StyledFormControl>
    </>
  );
};

export default TimePeriodToggle;
