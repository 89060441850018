const days = {
  // everyday: "Everyday",
  sunday: {
    long: "Sunday",
    short: "Su",
  },
  monday: {
    long: "Monday",
    short: "Mo",
  },
  tuesday: {
    long: "Tuesday",
    short: "Tu",
  },
  wednesday: {
    long: "Wednesday",
    short: "We",
  },
  thursday: {
    long: "Thursday",
    short: "Th",
  },

  friday: {
    long: "Friday",
    short: "Fr",
  },
  saturday: {
    long: "Saturday",
    short: "Sa",
  },
};

export default days;
