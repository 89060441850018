import { useEffect, useState } from "react";
import useDates from "./useDates";

const getCurrentPen = (pens, penId) =>
  pens.find((pen) => parseInt(penId, 10) === pen.id);

const useDatesforPen = (
  profile,
  penId,
  dateRange = 7,
  excludeToday = false
) => {
  const {
    farm: { pens },
  } = profile;
  const [currentPen, setCurrentPen] = useState(getCurrentPen(pens, penId));
  const [startTz, endTz] = useDates([currentPen.start_date, new Date()]);

  useEffect(() => {
    if (penId) {
      setCurrentPen(getCurrentPen(pens, penId));
    }
  }, [penId, pens]);

  let penStartDate = currentPen.start_date
    ? startTz.toDate()
    : new Date(2019, 7, 1);

  let penEndDate = endTz;

  if (excludeToday) {
    penEndDate = endTz.subtract(1, "day");
  }
  const daysActive = penEndDate.diff(startTz, "day");

  // we subtract 1 because it's inclusive of the end date
  let initialStart = penEndDate.subtract(dateRange - 1, "day");
  if (daysActive < 7) {
    initialStart = penEndDate.subtract(daysActive, "day");
  }

  const [dates, setDates] = useState({
    start: initialStart.toDate(),
    end: penEndDate.toDate(),
  });

  return [
    {
      start: dates.start,
      end: dates.end,
      minimum: penStartDate,
      maximum: penEndDate,
    },
    setDates,
  ];
};

export default useDatesforPen;
