import { useReducer } from "react";

// eslint-disable-next-line no-control-regex
const validEmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const update = (state, name, value, errorMessage) => {
  const toUpdate = deepClone(state);
  toUpdate[name] = { value, errorMessage };
  return toUpdate;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "update-input": {
      const { name, value } = action.payload;
      return update(state, name, value, "");
    }
    case "update-subscriptions": {
      const { value } = action.payload;
      return { ...state, subscriptions: value };
    }
    case "raise-input-error": {
      const { name, error } = action.payload;
      const { value } = state[name];
      return update(state, name, value, error);
    }
    case "update-admin":
      return update(state, "isAdmin", action.payload);
    case "toggle-expanded":
      return { ...state, expanded: !state.expanded };
    case "reset":
      return init(action.payload);
    default:
      throw new Error("Edit User Section has entered an unknown state");
  }
};

const init = ({
  name = "",
  email = "",
  isAdmin = false,
  subscriptions = { daily: true, weekly: true },
}) => {
  return {
    name: { value: name, errorMessage: "" },
    email: { value: email, errorMessage: "" },
    isAdmin: { value: isAdmin },
    subscriptions,
  };
};

const useUserMangement = (initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const reset = () => dispatch({ type: "reset", payload: { ...initialState } });

  const setAdminSwitch = (e, payload) =>
    dispatch({ type: "update-admin", payload });

  const setSubscriptions = (event) => {
    const { name } = event.target;

    const updatedSubs = {
      ...state.subscriptions,
      [name]: !state.subscriptions[name],
    };

    dispatch({
      type: "update-subscriptions",
      payload: { value: updatedSubs },
    });
  };

  const setInputValue = (event) => {
    const { name, value } = event.target;
    dispatch({
      type: "update-input",
      payload: { name, value },
    });
  };

  const validateName = (event) => {
    const { value } = event.target;
    if (value === "") {
      dispatch({
        type: "raise-input-error",
        payload: { name: "name", error: "Enter name" },
      });
    }
  };

  const validateEmail = (event) => {
    const { value } = event.target;
    const isValid = validEmailRegex.test(value);
    if (!isValid) {
      dispatch({
        type: "raise-input-error",
        payload: { name: "email", error: "Enter a valid email" },
      });
    }
  };

  return [
    state,
    reset,
    setInputValue,
    validateName,
    validateEmail,
    setAdminSwitch,
    setSubscriptions,
  ];
};

export default useUserMangement;
