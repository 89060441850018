import React, { Suspense } from "react";
import { useFeedingOpsContext } from "../utils/FeedingOpsContext";
import FoPenDetails from "./FoPenDetails";
import FoPenDetailsProvider from "../utils/FoPenDetailsProvider";
import LoadingSpinner from "../../../../CommonComponents/UI/LoadingSpinner";
import StyledSlide, {
  classes,
} from "../../../../CommonComponents/UI/Operations/StyledSlide";

const FoPenDetailsOverview = () => {
  const { selectedPen } = useFeedingOpsContext();

  return (
    <StyledSlide
      direction="left"
      className={classes.slide}
      in={selectedPen.isPenSelected && !selectedPen.isPenLoading}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <div>
        {selectedPen.isPenSelected && (
          <Suspense fallback={<LoadingSpinner />}>
            <FoPenDetailsProvider>
              <FoPenDetails></FoPenDetails>
            </FoPenDetailsProvider>
          </Suspense>
        )}
      </div>
    </StyledSlide>
  );
};

export default FoPenDetailsOverview;
