import React from "react";
import { useQuery } from "react-query";
import { fetchMilkingOperationsByPenId } from "../../../services/fetchMilkingOperations";
import PenDetailsContext from "./PenDetailsContext";
import { useMilkingOperationsContext } from "./MilkingOperationsContext";
import OpsPenDetailsSpinner from "../../../../CommonComponents/UI/Operations/OpsPenDetailsSpinner";

const PenDetailsProvider = (props) => {
  const { selectedPen } = useMilkingOperationsContext();

  const { data, isLoading } = useQuery(
    ["milkingOperationsByPenId", selectedPen.selectedPenId],
    fetchMilkingOperationsByPenId,
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      // do not remove suspense as will cause ops page to flash every time pen is selected and pen api is called
      suspense: false,
      useErrorBoundary: false,
    }
  );

  if (!isLoading) {
    const { penDetails, difference, duration } = data;

    const providerState = { penDetails, difference, duration };

    return (
      <PenDetailsContext.Provider value={providerState}>
        {props.children}
      </PenDetailsContext.Provider>
    );
  } else {
    return <OpsPenDetailsSpinner></OpsPenDetailsSpinner>;
  }
};

export default PenDetailsProvider;
