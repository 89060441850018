import React from "react";
import { IMaskInput } from "react-imask";
import TextField from "@mui/material/TextField";

const MaskedInput = React.forwardRef(({ width, onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    mask={"PPP%"}
    blocks={{
      PPP: {
        mask: Number,
        min: 0,
        max: 999,
      },
    }}
    onAccept={(value, mask) => onChange(value)}
    unmask={true}
    style={{ width }}
    overwrite={true}
    lazy={false}
    inputRef={ref}
  />
));

const PercentageInput = (props) => {
  const { onChange: changeCallback, value, ...rest } = props;
  return (
    <TextField
      {...rest}
      variant="standard"
      value={value.toString()}
      InputProps={{
        inputComponent: MaskedInput,
        onChange: (value) => {
          changeCallback({
            target: { name: props.name, value: parseInt(value, 10) },
          });
        },
      }}
    />
  );
};

export default PercentageInput;
