const config = {
  lyingTime: {
    color: "#74b691",
    order: 1,
    group: "productive",
    sectionId: "lying",
  },
  feedbunk: {
    color: "#bfe4cf",
    order: 2,
    group: "productive",
    sectionId: "feedbunk",
  },
  standing: {
    color: "#eb4d47",
    order: 3,
    group: "unproductive",
    sectionId: "standing",
  },
  emptyPen: {
    color: "#f39491",
    order: 4,
    group: "unproductive",
    sectionId: "out-of-pen",
  },
  uncategorized: {
    color: "#cccccc",
    order: 5,
    group: "uncategorized",
    sectionId: "uncategorized",
  },
};

export default config;
