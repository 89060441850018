import React from "react";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

const PREFIX = "CardActions";

const classes = {
  actions: `${PREFIX}-actions`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.actions}`]: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(3.5)}`,
  },
}));

const CardActions = ({ children, className }) => {
  return (
    <>
      <Divider />
      <StyledGrid
        container
        justifyContent="flex-end"
        className={classNames(classes.actions, className)}
      >
        {children}
      </StyledGrid>
    </>
  );
};

export default CardActions;

CardActions.propTypes = {
  children: PropTypes.object.isRequired,
};
