import React, { Suspense } from "react";
import { useQuery } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import LoadingSpinner from "../../CommonComponents/UI/LoadingSpinner";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import GaugeChart from "../../CommonComponents/UI/GaugeChart";
import ErrorView from "./ErrorView";
import KPITable from "./KPITable";
import { getHerdWelfareTrends } from "../services/trends";

const PREFIX = "HerdWelfareCard";

const classes = {
  chartContainer: `${PREFIX}-chartContainer`,
  tableContainer: `${PREFIX}-tableContainer`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  "@media print": {
    boxShadow: "none",
    overflow: "visible",
  },

  [`& .${classes.chartContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    height: 180,
    marginTop: -25,
    width: "55%",
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
      height: 208,
      maxWidth: 360,
      margin: "0 15px",
      marginTop: 0,
    },
  },
  [`& .${classes.tableContainer}`]: {
    width: "45%",
    padding: "0 16px 0 8px",
    [theme.breakpoints.up("sm")]: {
      width: "35%",
      marginTop: 36,
    },
  },
}));

const dataErrorView = () => (
  <ErrorView messageKey="waiting-for-data-to-process" />
);

const HerdWelfare = () => {
  const { data: trendsData } = useQuery(
    ["herdWelfareTrends", { period: "last30Days", includeWorstPens: true }],
    getHerdWelfareTrends,
    { refetchOnWindowFocus: false }
  );

  const cowComfortData = trendsData?.summaries.farm.find(
    (item) => item.kpi === "cowComfortIndex"
  );

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary FallbackComponent={dataErrorView}>
        <StyledBox>
          <Box className={classes.chartContainer}>
            <GaugeChart size="small" data={cowComfortData} />
          </Box>
          <Box className={classes.tableContainer}>
            <KPITable
              worstPens={cowComfortData.worst_pens}
              kpi="cowComfortIndex"
            />
          </Box>
        </StyledBox>
      </ErrorBoundary>
    </Suspense>
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <HerdWelfare />
    </ErrorBoundary>
  </Suspense>
);
