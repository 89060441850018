import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import LoadingSpinner from "../../../CommonComponents/UI/LoadingSpinner";
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { useActiveModules } from "../../../App/useActiveModules";
import Switch from "../../../CommonComponents/UI/Switch";
import Button from "../../../CommonComponents/UI/Button";
import Header from "../../../CommonComponents/UI/Header";
import useUserManagement from "../../utils/useUserManagement";

const PREFIX = "AddUserSection";

const classes = {
  styledLabel: `${PREFIX}-styledLabel`,
  mobileHeader: `${PREFIX}-mobileHeader`,
  mobileTitle: `${PREFIX}-mobileTitle`,
  content: `${PREFIX}-content`,
  contentExpanded: `${PREFIX}-contentExpanded`,
  styledIcon: `${PREFIX}-styledIcon`,
  adminSwitchLabel: `${PREFIX}-adminSwitchLabel`,
  adminSwitchSection: `${PREFIX}-adminSwitchSection`,
  toolSection: `${PREFIX}-toolSection`,
  inputWrapper: `${PREFIX}-inputWrapper`,
  input: `${PREFIX}-input`,
  hideOnDesktop: `${PREFIX}-hideOnDesktop`,
  background: `${PREFIX}-background`,
  backgroundExpanded: `${PREFIX}-backgroundExpanded`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  button: `${PREFIX}-button`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
  label: `${PREFIX}-label`,
};

const StyledSection = styled("div", {
  shouldForwardProp: (prop) => prop !== "hasSubscriptions",
})(({ hasSubscriptions, theme }) => ({
  [`& .${classes.styledLabel}`]: {
    height: 20,
    fontSize: 16,
    color: "#888888",
    marginBottom: 8,
    textTransform: "capitalize",
  },

  [`& .${classes.mobileHeader}`]: {
    height: 55,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${classes.mobileTitle}`]: {
    fontSize: 20,
    color: "#595959",
    width: 146,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  [`& .${classes.content}`]: {
    display: "none",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },

  [`& .${classes.contentExpanded}`]: {
    display: "flex",
  },

  [`& .${classes.styledIcon}`]: {
    height: 40,
    width: 40,
    padding: 8,
    color: "#bfbfbf",
    fontSize: 24,
    cursor: "pointer",
    userSelect: "none",
  },

  [`& .${classes.adminSwitchLabel}`]: {
    fontSize: 16,
    color: "#888888",
    marginBottom: 8,
  },

  [`& .${classes.adminSwitchSection}`]: {
    margin: "15px 0",
    justifyContent: hasSubscriptions ? "center" : "flex-start",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },

  [`& .${classes.toolSection}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: 120,

    "@media screen and (min-width: 960px)": {
      height: 100,
      flexDirection: "row",
      flex: "1 0 145px",
      alignItems: "center",
    },
  },

  [`& .${classes.inputWrapper}`]: {
    textAlign: "left",
    paddingTop: 5,
    minHeight: 70,
    "@media screen and (min-width: 960px)": {
      flex: "1 0 200px",
      paddingRight: 15,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  [`& .${classes.input}`]: {
    width: "100%",
  },

  [`& .${classes.hideOnDesktop}`]: {
    "@media screen and (min-width: 960px)": {
      display: "none",
    },
  },

  [`&.${classes.background}`]: {
    display: "inline-block",
    background: "white",
    width: "100%",
    padding: "10px 20px 20px 20px",
    border: "1px solid #e9e9e9",

    "@media screen and (min-width: 960px)": {
      width: "100%",
      marginBottom: 15,
    },
  },

  [`&.${classes.backgroundExpanded}`]: {
    marginBottom: 8,
  },

  [`& .${classes.buttonWrapper}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 5,
    "@media screen and (min-width: 960px)": {
      justifyContent: "flex-end",
    },
  },

  [`& .${classes.button}`]: {
    padding: "5px 7px",
    flexBasis: "80%",
  },

  [`& .${classes.loadingSpinner}`]: {
    margin: "10px 20px 0 0",
  },

  [`& .${classes.label}`]: {
    textAlign: "center",
  },
}));

const Icon = (props) => {
  return <span className={classes.styledIcon} {...props} />;
};

const AddUserSection = ({ onAddAccount: doAddAccount, isLoading }) => {
  const { t } = useTranslation();
  const { flags } = useActiveModules();

  const [
    state,
    reset,
    setInputValue,
    validateName,
    validateEmail,
    setAdminSwitchState,
    setSubscriptions,
  ] = useUserManagement();

  const onCreate = () =>
    doAddAccount(
      {
        name: state.name.value,
        email: state.email.value,
        is_admin: state.isAdmin.value,
        subscriptions: state.subscriptions,
      },
      {
        onSuccess: reset,
      }
    );

  const [isEditMode, setIsEditMode] = React.useState(false);
  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const contentClass = isEditMode
    ? `${classes.content} ${classes.contentExpanded}`
    : classes.content;

  const backgroundClass = isEditMode
    ? `${classes.background} ${classes.backgroundExpanded}`
    : classes.background;

  return (
    <>
      <Header dataCyAtt="User Accounts Page Title" title={t("user-accounts")} />
      <StyledSection
        hasSubscriptions={true}
        className={backgroundClass}
      >
        <div className={classes.mobileHeader}>
          <div
            data-cy="User Accounts Add User Title"
            className={classes.mobileTitle}
          >
            {t("add-user")}
          </div>
          <div className={classes.hideOnDesktop}>
            <Icon onClick={toggleEditMode}>
              {isEditMode ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Icon>
          </div>
        </div>
        <div className={contentClass}>
          <Grid
            item
            xs={12}
            lg={3}
            className={classes.inputWrapper}
          >
            <TextField
              data-cy="User Accounts Name Input"
              name="name"
              placeholder={t("enter-a-name")}
              label={t("name")}
              variant="outlined"
              size="small"
              helperText={state.name.errorMessage}
              error={!!state.name.errorMessage}
              value={state.name.value}
              className={classes.input}
              onBlur={validateName}
              onChange={setInputValue}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            className={classes.inputWrapper}
          >
            <TextField
              name="email"
              data-cy="User Accounts Email Input"
              label={t("email")}
              variant="outlined"
              size="small"
              placeholder={t("enter-an-email")}
              className={classes.input}
              error={!!state.email.errorMessage}
              helperText={state.email.errorMessage}
              value={state.email.value}
              onBlur={validateEmail}
              onChange={setInputValue}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className={classes.adminSwitchSection}
          >
            <FormControlLabel
              labelPlacement="top"
              control={
                <Switch
                  checked={state.isAdmin.value}
                  onChange={setAdminSwitchState}
                  name="add-user-admin"
                  label={t("admin")}
                />
              }
              label={t("admin")}
              data-cy="User Accounts Admin Label"
            />
            {
              Object.keys(state.subscriptions).map((key) => (
                <FormControlLabel
                  labelPlacement="top"
                  key={key}
                  classes={{
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      name={key}
                      id={key}
                      checked={state.subscriptions[key]}
                      onChange={setSubscriptions}
                    />
                  }
                  label={t(`${key}-email`)}
                />
              ))
            }
          </Grid>
          <Grid item xs={12} md={2} className={classes.buttonWrapper}>
            {isLoading ? (
              <div className={classes.loadingSpinner}>
                <LoadingSpinner size={30} />
              </div>
            ) : (
              <Button
                className={classes.button}
                title={t("add")}
                variant="outlined"
                onClick={onCreate}
                dataCyAtt="User Accounts Add Button"
              />
            )}
          </Grid>
        </div>
      </StyledSection>
    </>
  );
};

export default AddUserSection;
