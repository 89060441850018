import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { flexColumnMixIn } from "../../../../CommonComponents/utils/Operations/cssMixins";
import { colors } from "../../../../CommonComponents/UI/theme";
import { useFeedingOpsContext } from "../utils/FeedingOpsContext";
import { useFoPenDetailsContext } from "../utils/FoPenDetailsContext";
import OpsTabs from "../../../../CommonComponents/UI/Operations/OpsTabs";
import OpsTabbedContent from "../../../../CommonComponents/UI/Operations/OpsTabbedContent";
import FoBarChart from "./FoBarChart";
import OpsPenSummary from "../../../../CommonComponents/UI/Operations/OpsPenSummary";
import usePenDetails from "../../../../CommonComponents/utils/usePenDetails";

const Root = styled("div")(() => ({
  ...flexColumnMixIn,
  alignItems: "center",
  justifyContent: "flex-start",
  background: colors.white,
  width: "100%",
  visibility: "visible",
  transition: "visibility .2s ease",
}));

const penDetailsTabs = [
  {
    label: "Deliveries",
    dataCyAtt: "FO Deliveries Tab",
    translationKey: "deliveries",
  },
  {
    label: "Push Ups",
    dataCyAtt: "FO Push Ups Tab",
    translationKey: "push-ups-capitalised",
  },
];

const FoPenDetails = () => {
  const { selectPenId, selectedPen } = useFeedingOpsContext();
  const penDetails = usePenDetails(selectedPen.selectedPenId);
  const { deliveries, pushups } = useFoPenDetailsContext();
  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const deliveryCharts = [
    <FoBarChart
      data={deliveries.firstDeliveryDifference}
      name="firstDeliveryDifference"
    />,
    <FoBarChart data={deliveries.cleanBunkDuration} name="cleanBunkDuration" />,
  ];

  const pushupCharts = [
    <FoBarChart data={pushups.completed} name="completed" />,
    <FoBarChart data={pushups.ontime} name="onTime" />,
  ];

  return (
    <Root data-cy={`feeding-ops-pen-details-${selectedPen.selectedPenId}`}>
      <OpsPenSummary penDetails={penDetails} selectPenId={selectPenId} />
      <OpsTabs tabs={penDetailsTabs} onChange={onTabChange} value={tabValue} />

      {tabValue === 0 && penDetails && (
        <OpsTabbedContent charts={deliveryCharts} />
      )}

      {tabValue === 1 && penDetails && (
        <OpsTabbedContent charts={pushupCharts} />
      )}
    </Root>
  );
};

export default FoPenDetails;
