import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { colors } from "./theme";
import CanvasJSReact from "../Vendor/canvasjs.react";
const { CanvasJSChart } = CanvasJSReact;

const PREFIX = "GaugeChart";

const classes = {
  chartContainer: `${PREFIX}-chartContainer`,
  chartTitle: `${PREFIX}-chartTitle`,
  chartIcon: `${PREFIX}-chartIcon`,
  subtitle: `${PREFIX}-subtitle`,
};

const StyledContainer = styled("div")(() => ({
  position: "relative",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& button": {
    marginTop: -25,
    marginRight: -10,
  },
}));

const StyledTypography = styled(Typography)(() => ({
  color: colors.gray64,
  fontSize: 14,
  textTransform: "uppercase",
  position: "absolute",
}));

const GaugeChart = ({ data, size }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledContainer id="donut-chart">
        <CanvasJSChart
          options={{
            animationEnabled: true,
            subtitles: [
              {
                text: data.value,
                verticalAlign: "center",
                fontSize: size === "small" ? 40 : 60,
                fontFamily: "Noto",
              },
            ],
            toolTip: {
              enabled: false,
            },
            data: [
              {
                legendMarkerType: "square",
                type: "doughnut",
                explodeOnClick: false,
                dataPoints: [
                  {
                    y: 100,
                    color: "transparent",
                    toolTipContent: null,
                  },
                  {
                    color: "#186490",
                    y: data.raw_value,
                    highlightEnabled: false,
                  },
                  {
                    y: 100 - data.raw_value,
                    color: "#DEDEDE",
                    toolTipContent: null,
                    highlightEnabled: false,
                  },
                ],
              },
            ],
          }}
          containerProps={{
            height: "200%",
            top: 60,
            width: "100%",
            position: "relative",
          }}
        />
      </StyledContainer>
      <StyledTypography className={classes.subtitle} variant="subtitle2">
        {t("cow-comfort-score")}
      </StyledTypography>
    </>
  );
};

GaugeChart.propTypes = {
  data: PropTypes.object,
};

export default GaugeChart;
