import { useQuery } from "react-query";
import { getReportDashboardList } from "./services/reports";
import { useActiveModules } from "../App/useActiveModules";

const modules = {
  "FEEDBUNK MANAGEMENT": "feedbunkManagement",
  "PEOPLE & PROTOCOLS": "peopleProtocols",
  "HERD WELFARE": "herdWelfare",
  "HERD PRODUCTIVITY": "herdProductivity",
};

const useReports = () => {
  const { data } = useQuery(["reports"], getReportDashboardList);
  const { flags } = useActiveModules();

  const availableReports = data?.dashboards.filter((report) => {
    return flags[modules[report.module.toUpperCase()]];
  });

  return {
    reports: availableReports,
  };
};

export default useReports;
