import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const classes = {
  cell: `cell`,
};

const StyledCell = styled("div")(({ theme }) => ({
  [`&.${classes.cell}`]: {
    width: "auto",
    whiteSpace: "nowrap",
    [theme.breakpoints.up(768)]: {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
      wordBreak: "break-all",
      whiteSpace: "normal",
    },
  },
}));

export const createCellTemplate = (value, penId, cellHeading, dataCyAtt) => {
  return (
    <StyledCell
      className={classes.cell}
      data-cy={`${dataCyAtt}-table-cell-content-${penId}-${cellHeading}`}
    >
      {value}
    </StyledCell>
  );
};

export const createHeaderCellTemplate = (text, dataCyAtt) => {
  return (
    <Tooltip title={<div>{text}</div>} placement="top-start" arrow>
      <StyledCell className={classes.cell} data-cy={dataCyAtt}>
        {text}
      </StyledCell>
    </Tooltip>
  );
};

export const sortTableColumns = (key) => (array, order) => {
  array.sort((a, b) => {
    if (order === "asc") {
      return a[key].template.props.children?.localeCompare(
        b[key].template.props.children,
        "en",
        {
          sensitivy: "base",
          numeric: true,
        }
      );
    }
    return b[key].template.props.children?.localeCompare(
      a[key].template.props.children,
      "en",
      {
        sensitivy: "base",
        numeric: true,
      }
    );
  });
  return array;
};
