import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Toast from "../../../CommonComponents/UI/Toast";
import TimeField, { isValidTime } from "./TimeField";
import TimeFieldDisplay from "./TimeFieldDisplay";
import Button from "./Button";
import Schedule from "@mui/icons-material/Schedule";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import {
  ERROR_ALREADY_ADDED,
  ERROR_SELECT_DAY,
  ERROR_INVALID_TIME,
} from "./errorMessages";

const PREFIX = "ScheduleSettingsView";

const classes = {
  SSVPenIcon: `${PREFIX}-SSVPenIcon`,
  warningText: `${PREFIX}-warningText`,
  SSVScheduleTitle: `${PREFIX}-SSVScheduleTitle`,
  SSVScheduleTitleText: `${PREFIX}-SSVScheduleTitleText`,
  SSVScheduleInput: `${PREFIX}-SSVScheduleInput`,
  SSVScheduleDisplay: `${PREFIX}-SSVScheduleDisplay`,
  SSVScheduleTimeFieldInput: `${PREFIX}-SSVScheduleTimeFieldInput`,
  SSVScheduleTimeFieldDisplay: `${PREFIX}-SSVScheduleTimeFieldDisplay`,
  chip: `${PREFIX}-chip`,
  daySelectContainer: `${PREFIX}-daySelectContainer`,
  daySelect: `${PREFIX}-daySelect`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.SSVPenIcon}`]: {
    display: "flex",
    height: 38,
    width: 38,
    fontSize: 38,
  },

  [`& .${classes.warningText}`]: {
    color: "#ff5d5a",
    fontSize: 16,
  },

  [`& .${classes.SSVScheduleTitle}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 24,
  },

  [`& .${classes.SSVScheduleTitleText}`]: {
    fontSize: 20,
    marginLeft: 12,
    color: "#595959",
  },

  [`& .${classes.SSVScheduleInput}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    marginBottom: 40,
  },

  [`& .${classes.SSVScheduleDisplay}`]: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  [`& .${classes.SSVScheduleTimeFieldInput}`]: {
    width: "calc(25% - 36)",
    marginRight: 36,
    minWidth: 120,
  },

  [`& .${classes.SSVScheduleTimeFieldDisplay}`]: {
    flex: "1 1 calc(25% - 36px)",
    marginRight: 36,
    marginBottom: 16,
    minWidth: 88,
    "&:nth-child(4n)": {
      marginRight: 0,
    },
  },

  [`& .${classes.chip}`]: {
    textTransform: "capitalize",
  },

  [`& .${classes.daySelectContainer}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.daySelect}`]: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 38,
    maxWidth: "75rem",
  },
}));

const ScheduleIcon = styled(() => (
  <Schedule htmlColor={"#999999"} fontSize="inherit" />
))({});

const addTime = (times, newTime) => {
  const found = times.find((time) => time.start === newTime.start);
  if (found) return times;
  return [...times, newTime];
};

const DaysOfTheWeek = [
  { value: "sunday", label: "Sunday" },
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
];

const ScheduleSettingsView = ({ initialTimes, initialDays = [], onChange }) => {
  const [currentTime, setCurrentTime] = useState({ start: "0:00 am" });
  const { t } = useTranslation();

  let times = initialTimes;
  if (times.length > 12) {
    times = [...times].slice(0, 12);
  }

  const [savedDays, setSavedDays] = useState(initialDays);
  const [doFocus, setDoFocus] = useState(false);
  const disableEntry = times.length === 12;
  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  useEffect(() => {
    setSavedDays(initialDays);
  }, [initialDays]);
  // FIX ME Pull the following into a reducer once you figure out the correct way
  const [dayErrorState, setDayError] = useState({
    hasError: false,
    message: ERROR_SELECT_DAY,
  });
  const [timeErrorState, setTimeError] = useState({
    hasError: false,
    message: "",
  });

  const createDayError = () =>
    setDayError({ hasError: true, message: ERROR_SELECT_DAY });

  const clearDayError = () => setDayError({ hasError: false, message: "" });

  const createTimeError = (message) =>
    setTimeError({ hasError: true, message: message });

  const clearTimeError = () => setTimeError({ hasError: false, message: "" });

  const clearErrors = () => clearDayError() || clearTimeError();

  const onSaveClick = () => {
    clearErrors();
    if (!savedDays || savedDays.length === 0) {
      createDayError();
      return;
    }
    if (!currentTime.start || !isValidTime(currentTime.start)) {
      createTimeError(ERROR_INVALID_TIME);
      return;
    }
    if (times.find((time) => time.start === currentTime.start)) {
      createTimeError(ERROR_ALREADY_ADDED);
      return;
    }
    const updated = addTime(times, currentTime);
    onChange({ updatedTimes: updated, updatedDays: savedDays });
    setCurrentTime({ start: "0:00 am" });
    setDoFocus(true);

    setToast({
      show: true,
      type: "success",
      message: t("successfully-added"),
    });
  };

  return (
    <Root>
      <div className={classes.SSVScheduleTitle} data-cy="cleanouts-schedule">
        <div className={classes.SSVPenIcon}>
          <ScheduleIcon />
        </div>
        <span className={classes.SSVScheduleTitleText}>
          {t("scheduled-times")}
        </span>
      </div>
      <div className={classes.daySelectContainer}>
        <Select
          className={classes.daySelect}
          multiple
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          variant="standard"
          value={savedDays}
          error={dayErrorState.hasError}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          onChange={(event) => {
            const days = event.target.value;
            clearErrors();
            // // Days Times Result
            // // NO   NO    SAVE with empty
            // // YES  NO    SAVE with current
            // // NO   YES   NO
            // // YES  YES   SAVE with current

            if (times.length === 0 && (!days || days.length === 0)) {
              setSavedDays([]);
              onChange({ updatedTimes: times, updatedDays: [] });
              return;
            }
            if (times.length > 0 && (!days || days.length === 0)) {
              createDayError();
              setSavedDays([]);
              return;
            }

            setSavedDays(days);
            onChange({ updatedTimes: times, updatedDays: days });
            return;
          }}
        >
          {DaysOfTheWeek.map((day) => (
            <MenuItem key={day.label} value={day.value}>
              {day.label}
            </MenuItem>
          ))}
        </Select>
        {dayErrorState.hasError ? (
          <span className={classes.warningText}>{dayErrorState.message}</span>
        ) : (
          ""
        )}
      </div>
      <div className={classes.SSVScheduleInput}>
        <div
          className={classes.SSVScheduleTimeFieldInput}
          data-cy="time-field-input"
        >
          <TimeField
            afterRender={(inputRef) => {
              if (doFocus) {
                inputRef.current.focus();
                setDoFocus(false);
              }
            }}
            error={timeErrorState.hasError}
            disabled={disableEntry}
            value={currentTime.start}
            onChange={(event, value) => setCurrentTime({ start: value })}
          />
          {timeErrorState.hasError ? (
            <span classname={classes.warningText}>
              {timeErrorState.message}
            </span>
          ) : (
            ""
          )}
        </div>
        <Button
          data-thirdparty={`schedule-settings-button-cleanouts`}
          data-cy={`schedule-settings-button-cleanouts`}
          disabled={disableEntry}
          onClick={onSaveClick}
        >
          {t("add-time")}
        </Button>
      </div>
      <div className={classes.SSVScheduleDisplay}>
        {times.map(({ start }, index) => {
          return (
            <div className={classes.SSVScheduleTimeFieldDisplay} key={start}>
              <TimeFieldDisplay
                tag={`schedule-settings-button-remove-cleanouts-${index}`}
                time={start}
                onClick={() => {
                  const updated = times.filter(
                    (time) => time && time.start !== start
                  );
                  onChange({ updatedTimes: updated, updatedDays: savedDays });
                  if (!savedDays.length) clearDayError();
                }}
              />
            </div>
          );
        })}
        {Array.from(Array(12 - times.length)).map((_, index) => {
          return (
            <div className={classes.SSVScheduleTimeFieldDisplay} key={index}>
              <TimeFieldDisplay />
            </div>
          );
        })}
      </div>
      <Toast toast={toast} closeToast={closeToast} />
    </Root>
  );
};

export default ScheduleSettingsView;
