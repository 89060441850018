import axios from "axios";

const NO_SCHEDULE_ERRORS = [
  "no pens have any scheduled deliveries",
  "no pens have any scheduled pushups",
  "no pens have any scheduled milkings",
  "no scheduled deliveries for yesterday",
  "no scheduled milkings for yesterday",
  "no scheduled pushups for yesterday",
  "no milking schedule for pen",
];

export async function getPensProductivitySummary({ queryKey }) {
  const [_, params] = queryKey;
  const response = await axios.get(
    `${window._env_.API_URL}/private/herd-welfare/pens`,
    {
      params,
    }
  );
  return response.data;
}

export async function getTimeOnFeet({ queryKey }) {
  const [, params] = queryKey;
  const { startDate, endDate } = params;
  const response = await axios.get(
    `${window._env_.API_URL}/private/behavior/pens/${params.penId}/time-on-feet`,
    {
      params: {
        startDate,
        endDate,
      },
    }
  );
  return response.data;
}

export async function getPenBehaviorBreakdown({ queryKey }) {
  try {
    const [breakdown, params] = queryKey;
    const { penId, type, ...dates } = params;
    const response = await axios.get(
      `${window._env_.API_URL}/private/behavior/pens/${penId}/${breakdown}${
        type && `/${type}`
      }`,
      { params: dates }
    );
    return response.data;
  } catch (error) {
    const message = error?.response?.data?.error;
    if (NO_SCHEDULE_ERRORS.includes(message)) {
      return { message: "no schedules set" };
    }
    throw error;
  }
}
