import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { colors } from "../../../CommonComponents/UI/theme";
import { TIMELINE_OFFSET, TIMELINE_OFFSET_MOBILE } from "./constants";

const PREFIX = "ChartHeader";

const classes = {
  timeContainer: `${PREFIX}-timeContainer`,
  timesContainer: `${PREFIX}-timesContainer`,
  timeItem: `${PREFIX}-timeItem`,
  headerContainer: `${PREFIX}-headerContainer`,
  squareContainer: `${PREFIX}-squareContainer`,
  square: `${PREFIX}-square`,
  box: `${PREFIX}-box`,
};

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "isResponsive",
})(({ isResponsive, mins, theme }) => ({
  [`& .${classes.timeContainer}`]: {
    width: "100%",
    marginTop: "16px",
    marginBottom: "8px",
    marginLeft: "-50%",
    textAlign: "center",
    "@media print": {
      marginTop: 0,
    },
  },

  [`& .${classes.timesContainer}`]: {
    display: "inline-block",
    whiteSpace: "nowrap",
    borderLeft: `1px solid #e1e1e1`,
    overflow: "hidden",
    width: `calc(100% - ${TIMELINE_OFFSET_MOBILE})`,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      width: `calc(100% - ${TIMELINE_OFFSET})`,
    },
  },

  [`& .${classes.timeItem}`]: {
    display: "inline-block",
    width: "calc(100% / 24)",
    textAlign: "left",
    fontSize: 8,
    "&:first-of-type": {
      width: `calc((100% / 24) * (60 - ${mins})/60)`,
    },
    "&:last-of-type": {
      width: `calc((100% / 24) * ${mins}/60)`,
    },

    "&:nth-of-type(2n + 1)": {
      visibility: "hidden",
    },

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      "&:nth-of-type(2n + 1)": {
        visibility: "visible",
      },
    },
    "@media print": {
      "&:nth-of-type(2n + 1)": {
        visibility: "visible",
      },
    },
    [isResponsive ? theme.breakpoints.up("md") : null]: {
      fontSize: 10,
    },
  },

  [`&.${classes.headerContainer}`]: {
    display: "flex",

    borderBottom: "1px solid #e9e9e9",
  },

  [`& .${classes.squareContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 11,
    width: 60,
    borderRight: "none",
    borderLeft: `1px solid #e1e1e1`,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      width: 70,
      borderRight: `1px solid #e1e1e1`,
    },
  },

  [`& .${classes.square}`]: {
    height: 10,
    width: 10,
    backgroundColor: colors.red,
  },

  [`& .${classes.box}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,

    [isResponsive ? theme.breakpoints.up("md") : null]: {
      width: 73,
    },
  },
}));

const getHours = (hour, ampm) => {
  let hours = [{ hour: "", ampm: "" }];
  for (let i = 0; i < 24; i++) {
    if (hour === 11) {
      ampm = ampm === "PM" ? "AM" : "PM";
      hour++;
    } else if (hour === 12) {
      hour = 1;
    } else {
      hour++;
    }
    hours.push({ hour, ampm });
  }

  return hours;
};

const ChartHeader = ({ hour, mins, ampm, isResponsive }) => {
  const { t } = useTranslation();
  const hours = useMemo(() => getHours(hour, ampm), [hour, ampm]);

  return (
    <Root
      isResponsive={isResponsive}
      className={classes.headerContainer}
      mins={mins}
      hour={hour}
    >
      <div className={classes.box}>Pen</div>

      <div className={classes.timesContainer}>
        {hours.map(({ hour, ampm }, index) => (
          <div
            className={classes.timeItem}
            key={`FeedStatus-${hour}-${index}`}
            data-cy="time-item"
            data-testid="time-item"
          >
            <div className={classes.timeContainer}>
              <div className={classes.time}>{hour}</div>
              <span>{ampm}</span>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.squareContainer}>
        <div className={classes.square} />
        {t("feed-low")}
      </div>
    </Root>
  );
};

export default ChartHeader;

ChartHeader.propTypes = {
  hour: PropTypes.number.isRequired,
  mins: PropTypes.number.isRequired,
  ampm: PropTypes.string.isRequired,
};
