import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useMutation, useQueryClient } from "react-query";
import { Check, Create, Close, Delete } from "@mui/icons-material";
import {
  Checkbox,
  Box,
  Divider,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  FormControlLabel,
  Typography,
} from "@mui/material";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import LoadingSpinner from "../../../../CommonComponents/UI/LoadingSpinner";
import DeleteModal from "../../../../CommonComponents/UI/DeleteModal";
import TimeField from "./TimeField";
import PhoneField from "./PhoneField";
import useRecipientManagement from "../../../utils/useRecipientManagement";
import useEditableRow from "../../../utils/useEditableRow";
import { editRecipient } from "../../../services/alerts";
import days from "./days";
import "react-phone-input-2/lib/material.css";

const PREFIX = "RecipientsRow";

const classes = {
  row: `${PREFIX}-row`,
  rowExpanded: `${PREFIX}-rowExpanded`,
  input: `${PREFIX}-input`,
  iconsWrapper: `${PREFIX}-iconsWrapper`,
  checkbox: `${PREFIX}-checkbox`,
  checkboxExpanded: `${PREFIX}-checkboxExpanded`,
  label: `${PREFIX}-label`,
  timeWrapper: `${PREFIX}-timeWrapper`,
  helperText: `${PREFIX}-helperText`,
  cell: `${PREFIX}-cell`,
  cellCollapsed: `${PREFIX}-cellCollapsed`,
  timeCell: `${PREFIX}-timeCell`,
  checkboxCell: `${PREFIX}-checkboxCell`,
  phoneInput: `${PREFIX}-phoneInput`,
  styledText: `${PREFIX}-styledText`,
  textField: `${PREFIX}-textField`,
  mobileText: `${PREFIX}-mobileText`,
  divider: `${PREFIX}-divider`,
  grid: `${PREFIX}-grid`,
  gridItem: `${PREFIX}-gridItem`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  daysCell: `${PREFIX}-daysCell`,
  mobileEdit: `${PREFIX}-mobileEdit`,
  desktopEdit: `${PREFIX}-desktopEdit`,
};

const StyledTableRow = styled(TableRow)(({ isEditMode, theme }) => ({
  [`&.${classes.row}`]: {
    "& td:first-of-type": {
      padding: `${isEditMode ? "16px 0" : "16px"}`,
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      "& td:last-of-type": {
        padding: "16px 0",
        maxWidth: "10%",
      },
    },
  },

  [`&.${classes.rowExpanded}`]: {
    "& td": {
      borderBottom: "none",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& td": {
        borderBottom: "none",
      },
    },
  },

  [`& .${classes.input}`]: {
    height: 57,
  },

  [`& .${classes.iconsWrapper}`]: {
    marginLeft: "auto",
    width: 96,
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      margin: "-16px 0",
    },
  },

  [`& .${classes.checkbox}`]: {
    margin: "-16px 0",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  [`& .${classes.checkboxExpanded}`]: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },

  [`& .${classes.label}`]: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  [`& .${classes.timeWrapper}`]: {
    minWidth: 100,
    position: "relative",
    top: 4,
    padding: "26px 0 10px 0",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },

  [`& .${classes.helperText}`]: {
    marginLeft: 6,
  },

  [`& .${classes.cell}`]: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    padding: "16px 8px",
    [theme.breakpoints.up("lg")]: {
      padding: 16,
    },
  },

  [`& .${classes.cellCollapsed}`]: {
    [theme.breakpoints.down("md")]: {
      "&:first-of-type": {
        display: "table",
        tableLayout: "fixed",
        width: "100%",
      },
    },
  },

  [`& .${classes.timeCell}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: `${isEditMode ? "250px" : "auto"}`,
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
    },
  },

  [`& .${classes.checkboxCell}`]: {
    padding: "16px 0",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },

  [`& .${classes.phoneInput}`]: {
    width: 220,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  [`& .${classes.styledText}`]: {
    [theme.breakpoints.down("md")]: {
      height: 40,
      lineHeight: "80px",
      fontSize: 20,
      color: "#595959",
      display: "table-cell",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },

  [`& .${classes.textField}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  [`& .${classes.mobileText}`]: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  [`& .${classes.divider}`]: {
    marginTop: 10,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  [`& .${classes.grid}`]: {
    flexDirection: "column",
    maxHeight: 200,
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
      width: "auto",
      flexWrap: "nowrap",
    },
  },

  [`& .${classes.gridItem}`]: {
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
      maxWidth: "none",
    },
  },

  [`&.${classes.tableRow}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
    },
  },

  [`& .${classes.tableCell}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  [`& .${classes.daysCell}`]: {
    [theme.breakpoints.down("md")]: {
      borderBottom: "none",
    },
  },

  [`&.${classes.mobileEdit}`]: {
    "& td": {
      paddingRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  [`& .${classes.desktopEdit}`]: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const DELETEMODALPREFIX = "DeleteModal";

const deleteModalClasses = {
  title: `${DELETEMODALPREFIX}-title`,
  information: `${DELETEMODALPREFIX}-information`,
  strong: `${DELETEMODALPREFIX}-strong`,
};
const StyledDeleteModal = styled(DeleteModal)(() => ({
  [`& .${classes.title}`]: {
    fontSize: 27,
    margin: 0,
    color: "#595959",
  },
  [`& .${classes.information}`]: {
    fontSize: 16,
    textAlign: "center",
  },
  [`& .${classes.strong}`]: {
    color: "#595959",
    whiteSpace: "nowrap",
  },
}));

dayjs.extend(customParseFormat);

const RecipientsRow = ({
  item,
  headers,
  setToast,
  deleteRecipient,
  availableAlerts,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    recipientState,
    reset,
    toggleAlerts,
    toggleDays,
    onInputChange,
    onPhoneChange,
    onPhoneBlur,
    convertToISOTime,
  } = useRecipientManagement(item);

  const {
    alerts_enabled,
    days_enabled,
    name,
    id,
    phone_number,
    startTime,
    endTime,
  } = recipientState;

  const {
    isEditMode,
    isModalOpen,
    toggleEditMode,
    toggleModalOpen,
  } = useEditableRow();

  const onDelete = () => deleteRecipient({ id });

  const { isLoading, mutate: updateRecipient } = useMutation(editRecipient, {
    onSuccess: (response) => {
      queryClient.setQueryData("alerts/recipients", (oldData) => {
        const indexToUpdate = oldData?.findIndex(
          ({ id }) => id === response.id
        );
        let newData = [...oldData];
        newData[indexToUpdate] = response;
        return newData;
      });
    },
    onError: () => {
      setToast({
        show: true,
        type: "error",
        message: "We had a problem updating the recipient, try again",
      });
    },
  });

  const onUpdate = () => {
    updateRecipient(convertToISOTime(), {
      onSuccess: toggleEditMode,
    });
  };

  const onCancel = () => {
    reset();
    toggleEditMode();
  };

  const renderSaveIcon = () => (
    <>
      {isLoading ? (
        <LoadingSpinner className={classes.loadingSpinner} size={30} />
      ) : (
        <IconButton onClick={onUpdate} aria-label="save" size="large">
          <Check data-cy="save-icon" />
        </IconButton>
      )}
    </>
  );

  const editSection = (variant) => (
    <div className={classes.iconsWrapper}>
      {isEditMode ? (
        <div
          className={classNames({
            [classes.desktopEdit]: variant === "desktop",
          })}
        >
          {renderSaveIcon()}
          <IconButton aria-label="cancel" onClick={onCancel} size="large">
            <Close data-cy="cancel-icon" />
          </IconButton>
        </div>
      ) : (
        <>
          <IconButton
            aria-label="edit"
            onClick={toggleEditMode}
            className={classes.editIcon}
            size="large"
          >
            <Create />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={toggleModalOpen}
            size="large"
          >
            <Delete data-cy="delete-icon" />
          </IconButton>
        </>
      )}
    </div>
  );

  const checkboxes = Object.keys(availableAlerts).reduce((map, key) => {
    map[key] = (
      <FormControlLabel
        labelPlacement="end"
        key={key}
        classes={{
          root: classNames(classes.checkbox, {
            [classes.checkboxExpanded]: isEditMode,
          }),
          label: classes.label,
        }}
        control={
          <Checkbox
            name={availableAlerts[key].value}
            inputProps={{
              "data-cy": availableAlerts[key].value,
            }}
            color="primary"
            disabled={!isEditMode}
            checked={alerts_enabled.includes(availableAlerts[key].value)}
            onChange={toggleAlerts}
          />
        }
        label={availableAlerts[key].label}
      />
    );
    return map;
  }, {});

  const row = {
    key: id,
    ...checkboxes,
    name: (
      <>
        {isEditMode ? (
          <TextField
            name="name"
            placeholder={t("enter-a-name")}
            label={t("name")}
            variant="outlined"
            size="small"
            value={name}
            onChange={onInputChange}
            inputProps={{
              "aria-label": "name",
            }}
            className={classes.textField}
            InputProps={{
              className: classes.input,
            }}
          />
        ) : (
          <span className={classes.styledText}>{name}</span>
        )}
      </>
    ),
    phoneNumber: (
      <>
        {isEditMode ? (
          <div className={classes.phoneInput}>
            <PhoneField
              onPhoneChange={onPhoneChange}
              onPhoneBlur={onPhoneBlur}
              value={phone_number}
            />
          </div>
        ) : (
          <span className={classes.mobileText}>{`${phone_number}`}</span>
        )}
      </>
    ),
    alertsTime: (
      <>
        {isEditMode ? (
          <div className={classes.timeWrapper}>
            <TimeField
              id="startTime"
              value={startTime}
              onInputChange={onInputChange}
            />

            <TimeField
              id="endTime"
              value={endTime}
              onInputChange={onInputChange}
            />
          </div>
        ) : (
          <>
            <span
              className={classes.mobileText}
            >{`${startTime} - ${endTime}`}</span>
            <span className={classes.mobileText}>
              {days_enabled.includes("everyday")
                ? "everyday"
                : days_enabled.map((day) => days[day].short).join(", ")}
            </span>
          </>
        )}
      </>
    ),

    edit: editSection("desktop"),
  };

  return (
    <>
      <StyledTableRow
        key={row.key}
        onClick={row.onClick}
        isEditMode={isEditMode}
        className={classNames(classes.row, {
          [classes.rowExpanded]: isEditMode,
        })}
      >
        {headers.map((header) => {
          return (
            <TableCell
              className={classNames(classes.cell, {
                [classes[header.cellClass]]: classes[header.cellClass],
                [classes.cellCollapsed]: !isEditMode,
              })}
              key={`${header.id}-${row.key}`}
            >
              {row[header.id]}
            </TableCell>
          );
        })}
      </StyledTableRow>
      {isEditMode && (
        <>
          <StyledTableRow isEditMode={isEditMode} className={classes.tableRow}>
            <TableCell
              className={`${classes.tableCell} ${classes.daysCell}`}
              colSpan="100%"
            >
              <Typography>Send Alerts:</Typography>
              <Box mt={1}>
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      name="everyday"
                      inputProps={{
                        "data-cy": "everyday",
                      }}
                      color="primary"
                      checked={days_enabled.includes("everyday")}
                      onChange={toggleDays}
                    />
                  }
                  label="Everyday"
                />
                <Divider className={classes.divider} />
                <Grid container className={classes.grid}>
                  {Object.keys(days).map((key) => (
                    <Grid item xs={6} key={key} className={classes.gridItem}>
                      <FormControlLabel
                        labelPlacement="end"
                        key={key}
                        control={
                          <Checkbox
                            name={key}
                            inputProps={{
                              "data-cy": key,
                            }}
                            color="primary"
                            disabled={days_enabled.includes("everyday")}
                            checked={days_enabled.includes(key)}
                            onChange={toggleDays}
                          />
                        }
                        label={days[key].long}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </TableCell>
          </StyledTableRow>
          <StyledTableRow
            className={`${classes.tableRow} ${classes.mobileEdit}`}
          >
            <TableCell className={classes.tableCell} colSpan="100%">
              {editSection("mobile")}
            </TableCell>
          </StyledTableRow>
        </>
      )}
      <StyledDeleteModal
        onConfirmDelete={onDelete}
        onCancel={toggleModalOpen}
        isOpen={isModalOpen}
      >
        <h4 className={deleteModalClasses.title}>{t("remove-recipient")}</h4>
        <p className={deleteModalClasses.information}>
          {t("are-you-sure-you-want-to-remove")} <br />
          <strong className={deleteModalClasses.strong}>{name}</strong>?
        </p>
      </StyledDeleteModal>
    </>
  );
};
export default RecipientsRow;

RecipientsRow.propTypes = {
  item: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  setToast: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
  availableAlerts: PropTypes.object.isRequired,
};
