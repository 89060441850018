import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Targets from "./Pages/Targets/Targets";
import Accounts from "./Pages/Accounts";
import Alerts from "./Pages/Alerts/Alerts";
import { useActiveModules } from "../App/useActiveModules";

function Settings() {
  const { path } = useRouteMatch();
  const { features } = useActiveModules();

  return (
    <Switch>
      <Route path={`${path}/accounts`} component={Accounts} exact />
      <Route path={`${path}/targets`} component={Targets} />
      {features.includes("alerts") && (
        <Route path={`${path}/alerts/:sectionId?`} component={Alerts} />
      )}
    </Switch>
  );
}

export default Settings;
