import React from "react";
import ReactDOM from "react-dom";
import "../index.css";
import App from "../App/index";
import TestClient from "./TestClient";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

// NB: This should only be started up as part of Cypress where endpoints are mocked

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Route
        render={() => <App history={history} testClient={TestClient} />}
        path="/"
      />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
