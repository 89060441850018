import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import PenSummary from "./Pages/PenSummary";
import Pen from "./Pages/Pen";
import LiveFeedStatus from "./Pages/LiveFeedStatus";
import ScheduleSettings from "./Pages/ScheduleSettings";

function FeedbunkManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} component={LiveFeedStatus} exact />
      <Route
        path={`${path}/live-feed-status`}
        component={LiveFeedStatus}
        exact
      />
      <Route path={`${path}/pen-summary`} component={PenSummary} exact />
      <Route path={`${path}/pens/:id/:sectionId?`} component={Pen} />
      <Route
        path={`${path}/schedule/pens/:id/:eventType`}
        component={ScheduleSettings}
        exact
      />
      <Redirect to={`${path}`} />
    </Switch>
  );
}

export default FeedbunkManagement;
