import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../CommonComponents/UI/Tooltip";
import TooltipContent from "./TooltipContent";

const PREFIX = "FeedStatusRow";

const classes = {
  styledWindow: `${PREFIX}-styledWindow`,
  tooltipInner: `${PREFIX}-tooltipInner`,
};

const Content = styled("div")(() => ({
  [`&.${classes.tooltipInner}`]: {
    display: "inline-block",
  },

  [`& .${classes.styledWindow}`]: {
    display: "block",
    width: "100%",
  },
}));

const Window = (props) => {
  const { window } = props;
  const { t } = useTranslation();

  return (
    <Tooltip
      sx={{ padding: 0, margin: 0 }}
      content={
        <TooltipContent
          status={t(window.status)}
          duration={window.duration}
          startTime={window.startTime}
          endTime={window.endTime}
          date={window.date}
        />
      }
    >
      <Content
        className={classes.tooltipInner}
        style={{
          width: `calc((${props.window.width * props.modifier} / ${
            props.rowWidth
          }) * 100%)`,
        }}
      >
        <div
          className={classes.styledWindow}
          style={{ height: props.barHeight, background: props.window.color }}
        />
      </Content>
    </Tooltip>
  );
};

const FeedStatusRow = ({ windows, barHeight, rowWidth, modifier = 1 }) => {
  return (
    <>
      {windows.map((window) => (
        <React.Fragment key={`${window.date} - ${window.startTime}`}>
          <Window
            barHeight={barHeight}
            rowWidth={rowWidth}
            window={window}
            modifier={modifier}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default FeedStatusRow;
