import React from "react";
import PropTypes from "prop-types";

const IContextProps = {
  state: PropTypes.StateType,
  dispatch: PropTypes.Dispatch,
};

const FoPenDetailsContext = React.createContext(IContextProps);

export function useFoPenDetailsContext() {
  return React.useContext(FoPenDetailsContext);
}

export default FoPenDetailsContext;
