import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import ReportsList from "./ReportsList";
import Report from "./Report";

function Reports() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={ReportsList} exact />
      <Route path={`${path}/:id`} component={Report} exact />
      <Redirect to={`${path}/all-reports`} />
    </Switch>
  );
}

export default Reports;
