import CowComfort from "./CowComfort";
import TimeOnFeet from "./TimeOnFeet";

const sections = [
  {
    name: "Cow Comfort",
    id: "cowComfort",
    translationKey: "cow-comfort",
    component: CowComfort,
    route: "/cow-comfort",
  },
  {
    name: "Total Time on Feet",
    id: "timeOnFeet",
    translationKey: "total-time-on-feet",
    component: TimeOnFeet,
    route: "/time-on-feet",
  },
];

export default sections;
