import React from "react";
import { IMaskInput } from "react-imask";
import TextField from "@mui/material/TextField";

const time = /^([0-9][0-9])h ([0-9][0-9])m$/;

const conversionIn = (value) => {
  const minutes = (value % 60).toString().padStart(2, "0");
  const hours = Math.floor(value / 60)
    .toString()
    .padStart(2, "0");
  return `${hours}${minutes}`;
};

const conversionOut = (value) => {
  const [, hours, minutes] = time.exec(value);
  const maxMinutes = Math.min(parseInt(minutes, 10), 59);
  const total = hours * 60 + maxMinutes;
  return total;
};

const MaskedInput = React.forwardRef(({ width, onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    inputRef={ref}
    mask="HHh MMm"
    blocks={{
      HH: {
        mask: "00",
        placeholderChar: "0",
      },
      MM: {
        mask: "00",
        placeholderChar: "0",
        max: 59,
      },
    }}
    onAccept={(value, mask) => {
      onChange(value, mask);
    }}
    overwrite={true}
    unmask={false}
    style={{ width }}
    lazy={false}
  />
));

const TimeInput = (props) => {
  const { onChange: changeCallback, value, ...rest } = props;
  return (
    <TextField
      {...rest}
      variant="standard"
      value={conversionIn(value)}
      InputProps={{
        inputComponent: MaskedInput,
        onChange: (value) => {
          const converted = conversionOut(value);
          changeCallback({ target: { name: props.name, value: converted } });
        },
      }}
    />
  );
};

export default TimeInput;
