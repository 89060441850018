import { useState, useEffect } from "react";
import { useAccountDetails } from "../../App/useAccountDetails";
import { READ_ONLY_ROLE } from "../../constants/permissions";

const useReadOnly = () => {
  const { permissions } = useAccountDetails();
  const initialValue = permissions.includes(READ_ONLY_ROLE);
  const [isReadOnly, setIsReadOnly] = useState(initialValue);

  useEffect(() => {
    setIsReadOnly(permissions.includes(READ_ONLY_ROLE));
  }, [permissions]);

  return isReadOnly;
};

export default useReadOnly;
