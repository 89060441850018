export const NutrtionTargetGroupsConfig = [
  {
    title: "Feed Available over 24 hours",
    description: "Duration cows should have access to feed over 24 hour day",
    key: "feed_availability_target",
    titleTranslationKey: "feed-availability-24hrs",
    descriptionTranslationKey: "target-feed-avail-24hrs-subtitle"
  },
  {
    title: "Feed Available After Milking",
    description:
      "Duration cows should have access to feed in the 60 mins after milking",
    key: "feed_availability_post_milking",
    titleTranslationKey: "feed-availability-after-milking",
    descriptionTranslationKey: "target-feed-avail-after-milking-subtitle"
  },
  {
    title: "Late Deliveries",
    description: "Number of late deliveries allowed per day",
    key: "late_feed_deliveries_target",
    titleTranslationKey: "late-deliveries",
    descriptionTranslationKey: "target-late-deliveries-subtitle"
  },
  {
    title: "Push ups complete",
    description: "% of push ups that should be completed per day",
    key: "push_up_completed_target",
    titleTranslationKey: "push-ups-complete",
    descriptionTranslationKey: "target-push-ups-complete-subtitle"
  },
];

export const BehaviorTargetGroupsConfig = [
  {
    title: "Cow Comfort",
    description:
      "% of cows that should be lying down if in contact with a stall",
    key: "cow_comfort_index_target",
    titleTranslationKey: "cow-comfort",
    descriptionTranslationKey: "target-cow-comfort-subtitle"
  },
  {
    title: "Lying in Bed",
    description: "Minimum duration cows should spend lying in bed",
    key: "lying_in_bed_target",
    titleTranslationKey: "lying-in-bed",
    descriptionTranslationKey: "target-lying-in-bed-subtitle"
  },
  {
    title: "Out of Pen",
    description: "Maximum duration cows should spend outside pen",
    key: "out_of_pen_target",
    titleTranslationKey: "out-of-pen",
    descriptionTranslationKey: "target-out-of-pen-subtitle"
  },
  {
    title: "Standing time",
    description: "Maximum duration cows should spend standing",
    key: "standing_other_target",
    titleTranslationKey: "standing-time",
    descriptionTranslationKey: "target-standing-time-other-subtitle"
  },
  {
    title: "Time at feedbunk",
    description: "Maximum duration cows should spend standing at the feedbunk",
    key: "standing_feedbunk_target",
    titleTranslationKey: "time-at-feedbunk",
    descriptionTranslationKey: "target-time-at-feedbunk-subtitle"
  },
];
