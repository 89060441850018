import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import MuiLink from "@mui/material/Link";
import theme from "./theme";
import { Link as RouterLink } from "react-router-dom";

const InternalLink = React.forwardRef((props, ref) => (
  <MuiLink
    {...props}
    ref={ref}
    to={props.url}
    underline="hover"
    component={RouterLink}
  >
    {props.text}
  </MuiLink>
));

const ExternalLink = React.forwardRef((props, ref) => (
  <MuiLink {...props} href={props.url} underline="hover" target="_blank">
    {props.text}
  </MuiLink>
));

const Link = ({ external, className, ...props }) => {
  const Component = external ? ExternalLink : InternalLink;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Component {...props} className={className} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Link;

Link.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  external: PropTypes.bool.isRequired,
};

Link.defaultProps = {
  external: false,
};
