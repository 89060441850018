import { styled } from "@mui/material/styles";
const PREFIX = "Button";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("button")(() => ({
  [`&.${classes.root}`]: {
    background: "#e69e6c",
    color: "#203354",
    fontSize: 20,
    height: "auto",
    minHeight: "40px",
    width: "auto",
    borderRadius: 20,
    border: "none",
    cursor: "pointer",
    outline: "none",
    fontWeight: "bold",
    padding: "7px 10px",
    "&:hover": {
      background: "#da7025",
    },
    "&:focus": {
      background: "#e69e6c",
      border: "3px solid #186490",
    },
    "&:active": {
      background: "#e69e6c",
      border: "none",
    },
    "&:disabled": {
      background: "#e9e9e9",
      color: "#cccccc",
    },
  },
}));

const Button = (props) => {
  return (
    <Root {...props} className={classes.root}>
      {props.children}
    </Root>
  );
};

export default Button;
