import { colors } from "../../../../CommonComponents/UI/theme";

const kpiInfo = {
  pushUpsComplete: {
    translationKey: "push-ups-completed",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
  deliveriesOnTime: {
    translationKey: "1st-deliveries-on-time",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
  lowFeedTime: {
    translationKey: "low-feed-time-avg",
    up: colors.valenciaRed,
    down: colors.silverTreeGreen,
  },
};

export default kpiInfo;
