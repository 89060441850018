import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { getOpsTrends } from "../services/trends";
import LoadingSpinner from "../../CommonComponents/UI/LoadingSpinner";
import milkingKpiInfo from "../../PeopleProtocols/Pages/MilkingOperations/utils/kpiInfo";
import ErrorView from "./ErrorView";
import { colors } from "../../CommonComponents/UI/theme";
import { useActiveModules } from "../../App/useActiveModules";
import KPI from "./KPI";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: 10,
  flexWrap: "wrap",

  [theme.breakpoints.up("md")]: {
    flexWrap: "nowrap",
  },
}));

const worstPens = [
  { name: "Pen 2", id: 2, value: "86%" },
  { name: "Pen 3", id: 3, value: "96%" },
];

const dataErrorView = () => (
  <ErrorView messageKey="waiting-for-data-to-process" />
);

const kpiFlags = {
  pushups: "feedingOperations",
  deliveries_on_time: "feedingOperations",
  onTime: "milkingOperations",
  duration: "milkingOperations",
};

const kpiInfo = {
  ...milkingKpiInfo,
  protocolPushups: {
    translationKey: "push-ups-completed",
    up: colors.silverTreeGreen,
    down: colors.valenciaRed,
  },
};

const PeopleProtocols = () => {
  const { flags } = useActiveModules();
  const { data } = useQuery(
    ["opsTrends", { period: "last7Days", includeWorstPens: true }],
    getOpsTrends,
    {
      refetchOnWindowFocus: false,
    }
  );

  const trends = data.summaries.farm
    .filter((trend) => trend.worst_pens && flags[kpiFlags[trend.kpi]])
    .map((trend) => {
      if (trend.kpi === "pushups") {
        return {
          ...trend,
          kpi: "protocolPushups",
        };
      }

      return trend;
    });

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ErrorBoundary FallbackComponent={dataErrorView}>
        <StyledBox>
          {trends.map(
            (item) =>
              kpiInfo[item.kpi] && (
                <KPI trend={item} worstPens={worstPens} kpiInfo={kpiInfo} />
              )
          )}
        </StyledBox>
      </ErrorBoundary>
    </Suspense>
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <PeopleProtocols />
    </ErrorBoundary>
  </Suspense>
);
