import axios from "axios";

export async function getGroupTargetSettings() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/settings/groups/targets`
  );
  return response.data;
}

export async function setGroupTargetSettings(payload) {
  const response = await axios.post(
    `${window._env_.API_URL}/private/settings/groups/targets`,
    payload
  );
  return response.data;
}
