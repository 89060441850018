export const flexColumnMixIn = {
  display: "flex",
  flexDirection: "column",
};

export const flexRowMixIn = {
  display: "flex",
  flexDirection: "row",
};

export const flexCenterColumnMixIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const flexEndMixIn = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
};

export const flexSpaceBetweenMixIn = {
  justifyContent: "space-between",
};
