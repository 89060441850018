import axios from "axios";

export async function getPens({ queryKey }) {
  const [, params] = queryKey;
  const response = await axios.get(
    `${window._env_.API_URL}/private/feedbunk-management/pens`,
    {
      params,
    }
  );

  return response.data;
}

export async function getTrends({ queryKey }) {
  const [, params] = queryKey;
  const response = await axios.get(
    `${window._env_.API_URL}/private/feedbunk-management/trends`,
    {
      params,
    }
  );

  return response.data;
}

export async function getPenFeedStatusWindows({ queryKey }) {
  const [, params] = queryKey;
  const { id, ...rest } = params;

  const response = await axios.get(
    `${window._env_.API_URL}/private/nutrition/pens/${id}/feed-status-windows`,
    {
      params: rest,
    }
  );

  return response.data;
}

export async function getPenEvents({ queryKey }) {
  const [, params] = queryKey;
  const { id, startDate, endDate } = params;

  const response = await axios.get(
    `${window._env_.API_URL}/private/nutrition/pens/${id}/events?startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
}

export async function getFeedStatusWindowsAndEvents(props) {
  return Promise.all([getPenEvents(props), getPenFeedStatusWindows(props)]);
}

export async function get24hrFeedStatus() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/nutrition/pens/feed-availability?showSectionBreakdown=true`
  );
  return response.data;
}
