import React, { useContext, useState } from "react";

const ExpandedContext = React.createContext();

export const useExpandedState = () => useContext(ExpandedContext);

const ExpandedProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const value = {
    isExpanded,
    setIsExpanded,
  };

  return (
    <ExpandedContext.Provider value={value}>
      {children}
    </ExpandedContext.Provider>
  );
};

export default ExpandedProvider;
