import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

const PREFIX = "CardContent";

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.card}`]: {
    marginBottom: 20,
  },

  [`& .${classes.title}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
  },

  [`&.${classes.content}`]: {
    padding: theme.spacing(2.5),

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5),
    },
  },
}));

const CardContent = ({ children }) => {
  return (
    <StyledGrid container className={classes.content} data-cy="card-content">
      {children}
    </StyledGrid>
  );
};

export default CardContent;

CardContent.propTypes = {
  children: PropTypes.node.isRequired,
};
