import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import Box from "@mui/material/Box";
import Header from "../../../CommonComponents/UI/Header";
import DetailsTab from "./DetailsTab";
import SettingsView from "./ScheduleSettingsMainView";
import SettingsSubView from "./ScheduleSettingsSubView";
import SettingsCleanoutSubView from "./ScheduleSettingsCleanoutSubView";
import SettingsMilkingView from "./ScheduleSettingsMilkingView";
import { useQuery, useMutation } from "react-query";
import Breadcrumbs from "./Breadcrumb";
import { useAccountDetails } from "../../../App/useAccountDetails";
import { useActiveModules } from "../../../App/useActiveModules";

import { queryClient } from "../../../App/index";
import { getSchedules, setSchedules } from "../../services/settings";
import PenNavigator from "../../../CommonComponents/UI/PenNavigator";

const PREFIX = "ScheduleSettings";

const classes = {
  container: `${PREFIX}-container`,
  scheduleSettingsSectionHeader: `${PREFIX}-scheduleSettingsSectionHeader`,
  scheduleSettingsDetailsContainer: `${PREFIX}-scheduleSettingsDetailsContainer`,
  select: `${PREFIX}-select`,
};

const Container = styled("section")(({ theme }) => ({
  [`&.${classes.container}`]: {
    height: "100%",
    backgroundColor: "#f8f8f8",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },

  [`& .${classes.scheduleSettingsSectionHeader}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.scheduleSettingsDetailsContainer}`]: {
    marginTop: 24,
    height: "calc(100% - 64px)",
  },

  [`& .${classes.select}`]: {
    marginLeft: "auto",
  },
}));

const DeliveryTabConfiguration = {
  id: "deliveries",
  activeColor: "#a47af4",
  iconKey: "delivery",
  title: "Deliveries",
};

const CleanoutTabConfiguration = {
  id: "cleanouts",
  activeColor: "#e69e6c",
  iconKey: "cleanout",
  title: "Cleanouts",
};

const PushupTabConfiguration = {
  id: "pushups",
  activeColor: "#00bfff",
  iconKey: "pushup",
  title: "Push-ups",
};

const MilkingTabConfiguration = {
  id: "milking",
  activeColor: "#fd93da",
  iconKey: "milking",
  title: "Milking",
};

const getSchedule = (schedules, event) => {
  return schedules.filter(({ eventType }) => eventType === event)[0] || {};
};

const isoToTwelveHour = (isoTime) => {
  if (!isoTime) return null;
  let [hours, minutes] = isoTime.split(":");
  hours = Number(hours);
  if (hours === 0) return `12:${minutes} am`;
  if (hours === 12) return `${hours}:${minutes} pm`;
  if (hours > 12) return `${hours - 12}:${minutes} pm`;
  return `${hours}:${minutes} am`;
};

// FIXME: can definitely be better
const twelveHourToIso = (twelve) => {
  if (!twelve) return null;
  const [time, period] = twelve.split(" ");
  let [hour, minutes] = time.split(":");
  hour = Number(hour);
  if (period === "am" && hour === 12) return `00:${minutes}`;
  if (period === "am") return `${hour.toString().padStart(2, "0")}:${minutes}`;
  if (period === "pm" && hour !== 12) return `${hour + 12}:${minutes}`;
  return `${time}`;
};

const ScheduleSettings = ({ match, history }) => {
  const { params } = useRouteMatch();
  const { eventType, id: penId } = params;
  const [selected, setSelected] = useState(eventType);
  const { t } = useTranslation();

  const { info } = useAccountDetails();
  const { features } = useActiveModules();

  DeliveryTabConfiguration.title = t("deliveries");
  CleanoutTabConfiguration.title = t("Cleanouts");
  PushupTabConfiguration.title = t("push-ups");
  MilkingTabConfiguration.title = t("milking");

  const displayPushups = features.includes("pushups");

  const pens = info.farm.pens
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  const [currentPen] = pens.filter(
    (pen) => Number(pen.value) === Number(penId)
  );

  const { isLoading, isError, data } = useQuery(["schedules", penId], () =>
    getSchedules(penId)
  );

  const { mutate } = useMutation(setSchedules, {
    onSuccess: (update, variables) => {
      let copySchedule = [...data.schedules];
      let { eventType } = variables;
      let index = copySchedule.findIndex(
        (schedule) => schedule.eventType === eventType
      );
      copySchedule[index] = update.data;
      queryClient.setQueryData(["schedules", penId], {
        pen: data.pen,
        schedules: copySchedule,
      });
    },
  });

  useEffect(() => {
    setSelected(eventType);
  }, [match.params]);

  const update = (eventType, { updatedTimes, updatedDays = ["everyday"] }) => {
    const converted = updatedTimes.map((time) => {
      const { start = null, end = null } = time;
      return {
        startTime: twelveHourToIso(start),
        endTime: twelveHourToIso(end),
      };
    });
    mutate({
      id: penId,
      eventType,
      times: converted,
      daysOfWeek: updatedDays,
    });
  };

  if (isLoading) return <div></div>;
  if (isError) return <div>error</div>;

  const { schedules: loadedSchedules } = data;

  const milking = getSchedule(loadedSchedules, "milking");
  const cleanouts = getSchedule(loadedSchedules, "cleanouts");
  const pushups = getSchedule(loadedSchedules, "pushups");
  const deliveries = getSchedule(loadedSchedules, "deliveries");

  const schedules = {
    milking: {
      times: (milking.times || []).map(({ start, end }) => {
        return {
          start: isoToTwelveHour(start),
          end: isoToTwelveHour(end),
        };
      }),
      daysOfWeek: milking.daysOfWeek || [],
    },
    cleanouts: {
      times: (cleanouts.times || []).map(({ start, end }) => {
        return {
          start: isoToTwelveHour(start),
          end: isoToTwelveHour(end),
        };
      }),
      daysOfWeek: cleanouts.daysOfWeek || [],
    },
    pushups: {
      times: (pushups.times || []).map(({ start, end }) => {
        return {
          start: isoToTwelveHour(start),
          end: isoToTwelveHour(end),
        };
      }),
      daysOfWeek: pushups.daysOfWeek || [],
    },
    deliveries: {
      times: (deliveries.times || []).map(({ start, end }) => {
        return {
          start: isoToTwelveHour(start),
          end: isoToTwelveHour(end),
        };
      }),
      daysOfWeek: deliveries.daysOfWeek || [],
    },
  };
  let TabDetailsConfiguration = [DeliveryTabConfiguration];
  if (displayPushups) {
    TabDetailsConfiguration = [
      ...TabDetailsConfiguration,
      PushupTabConfiguration,
    ];
  }
  TabDetailsConfiguration = [
    ...TabDetailsConfiguration,
    CleanoutTabConfiguration,
    MilkingTabConfiguration,
  ];

  let config = TabDetailsConfiguration.map((entry) => {
    const { times, daysOfWeek } = schedules[entry.id] || {};
    let period = t("weekly");
    if (
      daysOfWeek.length === 0 ||
      daysOfWeek.length === 7 ||
      daysOfWeek.includes("everyday")
    ) {
      period = t("daily");
    }
    const info = `${times.length} ${period}`;
    return {
      ...entry,
      info,
    };
  });

  return (
    <Container className={classes.container}>
      <div className={classes.scheduleSettingsSectionHeader}>
        <Header
          title={
            <Breadcrumbs
              routes={[
                {
                  to: "/feedbunk-management/pens/",
                  name: "Pens",
                },
                {
                  to: `/feedbunk-management/pens/${penId}/feed-events`,
                  name: currentPen.label,
                },
                {
                  name: t("schedule-settings"),
                },
              ]}
            />
          }
        >
          <Box marginLeft="auto">
            <PenNavigator />
          </Box>
        </Header>
      </div>
      <div className={classes.scheduleSettingsDetailsContainer}>
        <DetailsTab
          config={config}
          activeTab={selected}
          onChange={(id) => {
            setSelected(id);
            const url = match.path
              .replace(":eventType", id)
              .replace(":id", penId);
            history.replace(url);
          }}
          render={() => {
            return (
              <SettingsView pen={data.pen}>
                <Switch>
                  <Route
                    path={match.path
                      .replace(":eventType", "deliveries")
                      .replace(":penId", penId)}
                    render={() => (
                      <SettingsSubView
                        penId={penId}
                        key={"deliveries"}
                        id={"deliveries"}
                        initialTimes={schedules.deliveries.times}
                        onChange={(updatedSchedule) => {
                          update("deliveries", updatedSchedule);
                        }}
                      />
                    )}
                  />
                  {displayPushups ? (
                    <Route
                      path={match.path
                        .replace(":eventType", "pushups")
                        .replace(":penId", penId)}
                      render={() => (
                        <SettingsSubView
                          penId={penId}
                          key={"pushups"}
                          id={"pushups"}
                          initialTimes={schedules.pushups.times}
                          onChange={(updatedSchedule) => {
                            update("pushups", updatedSchedule);
                          }}
                        />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  <Route
                    path={match.path
                      .replace(":eventType", "cleanouts")
                      .replace(":penId", penId)}
                    render={() => (
                      <SettingsCleanoutSubView
                        penId={penId}
                        key={"cleanouts"}
                        initialTimes={schedules.cleanouts.times}
                        initialDays={
                          schedules.cleanouts.daysOfWeek.length === 0 ||
                          schedules.cleanouts.daysOfWeek.includes("everyday")
                            ? [
                                "sunday",
                                "monday",
                                "tuesday",
                                "wednesday",
                                "thursday",
                                "friday",
                                "saturday",
                              ]
                            : schedules.cleanouts.daysOfWeek
                        }
                        onChange={(updatedSchedule) => {
                          if (
                            updatedSchedule.updatedDays.length === 0 ||
                            updatedSchedule.updatedDays.length === 7
                          ) {
                            update("cleanouts", {
                              ...updatedSchedule,
                              updatedDays: ["everyday"],
                            });
                            return;
                          }
                          update("cleanouts", updatedSchedule);
                        }}
                      />
                    )}
                  />
                  <Route
                    path={match.path
                      .replace(":eventType", "milking")
                      .replace(":penId", penId)}
                    render={() => (
                      <SettingsMilkingView
                        penId={penId}
                        key={"milking"}
                        initialTimes={schedules.milking.times}
                        onChange={(updatedSchedule) => {
                          update("milking", updatedSchedule);
                        }}
                      />
                    )}
                  />
                  <Redirect
                    to={match.path
                      .replace(":eventType", "deliveries")
                      .replace(":penId", penId)}
                  />
                </Switch>
              </SettingsView>
            );
          }}
        />
      </div>
    </Container>
  );
};

export default ScheduleSettings;
