import React from "react";
import PropTypes from "prop-types";

const IContextProps = {
  state: PropTypes.StateType,
  dispatch: PropTypes.Dispatch,
};

const PenDetailsContext = React.createContext(IContextProps);

export function usePenDetailsContext() {
  return React.useContext(PenDetailsContext);
}

export default PenDetailsContext;
