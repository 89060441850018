import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../CommonComponents/UI/Header";
import { styled } from "@mui/material/styles";
import MilkingOperationsProvider from "./utils/MilkingOperationsProvider";
import MoInsightsOverview from "./Components/InsightsOverview";
import {
  flexColumnMixIn,
  flexRowMixIn,
  flexSpaceBetweenMixIn,
} from "../../../CommonComponents/utils/Operations/cssMixins";
import MoPensOverview from "./Components/PensOverview";

const PREFIX = "MilkingOperations";

const classes = {
  content: `${PREFIX}-content`,
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    ...flexRowMixIn,
    ...flexSpaceBetweenMixIn,
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("xs")]: {
      ...flexColumnMixIn,
    },
    [theme.breakpoints.up("lg")]: {
      ...flexRowMixIn,
    },
  },

  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "flex-start",
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      ...flexColumnMixIn,
    },
    [theme.breakpoints.up("sm")]: {
      overflowX: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      overflowX: "hidden",
    },
  },
}));

const MilkingOperations = () => {
  const { t } = useTranslation();
  return (
    <MilkingOperationsProvider>
      <Header
        title={t("milking-operations")}
        dataCyAtt="Ops Header Milking Operations"
      />
      <Root className={classes.wrapper}>
        <div className={classes.content}>
          <MoPensOverview></MoPensOverview>

          <MoInsightsOverview></MoInsightsOverview>
        </div>
      </Root>
    </MilkingOperationsProvider>
  );
};

export default MilkingOperations;
