import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "../CommonComponents/UI/Button";
import { colors } from "../CommonComponents/UI/theme";
import { Typography } from "@mui/material";

const PREFIX = "DashboardCard";

const classes = {
  icon: `${PREFIX}-icon`,
  inner: `${PREFIX}-inner`,
  title: `${PREFIX}-title`,
  titleContainer: `${PREFIX}-titleContainer`,
  btn: `${PREFIX}-btn`,
  mobile: `${PREFIX}-mobile`,
  desktop: `${PREFIX}-desktop`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  borderTop: `8px solid ${colors.green}`,
  paddingBottom: 16,
  minHeight: 250,
  display: "flex",
  flexDirection: "column",
  height: "100%",

  [`& .${classes.inner}`]: {
    margin: 16,
    position: "relative",
    zIndex: 1,
  },
  [`& .${classes.icon}`]: {
    width: 30,
    height: 30,
    color: colors.green,
  },
  [`& .${classes.title}`]: {
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 148,
    },
  },
  [`& .${classes.titleContainer}`]: {
    lineHeight: 1,
    margin: "2px 0 0 4px",
  },
  [`& .${classes.btn}`]: {
    padding: "0 24px",
    height: 36,
  },
  [`& .${classes.desktop}`]: {
    display: "none",

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  [`& .${classes.mobile}`]: {
    display: "block",
    margin: "12px auto 0",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const DashboardCard = ({
  className,
  children,
  title,
  subtitle,
  icon: Icon,
  url,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClick = () => history.push(url);
  return (
    <StyledCard className={className} data-cy={`${title}-dashboard-card`}>
      <Box
        className={classes.inner}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex">
          <Icon className={classes.icon} />
          <Box className={classes.titleContainer}>
            <Typography
              data-cy="dashboard-card-title"
              className={classes.title}
              variant="h6"
            >
              {t(title)}
            </Typography>
            <Typography variant="caption">{subtitle}</Typography>
          </Box>
        </Box>
        <Button
          title={t("see-more")}
          variant="outlined"
          onClick={onClick}
          className={classNames(classes.btn, classes.desktop)}
        />
      </Box>
      {children}

      <Button
        title="See More"
        variant="outlined"
        onClick={onClick}
        className={classNames(classes.btn, classes.mobile)}
      />
    </StyledCard>
  );
};

export default DashboardCard;
