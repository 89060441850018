import axios from "axios";

export async function fetchMilkingOperations() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/behavior/milking-operations`
  );
  return response.data;
}

export async function fetchMilkingOperationsByPenId(query) {
  const penId = query.queryKey[1];

  const response = await axios.get(
    `${window._env_.API_URL}/private/behavior/milking-operations/${penId}`
  );
  return response.data;
}

export async function fetchMilkingOpsSmartInsights() {
  const response = await axios.get(
    `${window._env_.API_URL}/private/behavior/milking-operations/smart-insights`
  );
  return response.data;
}
