import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { useMilkingOperationsContext } from "../utils/MilkingOperationsContext";
import {
  flexCenterColumnMixIn,
  flexColumnMixIn,
  flexRowMixIn,
} from "../../../../CommonComponents/utils/Operations/cssMixins";
import { colors } from "../../../../CommonComponents/UI/theme";
import OpsBarChart from "../../../../CommonComponents/UI/Operations/OpsBarChart";
import MoPerformance from "./MoPerformance";

const PREFIX = "MilkingDurationChart";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  titleRow: `${PREFIX}-titleRow`,
  title: `${PREFIX}-title`,
  chartArea: `${PREFIX}-chartArea`,
  printIcon: `${PREFIX}-printIcon`,
};

const Root = styled("div")(() => ({
  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    width: "100%",
  },

  [`& .${classes.titleRow}`]: {
    ...flexRowMixIn,
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 10px",
    position: "relative",
    width: "100%",
  },

  [`& .${classes.title}`]: {
    fontSize: "16px",
    color: colors.black,
    height: "28px",
    fontWeight: "500",
  },

  [`& .${classes.chartArea}`]: {
    padding: "0 0 0 5px",
    ...flexCenterColumnMixIn,
    width: "100%",
  },

  [`& .${classes.printIcon}`]: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "rgba(0, 0, 0, 0.3)",
    "@media print": {
      display: "none",
    },
  },
}));

const kpis = {
  duration: {
    label: "milking-duration",
    title: "milking-duration",
    averageLabel: "avg-duration",
  },
  difference: {
    label: "difference",
    title: "difference-from-scheduled-start-time",
    averageLabel: "avg-deviation",
  },
};

const MoBarChart = ({ data, name }) => {
  const { t } = useTranslation();
  const { selectPrintSection } = useMilkingOperationsContext();
  const { label, title, averageLabel } = kpis[name];
  const { milkings, summary } = data;

  const onBarChartPrint = () => {
    selectPrintSection({
      printPensOverview: false,
      printInsightsOverview: true,
    });
    setTimeout(() => {
      window.print();
    });
  };

  return (
    <Root className={classes.wrapper} data-cy="Mo Barchart">
      <div className={classes.titleRow}>
        <div className={classes.title} data-cy={title}>
          {t(title)}
        </div>
        <IconButton
          aria-label="print chart"
          onClick={onBarChartPrint}
          data-cy="Milking Ops BarCharts Print Button"
          edge="start"
          className={classes.printIcon}
          size="large"
        >
          <PrintIcon color="inherit" />
        </IconButton>
      </div>

      <div className={classes.chartArea}>
        {milkings.error ? (
          <p>{milkings.error}</p>
        ) : (
          <>
            {milkings.map((milking) => {
              return (
                <OpsBarChart
                  key={milking.milkingName}
                  chartTitle={milking.milkingName}
                  barChartData={milking.barChartData}
                  dataCyPrefix="MO Barchart"
                  valueUnit="m"
                  label={t(label)}
                />
              );
            })}
            <MoPerformance averageLabel={averageLabel} summary={summary} />
          </>
        )}
      </div>
    </Root>
  );
};

export default MoBarChart;
