import React, { useCallback, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAccountDetails } from "../../../App/useAccountDetails";
import useDatesForPen from "../../../CommonComponents/utils/useDatesForPen";
import Datepicker from "../../../CommonComponents/UI/Datepicker/Datepicker.jsx";
import usePenDetails from "../../../CommonComponents/utils/usePenDetails";
import LCTile from "../../../CommonComponents/UI/LineChartTile";
import { getPenBehaviorBreakdown } from "../../services/pen";
import dayjs from "dayjs";
import FeedbunkIcon from "../../../CommonComponents/Icons/Feedbunk";

import {
  minutesToHours,
  hoursMinFormatter,
} from "../../../CommonComponents/utils/time";

const PREFIX = "LockupTime";

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  datePickerSection: `${PREFIX}-datePickerSection`,
  chart: `${PREFIX}-chart`,
  text: `${PREFIX}-text`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    "@media print": {
      padding: `16px 0 0 0`,
    },
  },

  [`& .${classes.text}`]: {
    display: "block",
    textAlign: "center",
    width: "100%",
  },

  [`& .${classes.grid}`]: {
    height: "100%",
  },

  [`& .${classes.datePickerSection}`]: {
    height: 42,
    marginBottom: 16,
    maxHeight: 42,
  },

  [`& .${classes.chart}`]: {
    position: "relative",
    height: 688,
    marginBottom: 20,
    "@media print": {
      marginBottom: 0,
    },
  },
}));

const formatForChart = ({ value }) => minutesToHours(value, hoursMinFormatter);

const LockupTime = () => {
  const { t } = useTranslation();
  const { info } = useAccountDetails();
  const { id } = useParams();
  const pen = usePenDetails(id);
  const initialRange = 30;
  const excludeToday = true;
  const [dates, setDates] = useDatesForPen(
    info,
    id,
    initialRange,
    excludeToday
  );

  const queryStartDate = dayjs(dates.start).format("YYYY-MM-DD");
  const queryEndDate = dayjs(dates.end).format("YYYY-MM-DD");
  const gridRef = useRef(null);

  const onChartPrint = useCallback((e) => {
    gridRef.current.className = `${gridRef.current.className} show-${e.currentTarget.offsetParent.id}`;
    window.print();
  }, []);

  useEffect(() => {
    const removeClass = () => {
      const regEx = new RegExp(/show-.*$/g);
      const updatedClass = gridRef.current.className.replace(regEx, "");
      gridRef.current.className = updatedClass;
    };
    window.addEventListener("afterprint", removeClass);
    return () => window.removeEventListener("afterprint", removeClass);
  }, []);

  return (
    <Root className={classes.root}>
      <Grid
        container
        spacing={0}
        ref={gridRef}
        className={classes.grid}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        {!pen.useHeadlock ? (
          <span className={classes.text}>
            Headlocks not available for this pen
          </span>
        ) : (
          <>
            <Grid item className={classes.datePickerSection}>
              <Datepicker
                startDate={dates.start}
                endDate={dates.end}
                minimumDate={dates.minimum}
                maximumDate={dates.maximum}
                maxRange={90}
                hook="lockup-chart"
                onApplyPressed={(startDate, endDate) => {
                  setDates({ start: startDate, end: endDate });
                }}
              />
            </Grid>
            <Grid xs={12} item className={classes.chart} id="lockup-chart">
              <LCTile
                title={t("lockup-time")}
                subtitle="Total time spent in headlocks per day"
                icon={FeedbunkIcon}
                config={{
                  "7drollingAverage": {
                    lineDashType: "dash",
                    markerSize: 1,
                    legendMarkerType: "square",
                    legendMarkerColor: "white",
                  },
                  tooltipFormatter: formatForChart,
                  axisY: {
                    labelFormatter: formatForChart,
                    title: t("duration"),
                    interval: 60,
                  },
                }}
                query={{
                  key: "lockup-time",
                  params: {
                    penId: id,
                    type: "",
                    startDate: queryStartDate,
                    endDate: queryEndDate,
                  },
                  service: getPenBehaviorBreakdown,
                  validation: () => true,
                }}
                onChartPrint={onChartPrint}
              />
            </Grid>
            <Grid style={{ height: "2px" }}></Grid>
          </>
        )}
      </Grid>
    </Root>
  );
};

export default LockupTime;
