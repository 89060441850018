import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "react-query";
import { Box } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LoadingSpinner from "./LoadingSpinner";

import CanvasJSReact from "../Vendor/canvasjs.react";
import { useAccountDetails } from "../../App/useAccountDetails";
const { CanvasJSChart } = CanvasJSReact;

dayjs.extend(utc);
dayjs.extend(timezone);

const centerMixIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...centerMixIn,
    justifyContent: "space-around",
    padding: 10,
    height: "100%",
    "@media print": {
      padding: "10px 0 0 0",
      boxShadow: "none",
    },
  },
  errorRoot: {
    ...centerMixIn,
    padding: 25,
    height: "100%",
  },
  disabled: {
    opacity: "20%",
  },
  errorMessage: {
    flex: 1,
    ...centerMixIn,
  },
  chartTitle: {
    ...centerMixIn,
    flexDirection: "row",
    "@media print": {
      marginTop: 20,
    },
  },
  chartIcon: {
    height: 40,
    width: 40,
    marginRight: 8,
  },
  chartContainer: {
    height: "85%",
    width: "90%",
    position: "relative",
  },
  subtitle: {
    color: "rgb(89,89,89)",
    marginTop: theme.spacing(2),
  },
}));

const ErrorView = ({ title }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={3} classes={{ root: classes.errorRoot }}>
      <Typography variant="h5" className={classes.disabled}>
        {title}
      </Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {t("card-loading-error-message")}
        </Typography>
      </Box>
    </Paper>
  );
};

const NoDataView = ({ title }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper elevation={3} classes={{ root: classes.errorRoot }}>
      <Typography variant="h5" className={classes.disabled}>
        {title}
      </Typography>
      <Box className={classes.errorMessage}>
        <Typography variant="h5" align="center">
          {t("no-data-to-display-chart")}
        </Typography>
      </Box>
    </Paper>
  );
};

const getTooltipContent = (e, title, yFormatter) => {
  const { label, y } = e.entries[0].dataPoint;
  const value = typeof yFormatter === "function" ? yFormatter({ value: y }) : y;
  const name = e.entries[0].dataSeries.name;
  return `
    <div style="min-width: 200px; height: 145px; display: flex;  align-items: center; flex-direction: column; justify-content: space-between; padding: 20px;">
      <span>${title}</span>
      <hr style="flex: none; border: none; height:2px; width: 100%; background-color:#5d7b84;">
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <span style="margin-right:15px" >${name}</span>
        <span><bold>${value}</bold></span>
      </div>
      
      <hr style="flex: none; border: none; height:2px; width: 100%; background-color:#5d7b84;">
      <span>${label}</span>
    </div>
  `;
};

const LineChartTile = (props) => {
  const { t } = useTranslation();
  const { config, title, subtitle, icon: Icon, query, onChartPrint } = props;
  const classes = useStyles();
  const history = useHistory();
  const { info } = useAccountDetails();
  const { pathname } = useLocation();
  const isWelfare = pathname.includes("herd-welfare");

  const { data } = useQuery([query.key, query.params], query.service);

  const hasData = query.validation(data);

  if (!hasData) return <NoDataView title={title} />;

  const { series, target } = data;

  const displayData = series.map((entry) => {
    const { data, id, label, color } = entry;
    const dp = data.map(({ value, date }) => {
      const label = dayjs(date).format("MMM D");
      const hoursDifference = dayjs().tz(info.farm.timezone).format("Z");
      return {
        label,
        y: value,
        name: id,
        click: isWelfare
          ? null
          : () => {
              history.push(
                `${pathname}/drill-down?date=${date}T00:00:00${hoursDifference}`
              );
            },
      };
    });

    return {
      dataPoints: dp,
      id,
      type: "line",
      showInLegend: true,
      name: label,
      markerSize: 0,
      lineThickness: 4,
      color: color || "#186490",
      ...config[entry.id],
    };
  });

  // striplines label will be displayed in canvasjs even
  // if the value is null, best just not to show it
  let stripLines = null;
  if (typeof target === "number") {
    stripLines = [
      {
        value: target,
        color: "#666666",
        label: t("target"),
        labelFontColor: "#666666",
        lineDashType: "dash",
      },
    ];
  }

  const getMinValue = (values) => Math.min(...values.map((point) => point.y));
  const dpMin = displayData.reduce((acc, current) => {
    return Math.min(acc, getMinValue(current.dataPoints));
  }, Infinity);

  const minValue = typeof target === "number" ? Math.min(target, dpMin) : dpMin;

  // Add a buffer based on interval
  const yAxisMin = Math.max(0, minValue - config.axisY.interval);

  return (
    <Paper elevation={3} classes={{ root: classes.root }}>
      <div data-cy="chart-title">
        <div className={classes.chartTitle}>
          <Icon color="primary" className={classes.chartIcon} />
          <Typography variant="h4">{title}</Typography>
        </div>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </div>

      {!isWelfare && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t("click-line-cow-activity-24hrs-message")}
        </Typography>
      )}

      <CanvasJSChart
        options={{
          animationEnabled: true,
          toolTip: {
            backgroundColor: "#374851",
            borderColor: "#374851",
            borderThickness: 2,
            fontSize: 16,
            fontColor: "#ffffff",
            fontFamily: "Noto",
            contentFormatter: (e) =>
              getTooltipContent(e, title, config.tooltipFormatter),
          },
          legend: {
            markerMargin: 5,
            type: "square",
            fontFamily: "Noto",
            fontWeight: "normal",
            horizontalAlign: "center",
            fontSize: 14,
          },
          axisY: {
            lineThickness: 0,
            tickThickness: 0,
            labelFontSize: 16,
            labelFontFamily: "Noto",
            labelFontColor: "#595959",
            titleFontSize: 16,
            titleFontWeight: 300,
            titleFontFamily: "Noto",
            titleFontColor: "#595959",
            gridColor: "#e9e9e9",
            lineColor: "#e9e9e9",
            tickColor: "#e9e9e9",
            minimum: yAxisMin,
            stripLines,
            ...config.axisY,
          },
          axisX: {
            lineColor: "#e9e9e9",
            tickThickness: 0,
            tickLength: 15,
            labelFontSize: 16,
            labelFontFamily: "Noto",
            labelFontColor: "#595959",
          },

          data: displayData,
        }}
        containerProps={{
          height: "85%",
          width: "90%",
          position: "relative",
        }}
        onChartPrint={onChartPrint}
      />
    </Paper>
  );
};

export default (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary
      FallbackComponent={(fbprops) => (
        <ErrorView {...fbprops} title={props.title} />
      )}
    >
      <LineChartTile {...props} />
    </ErrorBoundary>
  </Suspense>
);
