import React from "react";
import { styled } from "@mui/material/styles";
import FilterIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

const PREFIX = "FilterLabel";

const classes = {
  filterRoot: `${PREFIX}-filterRoot`,
  iconButton: `${PREFIX}-iconButton`,
  filterIcon: `${PREFIX}-filterIcon`,
  divider: `${PREFIX}-divider`,
};

const Root = styled("div")(() => ({
  [`& .${classes.filterRoot}`]: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "inherit",
    justifyContent: "flex-start",
  },

  [`& .${classes.iconButton}`]: {
    margin: "0 4px",
  },

  [`& .${classes.filterIcon}`]: {
    fontSize: "18px",
    userSelect: "none",
  },

  [`& .${classes.divider}`]: {
    marginTop: 20,
  },
}));

const FilterLabel = ({ text, options = [], onClear, onSelected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseWithoutSelection = () => {
    setAnchorEl(null);
  };

  const handleCloseWithSelection = (event, option) => {
    setAnchorEl(null);
    setSelectedOption(option);
    onSelected(option);
  };

  const handleClear = () => {
    setAnchorEl(null);
    setSelectedOption(null);
    onClear();
  };

  return (
    <Root>
      <span className={classes.filterRoot}>
        {text}
        <IconButton
          className={classes.iconButton}
          onClick={handleClick}
          size="small"
        >
          <FilterIcon className={classes.filterIcon} />
        </IconButton>
      </span>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseWithoutSelection}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === selectedOption}
            onClick={(event) => handleCloseWithSelection(event, option)}
          >
            {option}
          </MenuItem>
        ))}
        <Divider className={classes.divider} />
        <MenuItem onClick={handleClear}>Clear Filter</MenuItem>
      </Menu>
    </Root>
  );
};

export default FilterLabel;
