import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import DetailsTabItem from "./DetailsTabItem";

const PREFIX = "DetailsTab";

const classes = {
  detailsTabView: `${PREFIX}-detailsTabView`,
};

const DetailsTabView = styled("div")(() => ({
  [`&.${classes.detailsTabView}`]: {
    background: "white",
    height: "calc(100% - 64px)",
    width: "100%",
  },
}));

const DetailsTab = ({
  activeTab,
  config: passedConfig,
  onChange: onChangeHandler,
  render,
}) => {
  const [selected, setSelected] = useState(activeTab || passedConfig[0].id);
  const [config, setConfig] = useState(passedConfig);

  useEffect(() => {
    setSelected(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setConfig(passedConfig);
  }, [passedConfig]);

  return (
    <>
      <div style={{ display: "flex" }}>
        {config.map(({ id, ...props }) => (
          <DetailsTabItem
            active={selected === id ? 1 : 0}
            key={id}
            id={id}
            tag="schedule-settings-details-tab"
            {...props}
            onClick={(id) => {
              setSelected(id);
              onChangeHandler(id);
            }}
          />
        ))}
      </div>
      <DetailsTabView className={classes.detailsTabView}>
        {render(selected)}
      </DetailsTabView>
    </>
  );
};

export default DetailsTab;
