import React from "react";
import PropTypes from "prop-types";

const IContextProps = {
  state: PropTypes.StateType,
  dispatch: PropTypes.Dispatch,
};

const MoSmartInsightsContext = React.createContext(IContextProps);

export function useMoSmartInsightsContext() {
  return React.useContext(MoSmartInsightsContext);
}

export default MoSmartInsightsContext;
