import React, { useState } from "react";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../../App/index";
import { useActiveModules } from "../../../App/useActiveModules";
import Toast from "../../../CommonComponents/UI/Toast";
import UserRow from "./UserRow";
import AddUserSection from "./AddUserSection";
import {
  getAccounts,
  createAccount,
  updateAccount,
  deleteAccount,
} from "../../services/settings";
import { useAccountDetails } from "../../../App/useAccountDetails";

const PREFIX = "Accounts";

const classes = {
  accounts: `${PREFIX}-accounts`,
  accountsHeaderTitle: `${PREFIX}-accountsHeaderTitle`,
  accountsHeader: `${PREFIX}-accountsHeader`,
  checkboxes: `${PREFIX}-checkboxes`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.accounts}`]: {
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
  },

  [`& .${classes.accountsHeaderTitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    flex: (props) => `1 0 ${props.basis || "0px"}`,
    fontSize: 16,
    color: "#595959",
    "&:last-of-type": {
      paddingLeft: 16,
    },
  },

  [`& .${classes.accountsHeader}`]: {
    display: "none",
    width: "100%",
    marginBottom: "0",
    height: 40,
    padding: "10px 20px",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  [`& .${classes.checkboxes}`]: {
    marginLeft: 20,
  },
}));

const Accounts = () => {
  const { info } = useAccountDetails();
  const { t } = useTranslation();
  const { flags } = useActiveModules();

  const { email: currentUserEmail } = info;
  const farmName = info?.farm?.name;
  const { data: accounts } = useQuery("settings/accounts", getAccounts);

  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  const { isLoading: isAdding, mutate: createUser } = useMutation(
    createAccount,
    {
      onSuccess: (data) => {
        setToast({
          show: true,
          type: "success",
          message: `${data.name} successfully added!`,
        });
        queryClient.setQueryData("settings/accounts", (oldData) => [
          ...oldData,
          data,
        ]);
      },
      onError: (error) => {
        setToast({
          show: true,
          type: "error",
          message:
            error.response.data.error ||
            "We had a problem adding the user, try again",
        });
      },
    }
  );

  const { isLoading: isSaving, mutate: updateUser } = useMutation(
    updateAccount,
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData("settings/accounts", (oldData) => {
          const indexToUpdate = oldData.findIndex(
            ({ id }) => id === variables.id
          );
          let newData = [...oldData];
          newData[indexToUpdate] = data;
          return newData;
        });
      },
      onError: () =>
        setToast({
          show: true,
          type: "error",
          message: "We had a problem adding the user, try again",
        }),
    }
  );

  const { mutate: deleteUser } = useMutation(deleteAccount, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData("settings/accounts", (oldData) =>
        oldData.filter(({ id }) => id !== variables.id)
      );
    },
    onError: () =>
      setToast({
        show: true,
        type: "error",
        message: "We had a problem deleting the user, try again",
      }),
  });

  return (
    <StyledGrid container spacing={0} className={classes.accounts}>
      <Grid item xs={12}>
        <AddUserSection onAddAccount={createUser} isLoading={isAdding} />
        <Grid container className={classes.accountsHeader}>
          <Grid
            item
            md={3}
            className={classes.accountsHeaderTitle}
          >
            {t("name")}
          </Grid>
          <Grid
            item
            md={3}
            className={classes.accountsHeaderTitle}
          >
            {t("email")}
          </Grid>
          <Grid
            item
            md={4}
            className={classNames(
              classes.accountsHeaderTitle,
              classes.checkboxes
            )}
          >
            <span>{t("admin")}</span>
            <span>{t("daily-email")}</span>
            <span>{t("weekly-email")}</span>
          </Grid>
          <Grid item md={2} />
        </Grid>
        {accounts.map((account) => (
          <UserRow
            key={account.id}
            {...account}
            isAdmin={account.is_admin}
            farmName={farmName}
            isLoading={isSaving}
            onUpdate={updateUser}
            onDelete={deleteUser}
            isCurrentUser={account.email === currentUserEmail}
          />
        ))}
      </Grid>
      <Toast toast={toast} closeToast={closeToast} />
    </StyledGrid>
  );
};

export default Accounts;
