import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { useAccountDetails } from "../../../../App/useAccountDetails";
import { useActiveModules } from "../../../../App/useActiveModules";
import TimelineWindows from "./TimelineWindows";
import { getPenFeedStatusWindows } from "../../../services/pen";
import useDatesforPen from "../../../../CommonComponents/utils/useDatesForPen";
import Datepicker from "../../../../CommonComponents/UI/Datepicker/Datepicker";
import {
  hoursMinFormatter,
  minutesToHours,
  twelveHoursFormatter,
} from "../../../../CommonComponents/utils/time";
import {
  STATUS_COLOR_MAP,
  STATUS_COLOR_MAP_LIGHT,
  STATUS_TEXT_MAP,
} from "../../../utils/feedStatus/statusMaps";

const PREFIX = "FeedStatus";

const classes = {
  timelineContainer: `${PREFIX}-timelineContainer`,
  datePickerSection: `${PREFIX}-datePickerSection`,
  printTitle: `${PREFIX}-printTitle`,
  grid: `${PREFIX}-grid`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.datePickerSection}`]: {
    display: "none",
    height: 42,
    marginBottom: 16,
    maxHeight: 42,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "@media print": {
      display: "none",
    },
  },
  [`&.${classes.grid}`]: {
    "@media print": {
      marginTop: 25,
    },
  },

  [`& .${classes.timelineContainer}`]: {
    maxWidth: "1360px",
    width: "100%",
    margin: "0 auto",
  },

  [`& .${classes.printTitle}`]: {
    display: "none",
    "@media print": {
      display: "inline-flex",
      marginRight: "auto",
    },
  },
}));

const convertWindowForDisplay = (rawWindow, date, colorMap, t) => ({
  color: colorMap[rawWindow.status.code],
  width: rawWindow.endTimeMinutes - rawWindow.startTimeMinutes,
  duration: minutesToHours(
    rawWindow.endTimeMinutes - rawWindow.startTimeMinutes,
    hoursMinFormatter
  ),
  startTime: minutesToHours(rawWindow.startTimeMinutes, twelveHoursFormatter),
  endTime: minutesToHours(rawWindow.endTimeMinutes, twelveHoursFormatter),
  status: t(STATUS_TEXT_MAP[rawWindow.status.code]),
  date,
});

const formatDataWindows = (data, t) => {
  const { barnOrientation = "", id } = data.pen;
  const windowsData = [...data.windows].reverse();

  const days = windowsData.map(
    (
      { minutelyWindows, minutelyWindowsPerSection = [], summary, range },
      index
    ) => {
      const date = dayjs(range.start).format("MMM D");
      return {
        key: range.start,
        date: date,
        accessTime: `${Math.floor(summary.minutesNotOK / 60)}h ${(
          summary.minutesNotOK % 60
        )
          .toString()
          .padStart(2, "0")}m`,
        windows: minutelyWindows.map((raw) =>
          convertWindowForDisplay(raw, date, STATUS_COLOR_MAP("FEED_STATUS"), t)
        ),
        penId: id,
        isBreakdownOpen: index === 0 || index === 1,
        barnOrientation,
        sectionBreakdown: minutelyWindowsPerSection.map((rawBreakdown) => {
          return {
            label: parseInt(rawBreakdown.sectionNumber, 10),
            windows: rawBreakdown.windows.map((raw) =>
              convertWindowForDisplay(raw, date, STATUS_COLOR_MAP_LIGHT, t)
            ),
          };
        }),
      };
    }
  );
  return days;
};

const FeedStatus = ({ id }) => {
  const { t } = useTranslation();
  const { info } = useAccountDetails();

  const initialRange = 7;
  const [dates, setDates] = useDatesforPen(
    info,
    id,
    initialRange
  );

  const queryStartDate = dayjs(dates.start).format("YYYY-MM-DD");
  const queryEndDate = dayjs(dates.end).format("YYYY-MM-DD");

  const { data: fetched } = useQuery(
    [
      "feed-status",
      {
        id,
        startDate: queryStartDate,
        endDate: queryEndDate,
        showSectionBreakdown: true,
      },
    ],
    getPenFeedStatusWindows
  );

  const data = formatDataWindows(fetched, t);

  return (
    <>
      <StyledGrid item className={classes.datePickerSection}>
        <Datepicker
          startDate={dates.start}
          endDate={dates.end}
          minimumDate={dates.minimum}
          maximumDate={dates.maximum}
          maxRange={90}
          hook="feed-status"
          onApplyPressed={(startDate, endDate) => {
            setDates({ start: startDate, end: endDate });
          }}
        />
      </StyledGrid>
      <StyledGrid
        container
        spacing={0}
        justifyContent="flex-end"
        alignItems="flex-start"
        classes={{ root: classes.grid }}
      >
        <Typography
          data-cy="Feed Status Title"
          variant="h5"
          className={classes.printTitle}
        >
          {t("feed-status")}
        </Typography>
        <div className={classes.timelineContainer}>
          <TimelineWindows days={data} />
        </div>
      </StyledGrid>
    </>
  );
};

export default FeedStatus;
