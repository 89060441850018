import axios from "axios";

const { API_URL } = window._env_;

export async function getSchedules(id) {
  const response = await axios.get(
    `${API_URL}/private/settings/schedules?penId=${id}`
  );
  return response.data;
}

export async function setSchedules({ id, ...payload }) {
  return axios.post(
    `${API_URL}/private/settings/schedules?penId=${id}`,
    payload
  );
}
