import React, { Suspense } from "react";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Iframe from "react-iframe";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../CommonComponents/UI/ErrorFallback";
import Header from "../CommonComponents/UI/Header";
import ListNavigator from "../CommonComponents/UI/ListNavigator";
import LoadingSpinner from "../CommonComponents/UI/LoadingSpinner";
import { getReportDashboardById } from "./services/reports";
import useReports from "./useReports";

const PREFIX = "Report";

const classes = {
  content: `${PREFIX}-content`,
  iframe: `${PREFIX}-iframe`,
  title: `${PREFIX}-title`,
  menu: `${PREFIX}-menu`,
};

const Content = styled("div")(({ theme }) => ({
  [`&.${classes.content}`]: {
    padding: theme.spacing(1),
    height: "calc(100% - 96px)",
  },

  [`& .${classes.iframe}`]: {
    height: "100%",
  },

  [`& .${classes.title}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.menu}`]: {
    fontSize: 24,
    marginBottom: theme.spacing(1),
  },
}));

const ReportInner = () => {
  const { id } = useParams();
  const { data } = useQuery([`dashboardById`, id], getReportDashboardById, {
    refetchOnWindowFocus: false,
  });

  return (
    <Iframe
      title={data.title}
      url={data.embedUrl}
      className={classes.iframe}
      width="100%"
      height="100%"
      display="initial"
      position="relative"
    />
  );
};

const Report = () => {
  const history = useHistory();
  const { id } = useParams();
  const { reports } = useReports();
  const onReportSelected = (id) => history.push(`/reports/${id}`);
  const title = reports.find((report) => report.id === id).title;

  const options = reports.map(({ id, title }) => ({
    id,
    title,
  }));

  return (
    <>
      <Header title={title}>
        {reports.length > 1 ? (
          <Box marginLeft="auto">
            <ListNavigator
              buttonText="Change Report"
              options={options}
              defaultOption={parseInt(id, 10)}
              onOptionSelected={onReportSelected}
            />
          </Box>
        ) : null}
      </Header>
      <Content className={classes.content}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingSpinner />}>
            <ReportInner />
          </Suspense>
        </ErrorBoundary>
      </Content>
    </>
  );
};

export default Report;
