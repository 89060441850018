import {
  flexCenterColumnMixIn,
  flexColumnMixIn,
  flexRowMixIn,
} from "./cssMixins";
import { colors } from "../../UI/theme";

export const performanceCssMixIn = {
  performance: {
    ...flexCenterColumnMixIn,
    padding: "10px 5px 30px 5px",
    width: "100%",
    justifyContent: "space-between",
    marginTop:"-30px"
  },
  titleRow: {
    ...flexRowMixIn,
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    position: "relative",
    width: "100%",
  },
  title: {
    fontSize: "12px",
    color: colors.gray35,
  },
  column: {
    ...flexColumnMixIn,
    width: "45%",
  },
  columnFullWidth: {
    ...flexColumnMixIn,
    width: "100%",
  },
  row: {
    ...flexRowMixIn,
    width: "100%",
    padding: "15px 20px",
    justifyContent: "space-between",
  },
  subTitle: {
    fontSize: "12px",
    color: colors.gray35,
    ...flexRowMixIn,
  },
  text: {
    fontSize: "16px",
    fontWeight: "500",
    ...flexRowMixIn,
    color: colors.charcoal,
  },
};
