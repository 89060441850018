import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { colors } from "../theme";
import {
  flexCenterColumnMixIn,
  flexColumnMixIn,
  flexRowMixIn,
} from "../../utils/Operations/cssMixins";
import { xxlPadding } from "../../utils/Operations/css.const";
import CloseIcon from "@mui/icons-material/Close";
import PenInfo from "../HeaderLarge/PenInfo";
import { useActiveModules } from "../../../App/useActiveModules";

const PREFIX = "OpsPenSummary";

const classes = {
  tabContent: `${PREFIX}-tabContent`,
  wrapper: `${PREFIX}-wrapper`,
  titleRow: `${PREFIX}-titleRow`,
  title: `${PREFIX}-title`,
  content: `${PREFIX}-content`,
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  sectionText: `${PREFIX}-sectionText`,
  closeIcon: `${PREFIX}-closeIcon`,
  icon: `${PREFIX}-icon`,
  hidden: `${PREFIX}-hidden`,
  tabContentWrapper: `${PREFIX}-tabContentWrapper`,
  tabContainer: `${PREFIX}-tabContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tabContent}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "flex-start",
    background: colors.gray88,
    width: "100%",
    padding: "10px 0 0 0",
  },

  [`&.${classes.wrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "flex-start",
    background: colors.white,
    width: "100%",
    padding: "0 20px",
  },

  [`& .${classes.titleRow}`]: {
    ...flexRowMixIn,
    alignItems: "center",
    padding: "16px 0",
    position: "relative",
    width: "100%",
  },

  [`& .${classes.title}`]: {
    fontSize: "20px",
    color: colors.black,
    lineHeight: "37px",
    fontWeight: "500",
  },

  [`& .${classes.content}`]: {
    ...flexRowMixIn,
    justifyContent: "center",
    paddingBottom: "30px",
    flexWrap: "wrap",
  },

  [`& .${classes.section}`]: {
    ...flexCenterColumnMixIn,
    minWidth: "150px",
    padding: "0 10px",
  },

  [`& .${classes.sectionTitle}`]: {
    fontSize: "12px",
    color: colors.gray35,
    padding: "0 20px",
  },

  [`& .${classes.sectionText}`]: {
    fontSize: "20px",
    color: colors.charcoal,
    fontWeight: "500",
  },

  [`& .${classes.closeIcon}`]: {
    userSelect: "none",
    color: "#595959",
    cursor: "pointer",
    position: "absolute",
    right: "0",
    zIndex: "1",
    padding: "10px",
    display: "flex",
    "@media print": {
      display: "none",
    },
    [theme.breakpoints.up("xxl")]: {
      right: `${xxlPadding}`,
    },
  },

  [`& .${classes.icon}`]: {
    fontSize: "24px",
  },

  [`& .${classes.hidden}`]: {
    display: "none",
  },

  [`& .${classes.tabContentWrapper}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "flex-start",
    background: colors.whiteSmoke,
    width: "100%",
    padding: "15px",
  },

  [`& .${classes.tabContainer}`]: {
    ...flexColumnMixIn,
    alignItems: "center",
    justifyContent: "center",
    background: colors.white,
    width: "100%",
    padding: "10px 0 0 0",
    borderRadius: "4px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.11)",
  },
}));

const usePenInfoStyles = makeStyles((theme) => ({
  penField: {
    "&:first-of-type": {
      flexBasis: (props) => `${props.isLongName ? "66%" : "33%"}`,
      overflow: "hidden",
      [theme.breakpoints.up("xxl")]: {
        //If the ternary is removed, the earlier flexBasis declaration takes precedence over the media query
        flexBasis: (props) => `${props.isLongName ? "auto" : "auto"}`,
      },
    },
    flexBasis: "33%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("xxl")]: {
      marginRight: 16,
      flexBasis: "auto",
      "&:last-of-type": {
        marginRight: 0,
      },
    },
  },
}));

const OpsPenSummary = ({ penDetails, selectPenId }) => {
  const { flags } = useActiveModules();
  const { t } = useTranslation();

  return (
    <Root className={classes.wrapper}>
      <div className={classes.titleRow}>
        <Typography
          style={{ width: "100%" }}
          variant="h5"
          width="100%"
          align={flags.cowCountStockingDensity ? "left" : "center"}
        >
          {penDetails.label}
        </Typography>
        <div
          onClick={() => selectPenId({ selectedPenId: null })}
          aria-label="back"
          className={classes.closeIcon}
        >
          <CloseIcon className={classes.icon} />
        </div>
      </div>

      {flags.cowCountStockingDensity ? (
        <PenInfo
          pen={penDetails}
          shouldWrap={true}
          usePenInfoStyles={usePenInfoStyles}
        />
      ) : (
        <div className={classes.content}>
          <div
            className={classNames(classes.section, {
              [classes.hidden]: !penDetails.group,
            })}
            data-cy="Ops Pen Summary Cow Group"
          >
            <div
              className={classes.sectionTitle}
              data-cy="Ops Pen Summary Cow Group Title"
            >
              {t("cow-group")}
            </div>
            <div
              className={classes.sectionText}
              data-cy="Ops Pen Summary Cow Group Text"
            >
              {penDetails.group}
            </div>
          </div>
        </div>
      )}
    </Root>
  );
};

export default OpsPenSummary;

OpsPenSummary.propTypes = {
  penDetails: PropTypes.object.isRequired,
  selectPenId: PropTypes.func.isRequired,
};
