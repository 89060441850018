import { useEffect } from "react";
import dayjs from "dayjs";
import { useAccountDetails } from "../../App/useAccountDetails";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

const useDates = (dates) => {
  const {
    info: {
      farm: { timezone },
    },
  } = useAccountDetails();

  useEffect(() => {
    dayjs.tz.setDefault(timezone);
  }, [timezone]);

  if (Array.isArray(dates)) {
    return dates.map(dayjs);
  }
  return dayjs(dates);
};

export default useDates;
