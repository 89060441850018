import axios from "axios";

const { API_URL } = window._env_;

export async function getReportDashboardList({ queryKey }) {
  const [, params] = queryKey;
  const response = await axios.get(`${API_URL}/private/quicksight/dashboards`, {
    params,
  });

  return response.data;
}

export async function getReportDashboardById({ queryKey }) {
  const [, id] = queryKey;
  const response = await axios.get(
    `${API_URL}/private/quicksight/dashboards/${id}`
  );

  return response.data;
}
