import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../CommonComponents/UI/LoadingSpinner";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { useActiveModules } from "../../../App/useActiveModules";
import Switch from "../../../CommonComponents/UI/Switch";
import DeleteModal from "../../../CommonComponents/UI/DeleteModal";
import useUserMangement from "../../utils/useUserManagement";
import useEditableRow from "../../utils/useEditableRow";
import { colors } from "../../../CommonComponents/UI/theme";

const PREFIX = "UserRow";

const classes = {
  button: `${PREFIX}-button`,
  styledLabel: `${PREFIX}-styledLabel`,
  content: `${PREFIX}-content`,
  contentExpanded: `${PREFIX}-contentExpanded`,
  styledIcon: `${PREFIX}-styledIcon`,
  adminSwitchSection: `${PREFIX}-adminSwitchSection`,
  mobileHeader: `${PREFIX}-mobileHeader`,
  mobileTitle: `${PREFIX}-mobileTitle`,
  inputWrapper: `${PREFIX}-inputWrapper`,
  inputExpanded: `${PREFIX}-inputExpanded`,
  input: `${PREFIX}-input`,
  labelPlacementTop: `${PREFIX}-labelPlacementTop`,
  background: `${PREFIX}-background`,
  toolSectionIcons: `${PREFIX}-toolSectionIcons`,
  styledText: `${PREFIX}-styledText`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
  title: `${PREFIX}-title`,
  information: `${PREFIX}-information`,
  strong: `${PREFIX}-strong`,
};

const StyledUserRow = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    color: "#d1815a",
    borderColor: "#d1815a",
    fontSize: 16,
    cursor: "pointer",
    userSelect: "none",
    border: "2px solid",
    height: 40,
    maxWidth: 144,
    textAlign: "center",
    lineHeight: 36,
    alignSelf: "center",
    width: 144,
    [theme.breakpoints.up("md")]: {
      width: "auto",
      marginBottom: 0,
      flex: "1 0 60px",
    },
  },

  [`& .${classes.styledLabel}`]: {
    height: 20,
    fontSize: 16,
    color: "#888888",
    marginBottom: 8,
    textTransform: "capitalize",
  },

  [`& .${classes.content}`]: {
    display: "none",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media screen and (min-width: 960px)": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },

  [`& .${classes.contentExpanded}`]: {
    display: "flex",
  },

  [`& .${classes.styledIcon}`]: {
    height: 40,
    width: 40,
    padding: 8,
    color: "#bfbfbf",
    fontSize: 24,
    cursor: "pointer",
    userSelect: "none",
  },

  [`& .${classes.adminSwitchSection}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  [`& .${classes.mobileHeader}`]: {
    height: 55,
    padding: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "& button": {
      marginRight: -12,
    },
  },

  [`& .${classes.mobileTitle}`]: {
    fontSize: 20,
    color: "#595959",
    width: 146,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  [`& .${classes.inputWrapper}`]: {
    textAlign: "left",
    paddingRight: 15,
    [theme.breakpoints.up("md")]: {
      flex: "1 0 200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  [`& .${classes.inputExpanded}`]: {
    height: 65,
    marginTop: 5,
  },

  [`& .${classes.input}`]: {
    width: "100%",
  },

  [`& .${classes.labelPlacementTop}`]: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  [`& .${classes.background}`]: {
    display: "inline-block",
    background: "white",
    padding: "10px 20px",
    width: "100%",
    border: "1px solid #e9e9e9",

    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },

  [`& .${classes.toolSectionIcons}`]: {
    display: "flex",
    alignItems: "center",
    color: colors.gray,
    width: 96,
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },

  [`& .${classes.styledText}`]: {
    height: 40,
    lineHeight: "40px",
    fontSize: 20,
    color: "#595959",
  },

  [`& .${classes.title}`]: {
    fontSize: 27,
    margin: 0,
    color: "#595959",
  },

  [`& .${classes.information}`]: {
    fontSize: 16,
    textAlign: "center",
  },

  [`& .${classes.strong}`]: {
    color: "#595959",
    whiteSpace: "nowrap",
  },
}));

const UserRow = ({
  onDelete,
  onUpdate,
  farmName,
  isCurrentUser,
  isLoading,
  ...initialState
}) => {
  const { t } = useTranslation();
  const { id, name } = initialState;
  const { flags } = useActiveModules();

  const [
    userState,
    reset,
    setInputValue,
    validateName,
    validateEmail,
    setAdminSwitch,
    setSubscriptions,
  ] = useUserMangement(initialState);

  const {
    isEditMode,
    isModalOpen,
    toggleEditMode,
    toggleModalOpen,
  } = useEditableRow();

  const deleteUser = () => onDelete({ id });

  const cancelEditMode = () => {
    reset();
    toggleEditMode();
  };

  const updateUser = () => {
    const { name, email, isAdmin, subscriptions } = userState;
    onUpdate(
      {
        id,
        name: name.value,
        email: email.value,
        is_admin: isAdmin.value,
        subscriptions,
      },
      {
        onSuccess: toggleEditMode,
      }
    );
  };

  const contentClass = isEditMode
    ? `${classes.content} ${classes.contentExpanded}`
    : classes.content;

  const inputClass = isEditMode
    ? `${classes.input} ${classes.inputExpanded}`
    : classes.input;

  const renderSaveIcon = () => (
    <>
      {isLoading ? (
        <LoadingSpinner className={classes.loadingSpinner} size={30} />
      ) : (
        <IconButton onClick={updateUser} size="large">
          <CheckIcon data-cy="save-icon" />
        </IconButton>
      )}
    </>
  );

  return (
    <StyledUserRow>
      <div className={classes.background} data-cy="acccounts-user-row">
        <div className={classes.mobileHeader}>
          <div className={classes.mobileTitle}>{name}</div>
          {!isEditMode ? (
            <IconButton onClick={toggleEditMode} size="large">
              <CreateIcon />
            </IconButton>
          ) : null}
        </div>
        <div className={contentClass}>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.inputWrapper}
          >
            {isEditMode ? (
              <TextField
                variant="outlined"
                name="name"
                size="small"
                label={t("name")}
                value={userState.name.value}
                helperText={userState.name.errorMessage}
                error={!!userState.name.errorMessage}
                onChange={setInputValue}
                onBlur={validateName}
                className={inputClass}
              />
            ) : (
              <span className={classes.styledText}>{userState.name.value}</span>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.inputWrapper}
          >
            {isEditMode ? (
              <TextField
                variant="outlined"
                name="email"
                size="small"
                label={t("email")}
                value={userState.email.value}
                onChange={setInputValue}
                onBlur={validateEmail}
                helperText={userState.email.errorMessage}
                error={!!userState.email.errorMessage}
                className={inputClass}
              />
            ) : (
              <span className={classes.styledText}>
                {userState.email.value}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="space-between"
          >
            <FormControlLabel
              labelPlacement="top"
              classes={{ label: classes.labelPlacementTop }}
              control={
                <Switch
                  checked={userState.isAdmin.value}
                  name="toggle-user-admin"
                  label={t("Admin")}
                  onChange={setAdminSwitch}
                  disabled={!isEditMode}
                />
              }
              label="Admin"
            />
            {
              Object.keys(userState.subscriptions).map((key) => (
                <FormControlLabel
                  labelPlacement="top"
                  key={key}
                  classes={{
                    root: classes.formControl,
                    label: classes.labelPlacementTop,
                  }}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      disabled={!isEditMode}
                      name={key}
                      id={key}
                      color="primary"
                      checked={userState.subscriptions[key]}
                      onChange={setSubscriptions}
                    />
                  }
                  label={t(`${key}-email`)}
                />
              ))
            }
            <div className={classes.toolSectionIcons}>
              {!isEditMode && !isCurrentUser ? (
                <IconButton onClick={toggleModalOpen} size="large">
                  <DeleteIcon data-cy="delete-icon" />
                </IconButton>
              ) : null}
              {!isEditMode && !isCurrentUser ? (
                <IconButton onClick={toggleEditMode} size="large">
                  <CreateIcon data-cy="edit-icon" />
                </IconButton>
              ) : null}
              {isEditMode ? renderSaveIcon() : null}
              {isEditMode ? (
                <IconButton onClick={cancelEditMode} size="large">
                  <CloseIcon data-cy="cancel-icon" />
                </IconButton>
              ) : null}
            </div>
          </Grid>
        </div>
      </div>
      <DeleteModal
        onConfirmDelete={deleteUser}
        onCancel={toggleModalOpen}
        isOpen={isModalOpen}
      >
        <h4 className={classes.title}>Remove User</h4>
        <p className={classes.information}>
          Are you sure you want to remove <br />
          <strong className={classes.strong}>{name}</strong> from{" "}
          <strong className={classes.strong}>{farmName}</strong>?
        </p>
      </DeleteModal>
    </StyledUserRow>
  );
};

export default UserRow;
