import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAccountDetails } from "../../../App/useAccountDetails";
import useDatesForPen from "../../../CommonComponents/utils/useDatesForPen";
import Datepicker from "../../../CommonComponents/UI/Datepicker/Datepicker.jsx";
import LCTile from "../../../CommonComponents/UI/LineChartTile";
import { getPenBehaviorBreakdown } from "../../services/pen";
import dayjs from "dayjs";
import FeedbunkIcon from "../../../CommonComponents/Icons/Feedbunk";
import {
  minutesToHours,
  hoursMinFormatter,
} from "../../../CommonComponents/utils/time";

const PREFIX = "Feedbunk";

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  kpiSection: `${PREFIX}-kpiSection`,
  datePickerSection: `${PREFIX}-datePickerSection`,
  chartSection: `${PREFIX}-chartSection`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },

  [`& .${classes.grid}`]: {
    height: "100%",
  },

  [`& .${classes.kpiSection}`]: {
    minHeight: 232,
  },

  [`& .${classes.datePickerSection}`]: {
    height: 42,
    marginBottom: 16,
    maxHeight: 42,
  },

  [`& .${classes.chartSection}`]: {
    position: "relative",
    height: 688,
    "@media print": {
      height: 650,
    },
  },
}));

const formatForChart = ({ value }) =>
  minutesToHours(value, (hours) => `${hours}h`);

const formatForToolip = ({ value }) => minutesToHours(value, hoursMinFormatter);

const Feedbunk = () => {
  const { t } = useTranslation();
  const { info } = useAccountDetails();
  const { id } = useParams();
  const initialRange = 30;
  const excludeToday = true;
  const [dates, setDates] = useDatesForPen(
    info,
    id,
    initialRange,
    excludeToday
  );

  const queryStartDate = dayjs(dates.start).format("YYYY-MM-DD");
  const queryEndDate = dayjs(dates.end).format("YYYY-MM-DD");

  return (
    <Root className={classes.root}>
      <Grid
        container
        spacing={0}
        className={classes.grid}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item className={classes.datePickerSection}>
          <Datepicker
            startDate={dates.start}
            endDate={dates.end}
            minimumDate={dates.minimum}
            maximumDate={dates.maximum}
            maxRange={90}
            hook="feedbunk"
            onApplyPressed={(startDate, endDate) => {
              setDates({ start: startDate, end: endDate });
            }}
          />
        </Grid>
        <Grid xs={12} item className={classes.chartSection}>
          <LCTile
            title={t("feedbunk-time")}
            subtitle={t("avg-duration-feedbunk-message")}
            icon={FeedbunkIcon}
            config={{
              "7drollingAverage": {
                lineDashType: "dash",
                markerSize: 1,
                legendMarkerType: "square",
                legendMarkerColor: "white",
              },
              tooltipFormatter: formatForToolip,
              axisY: {
                labelFormatter: formatForChart,
                title: t("duration-capitalised"),
                interval: 60,
              },
            }}
            query={{
              key: "feedbunk",
              params: {
                penId: id,
                type: "",
                startDate: queryStartDate,
                endDate: queryEndDate,
              },
              service: getPenBehaviorBreakdown,
              validation: () => true,
            }}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export default Feedbunk;
