import { useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Toast from "./Toast";
import { toBlob } from "html-to-image";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const PREFIX = "FileCopy";

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.icon}`]: {
    position: "absolute",
    top: 0,
    right: 40,
    fontSize: 22,
    color: "rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("md")]: {
      right: 0,
    },
    "@media print": {
      display: "none",
    },
  },
}));

const FileCopy = ({ domNode, fileCopyClass }) => {
  const [toast, setToast] = useState({ show: false });
  const closeToast = () => setToast({ show: false });

  const copy = useCallback(
    async (e) => {
      if (domNode === null) {
        return;
      }

      let clipboardItem;
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      // safari
      if (isSafari) {
        clipboardItem = new window.ClipboardItem({
          "image/png": new Promise(async (resolve) => {
            resolve(toBlob(domNode, { cacheBust: true }));
          }),
        });
      } else {
        // chrome
        const blob = await toBlob(domNode, { cacheBust: true });
        clipboardItem = new window.ClipboardItem({ "image/png": blob });
      }

      navigator.clipboard.write([clipboardItem]).then(
        () =>
          setToast({
            show: true,
            type: "success",
            message: "Chart successfully copied to your clipboard",
          }),
        (err) => {
          console.log(err);

          setToast({
            show: true,
            type: "error",
            message: "We had a problem, try again",
          });
        }
      );
    },
    [domNode]
  );

  // window.ClipboardItem not available on Firefox
  if (!window.ClipboardItem) {
    return null;
  }

  return (
    <>
      <StyledIconButton
        aria-label="copy chart"
        onClick={copy}
        edge="start"
        className={fileCopyClass || classes.icon}
        data-cy="copy-chart"
        title="Copy chart"
        size="large"
      >
        <FileCopyIcon color="inherit" fontSize="inherit" />
      </StyledIconButton>
      <Toast toast={toast} closeToast={closeToast} />
    </>
  );
};

FileCopy.propTypes = {
  domNode: PropTypes.instanceOf(Element),
};

FileCopy.defaultProps = {
  domNode: null,
};

export default FileCopy;
