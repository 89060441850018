import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForwardSharp";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { getPensProductivitySummary } from "../../services/pen";
import { getHerdWelfareTrends } from "../../services/trends";
import Header from "../../../CommonComponents/UI/Header";
import GaugeChart from "../../../CommonComponents/UI/GaugeChart";
import LineChart from "./LineChart";
import theme from "../../../CommonComponents/UI/theme";
import GroupFilter from "../../../CommonComponents/UI/GroupFilter";
import PrintButton from "../../../CommonComponents/UI/PrintButton";
import TimePeriodToggle from "../../../CommonComponents/UI/TimePeriodToggle";
import useMediaQuery from "../../../CommonComponents/utils/useMediaQuery";
import { colors } from "../../../CommonComponents/UI/theme";
import kpiInfo from "../../utils/kpiInfo";
import Pens from "./Pens";
import CCIcon from "../../../CommonComponents/Icons/CowComfort";

const PREFIX = "HerdWelfare";

const classes = {
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  printButton: `${PREFIX}-printButton`,
  groupContainer: `${PREFIX}-groupContainer`,
  chartSection: `${PREFIX}-chartSection`,
  gaugeSection: `${PREFIX}-gaugeSection`,
  trendWrapper: `${PREFIX}-trendWrapper`,
  checkboxSection: `${PREFIX}-checkboxSection`,
  arrow: `${PREFIX}-arrow`,
  label: `${PREFIX}-label`,
  text: `${PREFIX}-text`,
  caption: `${PREFIX}-caption`,
  chartTitle: `${PREFIX}-chartTitle`,
  chartIcon: `${PREFIX}-chartIcon`,
  paper: `${PREFIX}-paper`,
};

const Root = styled("section")(() => ({
  padding: "24px 48px",
  flexGrow: 1,
  backgroundColor: "#fff",
  minHeight: "100%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "16px 10px 10px 10px",
  },
  "@media print": {
    padding: 8,
  },

  [`& .${classes.title}`]: {
    margin: "12px 0 6px 0",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },

  [`& .${classes.toolbar}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    alignSelf: "flex-end",
    [theme.breakpoints.up("md")]: {
      width: 600,
      height: 48,
      justifyContent: "flex-end",
      "& > div:first-of-type": {
        margin: "0 14px",
      },
      "& > div:nth-of-type(3)": {
        marginRight: 60,
      },
    },
    "@media print": {
      marginTop: 0,
      paddingTop: 0,
      width: "100%",
      justifyContent: "flex-end",

      "& > div:first-of-type": {
        margin: "0 14px",
      },
    },
  },

  [`& .${classes.printButton}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    color: "rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },

  [`& .${classes.chartSection}`]: {
    minHeight: 300,
    "@media print": {
      paddingLeft: 8,
    },
    "@media print and (max-width: 40em)": {
      zoom: "84%",
    },
  },

  [`& .${classes.gaugeSection}`]: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      marginTop: 20,
      height: 285,
    },
    "@media print": {
      height: 300,
      paddingLeft: 0,
    },
  },

  [`& .${classes.trendWrapper}`]: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0 10px 6px",
    width: "50%",
    [theme.breakpoints.up("md")]: {
      padding: "0 40px 0 20px",
      width: 288,
      "&:first-of-type": {
        paddingLeft: 0,
      },
    },
  },

  [`& .${classes.checkboxSection}`]: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      marginTop: 0,
    },
  },

  [`& .${classes.arrow}`]: {
    verticalAlign: "middle",
    fontSize: 20,
    marginRight: 10,
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
    },
  },

  [`& .${classes.label}`]: {
    color: colors.labelGray,
    fontSize: 14,
  },
  [`& .${classes.text}`]: {
    fontSize: 16,
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
      marginLeft: 10,
    },
  },
  [`& .${classes.caption}`]: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  [`& .${classes.chartTitle}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    flexDirection: "row",
    marginBottom: 15,
    "@media print": {
      marginTop: 20,
    },
  },
  [`& .${classes.paper}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    "@media print": {
      boxShadow: "none",
      overflow: "visible",
    },
  },
  [`& .${classes.chartIcon}`]: {
    height: 40,
    width: 40,
    marginRight: 8,
  },
}));

const timePeriods = {
  last7Days: "last7Days",
  last30Days: "last30Days",
};

const allGroups = "All Groups";

const HerdWelfare = () => {
  const { t } = useTranslation();
  const [selectedPeriod, setSelectedPeriod] = useState(timePeriods.last30Days);
  const [selectedGroup, setSelectedGroup] = useState(allGroups);
  let isLargeScreen = useMediaQuery(
    `(min-width: ${theme.breakpoints.values.md}px)`
  );

  const { data: pensData } = useQuery(
    ["herdWelfarePens", { period: selectedPeriod }],
    getPensProductivitySummary
  );

  const { data: trendsData } = useQuery(
    ["herdWelfareTrends", { period: selectedPeriod }],
    getHerdWelfareTrends
  );

  const groups = [
    ...new Set(pensData?.pens.map(({ group }) => group).filter(Boolean)),
    allGroups,
  ];

  const selectGroup = (event) => setSelectedGroup(event.target.value);

  const changeTimePeriod = (value) => setSelectedPeriod(timePeriods[value]);

  const trends =
    selectedGroup === allGroups
      ? trendsData.summaries.farm
      : trendsData.summaries.groups.find(
          (summary) => summary.group === selectedGroup
        )?.summary;

  const filteredData =
    selectedGroup === allGroups
      ? pensData?.pens
      : pensData?.pens.filter((pen) => pen.group === selectedGroup);

  const cowComfortData = trends.find((item) => item.kpi === "cowComfortIndex");

  return (
    <Root>
      <Header title={t("herd-welfare")} />
      <div className={classes.toolbar}>
        <Typography className={classes.caption} variant="caption">
          {pensData.dates}
        </Typography>
        <TimePeriodToggle
          changeTimePeriod={changeTimePeriod}
          selectedPeriod={selectedPeriod}
        />
        <GroupFilter
          selectGroup={selectGroup}
          selectedGroup={selectedGroup}
          groups={groups}
        />
        <PrintButton />
      </div>
      <section className={classes.checkboxSection}>
        {trends.map(({ difference, kpi, increase, value }) => {
          const direction = increase ? "up" : "down";
          const textColor = kpiInfo[kpi][direction];
          return (
            <div key={kpi} className={classes.trendWrapper}>
              <div className={classes.label}>
                {t(kpiInfo[kpi].translationKey)}
              </div>

              <Typography variant="h4" data-cy={`${kpi}-value`}>
                {increase !== null && (
                  <ArrowForward
                    className={classes.arrow}
                    style={{
                      color: textColor,
                      transform: increase ? "rotate(-45deg)" : "rotate(45deg)",
                    }}
                  />
                )}
                {value}
                {increase !== null && (
                  <span
                    className={classes.text}
                    style={{
                      color: textColor,
                    }}
                  >
                    {difference}
                  </span>
                )}
              </Typography>
            </div>
          );
        })}
      </section>
      {isLargeScreen && (
        <Grid item container spacing={3} className={classes.chartSection}>
          <Grid item xs={8} className={classes.chartSection}>
            <LineChart
              selectedGroup={selectedGroup}
              selectedPeriod={selectedPeriod}
            />
          </Grid>
          <Grid item xs={4} className={classes.gaugeSection}>
            <Paper elevation={0} className={classes.paper}>
              <div className={classes.chartTitle} data-cy="gauge-chart-title">
                <CCIcon color="primary" className={classes.chartIcon} />
                <Typography variant="h4">{t("cow-comfort")}</Typography>
              </div>
              <GaugeChart data={cowComfortData} />
            </Paper>
          </Grid>
        </Grid>
      )}
      <Pens data={filteredData} />
    </Root>
  );
};

export default HerdWelfare;
