import React from "react";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Chip from "@mui/material/Chip";
import Tooltip from "../../../CommonComponents/UI/Tooltip";
import { colors } from "../theme";

const PREFIX = "PenInfo";

const classes = {
  appbarRoot: `${PREFIX}-appbarRoot`,
  toolbar: `${PREFIX}-toolbar`,
  placeholder: `${PREFIX}-placeholder`,
  expanded: `${PREFIX}-expanded`,
  toolbarInner: `${PREFIX}-toolbarInner`,
  titleSection: `${PREFIX}-titleSection`,
  pageTitle: `${PREFIX}-pageTitle`,
  chip: `${PREFIX}-chip`,
  renderTools: `${PREFIX}-renderTools`,
  arrow: `${PREFIX}-arrow`,
  arrowActive: `${PREFIX}-arrowActive`,
  arrowIcon: `${PREFIX}-arrowIcon`,
  penInfo: `${PREFIX}-penInfo`,
  penField: `${PREFIX}-penField`,
};

const Root = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "shouldWrap",
})(({ shouldWrap, theme }) => ({
  [`& .${classes.chip}`]: {
    backgroundColor: colors.white,
    height: 20,
    alignSelf: "center",
    border: `1px solid ${colors.teal}`,
    borderRadius: 5,
  },

  [`&.${classes.penInfo}`]: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      flexWrap: `${shouldWrap ? "wrap" : "nowrap"}`,
      "@media print": {
        flexWrap: "nowrap",
      },
    },
  },
}));

const PenInfo = ({ pen, usePenInfoStyles, shouldWrap }) => {
  const { t } = useTranslation();
  const isLongName = pen.group?.length > 10;
  const penFieldClasses = usePenInfoStyles({ isLongName });

  const penFields = {
    group: {
      label: (
        <Typography variant="caption">{t("group-capitalised")}</Typography>
      ),
      color: colors.whiteSmoke,
    },
    headlocks: {
      label: <Typography variant="caption">{t("headlocks")}</Typography>,
      color: colors.whiteSmoke,
    },
    bedCapacity: {
      label: <Typography variant="caption">{t("beds")}</Typography>,
      color: colors.whiteSmoke,
    },
    cowCount: {
      label: (
        <Typography style={{ display: "inline-flex" }} variant="caption">
          {t("cow-count")}
        </Typography>
      ),
      color: colors.white,
      tooltipMsg: t("based-on-maximum-number-of-observed-cows-yesterday"),
    },
    stockingDensity: {
      label: <Typography variant="caption">{t("stocking-density")}</Typography>,
      color: colors.white,
      unit: "%",
    },
  };

  return (
    <Root className={classes.penInfo} shouldWrap={shouldWrap}>
      {Object.entries(penFields).map(([key, field]) => {
        const unit = field.unit || "";
        const label = pen[key] ? `${pen[key]}${unit}` : "-";
        return (
          <div
            key={key}
            className={classNames(classes.penField, penFieldClasses.penField)}
            data-cy={`pen-field-${key}`}
          >
            {field.label}
            <br />
            <Chip
              data-cy={`pen-field-${key}-value`}
              label={label}
              style={{ backgroundColor: field.color }}
              className={classes.chip}
            />
            {field.tooltipMsg && (
              <Tooltip content={<span>{field.tooltipMsg}</span>}>
                <HelpOutlineIcon
                  style={{ verticalAlign: "middle", marginLeft: 4 }}
                  htmlColor={colors.green}
                  fontSize="small"
                />
              </Tooltip>
            )}
          </div>
        );
      })}
    </Root>
  );
};

export default PenInfo;

PenInfo.propTypes = {
  pen: PropTypes.object.isRequired,
  shouldWrap: PropTypes.bool,
};

PenInfo.defaultProps = {
  shouldWrap: false,
};
