import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { flexColumnMixIn } from "../../../../CommonComponents/utils/Operations/cssMixins";
import { colors } from "../../../../CommonComponents/UI/theme";
import OpsTabs from "../../../../CommonComponents/UI/Operations/OpsTabs";
import OpsPenSummary from "../../../../CommonComponents/UI/Operations/OpsPenSummary";
import { useMilkingOperationsContext } from "../utils/MilkingOperationsContext";
import { usePenDetailsContext } from "../utils/PenDetailsContext";
import OpsTabbedContent from "../../../../CommonComponents/UI/Operations/OpsTabbedContent";
import MoBarChart from "./MoBarChart";
import usePenDetails from "../../../../CommonComponents/utils/usePenDetails";

const Root = styled("div")(() => ({
  ...flexColumnMixIn,
  alignItems: "center",
  justifyContent: "flex-start",
  background: colors.white,
  width: "100%",
  padding: "10px 0 0 0",
  visibility: "visible",
  transition: "visibility .2s ease",
}));

const penDetailsTabs = [
  {
    label: "Start Time",
    dataCyAtt: "MO Start Time Tab",
    translationKey: "start-time",
  },
  {
    label: "Milking Duration",
    dataCyAtt: "MO Milking Duration Tab",
    translationKey: "milking-duration",
  },
];

const MoPenDetails = () => {
  const { selectPenId, selectedPen } = useMilkingOperationsContext();
  const penDetails = usePenDetails(selectedPen.selectedPenId);
  const { difference, duration } = usePenDetailsContext();

  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const milkingCharts = [<MoBarChart data={duration} name="duration" />];

  const startCharts = [<MoBarChart data={difference} name="difference" />];

  return (
    <Root
      data-cy={`milking-operations-pen-details-${selectedPen.selectedPenId}`}
    >
      <OpsPenSummary penDetails={penDetails} selectPenId={selectPenId} />
      <OpsTabs tabs={penDetailsTabs} onChange={onTabChange} value={tabValue} />

      {tabValue === 0 && penDetails && (
        <OpsTabbedContent charts={startCharts} />
      )}

      {tabValue === 1 && penDetails && (
        <OpsTabbedContent charts={milkingCharts} />
      )}
    </Root>
  );
};

export default MoPenDetails;
