import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAccountDetails } from "../../../App/useAccountDetails";
import useDatesForPen from "../../../CommonComponents/utils/useDatesForPen";
import Datepicker from "../../../CommonComponents/UI/Datepicker/Datepicker.jsx";
import LCTile from "../../../CommonComponents/UI/LineChartTile";
import { getPenBehaviorBreakdown } from "../../services/pen";
import dayjs from "dayjs";
import CCIcon from "../../../CommonComponents/Icons/CowComfort";

const PREFIX = "CowComfort";

const classes = {
  root: `${PREFIX}-root`,
  grid: `${PREFIX}-grid`,
  kpiSection: `${PREFIX}-kpiSection`,
  datePickerSection: `${PREFIX}-datePickerSection`,
  chartSection: `${PREFIX}-chartSection`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },

  [`& .${classes.grid}`]: {
    height: "100%",
  },

  [`& .${classes.kpiSection}`]: {
    minHeight: 232,
  },

  [`& .${classes.datePickerSection}`]: {
    height: 42,
    marginBottom: 16,
    maxHeight: 42,
  },

  [`& .${classes.chartSection}`]: {
    position: "relative",
    height: 688,
    "@media print": {
      height: 650,
    },
  },
}));

const CowComfort = () => {
  const { t } = useTranslation();
  const { info } = useAccountDetails();
  const { id } = useParams();
  const initialRange = 30;
  const excludeToday = true;
  const [dates, setDates] = useDatesForPen(
    info,
    id,
    initialRange,
    excludeToday
  );

  const queryStartDate = dayjs(dates.start).format("YYYY-MM-DD");
  const queryEndDate = dayjs(dates.end).format("YYYY-MM-DD");

  return (
    <Root className={classes.root}>
      <Grid
        container
        spacing={0}
        className={classes.grid}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item className={classes.datePickerSection}>
          <Datepicker
            startDate={dates.start}
            endDate={dates.end}
            minimumDate={dates.minimum}
            maximumDate={dates.maximum}
            maxRange={90}
            hook="cow-comfort-index"
            onApplyPressed={(startDate, endDate) => {
              setDates({ start: startDate, end: endDate });
            }}
          />
        </Grid>
        <Grid xs={12} item className={classes.chartSection}>
          <LCTile
            title={t("cow-comfort")}
            subtitle={t("percent-cows-stall-lying-down-message")}
            icon={CCIcon}
            config={{
              "7drollingAverage": {
                lineDashType: "dash",
                markerSize: 1,
                legendMarkerType: "square",
                legendMarkerColor: "white",
              },
              tooltipFomatter: ({ value }) => `${value}%`,
              axisY: {
                labelFormatter: ({ value }) => `${value}%`,
                title: "% of cows",
                maximum: 100,
                interval: 10,
              },
            }}
            query={{
              key: "cow-comfort-index",
              params: {
                penId: id,
                type: "summary",
                startDate: queryStartDate,
                endDate: queryEndDate,
              },
              service: getPenBehaviorBreakdown,
              validation: () => true,
            }}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export default CowComfort;
